import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import { MemberShipPlanResponse, ProductInterest, ProductInterestResponse, RewardCategoryResponse, RewardCoupan, RewardMembershipResponse, RewardOrder, RewardOrderResponse, RewardPartnerResponse, RewardPointsResponse, RewardProduct, RewardsDiscoverResponse } from "../models/reward";
class RewardService {

  getBanners(): AxiosPromise<RewardsDiscoverResponse> {
    return apiClient.get(
      '/v1/rewards/discover'
    );
  }

  getMembershipsList(): AxiosPromise<RewardMembershipResponse> {
    return apiClient.get(
      `v1/configuration/api/v1/db/data/v1/rewards/memberships`
    );
  }

  getAllCategories(): AxiosPromise<RewardCategoryResponse> {
    return apiClient.get(
      '/v1/rewards/categories?view=card'
    );
  }

  getAllPartners(): AxiosPromise<RewardPartnerResponse> {
    return apiClient.get(
      '/v1/rewards/partners'
    );
  }

  getAllProducts(): AxiosPromise<ApiResponse<Array<RewardProduct>>> {
    return apiClient.get(
      '/v1/rewards/products'
    );
  }

  getCoupans(): AxiosPromise<ApiResponse<Array<RewardCoupan>>> {
    return apiClient.get(
      '/v1/rewards/coupons'
    );
  }


  getPlans(): AxiosPromise<ApiResponse<MemberShipPlanResponse>> {
    return apiClient.get(
      '/v1/rewards/plans'
    );
  }

  getRewardPoints():AxiosPromise<ApiResponse<RewardPointsResponse>>{
    return apiClient.get(
      '/v1/rewards/points/total'
    );
  }

  getCalculatePrice(): AxiosPromise<ApiResponse<Array<RewardCoupan>>> {
    return apiClient.post(
      '/v1/rewards/coupons'
    );
  }

  // getPartnerDetail(
  //   partnerId: number
  // ): AxiosPromise<RewardPartner> {
  //   return apiClient.get(
  //     `/v1/configuration/api/v1/db/data/v1/${Constants.expoConfig.extra.nocoProjectIdReward}/products/${partnerId}`
  //   );
  // }

  // placeOrderCard(
  //   data: RewardOrder
  // ): AxiosPromise<ApiResponse<RewardOrderResponse>> {
  //   return apiClient.post(
  //     `/v1/rewards/orders/card`, data
  //   );
  // }

  placeOrder(
    data: RewardOrder
  ): AxiosPromise<ApiResponse<RewardOrderResponse>> {
    return apiClient.post(
      `/v1/rewards/orders`, data
    );
  }

  getPaymentSummary(
    data: RewardOrder
  ): AxiosPromise<ApiResponse<RewardOrderResponse>> {
    return apiClient.post(
      `/v1/rewards/orders/card`, data
    );
  }

  productInterest(
    data: ProductInterest
  ): AxiosPromise<ApiResponse<ProductInterestResponse>> {
    return apiClient.post(
      `/v1/rewards/interests`, data
    );
  }
  
}
const rewardService = new RewardService();
export default rewardService;
