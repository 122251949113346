import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionOnClub, AddMembersRequest, Club, ClubResponse, CreateClubRequest, DeleteClub, GetClubDetails, GetClubRequest, UpdateClubRequest,} from "../models/club";
import clubService from "../services/club.service";
import { toast } from "react-toastify";
import { logout } from "./loginSlice";


export interface ClubReduxState {
  clubs: ClubResponse;
  participatedClubs: ClubResponse;
  clubDetail: null | any; 
  pendingClubs: ClubResponse;
  hostedClubs: ClubResponse;
  clubMembers: Array<any>;
}
const initialState:ClubReduxState={
  clubs:{clubs: [],metadata:{}} as ClubResponse,
  participatedClubs:{} as ClubResponse,
  clubDetail:null,
  pendingClubs:{} as ClubResponse,
  hostedClubs:{} as ClubResponse,
  clubMembers:[]
}
export const clubSlice = createSlice({
    name: "clubs",
    initialState,
    reducers: {
      addParticipatingClubs:(state,action)=>{
        console.log(action.payload)
        console.log(action.payload.data.data.events,"Payload")
        state.participatedClubs=action.payload.data.data.events
      },
      addHoistingClubs:(state,action)=>{
        state.hostedClubs=action.payload.data.data.events
      },
      addPendingClubs:(state,action)=>{
        state.pendingClubs=action.payload.data.data.events
      }
     
    },
    extraReducers: (builder) => {
    builder
        .addCase(getAllClubsApi.fulfilled,(state,action)=>{
              console.log("CLUB DETAIL",action.payload.data.data)
               state.clubs=(action.payload.data.data)
         })

         .addCase(getClubDetailsApi.fulfilled, (state, action) => {
        //  console.log("ADDD",action.payload.data?.data)
          state.clubDetail = action.payload.data?.data;
        })
        .addCase(createClubApi.fulfilled,()=>{
            toast("Club created Successfully")
        })
        .addCase(getClubMemberApi.fulfilled,(state, action) => {
          state.clubMembers = action.payload.data.data;
        })
        .addCase(getMyClubsapi.fulfilled,(state,action)=>{
          if(action.meta.arg.clubType==="pending"){
            state.pendingClubs=action.payload.data?.data
          }
          if(action.meta.arg.clubType==="hosting"){
            state.hostedClubs=action.payload.data?.data
          }
          if(action.meta.arg.clubType==="participating"){
            state.participatedClubs=action.payload.data?.data
          }
        }) 
        .addCase(removeClubMembersApi.fulfilled,(state,action)=>{
           state.clubMembers=state.clubMembers.filter(
            (member)=>member.id !== action.meta.arg.memberId)
        })  
        .addCase( actionsOnClubMembers.fulfilled, (state, action) => {
          let members = state.clubMembers;
          if (action.meta.arg.action == 'accept') {
            members.map((m) => {
              if(action.meta.arg.memberId==m.id){
                m.status = 'ACCEPTED'
              }
            })
          }
          if (action.meta.arg.action == 'reject') {
            members.map((m) => {
              if(action.meta.arg.memberId==m.id){
                m.status = 'REJECT'
              }
            })
          }
          
          state.clubMembers = members;

        }
        
      )
      .addCase(getNextClubApi.fulfilled,(state,action)=>{
        // console.log(action.payload,"CLLUB  NEXT APIIII")
        // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
        state.clubs.clubs=[...state.clubs.clubs,...action.payload.data.data.clubs]
        state.clubs.metadata=action.payload.data.data.metadata

      })
      .addCase(getNextHostedClubApi.fulfilled,(state,action)=>{
         // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
        state.hostedClubs.clubs=[...state.hostedClubs.clubs,...action.payload.data.data.clubs]
        state.hostedClubs.metadata=action.payload.data.data.metadata
      })
      .addCase(getNextPendingClubApi.fulfilled,(state,action)=>{
        // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
       state.pendingClubs.clubs=[...state.pendingClubs.clubs,...action.payload.data.data.clubs]
       state.pendingClubs.metadata=action.payload.data.data.metadata
     })
     .addCase(getNextParticipatedClubApi.fulfilled,(state,action)=>{
      // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
     state.participatedClubs.clubs=[...state.participatedClubs.clubs,...action.payload.data.data.clubs]
     state.participatedClubs.metadata=action.payload.data.data.metadata
      })

      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });
      
    }
  });

  export const getMyClubsapi= createAsyncThunk("club/getClubs",async(data:GetClubRequest,{getState,rejectWithValue})=>{
      try{
        const response =await clubService.getMyClubs(data);
        return response;
      }
      catch(error){
        return rejectWithValue(error.response?.data)
      }
  })

  export const getAllClubsApi = createAsyncThunk(
    "club/getAllClubsApi",
    async (data: null, { getState, rejectWithValue }) => {
      try {
        // const response = await eventService.getAllEvents(data);
        const response = await clubService.getAllClubs();
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getAIRecommendedClubsApi = createAsyncThunk(
    "club/getAIRecommendedClubsApi",
    async (data: GetClubDetails, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.getAIRecommendedClubs(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getClubDetailsApi = createAsyncThunk(
    "club/getClubDetails",
    async (data: GetClubDetails, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.getClubDetails(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getClubPostsApi= createAsyncThunk(
    "club/getClubPosts",
    async (data: GetClubDetails,{getState,rejectWithValue})=>{
      try {
        const response = await clubService.getClubPosts(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  )

  export const createClubApi = createAsyncThunk(
    "clubs/createClubApi",
    async (data: CreateClubRequest, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.createClub(data);
        // console.log(response, "respoinse")
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const updateClubApi = createAsyncThunk(
    "event/updateClub",
    async (data: UpdateClubRequest, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.updateClub(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getClubMemberApi = createAsyncThunk(
    "jobs/getClubMemberApi",
    async (_data: GetClubDetails, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.getClubMembers(_data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const requestMembersApi = createAsyncThunk(
    "clubs/requestMembersApi",
    async (data: AddMembersRequest, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.requestToClub(data);
        console.log(response, "respoinse")
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const actionsOnClubMembers = createAsyncThunk(
    "event/actionsOnMembers",
    async (data: ActionOnClub, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.actionsOnMembers(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


  export const inviteClubMembersApi = createAsyncThunk(
    "clubs/inviteMembersApi",
    async (data: AddMembersRequest, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.inviteToMember(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const actionOnClubInviteApi = createAsyncThunk(
    "event/actionOnEventInviteApi",
    async (data: ActionOnClub, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.actionOnClubInvite(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );


  export const removeClubMembersApi = createAsyncThunk(
    "club/removeMembersApi",
    async (data: ActionOnClub, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.removeMember(data);
        console.log(response);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const deleteClubApi = createAsyncThunk(
    "club/deleteClubApi",
    async (data: DeleteClub, { getState, rejectWithValue }) => {
      try {
        const response = await clubService.deleteClub(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getNextClubApi = createAsyncThunk(
    "club/getNextClubApi",
    async (a: any, { getState, rejectWithValue, dispatch }) => {
      try {
        const state: any = getState();
        const response = await clubService.getClubByUrl(
          state.clubs.clubs.metadata.nextResultURL
        );
        //dispatch(updateClubs(response.data.data.clubs));
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getNextPendingClubApi = createAsyncThunk(
    "club/getNextPendingClubApi",
    async (a: any, { getState, rejectWithValue, dispatch }) => {
      try {
        const state: any = getState();
        const response = await clubService.getClubByUrl(
          state.clubs.pendingClubs.metadata.nextResultURL
        );
        //dispatch(updateClubs(response.data.data.clubs));
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getNextHostedClubApi = createAsyncThunk(
    "club/getNextHostedClubApi",
    async (a: any, { getState, rejectWithValue, dispatch }) => {
      try {
        const state: any = getState();
        // console.log(state,"HOSTED NETA")
        const response = await clubService.getClubByUrl(
          state.clubs.hostedClubs.metadata.nextResultURL
        );
        //dispatch(updateClubs(response.data.data.clubs));
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );
  export const getNextParticipatedClubApi = createAsyncThunk(
    "club/getNextParticipatedClubApi",
    async (a: any, { getState, rejectWithValue, dispatch }) => {
      try {
        const state: any = getState();
        const response = await clubService.getClubByUrl(
          state.clubs.participatedClubs.metadata.nextResultURL
        );
        //dispatch(updateClubs(response.data.data.clubs));
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export const getNextMembersApi = createAsyncThunk(
    "club/getNextMembersApi",
    async (data: DeleteClub, { getState, rejectWithValue, dispatch }) => {
      try {
        const state: any = getState();
        const response = await clubService.getNextMembers(
          state.clubs.memberMetadata.nextResultURL
        );
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );

  export default clubSlice.reducer;
  