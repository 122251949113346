import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import { SaveUserLog, UserLogs } from "../models/logger";

class LoggerService {
  postLogs(userLogs: SaveUserLog): AxiosPromise<ApiResponse<Array<UserLogs>>> {
    return apiClient.post(`/v1/event-logger`, userLogs);
  }
}

const loggerService = new LoggerService();
export default loggerService;