import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../config/hooks';

const RewardsHeader = () => {
    const dispatch = useAppDispatch();
    const[isOpen,setIsOpen]=useState(false)
    // const {popup} = useAppSelector((state)=>state.job)
    const { pathname } = useLocation();
    const activeJob = pathname.split("/");
  //  console.log(popup,"Popup")
    return (
      <div className={`py-2 dark:bg-lxgray-700 px-2 mx-2 relative`}>
        {/* <div className="">
        {isOpen && <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><CreateJob setIsOpen={setIsOpen} isOpen={isOpen} /></CommonPopup>}
        </div> */}
        <div className="flex items-center justify-between ">
          <div className="flex w-1/3">
            <div className="pr-5">
              <Link to={`/tab/rewards`}>
                <h1
                  className={`text-lg  w-fit active:scale-95 text-nowrap  ${
                    activeJob[activeJob.length - 1] === "rewards"
                      ? "text-golden font-bold"
                      : "text-lxgray-400"
                  } my-auto cursor-pointer`}        
                >
                  Discover
                </h1>
              </Link>
            </div>
            <div className="px-5">
              <Link to={`/tab/rewards/memberships`}>
                <h1
                  className={`text-lg w-fit active:scale-95 text-nowrap ${
                    activeJob[activeJob.length - 1] === "memberships"
                      ? "text-golden font-bold"
                      : "text-lxgray-400"
                  } my-auto cursor-pointer`}  
                >
                  Memberships
                </h1>
              </Link>
            </div>
            
          </div>
         
        </div>
      </div>
    )
}

export default RewardsHeader
