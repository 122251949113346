import React from "react";
import Tickmark from "../../../assets/icons/Tickmark";

const ApplicationStatus = ({subStatus}) => {
  console.log("subStatus",subStatus)
  return (
    <div className="px-2 py-4 border-b border-lxgray-400 ">
      <h1 className=" font-semibold">Application Status</h1>
      <div className=" mt-6  relative text-white">
        <div className="pl-1 sm:pr-28">
          <div className="h-0.5 bg-lxgray-400 relative">
              <div className={`h-0.5 bg-lxgreen-100 rounded-lg ${(subStatus==="APPLICATION_CONTACT_VIEWED")?"w-6/6": "w-3/6"} `}></div>
          </div>
        <div className="flex justify-between ">
         
          <div>
            <div className="h-4 w-4 bg-lxgreen-100 rounded-full relative bottom-2 "></div>
            <div className="relative bottom-5 left-0.5"><Tickmark/></div>
          </div>
          
          <div>
            <div className={`h-4 w-4 border-2 border-lxgreen-100  rounded-full relative bottom-2  ${(subStatus==="APPLICATION_VIEWED"||subStatus==="APPLICATION_CONTACT_VIEWED")?"bg-lxgreen-100": "bg-lxgray-500"}`}></div>
            {subStatus==="APPLICATION_VIEWED" && <div className="relative bottom-5 left-0.5"><Tickmark/></div>}
          </div>

          {/* <div>
            <div className="h-4 w-4 border-2  bg-lxgray-500 border-lxgreen-100  rounded-full relative bottom-2 "></div>
            <div className="relative bottom-5 left-0.5"><Tickmark/></div>
          </div> */}

          <div>
            <div className={`h-4 w-4 border-2  border-lxgreen-100 rounded-full relative bottom-2 ${(subStatus==="APPLICATION_CONTACT_VIEWED")?"bg-lxgreen-100": "bg-lxgray-500"}`}></div>
            {subStatus==="APPLICATION_CONTACT_VIEWED" && <div className="relative bottom-5 left-0.5"><Tickmark/></div>}
          </div>

        </div>
        </div>
       
        <div className="flex justify-evenly sm:justify-between sm:pr-3">
          <div>
            <h1 className="text-xs sm:text-sm ">Application Sent</h1>
            {/* <h1 className="text-xs text-lxgray-400">26 dec</h1> */}
          </div>
          <div>
            <h1 className="text-xs sm:text-sm  ">Application Viewed</h1>
            {/* <h1 className="text-xs text-lxgray-400">26 dec</h1> */}
            
          </div>
          {/* <div>
            <h1 className="text-sm ">Contact Viewed</h1>
            <h1 className="text-xs text-lxgray-400">26 dec</h1>

          </div> */}
          <div>
            <h1 className="text-xs sm:text-sm ">Awaiting Recruiter</h1>
            {/* <h1 className="text-xs text-lxgray-400">26 dec</h1> */}

          </div>
        </div>
      </div>
    </div>
  );
};



export default ApplicationStatus;
