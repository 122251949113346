import React from "react";

const OfflineVideo = () => {
  return (
    <div>
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.957 2.84211L10.4307 5.36842V3.15789C10.4307 2.81053 10.1465 2.52632 9.79914 2.52632H5.89598L12.957 9.58737V2.84211ZM1.76229 0L0.957031 0.805263L2.67808 2.52632H2.22019C1.87282 2.52632 1.58861 2.81053 1.58861 3.15789V9.47368C1.58861 9.82105 1.87282 10.1053 2.22019 10.1053H9.79914C9.92861 10.1053 10.0423 10.0579 10.1433 9.98842L12.1549 12L12.957 11.1947L1.76229 0Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default OfflineVideo;
