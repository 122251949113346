import React, { useEffect } from 'react'
import AddMultiple from '../Clubs and Events/BothCommon/AddMultiple'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getHiringJobs } from '../../slices/jobSlice'
import NoRecentJobs from "../../assets/images/NoRecentJobs.png";
import JobCard from '../job/components/JobCard';

const CompanyJobs = ({companyId}:{companyId:string}) => {
  const dispatch=useAppDispatch()
  const {createdJobList} = useAppSelector((state) => state.job);

  const openJobs = createdJobList?.ids.length>0? Object.values(createdJobList?.entities):[];
  
  useEffect(()=>{
    dispatch(getHiringJobs({authorId:companyId,authorType:"COMPANY"}))
  },[])
 
  console.log(openJobs,"openJobs")
  return (
    <div className='min-h-svh bg-lxgray-700'>
      <div className='py-4 pl-4'>
         <AddMultiple placeholder={"Create a new Job"} type={"createJob"}/>
      </div>
      { openJobs.length===0 ? (
        <div className="flex justify-center mt-2">
          <img src={NoRecentJobs} alt="No Jobs Available" className="" />
        </div>
      ): 
      <JobCard title="Company" active={false} jobs={openJobs} />
      }
    </div>
  )
}

export default CompanyJobs
