// import React from 'react'

// const PopupJob = ({children}) => {

//   return (
    
//     <div className=' bg-red-500 absolute z-20 left-1/4 right-1/4 opacity-100 h-screen overflow-y-scroll'>
//       {children}
//     </div>
   
//   )
// }

// export default PopupJob

import React, { useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { popupShowApply, popupShowCreate } from '../../../slices/jobSlice';


interface PopupJobProps {
  children: React.ReactNode;
}

const PopupJob: React.FC<PopupJobProps> = ({ children}) => {

    const dispatch=useAppDispatch()
    const {popup} = useAppSelector((state)=>state.job)
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
       if(popup.createJob){

         dispatch(popupShowCreate())
       }
       if(popup.applyJob){

         dispatch(popupShowApply())
       }
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {(popup.createJob || popup.applyJob) && (
        <div className='fixed inset-0 bg-black opacity-60 z-10'></div>
      )}
      <div ref={popupRef} className={`absolute  sm:w-3/5 h-[85dvh] overflow-y-scroll no-scrollbar z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  ${popup ? 'opacity-100' : 'opacity-0'} ${popup.applyJob?"":""}`}>
        {children}
      </div>
    </div>
  );
};

export default PopupJob;

