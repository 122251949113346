import React, { useEffect, useState } from "react";
import JobCardBox from "./JobCardBox";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { RootState } from "../../../config/store";
import { GetAllAppliedJObs, Job } from "../../../models/job";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import Filter from "../../../assets/icons/Filter";
import FilterJob from "./FilterJob";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFilteredJobApi, getNextHiringJobApi, getNextJobApi, getNextSaveJobApi } from "../../../slices/jobSlice";

interface Props {
  title: string;
  active: boolean;
  jobs: Array<Job>| Array<GetAllAppliedJObs>;
}
const JobCard = ({ title, active, jobs }: Props) => {
  const {metadataJob,metadataSavedJob,metadataHiringJob}=useAppSelector((state)=>state.job)
  const [metadata,setMetadata]=useState(null)
  const {userId}=useAppSelector((state)=>state.login.session)
  const dispatch=useAppDispatch()
  const { jobTypes, workplaceTypes } = useAppSelector(
    (state: RootState) => state.appConfig
  );
  const [viewAll,setViewAll]=useState<boolean>(false)
  const [inputValue, setInputValue] = useState("");
  const [debouncedInput, setDebouncedInput] = useState(inputValue);

  useEffect(()=>{
    if(title=="Recommended"){
      setMetadata(metadataJob)
    }
    if(title=="Saved"){
      setMetadata(metadataSavedJob)
    }
    if(title=="Open"||title=="InActive"||title=="Closed"){
      setMetadata(metadataHiringJob)
   
    }
  },[metadataJob,metadataSavedJob])


  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedInput(inputValue);
    }, 800); // 300ms debounce delay

    return () => clearTimeout(timer); // Cleanup the timer on component unmount or input change
  }, [inputValue]);

  useEffect(() => {
    if(debouncedInput.length==0){
      dispatch(getFilteredJobApi({notAuthorId: userId, status: "OPEN" }))
      return
    }

    if (debouncedInput) {
      // Dispatch search API with the input value
      dispatch(getFilteredJobApi({s:debouncedInput})); // Make sure you have this API in your slice
    }
    
  }, [debouncedInput]);



const handleView=()=>{
  setViewAll(!viewAll)
}

const getMoreDataHandler=()=>{
  console.log("GETTET MOTRE")
  if(title=="Recommended"){
    console.log("Recommmended",metadataJob?.nextResultURL)
    dispatch(getNextJobApi())  
  }
  if(title=="Saved"){
    dispatch(getNextSaveJobApi())  
  }
  if(title=="Open"||title=="InActive"||title=="Closed"){
    console.log("HELLOE  OPEN")
    dispatch(getNextHiringJobApi())
  }
}

// console.log("JObCArd",jobs)
// console.log("Title",title,viewAll)
  return (
    <div className={` flex flex-col  mx-2  `}>
     
      <div className="text-white w-full flex justify-between items-center px-2 ">
        <div className="flex  justify-between w-full mb-2">
          <h1 className="py-2 text-lxgray-100 font-semibold">{title} Jobs</h1>
            {title=="Recommended" && <input
            type="text"
            className="ml-6 py-1 pl-4 rounded-full bg-lxgray-800 border border-lxgray-300 text-white w-1/3 mr-16"
            value={inputValue}
            placeholder="Search by title, location, industry etc ..."
            onChange={(e) => setInputValue(e.target.value)} // Update input state on change
        />}
        </div> 
       
        {(active && jobs?.length>6) ? (
          <p className="text-base underline text-lxgray-200 cursor-pointer pr-3 text-nowrap" onClick={()=>handleView()}>{viewAll?"View less":"View all"}</p>
        ) : ""}
      </div>

      <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={jobs?.length-4}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
          <div className={`grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 justify-stretch  px-2 gap-1.5 ${(title==="Closed")?"opacity-50":""} `}>
            {((title=="Recommended"?true:viewAll)?jobs:jobs.slice(0,6)).map((ele, i) => {
              return (
                <Link key={ele.id} to={`/tab/jobs/description/${title}/${ele.id}`}>
                  <JobCardBox data={ele} title={title}/>
                </Link>
              );
            })}
          </div>
      </InfiniteScroll>
    </div>
  );
};

export default JobCard;
