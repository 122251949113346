import React from 'react'
import { VscLoading } from "react-icons/vsc";
const Loading = () => {
  return (
    <div className='h-screen flex items-center  gap-4 bg-lxgray-800 justify-center'>
        <h1 className='text-white text-2xl '>Loading... </h1>
        <div className='animate-spin text-white text-4xl'> 
            <VscLoading  />
        </div>
    </div>
  )
}

export default Loading
