import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../../config/hooks";

import "../../../App.css";

import PlusIcon from "../../../assets/icons/PlusIcon";
import EditIcon from "../../../assets/icons/EditIcon";

import ExperienceCard from "./ExperienceCard";
import EducationCard from "./EducationCard";
import LanguageCard from "./LanguageCard";
import PublicationsCard from "./PublicationsCard";
import AwardCertificationCard from "./Award&CertificationCard";

import {
  CreateEducationResponse,
  CreatePublication,
} from "../../../models/user";

import { openProfileModal } from "../../../slices/profileSlice";
import { baseUrl, contentUrl } from "../../../utils/contant";
import PlayButtonIcon from "../../../assets/icons/PlayButtonIcon";

import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";
import { getOtherUserDetailApi } from "../../../slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import AdditionalInfoCard from "./AdditionalInfoCard";

interface StartEndDate {
  month: string;
  year: string;
}

interface UserExperienceDetails {
  location: string;
  locationId: string;
  locationType: string;
  employmentType: string;
  title: string;
  description: string;
  startDate: StartEndDate;
  endDate: StartEndDate;
  isCurrentRole: boolean;
  skills: string[] | null;
  media: string[] | null;
}

interface WorkExperience {
  id: string;
  userId: string;
  organization: string;
  organizationId: string;
  userExperienceDetails: UserExperienceDetails[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

const AboutMe = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const session = useAppSelector((state) => state.login.session);
  const { pathname } = useLocation();
  const userData = useAppSelector((state) => state.user);
  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(userData.userDetails.about);

  const [otherUserDetails, setOtherUserDetails] = useState(null);

  console.log("profileId :>> ", profileId);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data);
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  const {
    userExperience,
    userEducation,
    userLanguages,
    userPublications,
    userDetails,
    userAwards,
    userAdditionalDetail
  } = userData;

  const {
    industry,
    skills,
    interest,
    about,
    profileVideo,
    firstName,
    lastName,
  } = userDetails;

  const inputfieldsArray = useMemo(() => {
    return [
      {
        id: 1,
        name: "profileSummery",
        label: "Profile Summary",
        edit: about,
        add: !about,
      },
      {
        id: 2,
        name: "videoIntroduction",
        label: "Video Introduction",
        edit: profileVideo,
        add: !profileVideo,
        defaultValue: null,
      },
      {
        id: 3,
        name: "experience",
        label: "Experience",
        edit: userExperience && userExperience.length > 0,
        add: true,
        linkto: `${pathname}/editexperience-details`,
      },
      {
        id: 4,
        name: "education",
        label: "Education",
        edit: userEducation ? (userEducation.length > 0 ? true : false) : false,
        add: true,
        linkto: `${pathname}/editeducation-details`,
      },
      {
        id: 5,
        name: "skills",
        label: "Skills",
        edit: skills ? (skills.length > 0 ? true : false) : false,
        add: true,
        defaultValue: skills,
      },
      {
        id: 6,
        name: "interest",
        label: "Interest",
        edit: interest && interest.length > 0,
        add: !(interest && interest.length > 0),
        defaultValue: interest,
      },
      {
        id: 7,
        name: "industry",
        label: "Industry",
        edit: industry && industry.length > 0,
        add: !(industry && industry.length > 0),
        defaultValue: industry,
      },
      // {
      //   id: 8,
      //   name: "affliations",
      //   label: "Affliations",
      //   edit: false,
      //   add: true,
      //   defaultValue: null,
      // },
      {
        id: 9,
        name: "certification",
        label: "Awards & Certification",
        edit: userAwards ? (userAwards.length > 0 ? true : false) : false,
        add: true,
        linkto: `${pathname}/editaward-details`,
      },
      {
        id: 10,
        name: "publication",
        label: "Publication",
        edit: userPublications && userPublications.length > 0,
        add: true,
        linkto: `${pathname}/editpublication-details`,
      },
      {
        id: 11,
        name: "language",
        label: "Language",
        edit: userLanguages && userLanguages.length > 0,
        add: !(userLanguages && userLanguages.length > 0),
        defaultValue: userLanguages,
      },
      {
        id: 12,
        name: "additionalInformation",
        label: "Additional Information",
        edit:userAdditionalDetail,
        add: !userAdditionalDetail,
        defaultValue: null,
      },
    ];
  }, [
    about,
    userExperience,
    pathname,
    userEducation,
    skills,
    interest,
    industry,
    userAwards,
    userPublications,
    userLanguages,
    profileVideo,
  ]);

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
  };

  const playVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  console.log("otherUserDetails :>> ", otherUserDetails);
  console.log(
    "otherUserDetails?.userLanguages :>> ",
    otherUserDetails?.userLanguage
  );

  if (profileId) {
    return (
      otherUserDetails && (
        <div className="w-full">
          {inputfieldsArray &&
            inputfieldsArray.map((fieldData) => (
              <div
                key={fieldData.id}
                className={`flex flex-col gap-2 justify-between ${
                  fieldData.name === "experience"
                    ? ""
                    : fieldData.name === "skills" ||
                      fieldData.name === "publication" ||
                      fieldData.name === "language" ||
                      fieldData.name === "additionalInformation"
                    ? "min-h-[54px]"
                    : "min-h-[44px]"
                }
             ${
               fieldData.name !== "additionalInformation" &&
               "border-b-[3px] border-lxgray-600"
               //  fieldData.name !== "language" &&
               //  "border-b-[3px] border-lxgray-600"
             }
             mt-6`}
              >
                <div
                  className={`flex justify-between ${
                    fieldData.name === "experience" && "mb-3"
                  }`}
                >
                  <h1 className="text-lxgray-200 text-xl font-light">
                    {fieldData.label}
                  </h1>
                  {/* <div className="flex flex-row-reverse items-center gap-4 p-1">
                  {fieldData.add && !profileId && (
                    <button
                      className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95"
                      onClick={() => toggleModalHandler(fieldData.name, false)}
                    >
                      <PlusIcon color={"#8C8C8C"} />
                    </button>
                  )}
                  {fieldData.edit &&
                    !profileId &&
                    (fieldData.name === "experience" ||
                    fieldData.name === "certification" ||
                    fieldData.name === "publication" ||
                    fieldData.name === "education" ? (
                      <Link to={fieldData.linkto}>
                        <button className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95">
                          <EditIcon />
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95"
                        onClick={() => toggleModalHandler(fieldData.name, true)}
                      >
                        <EditIcon />
                      </button>
                    ))}
                </div> */}
                </div>

                {fieldData.name === "profileSummery" &&
                  otherUserDetails?.userDetails?.about && (
                    <div className="mb-3">
                      <div
                        ref={contentRef}
                        className={`w-[99.38%] text-lxgray-100 text-sm font-normal ${
                          showMore ? "line-clamp-none" : "line-clamp-3"
                        }`}
                      >
                        <p>{otherUserDetails?.userDetails?.about}</p>
                      </div>
                      {otherUserDetails?.userDetails?.about &&
                        isOverflowing && (
                          <div className="flex justify-end items-center">
                            <button
                              className="border-none text-golden text-base font-normal w-[100px] text-center"
                              onClick={textToggleHandler}
                            >
                              {showMore ? "View Less" : "View More"}
                            </button>
                          </div>
                        )}
                    </div>
                  )}

                {fieldData.name === "videoIntroduction" &&
                  otherUserDetails?.userDetails?.profileVideo && (
                    <div className="mb-2">
                      <div className="w-full h-full relative">
                        <video
                          ref={videoRef}
                          controls={isPlaying}
                          onEnded={handleVideoEnd}
                          onPause={handleVideoEnd}
                          className={`w-full h-80 mx-auto rounded-t-2xl object-cover`}
                          src={`${baseUrl}/${contentUrl}/${otherUserDetails?.userDetails?.profileVideo}?t=${session.token}`}
                        ></video>
                        {!isPlaying && (
                          <div
                            onClick={playVideo}
                            className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 opacity-80 cursor-pointer rounded-full"
                          >
                            <PlayButtonIcon />
                          </div>
                        )}
                      </div>
                      <div className="h-12 dark:bg-lxgray-600 rounded-b-2xl flex items-center px-5">
                        <h1 className="text-xl text-white font-light tracking-wide">{`Introduction of ${otherUserDetails?.userDetails?.firstName} ${otherUserDetails?.userDetails?.lastName}`}</h1>
                      </div>
                    </div>
                  )}

                {fieldData.name === "experience" && (
                  <div
                    className={`${
                      otherUserDetails?.userExperience &&
                      otherUserDetails?.userExperience.length <= 2 &&
                      "pb-2"
                    }`}
                  >
                    <div className="flex flex-col gap-4">
                      {otherUserDetails?.userExperience &&
                        otherUserDetails?.userExperience
                          .slice(0, 2)
                          .map(
                            (
                              useExperienceData: WorkExperience,
                              index: number
                            ) => (
                              <ExperienceCard
                                key={index}
                                editExpDetails={false}
                                useExperienceData={useExperienceData}
                              />
                            )
                          )}
                    </div>
                    {otherUserDetails?.userExperience &&
                      otherUserDetails?.userExperience.length > 2 && (
                        <Link
                          to={`/tab/profile/experience-details/${profileId}`}
                          className="flex justify-end"
                        >
                          <button className="px-2 text-lg text-lxgray-400 font-normal">
                            {`Show all ${otherUserDetails?.userExperience.length} experience`}
                          </button>
                        </Link>
                      )}
                  </div>
                )}

                {fieldData.name === "education" && (
                  <div
                    className={`${
                      otherUserDetails?.userEducation &&
                      otherUserDetails?.userEducation.length <= 2 &&
                      "pb-2"
                    }`}
                  >
                    <div className="mt-2 flex flex-col gap-4">
                      {otherUserDetails?.userEducation
                        .slice(0, 2)
                        .map(
                          (
                            educationData: CreateEducationResponse,
                            index: number
                          ) => (
                            <EducationCard
                              educationDetail={educationData}
                              key={index}
                              editEducation={false}
                            />
                          )
                        )}
                    </div>
                    {otherUserDetails?.userEducation &&
                      otherUserDetails?.userEducation.length > 2 && (
                        <Link
                          to={`/tab/profile/education-details/${profileId}`}
                          className="flex justify-end"
                        >
                          <button className="px-2 text-lg text-lxgray-400 font-normal">
                            {`Show all ${otherUserDetails?.userEducation.length} education details`}
                          </button>
                        </Link>
                      )}
                  </div>
                )}

                {fieldData.name === "language" &&
                  otherUserDetails?.userLanguage &&
                  otherUserDetails?.userLanguage.length >= 1 && (
                    <LanguageCard
                      userLanguages={otherUserDetails?.userLanguage}
                    />
                  )}

                {fieldData.name === "publication" && (
                  <div
                    className={`${
                      otherUserDetails?.userPublications &&
                      otherUserDetails?.userPublications.length <= 2 &&
                      "pb-2"
                    }`}
                  >
                    <div className="flex flex-col gap-4">
                      {otherUserDetails?.userPublications
                        .slice(0, 2)
                        .map(
                          (
                            publicationData: CreatePublication,
                            index: number
                          ) => {
                            return (
                              <PublicationsCard
                                key={index}
                                publicationData={publicationData}
                                editPublicationDetail={false}
                              />
                            );
                          }
                        )}
                    </div>
                    {otherUserDetails?.userPublications &&
                      otherUserDetails?.userPublications.length > 2 && (
                        <Link
                          to={`/tab/profile/publication-details/${profileId}`}
                          className="flex justify-end"
                        >
                          <button className="px-2 text-lg text-lxgray-400 font-normal">
                            {`Show all ${otherUserDetails?.userPublications.length} publication`}
                          </button>
                        </Link>
                      )}
                  </div>
                )}

                {fieldData.name === "industry" && (
                  <div className=" flex flex-wrap gap-4 my-4 w-[92%]">
                    {otherUserDetails?.userDetails.industry &&
                      otherUserDetails?.userDetails.industry.map(
                        (data: string, index: number) => (
                          <div
                            className="border w-fit px-2 h-[37px] flex items-center justify-center rounded-lg"
                            key={index}
                          >
                            <span className="text-white text-base tracking-wider">
                              {data}
                            </span>
                          </div>
                        )
                      )}
                  </div>
                )}

                {fieldData.name === "interest" && (
                  <div className=" flex flex-wrap gap-4 my-4 w-[92%]">
                    {otherUserDetails?.userDetails.interest &&
                      otherUserDetails?.userDetails.interest.map(
                        (data: string, index: number) => (
                          <div
                            className="border w-fit px-2 h-[37px] flex items-center justify-center rounded-lg"
                            key={index}
                          >
                            <span className="text-white text-base tracking-wider">
                              {data}
                            </span>
                          </div>
                        )
                      )}
                  </div>
                )}

                {fieldData.name === "certification" && (
                  <div
                    className={`${
                      otherUserDetails?.userAwards &&
                      otherUserDetails?.userAwards.length <= 2 &&
                      "pb-2"
                    }`}
                  >
                    <div className="flex flex-col gap-4">
                      {otherUserDetails?.userAwards &&
                        otherUserDetails?.userAwards
                          .slice(0, 2)
                          .map((awardData, index) => {
                            return (
                              <AwardCertificationCard
                                key={index}
                                awardData={awardData}
                                editAwdDetails={false}
                              />
                            );
                          })}
                    </div>
                    {otherUserDetails?.userAwards &&
                      otherUserDetails?.userAwards.length > 2 && (
                        <Link
                          to={`/tab/profile/award-details/${profileId}`}
                          className="flex justify-end"
                        >
                          <button className="px-2 text-lg text-lxgray-400 font-normal">
                            {`Show all ${otherUserDetails?.userAwards.length} awards and certifications`}
                          </button>
                        </Link>
                      )}
                  </div>
                )}
              </div>
            ))}
        </div>
      )
    );
  }

  return (
    <div className="w-full">
      {inputfieldsArray &&
        inputfieldsArray.map((fieldData) => (
          <div
            key={fieldData.id}
            className={`flex flex-col gap-2 justify-between ${
              fieldData.name === "experience"
                ? ""
                : fieldData.name === "skills" ||
                  fieldData.name === "publication" ||
                  fieldData.name === "language" ||
                  fieldData.name === "additionalInformation"
                ? "min-h-[54px]"
                : "min-h-[44px]"
            }
             ${
               fieldData.name !== "additionalInformation" &&
               "border-b-[3px] border-lxgray-600"
               //  fieldData.name !== "language" &&
               //  "border-b-[3px] border-lxgray-600"
             }
             mt-6`}
          >
            <div
              className={`flex justify-between ${
                fieldData.name === "experience" && "mb-3"
              }`}
            >
              <h1 className="text-lxgray-200 text-xl font-light">
                {fieldData.label}
              </h1>
              <div className="flex flex-row-reverse items-center gap-4 p-1">
                {fieldData.add && (
                  <button
                    className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95"
                    onClick={() => toggleModalHandler(fieldData.name, false)}
                  >
                    <PlusIcon color={"#8C8C8C"} />
                  </button>
                )}
                {fieldData.edit &&
                  (fieldData.name === "experience" ||
                  fieldData.name === "certification" ||
                  fieldData.name === "publication" ||
                  fieldData.name === "education" ? (
                    <Link to={fieldData.linkto}>
                      <button className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95">
                        <EditIcon />
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="flex items-center justify-center text-lxgray-200 text-base font-normal active:scale-95"
                      onClick={() => toggleModalHandler(fieldData.name, true)}
                    >
                      <EditIcon />
                    </button>
                  ))}
              </div>
            </div>

            {fieldData.name === "profileSummery" && (
              <div className="mb-3">
                <div
                  ref={contentRef}
                  className={`w-[99.38%] text-lxgray-100 text-sm font-normal ${
                    showMore ? "line-clamp-none" : "line-clamp-3"
                  }`}
                >
                  <p>{about}</p>
                </div>
                {about && isOverflowing && (
                  <div className="flex justify-end items-center">
                    <button
                      className="border-none text-golden text-base font-normal w-[100px] text-center"
                      onClick={textToggleHandler}
                    >
                      {showMore ? "View Less" : "View More"}
                    </button>
                  </div>
                )}
              </div>
            )}

            {fieldData.name === "videoIntroduction" && profileVideo && (
              <div className="mb-2">
                <div className="w-full h-full relative">
                  <video
                    ref={videoRef}
                    controls={isPlaying}
                    onEnded={handleVideoEnd}
                    onPause={handleVideoEnd}
                    className={`w-full h-80 mx-auto rounded-t-2xl object-cover`}
                    src={`${baseUrl}/${contentUrl}/${profileVideo}?t=${session.token}`}
                  ></video>
                  {!isPlaying && (
                    <div
                      onClick={playVideo}
                      className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 opacity-80 cursor-pointer rounded-full"
                    >
                      <PlayButtonIcon />
                    </div>
                  )}
                </div>
                <div className="h-12 dark:bg-lxgray-600 rounded-b-2xl flex items-center px-5">
                  <h1 className="text-xl text-white font-light tracking-wide">{`Introduction of ${firstName} ${lastName}`}</h1>
                </div>
              </div>
            )}

            {fieldData.name === "experience" && (
              <div
                className={`${
                  userExperience && userExperience.length <= 2 && "pb-2"
                }`}
              >
                <div className="flex flex-col gap-4">
                  {userExperience &&
                    userExperience
                      .slice(0, 2)
                      .map(
                        (useExperienceData: WorkExperience, index: number) => (
                          <ExperienceCard
                            key={index}
                            editExpDetails={false}
                            useExperienceData={useExperienceData}
                          />
                        )
                      )}
                </div>
                {userExperience && userExperience.length > 2 && (
                  <Link
                    to={`${pathname}/experience-details`}
                    className="flex justify-end"
                  >
                    <button className="px-2 text-lg text-lxgray-400 font-normal">
                      {`Show all ${userExperience.length} experience`}
                    </button>
                  </Link>
                )}
              </div>
            )}

            {fieldData.name === "education" && (
              <div
                className={`${
                  userEducation && userEducation.length <= 2 && "pb-2"
                }`}
              >
                <div className="mt-2 flex flex-col gap-4">
                  {userEducation
                    .slice(0, 2)
                    .map(
                      (
                        educationData: CreateEducationResponse,
                        index: number
                      ) => (
                        <EducationCard
                          educationDetail={educationData}
                          key={index}
                          editEducation={false}
                        />
                      )
                    )}
                </div>
                {userEducation && userEducation.length > 2 && (
                  <Link
                    to={`${pathname}/education-details`}
                    className="flex justify-end"
                  >
                    <button className="px-2 text-lg text-lxgray-400 font-normal">
                      {`Show all ${userEducation.length} education details`}
                    </button>
                  </Link>
                )}
              </div>
            )}

            {fieldData.name === "language" &&
              userLanguages &&
              userLanguages.length >= 1 && (
                <LanguageCard userLanguages={userLanguages} />
              )}

            {fieldData.name === "publication" && (
              <div
                className={`${
                  userPublications && userPublications.length <= 2 && "pb-2"
                }`}
              >
                <div className="flex flex-col gap-4">
                  {userPublications
                    .slice(0, 2)
                    .map(
                      (publicationData: CreatePublication, index: number) => {
                        return (
                          <PublicationsCard
                            key={index}
                            publicationData={publicationData}
                            editPublicationDetail={false}
                          />
                        );
                      }
                    )}
                </div>
                {userPublications && userPublications.length > 2 && (
                  <Link
                    to={`${pathname}/publication-details`}
                    className="flex justify-end"
                  >
                    <button className="px-2 text-lg text-lxgray-400 font-normal">
                      {`Show all ${userPublications.length} publication`}
                    </button>
                  </Link>
                )}
              </div>
            )}

            {fieldData.name === "industry" && (
              <div className=" flex flex-wrap gap-4 my-4 w-[92%]">
                {userDetails.industry &&
                  userDetails.industry.map((data: string, index: number) => (
                    <div
                      className="border w-fit px-2 h-[37px] flex items-center justify-center rounded-lg"
                      key={index}
                    >
                      <span className="text-white text-base tracking-wider">
                        {data}
                      </span>
                    </div>
                  ))}
              </div>
            )}

            {fieldData.name === "skills" && (
              <div className=" flex flex-wrap gap-4 my-4 w-[92%] ">
                {userDetails.skills &&
                  userDetails.skills.map((data: string, index: number) => (
                    <div
                      className="border w-fit px-2 h-[37px] flex items-center justify-center rounded-lg"
                      key={index}
                    >
                      <span className="text-white text-base tracking-wider">
                        {data}
                      </span>
                    </div>
                  ))}
              </div>
            )}

            {fieldData.name === "interest" && (
              <div className=" flex flex-wrap gap-4 my-4 w-[92%] ">
                {userDetails.interest &&
                  userDetails.interest.map((data: string, index: number) => (
                    <div
                      className="border w-fit px-2 h-[37px] flex items-center justify-center rounded-lg"
                      key={index}
                    >
                      <span className="text-white text-base tracking-wider">
                        {data}
                      </span>
                    </div>
                  ))}
              </div>
            )}

            {fieldData.name === "certification" && (
              <div
                className={`${userAwards && userAwards.length <= 2 && "pb-2"}`}
              >
                <div className="flex flex-col gap-4">
                  {userAwards &&
                    userAwards.slice(0, 2).map((awardData, index) => {
                      return (
                        <AwardCertificationCard
                          key={index}
                          awardData={awardData}
                          editAwdDetails={false}
                        />
                      );
                    })}
                </div>
                {userAwards && userAwards.length > 2 && (
                  <Link
                    to={`${pathname}/award-details`}
                    className="flex justify-end"
                  >
                    <button className="px-2 text-lg text-lxgray-400 font-normal">
                      {`Show all ${userAwards.length} awards and certifications`}
                    </button>
                  </Link>
                )}
              </div>
            )}


            {
              fieldData.name === "additionalInformation" && (
              userAdditionalDetail &&  
                (<div>
                  <AdditionalInfoCard data={userAdditionalDetail}/>
                </div>)           
              )
            }
          </div>
        ))}
    </div>
  );
};

export default AboutMe;
