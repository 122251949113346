import React from "react";

const StandardEmailIcon = ({ width = "30", height = "30" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#424242" />
      <path
        d="M7 10.4689V19.5389L11.535 15.0039L7 10.4689ZM7.90112 9.56006L13.6425 15.3015C14.3677 16.0266 15.6323 16.0266 16.3574 15.3015L22.0988 9.56006H7.90112Z"
        fill="#AA933E"
      />
      <path
        d="M17.2625 16.2066C16.659 16.8107 15.8552 17.1442 15.0001 17.1442C14.1451 17.1442 13.3412 16.8107 12.7377 16.2066L12.4401 15.909L7.90894 20.4402H22.0913L17.5601 15.909L17.2625 16.2066ZM18.4651 15.004L23.0001 19.5391V10.469L18.4651 15.004Z"
        fill="#AA933E"
      />
    </svg>
  );
};

export default StandardEmailIcon;
