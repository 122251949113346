import React, { useEffect, useState } from 'react'
import About from './JobStatus/About';
import Applied from './JobStatus/Applied';
import Shortlisted from './JobStatus/Shortlisted';
import Hold from './JobStatus/Hold';
import Rejected from './JobStatus/Rejected';
import { useAppDispatch } from '../../../config/hooks';
import { getApplicantsApi } from '../../../slices/jobSlice';
import { GetJobApplicationsRequest, Job } from '../../../models/job';


interface Props{
    jobDes: Job
}
const JobStatus:React.FC<Props> = ({jobDes}) => {
  const dispatch=useAppDispatch()
  
    const [activeTab, setActiveTab] = useState('About'); // Default tab is 'About'
   
    useEffect(()=>{
      fetchJob()
      setActiveTab('About')
    },[jobDes.id])

    const fetchJob=async()=>{
      try{
        const response=await dispatch(getApplicantsApi({jobId:jobDes.id}))
      }
      catch(err){
        console.log(err)
      }

    }

    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
  

    return (
      <div>
        <div className='flex  gap-5 py-1 flex-wrap bg-lxgray-400 text-lxgray-100 justify-start px-2'>
          <h1 className={activeTab === 'About' ? 'mr-8  text-golden font-semibold' : 'mr-8 cursor-pointer'} onClick={() => handleTabClick('About')}>About</h1>
          <h1 className={activeTab === 'Applied' ? 'mr-8  text-golden font-semibold' : 'mr-8 cursor-pointer'} onClick={() => handleTabClick('Applied')}>Applied</h1>
          <h1 className={activeTab === 'Shortlisted' ? 'mr-8  text-golden font-semibold' : 'mr-8 cursor-pointer'} onClick={() => handleTabClick('Shortlisted')}>Shortlisted</h1>
          <h1 className={activeTab === 'Hold' ? 'mr-8  text-golden font-semibold' : 'mr-8 cursor-pointer'} onClick={() => handleTabClick('Hold')}>Hold</h1>
          <h1 className={activeTab === 'Rejected' ? 'mr-8  text-golden font-semibold' : 'mr-8 cursor-pointer'} onClick={() => handleTabClick('Rejected')}>Rejected</h1>  
        </div>
        {activeTab === 'About' && <About jobDes={jobDes}/>}
        {activeTab === 'Applied' && <Applied />}
        {activeTab === 'Shortlisted' && <Shortlisted />}
        {activeTab === 'Hold' && <Hold />}
        {activeTab === 'Rejected' && <Rejected />}
      </div>
    );
}

export default JobStatus
