import React from 'react'
import SucessImage from "../../../assets/images/SuccessImage.jpg"
import { useAppSelector } from '../../../config/hooks'

const PaymentSuccessful = () => {
  const {order} =useAppSelector((state)=>state.rewards.placeOrder)
  return (
    <div className='flex flex-col  gap-2 items-center text-white p-'>
        <img src={SucessImage} alt="SuccessFul" className='w-25 h-25 bg-blend-multiply rounded'/>
        <h1 className='font-bold  text-lg'>Payment Successful</h1>
        <h4 className='text-xs text-lxgray-200'>Transaction ID : {order.paymentStatus.transactionId}</h4>
        <p className='text-sm  text-lxgray-200 text-center'>Thank you for your purchase! Your payment has been successfully processed. We're excited to have you as our valued customer.</p>
        <div className='flex gap-4'>
            <button className='px-8 py-2 rounded text-golden border border-golden'>Back</button>
            <button className='px-8 py-2 rounded bg-golden hover:bg-white hover:text-golden'>Download Receipt</button>
        </div>
    </div>
  )
}

export default PaymentSuccessful
