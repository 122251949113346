import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { addParticipatingEvents, getMyEventApi, getNextParticipatedEventApi } from '../../../slices/eventSlice'
import { Link } from 'react-router-dom'
import NoEvents from "../../../assets/images/NoEventsImage.png"
import CommonCard from '../BothCommon/CommonCard'
import InfiniteScroll from 'react-infinite-scroll-component'

const ParticipatingEvents = () => {
  const dispatch=useAppDispatch()
  const {events,metadata}= useAppSelector((state)=>state.events.participatingEvents)
  const participatingEvents=events
  useEffect(()=>{
    dispatch(getMyEventApi({status:"participating"}))
  },[])

  const getMoreDataHandler=()=>{ 
    dispatch(getNextParticipatedEventApi({}))   
  }

  return (
    <>
    {participatingEvents && participatingEvents.length>0 && 
      (
        <div>
          <h1 className='text-white text-lg py-2'>Upcoming Events</h1>
          <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={participatingEvents?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
          <div className='flex flex-wrap gap-2 justify-start'>
            {
              participatingEvents?.map((event)=> 
                <Link  key={event.id} to={ `/tab/events/${event.id}`}>
                <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
                </Link>
              )
            }
            </div>
          </InfiniteScroll>
      </div>)}

      {
       participatingEvents &&  participatingEvents.length===0 && 
        <div className='flex justify-center'>
          <img src={NoEvents} alt="NO EVENTS AVAILABLE" />
        </div>
      }
    </>
  )
}

export default ParticipatingEvents
