import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import JobHeader from "./JobHeader";
import JobCard from "./JobCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import FilterJob from "./FilterJob";
import Filter from "../../../assets/icons/Filter";
import { Job } from "../../../models/job";
import { getFilteredJobApi } from "../../../slices/jobSlice";
import NoJobs from "./JobStatus/NoJobs";
import PopupJob from "./PopupJob";
import CreateJob from "./CreateJob";
import { useEventLogger } from "../../../utils/hooks/useEventLogger";

const RecommendedJobsPage = () => {
  const dispatch=useAppDispatch()
  const {userId}=useAppSelector((state)=>state.login.session)
  const {filteredJobList} = useAppSelector((state) => state.job);
  // const recJobs = Object.values(recommendedJobList?.entities);
  const [viewFilter,setViewFilter]=useState<boolean>(false)
  
  const {logUserEvent,isLogging}=useEventLogger();
  
  // const [recJobs,setRecJobs]=useState<Job[]|null>(null)
  // const [filRecJobs,setFilRecJobs]=useState<Job[]|null>([])
  
  useEffect(()=>{
    dispatch(getFilteredJobApi({notAuthorId: userId, status: "OPEN" }))
    logUserEvent({id:"",event:"recommendedJobs",module:"jobSearchScreen",subModule:""})
  },[])
  const handleView=(e)=>{
    e.preventDefault()
    setViewFilter(!viewFilter)
  }
  const jobs = Object.values(filteredJobList?.entities);
  


  return (
    
      <div className={`dark:bg-black py-1 relative  `}>
          <div className="absolute right-1 top-2 text-white ">
            <div onClick={(e)=>handleView(e)} className={`hover:text-white ${viewFilter?" ":""} cursor-pointer mx-2`}><Filter/></div>
            
              {viewFilter && <div className=""><FilterJob setViewFilter={setViewFilter} viewFilter={viewFilter}/></div>} 
              
            </div> 
            <div className={`${viewFilter?"opacity-40 ":""}`}>
             <JobCard title="Recommended" active={false} jobs={jobs}/>          
            </div>  

          {jobs.length===0 && <NoJobs text={"After filtering through the job listings, no records have been found matching the specified criteria."}/>}
      </div>
    
  );
 
  
};

export default RecommendedJobsPage;
