import { useAppSelector } from "../../../config/hooks";
import IMG from "../../../assets/images/Blank_Placeholders.png";
import { baseUrl, contentUrl } from "../../../utils/contant";

const AttendeesImage = ({ zindex,picUrl ,memberName}) => {
  const { token } = useAppSelector((state) => state.login.session);
  console.log(picUrl,memberName)
  return (
  
    <div className={``}>
      {/* <img src={`${baseUrl}/files/${url}?t=${token}`} alt="Banner Image" className='object-cover h-6 w-6  rounded-lg'/> */}
      {picUrl.length>0?
      <img
        src={`${baseUrl}/${contentUrl}/${picUrl}?t=${token}`}
        alt="Banner Image"
        className={`object-cover top-0 h-10 w-10 absolute  -z-${zindex}  rounded-full`}
      />:
      <h1  className={` top-0 h-10 w-10 absolute bg-gray-500 text-center border text-2xl font-semibold  -z-${zindex}  rounded-full`}>{memberName[0]}</h1>
      // <img
      //   src={`${baseUrl}/${contentUrl}/${picUrl}?t=${token}`}
      //   alt="Banner Image"
      //   className={`object-cover top-0 h-10 w-10 absolute  -z-${zindex}  rounded-full`}
      // />
      }
    </div>
  );
};

export default AttendeesImage;
