import { openProfileModal } from "../../../slices/profileSlice";
import Delete_Icon from "../../../assets/icons/Delete_Icon";
import EditIcon from "../../../assets/icons/EditIcon";
import { useAppDispatch } from "../../../config/hooks";
import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";

function Text({ content }: { content: string }) {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  const words = content.split(" ");
  return (
    <p>
      {words.map((word, index) => {
        return word.match(urlRegex) ? (
          <span key={index}>
            <a
              className="text-golden hover:opacity-95 underline leading-[22px] text-[14px] my-2"
              href={word}
              target="_blank">
              {word}
            </a>{" "}
          </span>
        ) : (
          <span
            key={index}
            className="text-[14px] text-white leading-[22px] my-2">
            {word + " "}
          </span>
        );
      })}
    </p>
  );
}

const AwardCertificationCard = ({ awardData, editAwdDetails }) => {
  const dispatch = useAppDispatch();

  const { title, description, issuingDate, issuedBy, id } = awardData;

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: id,
    };
    dispatch(openProfileModal(modalTypes));
  };

  return (
    <div>
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h1 className="text-white text-[22px] font-bold leading-[22px]">
            {title}
          </h1>
          {editAwdDetails && (
            <div className="flex flex-row-reverse  gap-4 self-start items-center justify-center">
              <div
                className="cursor-pointer"
                onClick={() => toggleModalHandler("certification", true)}>
                <EditIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  toggleModalHandler("certification?=delete", true)
                }>
                <Delete_Icon />
              </div>
            </div>
          )}
        </div>
        <h2 className="text-[16px] text-golden font-semibold leading-[19.09px]">
          {issuedBy}
        </h2>
        <h3 className="text-[16px] font-normal text-white tracking-wider leading-[22px]">
          Issued on {issuingDate.month + " " + issuingDate.year}
        </h3>
      </div>
      <div>
        <p
          ref={contentRef}
          className={`text-[14px] text-white leading-[22px] my-2 ${
            showMore ? "line-clamp-none" : "line-clamp-3"
          }`}>
          {description}
        </p>
        {/* <Text content={description} /> */}
        {description && isOverflowing && (
          <div className="flex justify-end items-center">
            <button
              className="border-none text-golden text-base font-normal w-[100px] text-center"
              onClick={textToggleHandler}>
              {showMore ? "View Less" : "View More"}
            </button>
          </div>
        )}
      </div>
      {/* <p className="text-[#DADADA] text-[14px] leading-[22px] font-normal">
        Skills: Training · Leadership Development · Executive Development ·
        Leadership Development Coachi
      </p> */}
    </div>
  );
};

export default AwardCertificationCard;
