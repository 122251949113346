import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  OrderSummary,
  ProductInterest,
  RewardCategory,
  RewardCoupan,
  RewardDiscover,
  RewardItemResponse,
  RewardMembership,
  RewardOrder,
  RewardOrderResponse,
  RewardPartner, 
  RewardPointsResponse,
  RewardProduct,
} from "../models/reward";
import rewardService from "../services/reward.service";
import { logout } from "./loginSlice";

export interface RewardReduxState {
  categories: Array<RewardCategory>;
  // category: RewardCategory;
  products: Array<RewardProduct>;
  partnerList:Array<RewardPartner>;
  banners: Array<RewardDiscover>;
  // reward: RewardPointResponse;
  // rewardItem: RewardItemResponse;
  rewardPoints: RewardPointsResponse;
  membershipList:Array<RewardMembership>;
  coupans:Array<RewardCoupan>;
  paymentSummary:OrderSummary;
  membershipPlans:any
  placeOrder:RewardOrderResponse
}
const initialState: RewardReduxState = {
  categories: [],
  // category: null,
  products: [],
  banners: [],
  // reward: null,
  // rewardItem: null,
  // total: 0,
  rewardPoints:null,
  partnerList: [],
  membershipList: [],
  coupans: [],
  paymentSummary: null,
  membershipPlans:[],
  placeOrder:null
};

export const getRewardCategoriesApi = createAsyncThunk(
  "reward/getRewardCategoriesApi",
  async (a, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getAllCategories();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPartnersApi = createAsyncThunk(
  "reward/getPartnersApi",
  async (a, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getAllPartners();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const getPartnersDetailApi = createAsyncThunk(
//   "reward/getPartnersDetailApi",
//   async (id: number, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getPartnerDetail(id);
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const getBannersApi = createAsyncThunk(
  "reward/getBannersApi",
  async (a, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getBanners();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMembershipsListApi = createAsyncThunk(
  "reward/getMembershipsListApi",
  async (a, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getMembershipsList();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMembershipPlansApi = createAsyncThunk(
  "reward/getMembershipPlansApi",
  async (a, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getPlans();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);



export const getProductListApi = createAsyncThunk(
  "reward/getProductListApi",
  async (id: null, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getAllProducts();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCoupansApi = createAsyncThunk(
  "reward/getCoupensApi",
  async (id: null, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getCoupans();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getRewardsPointsApi = createAsyncThunk(
  "reward/getRewardsPointsApi",
  async (id: null, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getRewardPoints();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCalculatePriceApi = createAsyncThunk(
  "reward/getCalculatePriceApi",
  async (id: null, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getCalculatePrice();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const placeOrderCardApi = createAsyncThunk(
//   "reward/getPlaceOrderCardApi",
//   async (data: RewardOrder, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.placeOrderCard(data);
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const placeOrderApi = createAsyncThunk(
  "reward/getPlaceOrderApi",
  async (data: RewardOrder, { getState, rejectWithValue }) => {
    try {
      return await rewardService.placeOrder(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPaymentSummaryApi = createAsyncThunk(
  "reward/getPaymentSummaryApi",
  async (data: RewardOrder, { getState, rejectWithValue }) => {
    try {
      return await rewardService.getPaymentSummary(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const productInterestApi = createAsyncThunk(
  "reward/productInterestApi",
  async (data: ProductInterest, { getState, rejectWithValue }) => {
    try {
      return await rewardService.productInterest(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const getRewardPartnerByIdApi = createAsyncThunk(
//   "reward/getRewardPartnerByIdApi",
//   async (id: number, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getPartnerById(id);
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );
// export const getRewardBannersApi = createAsyncThunk(
//   "reward/getRewardBannersApi",
//   async (a, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getBanner();
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );
// export const getRewardsApi = createAsyncThunk(
//   "reward/getRewardsApi",
//   async (a, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getRewardPoints();
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );
// export const getRewardItemsApi = createAsyncThunk(
//   "reward/getRewardItemsApi",
//   async (date: number, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getRewardPointDetailsByDate(date);
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

// export const getTotalRewardPointApi = createAsyncThunk(
//   "reward/getTotalRewardPointApi",
//   async (a, { getState, rejectWithValue }) => {
//     try {
//       return await rewardService.getTotalRewardPoint();
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const rewardSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getRewardCategoriesApi.fulfilled,
        (state: RewardReduxState, action) => {
          state.categories = action.payload.data.data;
        }
      )
      .addCase(
        getPartnersApi.fulfilled,
        (state: RewardReduxState, action) => {
          state.partnerList = action.payload.data.data
        }
      )
      .addCase(
        getBannersApi.fulfilled,
        (state: RewardReduxState, action) => {
          state.banners = action.payload.data.data
        }
      )
      .addCase(
        getMembershipsListApi.fulfilled,
        (state: RewardReduxState, action) => {
          state.membershipList = action.payload.data.list;
        }
      )
      .addCase(
        getProductListApi.fulfilled,
        (state: RewardReduxState, action) => {
          state.products = action.payload.data.data
        }
      )
      .addCase(
        getCoupansApi.fulfilled,
        (state: RewardReduxState, action) => {
         state.coupans = action.payload.data.data;
        }
      )
      .addCase(
        getPaymentSummaryApi.fulfilled,
        (state: RewardReduxState, action) => {
          console.log("dsfdsfsdff",action.payload.data.data)
         state.paymentSummary = action.payload.data.data.order.summary;
        }
      )
      .addCase(
        getRewardsPointsApi.fulfilled,
        (state: RewardReduxState, action) => {
          console.log(action.payload.data.data,"##KKKJ")
         state.rewardPoints = action.payload.data.data;
        }
      )
      .addCase(
        getMembershipPlansApi.fulfilled,
        (state: RewardReduxState, action) => {
          console.log(action.payload.data.data,"##KKKJ")
         state.membershipPlans = action.payload.data.data;
        }
      )
      .addCase(
        placeOrderApi.fulfilled,(state:RewardReduxState,action)=>{
          // console.log(action.payload.data.data,"PLACE ORDER")
         state.placeOrder=action.payload.data.data
        }
      )
      
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });
  },
});

export default rewardSlice.reducer;