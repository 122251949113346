
import React, { useEffect, useState } from "react";
import AccordianDownArrow from "../../../assets/icons/AccordianDownArrow";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import CheckBoxComponent from "./CheckBoxComponent";
import Search from "../../../assets/icons/Search";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMyNetworksApi } from "../../../slices/networkSlice";
import { getMyClubsapi } from "../../../slices/clubSlice";

interface AccordianProps {
  heading: string;
  showItems: boolean;
  setShowIndex: () => void;
  control: Control;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
}

const Accordian: React.FC<AccordianProps> = React.memo(
  ({
    heading,
    showItems,
    setShowIndex,
    control,
    register,
    watch,
    setValue,
  }) => {
    const dispatch = useAppDispatch();
    const { network } = useAppSelector((state) => state.networks.myNetworks);
    const myNetworks=network
    const { clubs } = useAppSelector((state) => state.clubs.participatedClubs);
    const [filNetworks, setFilNetworks] = useState(myNetworks);
    const [originalNetworks, setOriginalNetworks] = useState(myNetworks);
    const [filClubs, setFilClubs] = useState(clubs);
    const [originalClubs, setOriginalClubs] = useState(clubs);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
      if (heading === "Connections") {
        dispatch(getMyNetworksApi());
      } else if (heading === "Clubs") {
        dispatch(getMyClubsapi({ clubType: "participating" }));
      }
    }, [heading, dispatch]);

    useEffect(() => {
      setOriginalNetworks(myNetworks);
      setFilNetworks(myNetworks);
    }, [myNetworks]);

    useEffect(() => {
      setOriginalClubs(clubs);
      setFilClubs(clubs);
    }, [clubs]);

    const handleSearch = (value: string) => {
      setSearchText(value);
      if (heading === "Connections") {
        if (value === "") {
          setFilNetworks(originalNetworks);
        } else {
          const filList = originalNetworks.filter((res) =>
            res?.otherUserDetails?.firstName.toLowerCase().includes(value.toLowerCase())
          );
          setFilNetworks(filList);
        }
      } else if (heading === "Clubs") {
        if (value === "") {
          setFilClubs(originalClubs);
        } else {
          const filList = originalClubs.filter((res) =>
            res?.title.toLowerCase().includes(value.toLowerCase())
          );
          setFilClubs(filList);
        }
      }
    };

    const handleClick = () => {
      setShowIndex();
    };

    return (
      <div className="mb-5">
        <div className={`flex items-center justify-between ${!showItems ? "border-b border-lxgray-400" : ""}`}>
          <h1 className="text-white mt-2">{heading}</h1>
          <div onClick={handleClick} className={`${showItems ? "transform rotate-180" : ""}`}>
            <AccordianDownArrow />
          </div>
        </div>

        {showItems && (
          <div>
            <div className="w-full my-2 relative">
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full bg-lxgray-700 border border-lxgray-400 rounded-full py-2 pl-10 text-white"
              />
              <div className="absolute top-3 left-4">
                <Search />
              </div>
            </div>
            <CheckBoxComponent
              control={control}
              heading={heading}
              setValue={setValue}
              list={heading === "Connections" ? filNetworks : filClubs}
            />
          </div>
        )}
      </div>
    );
  }
);

export default Accordian;
