import * as React from "react"

function SurveyIcon() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <g clipPath="url(#clip0_4353_5505)">
        <rect width={48} height={48} rx={12} fill="#007AFF" />
        <rect width={48} height={48} rx={12} fill="#fff" fillOpacity={0.88} />
        <g clipPath="url(#clip1_4353_5505)" fill="#06C1FF">
          <path d="M19 17a1 1 0 00-1 1v2h-6v-6h3a1 1 0 000-2h-3a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-2a1 1 0 00-1-1zM18 26h-6a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-6a2 2 0 00-2-2zm-6 8v-6h6v6h-6zM23 16h14a1 1 0 000-2H23a1 1 0 000 2zM23 20h9a1 1 0 000-2h-9a1 1 0 000 2zM37 28H23a1 1 0 000 2h14a1 1 0 000-2zM32 32h-9a1 1 0 000 2h9a1 1 0 000-2z" />
          <path d="M18.29 13.29L15 16.59l-.29-.3a1.004 1.004 0 10-1.42 1.42l1 1a1.001 1.001 0 001.42 0l4-4a.999.999 0 000-1.42 1 1 0 00-1.41 0h-.01z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4353_5505">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
        <clipPath id="clip1_4353_5505">
          <path fill="#fff" transform="translate(10 12)" d="M0 0H28V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SurveyIcon
