import React, { useEffect, useRef, useState } from 'react'
import SendButtonIcon from '../../assets/icons/SendButtonIcon'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { sideChatActive } from '../../slices/chatSlice'
import Message from './Message'
import { getAllApplicationMessageApi, sendApplicationMessageApi } from '../../slices/jobSlice'
import { formatDate } from '../Clubs and Events/BothCommon/DateFormat'

const SideChatBox = () => {
   
    const dispatch =useAppDispatch()
    const {userId}=useAppSelector((state)=>state.login.session)
    const {applicationId,jobId,title}=useAppSelector((state)=>state.chat.sideChat)
    const {jobDes}=useAppSelector((state)=>state.job)
    const {comments}=useAppSelector((state)=>state.job.jobApplicationComments)
    const [textMessage,setTextMessage]=useState("")

    const handleCloseChat=()=>{
        dispatch(sideChatActive())
    }
    const messageEndRef = useRef(null);

    useEffect(() => {
      // Scroll to the bottom of the chat whenever messages are updated
      messageEndRef.current?.scrollIntoView({behavior:"smooth" });
    }, [comments]);
  
    useEffect(()=>{
        dispatch(getAllApplicationMessageApi({jobId,applicationId}))
    },[])

    const handleSendMessage=()=>{
      if(!textMessage.length){
        return
      }
      console.log(textMessage)
      dispatch(sendApplicationMessageApi({jobId,applicationId,comment:{content:textMessage,
         authorType:jobDes?.authorType,
        authorId:jobDes?.authorType==="COMPANY"?jobDes?.authorId:(userId!==jobDes?.authorId?userId:jobDes?.authorId)}}))
     setTextMessage("")
    }
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of Enter key (like submitting a form)
        handleSendMessage();
      }
    };
    
    console.log(applicationId,jobId,"LETS CHECK")
  return (
    <div   className='flex flex-col h-full '>
      <div className='flex bg-golden py-2 px-4 justify-between rounded-t-lg'>
        <h1 className='text-white text-lg font-semibold pt-1'>{title}</h1>
        <h1 onClick={handleCloseChat} className='font-extrabold text-white cursor-pointer'>__</h1>
      </div>
      <div className='bg-lxgray-700 p-5 flex-1  h-1/2  overflow-y-scroll no-scrollbar'>
        {comments && <div className='flex flex-col gap-5 text-lxgray-100'>
            {
              comments.slice().reverse().map((ele)=>{
                return (
                  <Message message={ele.content}  messageType={(ele.createdBy===userId)?"sender":""} time={formatDate(ele.createdAt,"Asia/Kolkata")}/>
                )
              })
            }
        </div>}
            
            <div ref={messageEndRef} />
        
       
      </div>
      <div className="flex gap-4 items-center bg-lxgray-600 py-2 px-4">
        <input onKeyDown={handleKeyDown} type="text" className="w-full py-2 rounded-lg bg-lxgray-800 text-lxgray-100 px-4" placeholder="Start Typing" value={textMessage} onChange={(e)=>setTextMessage(e.target.value)}/>
        
        <div onClick={handleSendMessage}>
           <SendButtonIcon/>
        </div>

      </div>
    </div>
  )
}

export default SideChatBox
