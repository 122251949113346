import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getCoupansApi } from '../../../slices/rewardSlice'
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { formatDate } from '../../Clubs and Events/BothCommon/DateFormat';
import CrossIcon from '../../../assets/icons/CrossIcon';

const CouponCodes = ({setCouponCode,setShowCoupons,setCouponAmount,setCouponCodeId}) => {
    const dispatch=useAppDispatch()
    const {coupans}=useAppSelector((state)=>state.rewards)
    
   
    const [placeHolder,setPlaceHolder]=useState('Enter your Code')

   
    useEffect(()=>{
        dispatch(getCoupansApi())
    },[])

    const handleCode=(coupon:string,discount:number,couponId:string)=>{
        setPlaceHolder(coupon)
        setCouponAmount(discount)
        setCouponCodeId(couponId)     
    }
    const handleApply=(coupon:string)=>{
        if(coupon=='Enter your Code'){
            return
        }
        setCouponCode(coupon)
        setShowCoupons(false)
    }

  return (
    <div className='min-h-svh'>
        <div className='flex justify-between '>
            <h1 className='text-lg font-semibold tracking-wide'>Coupons</h1>
            <div onClick={()=>setShowCoupons(false)} className=' cursor-pointer hover:scale-75'>
                        <CrossIcon width={'15'} height={"15"} />
            </div>
        </div>
      
      <div className='flex py-4'>
        <input  type="text" placeholder={placeHolder}  className='bg-lxgray-700 border border-lxgray-500 flex-1 p-2 rounded-l-lg'/>
        <button onClick={()=>handleApply(placeHolder)} className='text-lxgray-200 bg-lxgray-500 px-8 rounded-r-lg hover:bg-golden hover:text-white'>Apply</button>
      </div>


      {
        coupans?.map((ele)=>{
            return (
                <div className='flex items-center justify-between  px-3 py-4 bg-lxgray-500/70 mb-4 '>
                    <div className='flex flex-col gap-2'>
                        <h1 className='text-base text-white font-bold flex items-center'>Extra <MdOutlineCurrencyRupee />{ele.discount} off</h1>
                        <h6 className='text-sm  text-lxgray-200 font-normal tracking-wide line-clamp-2'>Valid upto : {formatDate(ele.expiredAt.toString(),"Asia/Kolkata").slice(5,14)}</h6>
                    </div>
                    <button onClick={()=>handleCode(ele.code,ele.discount,ele.id)}  className='text-golden font-semibold text-xs  outline-dashed outline-2  px-5 py-2 tracking-wider bg-golden/20'>{ele.code}</button>
                </div>
            )

        })
      }
      
      
    </div>
  )
}

export default CouponCodes
