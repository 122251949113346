const CheckedIcon = ({ width = "15", height = "11", color = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44118 5.66667L1.91176 2.125L0.5 3.54167L5.44118 8.5L6.5 7.4375L12.5 1.41667L11.0882 0L5.44118 5.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckedIcon;
