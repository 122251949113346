import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  actionOnClubInviteApi,
  deleteClubApi,
  getClubDetailsApi,
  getClubMemberApi,
  removeClubMembersApi,
  requestMembersApi,
} from "../../../slices/clubSlice";
import Participants from "../BothCommon/Participants";
import { baseUrl, contentUrl } from "../../../utils/contant";
import background from "../../../assets/images/default_profile_banner.png";
import Share from "../../../assets/icons/Share";
import EditDots from "../../../assets/icons/EditDots";
import AttendeesImage from "../BothCommon/AttendeesImage";
import ParticipatingComponent from "../BothCommon/ParticipatingComponent";
import Media from "../BothCommon/Media";
import ClubPosts from "./ClubPosts";
import ClubEvents from "./ClubEvents";
import banner from "../../../assets/images/default_profile_banner.png";
import Advertisement from "../../advertisement/Advertisement";
import Private from "../../../assets/icons/Private";
import Public from "../../../assets/icons/Public";
import Author from "../../job/components/Author";
import { MdCurrencyRupee } from "react-icons/md";
import VerificationPopup from "../../../components/VerificationPopup";
import ReportPopup from "../../../components/ReportPopup";
import { bookmarkPostApi } from "../../../slices/postSlice";
import { toast } from "react-toastify";
import SharePopup from "../../../components/SharePopup";
import ShareWithUsers from "../../../components/ShareWithUsers";
import { formatDate } from "../BothCommon/DateFormat";
import MultipleShareButtons from "../../../components/MultipleShareButtons";

const Club = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clubId } = useParams();
  const { clubDetail, clubMembers } = useAppSelector((state) => state.clubs);
  const { industries, categories } = useAppSelector((state) => state.appConfig);
  const { token, userId } = useAppSelector((state) => state.login.session);
  const { userDetails } = useAppSelector((state) => state.user);
  const [tab, setTab] = useState("posts");
  const [attend, setAttend] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [showShareButton, setShowShareButton] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [requestedSeeAll, setRequestedSeeAll] = useState(false);
  const [invitedSeeAll, setInvitedSeeAll] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isPopupReportVisible, setPopupReportVisible] = useState(false);
  const [isPopupShareVisible, setPopupShareVisible] = useState(false);
  const location =useLocation()

  console.log("Location",location.pathname)
  useEffect(() => {
    dispatch(getClubDetailsApi({ clubId }));
    dispatch(getClubMemberApi({ clubId }));
  }, [clubId]);

  const handleAttendClick = (clubId: string, userId: string) => {
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    }
    
    dispatch(
      requestMembersApi({ clubId: clubId, data: { userIds: [userId] } })
      // inviteMembersApi({ eventId: eventId, data: { userIds: [userId] } })
    );
    setAttend(true);
  };

  const handleEditButton = (clubId: string) => {
    navigate(`/tab/clubs/${clubId}/update`);
  };

  const handleAcceptInvite = (clubId) => {
    dispatch(actionOnClubInviteApi({ clubId, action: "accept" }));
    navigate("/tab/clubs/myclubs/joined");
  };

  const handleRejectInvite = (clubId) => {
    dispatch(actionOnClubInviteApi({ clubId, action: "reject" }));
  };

  const handleCancelRequestClick = () => {
    dispatch(
      removeClubMembersApi({
        clubId: clubId,
        memberId: clubDetail?.requestMember?.id,
      })
    );
    navigate("/tab/clubs/myclubs/pending");
  };

  const handleDeleteClub = (clubId) => {
    dispatch(deleteClubApi({ clubId }));
    navigate("/tab/clubs/myclubs/owned");
  };

  const handleSeeAll = (str) => {
    if (str == "requested") {
      setRequestedSeeAll(!requestedSeeAll);
    }
    if (str == "invited") {
      setInvitedSeeAll(!invitedSeeAll);
    }
  };

  const handleReport=()=>{
    console.log("LETS REPRT")
    setPopupReportVisible(true)
    setShow(false)
  }
  const handleBookmark=()=>{
    dispatch(bookmarkPostApi({
      tableType: "club",
      tableId: clubId,
    }))
    setShow(false)
  }

  const handleCopyLink = () => {
    const Url = `www.linkcxo.com${location.pathname}`; 

    // Copy the link to the clipboard
    navigator.clipboard.writeText(Url).then(
      () => {
        toast.success("Link Copied")
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
    setShow(false)
  };

  const handleShare=()=>{
    setPopupShareVisible(true)
  }
  
  console.log("CLUBDETAIL",clubDetail)
  return (
    <div className={`flex   mx-auto gap-2 text-white flex-wrap ${clubMembers.some(
      (member) =>
        member.status === "PENDING"
    )?"w-[100%]":"w-[80%]"} `}>
      <div className="bg-lxgray-600 flex-1">
        <img
          src={
            clubDetail?.bannerImage
              ? `${baseUrl}/${contentUrl}/${clubDetail?.bannerImage}?t=${token}`
              : background
          }
          alt="Poster"
          className="w-full h-72 object-contain rounded-lg bg-lxgray-500"
        />

        <div className="px-4 py-4 ">
          <div className="flex justify-between mb-1">
            <h2 className="text-2xl font-semibold">{clubDetail?.title}</h2>
            <div className="flex items-center gap-6 relative cursor-pointer">
              <div onClick={()=>setShowShareButton(true)}>
                <Share />
              </div>
              {showShareButton && <div className="absolute  right-0 bg-lxgray-700 p-2 rounded">
                <MultipleShareButtons setShowShareButton={setShowShareButton} shareUrl={`www.linkcxo.com${location.pathname}`}/>
              </div>}
              <div onClick={() => setShow(!show)} className="cursor-pointer">
                <EditDots />
              </div>
              {show && (
                <div className="absolute z-[20] bg-lxgray-300 top-0 right-5 w-36 rounded cursor-pointer text-center">
                  {/* <p className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">
                    Share in a post
                  </p>
                  <p className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">
                    Send in a message
                  </p> */}
                  <p onClick={handleCopyLink} className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">Copy link</p>
                  <p onClick={handleShare} className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">Share with connections</p>
                  <p onClick={handleBookmark} className="py-1 border-b border-lxgray-200 hover:text-golden hover:font-semibold cursor-pointer ">Bookmark</p>
                  <p onClick={handleReport} className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">Report</p>
                  {clubDetail?.authorId !== userId && clubDetail?.requestMember?.status === "ACCEPTED" && <p onClick={handleCancelRequestClick} className="py-1 border-b border-lxgray-200  hover:text-golden hover:font-semibold cursor-pointer">Leave Club</p>}
                  {clubDetail?.authorId === userId && (
                    <p
                      onClick={() => handleDeleteClub(clubId)}
                      className="py-1 cursor-pointer"
                    >
                      Deactivate Club
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center  justify-between gap-2">
            <div className="flex gap-2 items-center">
              {clubDetail?.isPrivate ? <Private /> : <Public />}
              <p className="text-base">
                {clubDetail?.isPrivate ? "Private" : "Public"}
              </p>
            </div>
            <div>
              <h1 className="text-base font-semibold">
                {clubDetail?.fee > 0 ? <span className="flex items-center gap-2"><MdCurrencyRupee/>{clubDetail?.authorId !== userId? `${clubDetail.fee} /-`:"Paid"}</span> : "Free"}
              </h1>
            </div>
          </div>

          <div className="flex  justify-between relative py-2 ">
            {/* {details?.members?.map((member)=><AttendeesImage url={member?.user.picUrl}/>)} */}

            {clubDetail?.authorId === userId && 
              <div onClick={()=>setTab("members")} className="flex relative gap-6 items-center w-fit cursor-pointer  ">
                {clubDetail?.members?.slice(0,3).map((member, index) => (
                  
                  member.memberUserId!==userId &&  <AttendeesImage key={member.id} zindex={(index + 1) * 10}  picUrl={member?.user.picUrl} memberName={member?.user.firstName} />
                ))}
                {clubDetail?.members.length>=2 && <h1 className="text-white bg-golden rounded-full p-1.5 w-10 h-10 z-50 ">
                  {" "}+{clubDetail?.members?.length-1}
                </h1>}
               { clubDetail?.members.length>=2 && <h1 className="text-center text-gray-400 ">{clubDetail?.members.length<=2?"Member":"Members"}</h1>}
              </div>
            }
            
          </div>

          <div className="flex justify-between items-center border-b border-lxgray-400 pb-4">
            <div>
              
              <Author
                fullName={`${clubDetail?.author.firstName} ${clubDetail?.author.lastName}`}
                picUrl={clubDetail?.author.picUrl}
                // designation={clubDetail?.author.designation}
                // organization={clubDetail?.author.organization}
              />
            </div>
            {!clubDetail?.requestMember && (
              <button
                className={`${
                  attend ? "bg-lxgray-200 cursor-not-allowed " : ""
                } text-white bg-golden rounded-lg px-12 py-3 font-semibold`}
                onClick={() => handleAttendClick(clubId, userId)}
              >
                {!attend ? "Join" : "Cancel Request"}
              </button>
            )}

            {clubDetail?.requestMember?.status === "PENDING" &&
              clubDetail?.requestMember?.type === "REQUEST" && (
                <button
                  className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
                  onClick={handleCancelRequestClick}
                >
                  Cancel Request
                </button>
              )}

            {clubDetail?.requestMember?.status === "PENDING" &&
              clubDetail?.requestMember.type === "INVITE" && (
                <div className="flex gap-6">
                  <button
                    onClick={() => handleAcceptInvite(clubDetail.id)}
                    className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
                  >
                    ACCEPT
                  </button>

                  <button
                    onClick={() => handleRejectInvite(clubDetail.id)}
                    className="text-golden border border-golden  rounded-lg px-12 py-3 font-semibold"
                  >
                    IGNORE
                  </button>
                </div>
              )}

            {clubDetail?.authorId === userId && (
              <button
                onClick={() => handleEditButton(clubDetail.id)}
                className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
              >
                Edit
              </button>
            )}
          </div>

          <div className="py-4">
            <h2 className="text-lxgray-200">About</h2>
            <p
              className={`whitespace-pre-line ${
                !seeMore ? "line-clamp-3" : ""
              } `}
            >
              {clubDetail?.details}
            </p>
            {clubDetail?.details.length > 450 && (
              <p
                onClick={() => setSeeMore(!seeMore)}
                className=" cursor-pointer text-golden font-semibold text-end"
              >
                {!seeMore ? "See More" : "See Less"}
              </p>
            )}
          </div>

          <div className="flex gap-20">
          <div>
              <h2 className="text-lxgray-200">Since</h2>
              <p>
                {formatDate(clubDetail?.createdAt,"UTC").split(" ")[1]} {formatDate(clubDetail?.createdAt,"UTC").split(",")[2]}
              </p>
            </div>
            <div>
              <h2 className="text-lxgray-200">Category</h2>
              <p>
                {industries
                  .filter((ele) =>
                    clubDetail?.industry.includes(ele.Id.toString())
                  )
                  .map((ele) => ele.Name)
                  .join(" , ")}
              </p>
            </div>
            <div>
              <h2 className="text-lxgray-200">Industry</h2>
              <p>
                {categories
                  .filter((ele) =>
                    clubDetail?.category.includes(ele.Id.toString())
                  )
                  .map((ele) => ele.Categories)
                  .join(" , ")}
              </p>
            </div>
          </div>
        </div>

        <div className="shadow-inner shadow-black">
          <div className="flex gap-8 text-lg  border-b border-lxgray-400 py-2 px-4">
            <h1
              onClick={() => setTab("posts")}
              className={
                tab === "posts"
                  ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                  : "text-lxgray-200 px-2 cursor-pointer"
              }
            >
              Posts
            </h1>
            {clubDetail?.authorId === userId && (
              <h1
                onClick={() => setTab("events")}
                className={
                  tab === "events"
                    ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                    : "text-lxgray-200 px-2 cursor-pointer"
                }
              >
                Events
              </h1>
            )}
            {clubDetail?.authorId === userId && (
              <h1
                onClick={() => setTab("members")}
                className={
                  tab === "members"
                    ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                    : "text-lxgray-200 px-2 cursor-pointer"
                }
              >
                Members
              </h1>
            )}
            {/* <h1 onClick={()=>setTab("media")} className={tab==="media"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Media</h1> */}
          </div>
          {tab === "posts" && <ClubPosts clubId={clubId} authorId={clubDetail?.authorId} />}
          {tab === "events" && <ClubEvents authorId={clubDetail?.id} />}
          {/* {tab==="media" && <Media/>} */}
          {tab === "members" && <ParticipatingComponent type={"club"} />}
        </div>
      </div>

      <div className="">
        {/* <CommonCard details={{ title: "Advertise", detail: "NEW ADVERTISE" }} /> */}

       
        {clubDetail?.authorId === userId && (
          <div
            className={`flex flex-col gap-2 p-4 ${
              requestedSeeAll ? "h-fit" : "max-h-[426px]"
            } overflow-hidden my-2 `}
          >
            {clubMembers.some(
              (member) =>
                member.status === "PENDING" && member.type === "REQUEST"
            ) && (
              <div className="flex items-center justify-between mb-2">
                <h1 className="text-lg font-semibold">Requested</h1>
                <h2
                  onClick={() => handleSeeAll("requested")}
                  className="text-sm font-bold text-golden"
                >
                  {requestedSeeAll ? "View Less" : "View More"}
                </h2>
              </div>
            )}
            {clubMembers.map((ele, i) => {
              if (ele.type === "REQUEST" && ele.status === "PENDING") {
                return (
                  <Participants
                    key={ele.id}
                    type={ele.type}
                    clubId={clubDetail.id}
                    memberId={ele.id}
                    fullName={`${ele.user.firstName} ${ele.user.lastName}`}
                    picUrl={ele.user.picUrl}
                    designation={ele.user.designation}
                    organization={ele.user.organization}
                    status={ele.status}
                    authorId={ele.authorId}
                    memberUserId={ele.memberUserId}
                  />
                );
              }
              return null;
            })}
          </div>
        )}

        {clubDetail?.authorId === userId && (
          <div
            className={` flex flex-col  gap-2 p-4 ${
              invitedSeeAll ? "h-fit" : "max-h-[426px]"
            } overflow-hidden  my-2 `}
          >
            {clubMembers.some(
              (member) =>
                member.status === "PENDING" && member.type === "INVITE"
            ) && (
              <div className="flex items-center justify-between mb-2">
                <h1 className="text-lg font-semibold">Invited</h1>
                <h2
                  onClick={() => handleSeeAll("invited")}
                  className="text-sm font-bold text-golden"
                >
                  {invitedSeeAll ? "View Less" : "View More"}
                </h2>
              </div>
            )
            }
            {clubMembers.map((ele, i) => {
              if (ele.type === "INVITE" && ele.status === "PENDING") {
                return (
                  <Participants
                    key={ele.id}
                    type={ele.type}
                    clubId={clubDetail.id}
                    memberId={ele.id}
                    fullName={`${ele.user.firstName} ${ele.user.lastName}`}
                    picUrl={ele.user.picUrl}
                    designation={ele.user.designation}
                    organization={ele.user.organization}
                    status={ele.status}
                    authorId={ele.authorId}
                    memberUserId={ele.memberUserId}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
      <VerificationPopup
        visible={isPopupVisible} 
        onClose={setPopupVisible} 
      />
      <ReportPopup
      visible={isPopupReportVisible}
      onClose={setPopupReportVisible}
      postId={clubId}/>

      <SharePopup
      visible={isPopupShareVisible}
      onClose={setPopupShareVisible}
      children={<ShareWithUsers setPopupShareVisible={setPopupShareVisible} contentType="club"/>}
      />
    </div>
  );
};

export default Club;
