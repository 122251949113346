import * as React from "react"

function PostIcon() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <g clipPath="url(#clip0_4353_5453)">
        <rect width={48} height={48} rx={12} fill="#007AFF" />
        <rect width={48} height={48} rx={12} fill="#fff" fillOpacity={0.88} />
        <path
          d="M27.143 12.12v4.451h4.794l-4.514-4.251a1.062 1.062 0 00-.28-.2z"
          fill="#007AFF"
        />
        <path
          d="M14 13.143v21.714A1.143 1.143 0 0015.143 36h16a1.143 1.143 0 001.143-1.143v-3.6c-.1.112-.218.206-.349.28l-2.651 1.469a1.405 1.405 0 01-1.406 0 1.44 1.44 0 01-.737-1.223l-.04-3.034c-.016-.26.041-.52.166-.749l5.017-8.623v-1.663h-5.143A1.143 1.143 0 0126 16.571V12H15.143A1.143 1.143 0 0014 13.143zm4 1.714h4A.571.571 0 1122 16h-4a.571.571 0 110-1.143zm0 3.429h6.286a.571.571 0 110 1.143H18a.571.571 0 110-1.143zm0 3.428h9.714a.571.571 0 110 1.143H18a.572.572 0 010-1.143zm0 3.429h8a.572.572 0 010 1.143h-8a.571.571 0 110-1.143zm0 3.428h7.429a.571.571 0 110 1.143H18a.571.571 0 110-1.143zM18 32h7.429a.571.571 0 110 1.143H18A.571.571 0 1118 32z"
          fill="#007AFF"
        />
        <path
          d="M28.286 32.251a.573.573 0 00.285.08.571.571 0 00.28-.074l2.52-1.4-3.428-2.023.04 2.943a.57.57 0 00.303.474zM37.806 19.383a1.142 1.142 0 00-.509-.663l-1.806-1.046a1.089 1.089 0 00-1.491.4l-5.6 9.686 3.674 2.166 5.612-9.715c.147-.25.19-.547.12-.828z"
          fill="#007AFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4353_5453">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PostIcon
