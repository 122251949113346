import { useEffect, useRef } from "react";
import EventImage from "../../../assets/images/Blank_Placeholders.png";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getEventMediaApi,
  uploadEventMediaApi,
} from "../../../slices/eventSlice";
import AddMultiple from "./AddMultiple";
import uploadContent from "../../../config/UploadContent";
import { baseUrl, contentUrl } from "../../../utils/contant";

const Media = ({ eventId }: { eventId?: string }) => {
  const dispatch = useAppDispatch();
  const { eventMedia, eventDetail } = useAppSelector((state) => state.events);
  const { userId, token } = useAppSelector((state) => state.login.session);

  const inputRef = useRef(null);
  useEffect(() => {
    dispatch(getEventMediaApi(eventId));
  }, [eventId]);

  const handleChange = (event) => {
    uploadContent.uploadEventContent(userId, event.target.files, (response) => {
      //console.log(response,jobId);
      const { contentType, name, size, url, id } = response[0];
      const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
        id: id,
      };
      dispatch(uploadEventMediaApi({ eventId, media: attachment }));
    });
  };

  return (
    <div className="min-h-svh">
      {eventId && (
        <div>
          <div className="py-6 px-4">
            <input
              ref={inputRef}
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
              accept=".jpg,.jpeg,.png"
              // placeholder={<UploadIcon/>}
              type="file"
              onChange={handleChange}
              //
            />
            {eventDetail?.authorId === userId && (
              <div onClick={() => inputRef?.current?.click()}>
                <AddMultiple placeholder="Add Media" type={"addMedia"} />
              </div>
            )}
          </div>

          {eventMedia.length > 0 ? (
            <div className="flex flex-wrap gap-3 px-4">
              {eventMedia.map((ele) => {
                return (
                  <img
                    src={`${baseUrl}/${contentUrl}/${ele?.content?.url}?t=${token}`}
                    alt="EventImage"
                    className="object-cover w-64 h-60"
                  />
                );
              })}
            </div>
          ) : (
            <div>
              <h1 className="text-center text-lxgray-300">
                No Images in the Event................
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Media;
