import React from "react";

const Location = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C4.40925 0.00178663 2.88416 0.634518 1.75933 1.75938C0.634501 2.88424 0.00178658 4.40936 0 6.00016C0 8.44147 1.77225 10.4688 3.6495 12.6146C4.2435 13.2941 4.8585 13.9969 5.406 14.7079C5.47606 14.7988 5.56605 14.8724 5.66903 14.923C5.77201 14.9737 5.88524 15 6 15C6.11476 15 6.22799 14.9737 6.33097 14.923C6.43395 14.8724 6.52394 14.7988 6.594 14.7079C7.1415 13.9969 7.7565 13.2941 8.3505 12.6146C10.2277 10.4688 12 8.44147 12 6.00016C11.9982 4.40936 11.3655 2.88424 10.2407 1.75938C9.11584 0.634518 7.59075 0.00178663 6 0ZM6 8.25022C5.55499 8.25022 5.11998 8.11825 4.74997 7.87101C4.37996 7.62377 4.09157 7.27236 3.92127 6.86122C3.75097 6.45007 3.70642 5.99766 3.79323 5.56119C3.88005 5.12472 4.09434 4.7238 4.40901 4.40912C4.72368 4.09445 5.12459 3.88015 5.56105 3.79333C5.9975 3.70651 6.4499 3.75107 6.86104 3.92137C7.27217 4.09167 7.62357 4.38007 7.87081 4.75009C8.11804 5.12011 8.25 5.55514 8.25 6.00016C8.25 6.59691 8.01295 7.16922 7.59099 7.59119C7.16903 8.01316 6.59674 8.25022 6 8.25022Z"
        fill="#D85B53"
      />
    </svg>
  );
};

export default Location;
