import { Link } from "react-router-dom";
import SampleProfile from "../../../../assets/images/Blank_Placeholders.png";
import { baseUrl, contentUrl } from "../../../../utils/contant";
import { useAppSelector } from "../../../../config/hooks";
import { dropDownByTitle } from "../../../../utils/nocoFunctions";
import AlternateProfile from "../../../../components/AlternateProfile";

const Profile = () => {
  const userData = useAppSelector((state) => state.user.userDetails);
  const session = useAppSelector((state) => state.login.session);
  const {designations} = useAppSelector((state) => state.appConfig);

  // console.log("userData?.organization :>> ", userData?.organization);
  return (
    <Link
      to={`/tab/profile`}
      className="flex flex-col justify-around items-center text-center bg-lxgray-700 w-full rounded p-4 cursor-pointer mx-auto active:scale-95 hover:bg-lxgray-400/60"
    >
      {  userData && userData?.profilePicture ?
      <div className="h-[102px] w-[102px] rounded-full  border my-3 overflow-hidden bg-center">     
       <img
          className="h-full w-full object-cover"
          src={       
           `${baseUrl}/${contentUrl}/${userData.profilePicture}?t=${session.token}`           
          }
          alt="Profile image"
        />
      </div>
       :
       // <h1>HELLO</h1>
       <div className="h-[102px] w-[102px] flex items-center justify-center">
         <AlternateProfile firstName={userData?.firstName} lastName={userData?.lastName} width="20" height="20"/>
       </div>
      }
      <h1 className="text-white text-lg leading-6 font-semibold cursor-pointer">
        {`${userData?.firstName} ${userData?.lastName}`}
      </h1>
      <p className="text-lxgray-200 text-sm leading-5 font-normal tracking-wide mb-3 cursor-pointer">
        {` ${dropDownByTitle(userData?.designation,designations)} at ${userData?.organization}`}
      </p>
    </Link>
  );
};

export default Profile;
