import React, { useEffect } from 'react'
import Categories from './Categories'
import { getBannersApi, getProductListApi } from '../../../slices/rewardSlice'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { useNavigate } from 'react-router-dom'
import { getAllEventApi } from '../../../slices/eventSlice'
import EventClubNetworkSuggestions from '../../home/components/suggestions/EventClubNetworkSuggestions'
import RewardsBanner from './RewardsBanner'

const DiscoverRewards = () => {
  const dispatch=useAppDispatch()
  const eventSearchList=useAppSelector((state)=>state.events.events.events)
  const navigate=useNavigate()
 
  useEffect(()=>{
    dispatch(getAllEventApi());
    dispatch(getProductListApi())
    dispatch(getBannersApi())
},[])

const handleClick=(productId:string)=>{
  navigate(`/tab/rewards/products/${productId}`)

}

  return (
    
    <div className=' flex justify-center mx-2 py-5 '>
      
      <div className=''>

        <div>
          <RewardsBanner/>
        </div>

        <div className='flex justify-between'>
          <img onClick={()=>handleClick("online")} src={"https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Group%201000006222.png"} alt="Online" className='w-1/2 h-56 cursor-pointer' />
          <img onClick={()=>handleClick("offline")}src={"https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Group%201000006314.png"} alt="Offline" className='w-1/2 h-56 cursor-pointer' />
        </div>
        <Categories/>
      </div>
      <div className='w-1/3 pl-5'>
        <div>
          <h1 className="font-semibold text-xl text-white ">Recommended Events</h1>
        </div>
        <div>
          {/* Event Results */}
          {
                (eventSearchList!==null && eventSearchList?.length>0) && 
                <div className='text-white'>
                  
                  {
                  eventSearchList.slice(0,3).map((ele)=>{
                      return (
                        <div key={ele.id} className='mb-2'>
                          <EventClubNetworkSuggestions title={`${ele.title}`} imgUrl={ele.bannerImageUrl} description={ele.details} description2={ele?.isOffline.toString()=="true"?"Offline":"Online"} buttonText={"+"} urlLink={`/tab/events/${ele.id}`}/>
                        </div>
                      )
                    })
                  }
                </div>
              }
        
        </div>
      </div>
    </div>
  )
}

export default DiscoverRewards
