import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import Dropdown from "./Dropdown";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  applyToJobApi,
  popupShowApply,
  uploadFiles,
} from "../../../slices/jobSlice";
import uploadContent from "../../../config/UploadContent";
import { useNavigate } from "react-router-dom";
import { useEventLogger } from "../../../utils/hooks/useEventLogger";

const ApplyJob = ({ jobId }) => {
  const dispatch = useAppDispatch();
  const navigate=useNavigate()
  const { industries, cities, currencies, countries ,preferredLocation,noticePeriod} = useAppSelector(
    (state) => state.appConfig
  );
  const { email, mobile } = useAppSelector((state) => state.user.userDetails);
  const { control, handleSubmit, register, watch ,setValue,formState:{errors}} = useForm();
  const inputRef = useRef(null);
  const [isUploading,setIsUploading]=useState<boolean>(false)
  const [resumeName,setResumeName]=useState("")
  const [salaryDropdown,setSalaryDropdown]=useState([])
  // Watch for changes in the "Serving Notice Period" field
  const servingNoticePeriod = watch("servingNoticePeriod");
  const {logUserEvent,isLogging}=useEventLogger();

  useEffect(()=>{
    const numbersArray = Array.from({ length: 90 }, (v, k) => k + 10);
    setSalaryDropdown(numbersArray)
  },[])

  useEffect(()=>{
    logUserEvent({id:jobId,event:"comeToApply",module:"Jobs",subModule:"applyJob"})
  },[])
  
  const handleChange=(event)=>{
    // console.log(event)
    setIsUploading(true)
    
    const selectedFile=event.target.files
    console.log(selectedFile)
    
    uploadContent.uploadJobResume(jobId, selectedFile, (response) => {
      //console.log(response,jobId);
      const { contentType, name, size, url } = response[0];
      const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
      };
      console.log(attachment)
      setValue("resume",JSON.stringify(attachment))
      setResumeName(selectedFile[0].name)
      setIsUploading(false)
    });   
  }

  const onSubmit = (data) => {
    console.log(data);
    console.log(data, jobId);
    
   
    dispatch(
      applyToJobApi({
        jobId: jobId,
        openForRelocation: data.openForRelocation === "Yes" ? true : false,
        location: data.preferredLocation,
        lastSalary: data.lastSalary,
        preferredIndustry:data.preferredIndustry,
        attachment:JSON.parse(data.resume)
      })
    ).then(()=>{
    logUserEvent({id:jobId,event:"jobApply",module:"Jobs",subModule:"jobApplySuccessFull"})
    });
    dispatch(popupShowApply());

    navigate(`/tab/jobs/myjobs`)

  };

 

  return (
    <div className="mx-12 p-8 dark:bg-lxgray-700 rounded-lg flex flex-col ">
      <div className="flex justify-between  items-center px-1 mb-2">
        <h1 className="text-white text-lg font-semibold">Apply Job</h1>
        <div
          onClick={() => dispatch(popupShowApply())}
          className="hover:scale-75 cursor-pointer"
        >
          <CrossIcon />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-1">
        <label className="text-lxgray-300 ">Enter your Email *</label>
        <input
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 mb-4"
          defaultValue={email}
          placeholder="Enter Email ID"
          type="text"
          {...register("email",{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address",
            },
          })} 
        />
        {errors.email && <p className="text-red-500 mb-1">{errors?.email?.message.toString()}</p>}
        
        <label className="text-lxgray-300">Mobile number *</label>

        <div className="flex justify-between  mb-4">
          <select
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 w-24 sm:w-1/5"
            defaultValue={+91}
            {...register("countryCode",{ required: "Country code is required" })}
          >
            {countries.map((ele) => (
              <option value={ele.PhoneCode}>+{ele.PhoneCode}</option>
            ))}
          </select>
          <input
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  ml-6 flex-1"
            defaultValue={mobile}
            placeholder="0000000000"
            type="text"
            {...register("mobileNumber",{
              required: "Mobile number is required",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Invalid mobile number",
              },
            })}
           
          />
        </div>

        {errors.countryCode && <p className="text-red-500">{errors?.countryCode?.message.toString()}</p>}
        {errors.mobileNumber && <p className="text-red-500">{errors?.mobileNumber?.message.toString()}</p>}

        <label className="text-lxgray-300 ">Last Salary *</label>
        <div className="flex mb-4">
          <select
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 w-24 sm:w-1/5"
            defaultValue={"INR"}
            {...register("currency",{
              required: "Currency is required",
            })}
          >
            {currencies.map((ele) => (
              <option value={ele.Title}>{ele.Title}</option>
            ))}
          </select>
         
          <div className="flex-1 ml-6 ">
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('lastSalary', {
              setValueAs: (value) => parseInt(value), required: "Last salary is required"})}>
                <option className="text-lxgray-300" value="" disabled>Select </option>
               
                {
                  salaryDropdown.map((ele,i)=>{
                    return (
                      <option key={`lst${i}`} value={ele}>{ele} lakhs</option>
                   )
                 })
                }
                <option className="" value="1" >1 CR </option>
                {/* <option className="" value="1.5" >1.5 CR </option> */}
                <option className="" value="2" >2 CR </option>
              

              </select>
            </div>
          {/* <input
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  ml-6 flex-1"
            placeholder="In Lakh"
            type="text"
            {...register("lastSalary", {
              setValueAs: (value) => parseInt(value),
              required: "Last salary is required",
              pattern: {
                value: /^\d+(\.\d{1,2})?$/,
                message: "Invalid salary format",
              },
            })}
            
          /> */}
        </div>
        {errors.currency && <p className="text-red-500">{errors?.currency?.message.toString()}</p>}
        {errors.lastSalary && <p className="text-red-500">{errors?.lastSalary?.message.toString()}</p>}

        <div className="mb-4">
          <label className="text-lxgray-300">Preferred Industry *</label>
          <Dropdown
            name="preferredIndustry"
            control={control}
            defaultValue={[]}
            placeholder={"Choose The Relevant Industry"}
            options={industries.map((ele,index) => {
              return { value: ele.Name, label: ele.Name , key: `ind-${index}`};
            })}
          />
        </div>

        <div className="mb-4">
          <label className="text-lxgray-300">Preferred Location *</label>
          <Dropdown
            name="preferredLocation"
            control={control}
            defaultValue={[]}
            placeholder={"Choose The Preferred Location"}
            options={preferredLocation.map((ele,index) => {
              return { value: ele.Title, label: ele.Title, key: `city-${index}` };
            })}
          />
        </div>

        <label className="text-lxgray-300 ">Open for Relocation *</label>
        <div className="flex mb-4 ">
          <div className="flex justify-between items-center text-lxgray-100 mr-8 ">
            <input
              type="radio"
              value={"Yes"}
              {...register("openForRelocation", {
                setValueAs: (value) => Boolean(value),
              })}
              className=""
            />
            <label className="px-2">Yes</label>
          </div>

          <div className="flex justify-between items-center text-lxgray-100">
            <input
              type="radio"
              value={"No"}
              {...register("openForRelocation", {
                setValueAs: (value) => Boolean(value),
              })}
            />
            <label className="px-2">No</label>
          </div>
        </div>

        <label className="text-lxgray-300 "> Are you serving notice period *</label>
        <div className="flex mb-4 ">
          <div className="flex justify-between items-center text-lxgray-100 mr-8">
            <input
              type="radio"
              value="Yes"
              {...register("servingNoticePeriod")}
              className=""
            />
            <label className="px-2">Yes</label>
          </div>
          <div className="flex justify-between items-center text-lxgray-100">
            <input
              type="radio"
              value="No"
              {...register("servingNoticePeriod")}
            />
            <label className="px-2">No</label>
          </div>
        </div>

        {servingNoticePeriod === "Yes" && (
          <div className="flex flex-col mb-4">
            <label className="text-lxgray-300 ">
              No. of Notice Period Days *
            </label>
            
            <div className="">
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('noticePeriodDays')}>
                <option className="text-lxgray-300" value="" disabled>Select </option>
                {noticePeriod.map((ele,i)=><option key={`${i}notice`} value={Number(ele.Title.slice(0,2))}>{ele.Title}</option>)}
              </select>
            </div>
          
            {/* <input
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 mb-4"
              type="number"
              {...register("noticePeriodDays")}
            /> */}
          </div>
        )}

        <label className="text-lxgray-300 ">
          Supported Format .doc .docx .pdf upto 5MB
        </label>
        <input
          ref={inputRef}
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 mb-4 hidden"
          accept=".doc, .docx, .pdf"
          placeholder="Attach your resume"
          type="file"
          onChange={handleChange}
          // 
        />

        <div className="mb-4">
          <button onClick={()=>inputRef?.current?.click()} {...register("resume",{required:"Please attach the Resume"})} className="bg-lxgray-400 rounded p-1 w-full " type="button">  {isUploading ? 'Uploading...' : 'Attach Your Resume'}</button>
         <h1 className="text-base text-lxgreen-100 mt-1">{resumeName}</h1>
        </div>

        {errors.resume && <p className="text-red-500">{errors?.resume?.message.toString()}</p>}


        <div className="flex items-center mb-2">
          <input type="checkbox" className="" />
          <label className="text-lxgray-100 text-sm ml-2">
            Could we save these details for future reference in the application?
          </label>
        </div>
        <div className="flex justify-end relative">
          <button
            type="submit"
            className="text-sm rounded font-bold px-12 py-2 bg-golden disabled:bg-lxgray-400 tracking-wide "
            disabled={isUploading}
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApplyJob;
