const NoContentIcon = () => {
  return (
    <svg
      width="299"
      height="288"
      viewBox="0 0 299 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3230_5374)">
        <path
          d="M10.0971 261H270.721C275.819 253.248 278.591 244.076 277.894 233.816C277.213 223.843 273.571 214.822 268.176 206.663C258.685 192.308 243.776 180.62 230.041 171.1C222.524 165.895 212.728 153.799 223.746 151.169C304.87 131.798 266.43 92.3064 261.144 85.1969C247.66 67.0643 218.272 78.79 193.747 78.79C167.632 78.79 156.356 35.3171 115.567 41.6257C65.6548 49.3396 89.8903 113.476 68.2623 140.751C61.2618 149.568 -4.92975 177.106 0.29322 235.962C1.06844 244.718 4.37293 253.452 10.0971 261Z"
          fill="#D9D9D9"
          fill-opacity="0.2"
        />
        <mask
          id="mask0_3230_5374"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="193"
          width="263"
          height="69"
        >
          <path
            d="M276.126 193.075H14.8516V261.352H276.126V193.075Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3230_5374)">
          <path
            d="M106.343 267.491C106.343 267.491 120.318 268.729 122.031 255.753C127.129 254.805 129.874 252.433 130.821 248.384C131.267 246.489 130.891 244.806 129.796 243.584C135.802 240.89 138.368 233.858 135.974 230.922C134.926 229.63 128.678 228.001 126.058 233.279C125.393 230.569 118.644 228.306 116.22 237.907C109.94 235.965 105.959 237.625 105.889 251.055C100.133 247.836 101.682 262.824 101.682 262.824L106.335 267.483L106.343 267.491Z"
            fill="#7EC0A4"
          />
          <path
            d="M105.592 263.216C105.592 263.216 122.32 249.488 129.749 235.236"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M131.681 238.893C131.681 238.893 128.983 238.932 126.645 240.405"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M123.446 244.813C123.446 244.813 124.572 239.857 122.977 236.568"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M118.402 258.47C118.402 258.47 114.304 257.264 110.785 258.752"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M116.117 253.333C116.117 253.333 118.252 244.665 115.82 240.945"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M127.489 247.789C127.489 247.789 124.213 246.92 120.646 248.259"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M184.007 267.491C184.007 267.491 170.032 268.729 168.319 255.753C163.22 254.805 160.475 252.433 159.529 248.384C159.083 246.489 159.458 244.806 160.553 243.584C154.547 240.89 151.982 233.858 154.375 230.922C155.423 229.63 161.672 228.001 164.292 233.279C164.956 230.569 171.705 228.306 174.13 237.907C180.41 235.965 184.39 237.625 184.461 251.055C190.216 247.836 188.668 262.824 188.668 262.824L184.015 267.483L184.007 267.491Z"
            fill="#7EC0A4"
          />
          <path
            d="M184.765 263.216C184.765 263.216 168.037 249.488 160.607 235.236"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M158.668 238.893C158.668 238.893 161.366 238.932 163.704 240.405"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M166.903 244.813C166.903 244.813 165.777 239.857 167.373 236.568"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M171.947 258.47C171.947 258.47 176.045 257.264 179.564 258.752"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M174.23 253.333C174.23 253.333 172.095 244.665 174.527 240.945"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M162.859 247.789C162.859 247.789 166.136 246.92 169.702 248.259"
            stroke="#449C77"
            stroke-width="1.14"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M78.6193 257.844C78.6193 257.844 77.7434 238.235 59.4826 238.259C57.2303 231.36 53.4374 228.001 47.6658 227.437C44.96 227.171 42.6999 228.001 41.2218 229.747C36.3887 221.916 26.183 219.661 22.5621 223.521C20.9746 225.213 19.8797 234.179 27.6611 236.834C24.0324 238.259 22.1555 248.04 35.8882 249.614C34.3632 258.682 37.4054 263.889 55.9868 261.501C52.6084 270.075 73.0433 265.15 73.0433 265.15L78.6193 257.836V257.844Z"
            fill="#7EC0A4"
          />
          <path
            d="M74.4206 261.352C74.4206 261.352 50.748 239.026 29.6719 231.36"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M34.3711 228.001C34.3711 228.001 34.9263 231.736 37.3976 234.712"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M44.0779 238.329C44.0779 238.329 37.0161 237.687 32.7617 240.506"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M63.9093 242.793C63.9093 242.793 63.0021 248.697 65.7158 253.302"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M57.2225 246.912C57.2225 246.912 44.8427 245.557 40.1426 249.621"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.4481 232.174C47.4481 232.174 46.8537 236.873 49.3641 241.571"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M68.7266 256.426C68.7266 256.426 61.7585 254.625 55.3145 257.585"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M75.4754 267.327C75.4754 267.327 95.0031 269.057 97.3962 250.929C104.528 249.606 108.353 246.293 109.674 240.64C110.292 237.993 109.776 235.636 108.243 233.936C116.635 230.178 120.216 220.35 116.877 216.239C115.415 214.438 106.687 212.159 103.019 219.536C102.088 215.746 92.657 212.59 89.2785 226.004C80.504 223.287 74.9436 225.612 74.8419 244.383C66.8025 239.888 68.9688 260.827 68.9688 260.827L75.4676 267.335L75.4754 267.327Z"
            fill="#7EC0A4"
          />
          <path
            d="M74.4199 261.352C74.4199 261.352 97.7953 242.167 108.181 222.261"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M110.878 227.366C110.878 227.366 107.109 227.421 103.84 229.481"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M99.3757 235.636C99.3757 235.636 100.948 228.713 98.7188 224.117"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M92.3292 254.727C92.3292 254.727 86.6046 253.043 81.6855 255.126"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M89.1371 247.546C89.1371 247.546 92.1167 235.44 88.7148 230.232"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M105.021 239.794C105.021 239.794 100.446 238.58 95.4648 240.452"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M233.442 257.844C233.442 257.844 232.566 238.235 214.305 238.259C212.053 231.36 208.26 228.001 202.488 227.437C199.782 227.171 197.522 228.001 196.044 229.747C191.211 221.916 181.005 219.661 177.384 223.521C175.797 225.213 174.702 234.179 182.483 236.834C178.855 238.259 176.978 248.04 190.71 249.614C189.185 258.682 192.228 263.889 210.809 261.501C207.431 270.075 227.866 265.15 227.866 265.15L233.442 257.836V257.844Z"
            fill="#7EC0A4"
          />
          <path
            d="M229.243 261.352C229.243 261.352 205.57 239.026 184.494 231.36"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M189.193 228.001C189.193 228.001 189.749 231.736 192.22 234.712"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M198.9 238.329C198.9 238.329 191.838 237.687 187.584 240.506"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M218.732 242.793C218.732 242.793 217.824 248.697 220.538 253.302"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M212.045 246.912C212.045 246.912 199.665 245.557 194.965 249.621"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M202.27 232.174C202.27 232.174 201.676 236.873 204.186 241.571"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M223.549 256.426C223.549 256.426 216.581 254.625 210.137 257.585"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M230.298 267.327C230.298 267.327 249.825 269.057 252.218 250.929C259.351 249.606 263.175 246.293 264.497 240.64C265.114 237.993 264.598 235.636 263.065 233.936C271.457 230.178 275.039 220.35 271.699 216.239C270.237 214.438 261.509 212.159 257.841 219.536C256.911 215.746 247.479 212.59 244.101 226.004C235.326 223.287 229.766 225.612 229.664 244.383C221.625 239.888 223.791 260.827 223.791 260.827L230.29 267.335L230.298 267.327Z"
            fill="#7EC0A4"
          />
          <path
            d="M229.242 261.352C229.242 261.352 252.618 242.167 263.003 222.261"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M265.701 227.366C265.701 227.366 261.931 227.421 258.662 229.481"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M254.198 235.636C254.198 235.636 255.77 228.713 253.541 224.117"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M247.151 254.727C247.151 254.727 241.427 253.043 236.508 255.126"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M243.959 247.546C243.959 247.546 246.939 235.44 243.545 230.232"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M259.844 239.794C259.844 239.794 255.269 238.58 250.287 240.452"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M79.1987 263.302C79.1987 263.302 93.3537 249.731 81.3102 235.98C85.0093 229.739 85.0405 224.673 81.6621 219.951C80.0745 217.742 77.9708 216.576 75.6794 216.615C78.3775 207.813 73.3568 198.643 68.0701 198.455C65.7553 198.369 58.2945 203.451 61.4227 211.071C57.9661 209.277 49.3714 214.305 57.2388 225.683C49.4183 230.507 47.5023 236.231 61.54 248.658C52.8671 251.759 70.0252 263.913 70.0252 263.913L79.1908 263.302H79.1987Z"
            fill="#7EC0A4"
          />
          <path
            d="M74.414 263.396C74.414 263.396 74.9848 229.911 66.8594 208.98"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M72.4816 210.303C72.4816 210.303 70.0338 213.177 69.4316 217.006"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M71.1206 224.414C71.1206 224.414 66.9523 218.666 62.0254 217.319"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M73.326 239.974C73.326 239.974 66.186 229.755 60.0391 228.886"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M77.9629 222.903C77.9629 222.903 74.0449 225.55 72.1602 230.538"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M84.3837 240.506C84.3837 240.506 76.0471 240.937 73.9355 247.546"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M234.011 263.302C234.011 263.302 248.166 249.731 236.123 235.98C239.822 229.739 239.853 224.673 236.475 219.951C234.887 217.742 232.783 216.576 230.492 216.615C233.19 207.813 228.169 198.643 222.883 198.455C220.568 198.369 213.107 203.451 216.235 211.071C212.779 209.277 204.184 214.305 212.051 225.683C204.231 230.507 202.315 236.231 216.353 248.658C207.68 251.759 224.838 263.913 224.838 263.913L234.003 263.302H234.011Z"
            fill="#7EC0A4"
          />
          <path
            d="M229.234 263.396C229.234 263.396 229.805 229.911 221.68 208.98"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M227.302 210.303C227.302 210.303 224.854 213.177 224.252 217.006"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M225.933 224.414C225.933 224.414 221.765 218.666 216.838 217.319"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M228.146 239.974C228.146 239.974 221.006 229.755 214.859 228.886"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M232.783 222.903C232.783 222.903 228.865 225.55 226.98 230.538"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M239.204 240.506C239.204 240.506 230.867 240.937 228.756 247.546"
            stroke="#449C77"
            stroke-width="1.59"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <path
          d="M284.071 259.379H1.49414V263.474H284.071V259.379Z"
          fill="#5BB28D"
        />
        <path
          d="M118.752 160.812L128.607 141.945L138.448 146.257L139 147.796L137.877 147.316L129.499 162.817L120.492 161.141L119.605 162.252L118.752 160.812Z"
          fill="#9EB0FF"
        />
        <path
          d="M119.605 162.252L130.143 164.112L139 147.796L129.342 143.687L119.605 162.252Z"
          fill="#6782FF"
        />
        <path
          opacity="0.7"
          d="M91.1375 288.321C109.579 288.321 124.529 284.972 124.529 280.84C124.529 276.708 109.579 273.359 91.1375 273.359C72.6959 273.359 57.7461 276.708 57.7461 280.84C57.7461 284.972 72.6959 288.321 91.1375 288.321Z"
          fill="#3F3F3F"
        />
        <path
          d="M81.9069 266.825L81.1191 271.328L76.9238 270.486L77.7051 265.424L81.9069 266.825Z"
          fill="#E3A5A2"
        />
        <path
          d="M84.3039 277.363C85.3412 277.047 85.676 275.732 84.8947 274.983C84.77 274.865 84.6387 274.74 84.4943 274.615C82.8201 273.116 82.1964 270.066 82.2489 269.717C82.3014 269.356 82.0651 269.02 81.7105 268.935L76.9835 268.014C76.9835 268.014 76.1234 267.837 75.8871 268.994C75.6376 270.23 74.5937 272.708 76.2547 273.477C78.8546 274.674 77.8238 278.04 81.1394 277.935C82.2817 277.902 83.3978 277.645 84.2973 277.369L84.3039 277.363Z"
          fill="#752741"
        />
        <path
          d="M86.0499 275.489C84.8878 274.773 82.695 273.925 80.3839 275.693C79.9112 276.054 79.6552 276.646 79.6749 277.244C79.688 277.645 80.0229 277.954 80.4168 277.948L86.2534 277.77C86.6407 277.757 86.9427 277.435 86.9296 277.054C86.9099 276.416 86.5882 275.824 86.0499 275.496V275.489Z"
          fill="#752741"
        />
        <path
          d="M99.8039 194.629C99.1999 192.716 81.5784 196.167 76.9236 193.873C75.5908 202.61 77.1928 211.439 79.3593 223.278C80.482 230.398 78.8669 236.808 77.8887 243.967C77.0352 250.186 76.4574 260.671 76.0701 267.719C75.5711 269.96 80.5542 270.795 82.2481 269.711C84.3884 263.807 86.1479 256.122 88.1372 250.14C90.4285 243.263 92.6608 234.303 94.9783 227.453C97.5191 219.926 101.018 206.567 99.8039 194.622V194.629Z"
          fill="#355679"
        />
        <path
          d="M101.57 280.308C100.611 279.861 99.0616 278.204 99.0419 277.764C98.9959 276.85 98.8383 275.128 98.8121 274.7C98.7792 274.128 98.451 274.128 97.8798 274.089C96.8031 274.017 94.6431 274.089 93.3103 274.431C92.8639 274.543 92.8113 274.819 92.8245 275.279C92.8704 277.093 92.5881 278.875 92.6603 279.578C92.726 280.176 93.0739 280.505 93.7042 280.729C94.1835 280.9 94.8269 281.682 95.2996 282.359C95.8511 283.148 96.3895 283.608 97.0197 283.832C98.7858 284.726 100.44 284.772 102.456 284.318C104.11 283.95 104.386 283.431 104.57 282.648V282.635C104.649 281.813 103.106 281.024 101.576 280.308H101.57Z"
          fill="#AB4467"
        />
        <path
          d="M98.9886 283.529C97.4261 283.306 97.3736 282.267 96.6054 281.406C96.0277 280.755 94.7408 280.078 94.2484 279.355C93.4278 278.132 94.3338 275.009 94.1959 274.266C93.8808 274.312 93.5788 274.372 93.3162 274.437C92.8697 274.549 92.8172 274.825 92.8303 275.285C92.8763 277.1 92.594 278.881 92.6662 279.585C92.7318 280.183 93.0798 280.512 93.7101 280.735C94.1894 280.906 94.8328 281.688 95.3055 282.366C95.857 283.154 96.3953 283.615 97.0256 283.838C98.7917 284.732 100.446 284.778 102.462 284.325C103.525 284.088 104.018 283.786 104.28 283.398C102.711 284.081 100.623 283.766 98.9886 283.536V283.529Z"
          fill="#873651"
        />
        <path
          d="M99.1923 275.049C98.9232 263.137 99.6191 235.822 99.7898 228.847C100.066 217.809 96.2445 199.73 94.485 193.879C87.2171 196.207 82.4901 195.589 75.1762 197.896C74.4344 206.423 86.9414 226.342 88.6024 234.198C88.9569 235.881 87.8211 245.439 89.3837 253.177C90.6771 259.593 91.8326 270.184 92.5416 275.18C92.929 276.219 97.2359 276.475 99.1858 275.055L99.1923 275.049Z"
          fill="#4975A6"
        />
        <path
          d="M91.1359 236.407C90.8077 228.912 86.1134 222.713 82.903 214.443C80.5526 208.388 80.7167 204.483 80.6839 198.146C79.4168 198.416 76.6068 197.436 75.1821 197.89C74.4402 206.416 86.9472 226.335 88.6083 234.191C88.9628 235.874 87.827 245.433 89.3895 253.17C90.6829 259.587 91.8384 270.177 92.5475 275.174C92.705 275.588 93.4929 275.877 94.504 275.982C93.2631 267.719 91.648 248.023 91.1359 236.407Z"
          fill="#3B5F87"
        />
        <path
          d="M100.928 158.209L98.3083 174.322L97.4088 171.692L111.649 189.337C112.568 190.481 112.391 192.15 111.255 193.077C110.178 193.952 108.622 193.84 107.677 192.86L107.664 192.847L91.9924 176.484C91.4278 175.893 91.1323 175.143 91.1126 174.387L91.0995 173.855L90.6137 157.538C90.5283 154.672 92.7802 152.272 95.6493 152.187C98.5118 152.101 100.908 154.356 100.994 157.229C101 157.558 100.974 157.906 100.928 158.222V158.209Z"
          fill="#E3A5A2"
        />
        <path
          d="M100.376 168.306L102.444 156.276C102.726 154.343 103.77 148.315 100.75 145.843L97.3884 147.092L89.8711 151.391L90.0287 167.886C93.482 168.083 96.9486 168.326 100.382 168.313L100.376 168.306Z"
          fill="#907923"
        />
        <path
          d="M113.725 193.182C113.849 193.242 113.869 193.261 113.908 193.288C113.948 193.314 113.987 193.34 114.007 193.353C114.059 193.393 114.119 193.439 114.158 193.472C114.243 193.544 114.322 193.616 114.388 193.682C114.532 193.82 114.637 193.952 114.749 194.083C114.959 194.346 115.13 194.602 115.287 194.865C115.596 195.378 115.852 195.891 116.068 196.404C116.508 197.429 116.837 198.455 117.126 199.48C117.263 199.967 116.981 200.466 116.495 200.604C116.075 200.723 115.648 200.526 115.451 200.164V200.151C114.966 199.263 114.46 198.402 113.895 197.626C113.613 197.239 113.318 196.877 113.016 196.568C112.865 196.417 112.714 196.272 112.569 196.16C112.497 196.108 112.425 196.055 112.372 196.029C112.339 196.009 112.32 196.003 112.307 196.003C112.293 195.996 112.3 196.009 112.3 196.009C112.3 196.009 112.3 196.009 112.32 196.029C112.333 196.036 112.326 196.042 112.418 196.088C111.617 195.727 111.263 194.786 111.624 193.991C111.985 193.189 112.924 192.834 113.718 193.196L113.725 193.182Z"
          fill="#E3A5A2"
        />
        <path
          d="M114.126 190.947C114.284 191.006 114.297 191.02 114.349 191.046C114.402 191.072 114.428 191.092 114.461 191.112C114.527 191.151 114.586 191.19 114.625 191.23C114.724 191.302 114.802 191.375 114.875 191.44C115.019 191.578 115.15 191.703 115.255 191.835C115.485 192.091 115.662 192.347 115.833 192.597C116.168 193.103 116.444 193.603 116.7 194.103C117.199 195.109 117.606 196.121 117.973 197.133C118.144 197.607 117.901 198.126 117.429 198.297C117.021 198.448 116.575 198.284 116.352 197.929C115.8 197.048 115.223 196.206 114.605 195.437C114.297 195.063 113.975 194.694 113.647 194.392C113.483 194.241 113.319 194.096 113.168 193.991C113.089 193.932 113.017 193.892 112.964 193.866C112.931 193.853 112.912 193.84 112.898 193.846C112.885 193.846 112.898 193.846 112.898 193.859C112.898 193.859 112.898 193.859 112.925 193.879C112.951 193.892 112.925 193.892 113.056 193.938C112.229 193.649 111.795 192.742 112.091 191.914C112.38 191.085 113.286 190.651 114.113 190.947H114.126Z"
          fill="#E3A5A2"
        />
        <path
          d="M111.945 193.386C112.083 193.511 112.129 193.564 112.201 193.643L112.392 193.859C112.51 194.004 112.628 194.142 112.726 194.287C112.943 194.569 113.127 194.859 113.311 195.148C113.665 195.726 113.967 196.325 114.236 196.923C114.506 197.521 114.729 198.133 114.926 198.744C115.123 199.355 115.287 199.973 115.431 200.591C115.55 201.078 115.248 201.571 114.762 201.683C114.328 201.788 113.902 201.564 113.724 201.17L113.711 201.15C113.468 200.618 113.219 200.092 112.943 199.585C112.667 199.079 112.372 198.586 112.05 198.126C111.728 197.666 111.387 197.225 111.026 196.824C110.842 196.627 110.658 196.436 110.468 196.266C110.376 196.18 110.278 196.101 110.186 196.022C110.14 195.989 110.094 195.95 110.054 195.924C110.021 195.897 109.949 195.851 109.976 195.878C109.286 195.339 109.168 194.339 109.706 193.649C110.245 192.959 111.243 192.84 111.932 193.38L111.945 193.386Z"
          fill="#E3A5A2"
        />
        <path
          d="M110.455 193.695C110.83 194.32 111.138 194.885 111.453 195.49C111.762 196.088 112.064 196.68 112.346 197.285C112.917 198.488 113.436 199.717 113.909 200.953C114.086 201.42 113.85 201.946 113.384 202.123C112.963 202.281 112.504 202.11 112.281 201.742C111.624 200.637 110.928 199.572 110.199 198.534C109.832 198.014 109.457 197.508 109.07 197.008C108.689 196.515 108.282 196.003 107.901 195.575C107.317 194.918 107.383 193.906 108.039 193.327C108.696 192.742 109.707 192.808 110.285 193.465C110.344 193.537 110.403 193.616 110.455 193.695Z"
          fill="#E3A5A2"
        />
        <path
          d="M106.392 193.261C105.794 191.434 108 188.324 108 188.324C111.447 188.186 113.154 189.304 114.099 190.244L114.9 194.635C114.9 194.635 113.266 195.852 113.259 195.852C112.55 196.384 111.729 196.877 110.823 196.936C109.589 197.015 106.963 194.977 106.398 193.255L106.392 193.261Z"
          fill="#E3A5A2"
        />
        <path
          d="M115.601 193.248C115.411 192.979 115.096 192.604 114.787 192.367C114.472 192.111 114.124 191.933 113.783 191.835C113.435 191.736 113.093 191.69 112.739 191.736C112.391 191.756 112.023 191.874 111.662 191.999L111.629 192.012C110.795 192.302 109.889 191.861 109.6 191.026C109.312 190.191 109.751 189.284 110.585 188.995C110.684 188.962 110.795 188.936 110.9 188.923C111.557 188.837 112.227 188.785 112.916 188.896C113.599 188.975 114.282 189.218 114.872 189.56C115.47 189.902 115.975 190.349 116.376 190.849C116.573 191.099 116.757 191.362 116.914 191.631C117.078 191.914 117.197 192.157 117.334 192.519C117.518 192.998 117.275 193.538 116.796 193.722C116.389 193.879 115.936 193.722 115.7 193.38L115.608 193.248H115.601Z"
          fill="#E3A5A2"
        />
        <path
          d="M103.684 196.036C103.001 191.322 102.542 185.576 102.627 179.108C102.85 161.857 101.734 149.373 101.734 149.373C101.911 146.737 100.769 144.18 95.5495 143.858L86.0494 144.259C83.3576 144.265 80.1143 144.522 78.4467 148.045C78.1578 148.657 78.02 149.327 77.974 150.004C77.6983 154.225 76.4508 172.823 74.002 199.868C80.9744 201.972 95.6808 205.377 103.684 196.029V196.036Z"
          fill="#AA933E"
        />
        <path
          d="M101.61 178.424C96.489 174.098 91.4599 169.628 87.7439 164C85.8334 161.108 84.8354 157.361 82.5638 154.751C81.1457 153.12 79.1761 153.041 77.6989 154.1C77.5676 155.98 77.3969 158.36 77.1934 161.206C77.3115 161.404 77.5479 161.791 77.8893 162.324C84.1067 169.424 89.8711 178.424 101.617 178.424H101.61Z"
          fill="#8A762A"
        />
        <path
          d="M80.2923 148.598C80.9357 146.336 82.4982 145.008 86.1158 144.259H86.0435C83.3517 144.272 80.1084 144.522 78.4408 148.052C78.152 148.663 78.0141 149.334 77.9681 150.011C77.6924 154.231 76.445 172.829 73.9961 199.875C75.598 200.342 77.9681 201.045 80.6862 201.525C80.8372 199.77 79.7342 150.57 80.2923 148.604V148.598Z"
          fill="#8A762A"
        />
        <path
          d="M88.7464 143.917C88.7464 143.917 88.3328 146.468 91.2807 147.552C94.2285 148.63 95.5613 146.415 95.5613 146.415C95.5613 146.415 96.0011 142.221 98.7126 138.671C98.5748 136.58 93.828 134.384 91.5433 136.271C90.8933 139.999 88.7464 143.917 88.7464 143.917Z"
          fill="#E3A5A2"
        />
        <path
          d="M108.442 123.235C108.442 123.235 111.626 128.961 104.339 136.607L103.098 135.752L108.435 123.235H108.442Z"
          fill="#4B0D03"
        />
        <path
          d="M106.972 136.219C105.587 138.027 103.644 138.868 102.639 138.092C101.635 137.317 101.937 135.226 103.328 133.412C104.714 131.604 106.657 130.762 107.662 131.538C108.666 132.314 108.364 134.404 106.972 136.219Z"
          fill="#FFB9B6"
        />
        <path
          d="M92.1017 118.502C95.0758 116.753 105.771 118.311 107.189 121.342C108.889 124.984 107.583 130.295 105.324 134.049C102.258 139.144 98.4767 145.015 94.6556 143.792C88.9766 141.971 85.8383 133.398 87.8473 127.607C88.5301 125.641 90.2503 119.599 92.1017 118.508V118.502Z"
          fill="#FFB9B6"
        />
        <path
          d="M92.4696 139.9C91.0252 132.024 94.1635 125.122 96.7108 117.779C94.7609 117.726 93.0408 117.95 92.1019 118.502C90.2505 119.593 88.5304 125.635 87.8476 127.6C86.0027 132.919 88.4976 140.571 93.3034 143.2C92.9423 142.096 92.6666 140.978 92.4696 139.9Z"
          fill="#E3A5A2"
        />
        <path
          d="M93.9265 117.101C90.1251 114.439 86.0677 118.798 85.1617 121.703C81.9513 131.992 86.3895 136.173 87.6106 135.542C87.6106 135.542 87.4005 133.668 87.4859 129.066C87.4859 129.066 90.0464 135.666 93.3619 124.767C93.1912 125.319 103.006 130.098 107.727 128.297C113.137 126.233 109.966 111.974 93.9199 117.108L93.9265 117.101Z"
          fill="#4B0D03"
        />
        <path
          d="M86.3363 128.119C86.1262 130.394 86.9862 132.327 88.2534 132.445C89.5205 132.564 90.7088 130.815 90.9189 128.54C91.129 126.266 90.2689 124.333 89.0018 124.215C87.7347 124.096 86.5464 125.845 86.3363 128.119Z"
          fill="#E3A5A2"
        />
        <path
          d="M91.3669 155.73L104.373 176.011L99.1271 175.262L108.969 168.543L113.866 165.164L116.296 163.468C117.09 162.903 117.911 162.324 118.626 161.792L118.685 161.746C119.913 160.825 121.653 161.082 122.572 162.318C123.439 163.481 123.248 165.111 122.185 166.058C121.364 166.788 120.609 167.419 119.834 168.07L117.523 169.989L112.901 173.789L103.624 181.33C102.134 182.539 99.9609 182.355 98.6938 180.935L98.3787 180.58L82.3789 162.574C80.2976 160.234 80.5077 156.644 82.845 154.56C85.1823 152.476 88.767 152.687 90.8482 155.027C91.0386 155.244 91.229 155.5 91.38 155.73H91.3669Z"
          fill="#FFB9B6"
        />
        <path
          d="M121.588 156.237C121.522 156.513 121.45 156.914 121.483 157.236C121.502 157.571 121.574 157.88 121.706 158.143C121.844 158.406 121.988 158.643 122.205 158.84C122.402 159.044 122.651 159.215 122.901 159.379C123.748 159.931 123.977 161.068 123.426 161.917C122.874 162.765 121.739 162.995 120.892 162.442C120.655 162.291 120.471 162.087 120.334 161.864L120.255 161.732C119.959 161.246 119.69 160.733 119.519 160.168C119.329 159.616 119.263 159.011 119.27 158.439C119.283 157.86 119.414 157.308 119.618 156.809C119.828 156.302 120.084 155.895 120.471 155.454C120.714 155.178 121.141 155.145 121.417 155.395C121.607 155.566 121.679 155.822 121.627 156.059L121.581 156.237H121.588Z"
          fill="#FFB9B6"
        />
        <path
          d="M119.742 160.036C119.394 160.838 119.033 161.745 119.132 162.902C119.204 163.751 120.898 165.657 121.856 165.821C124.161 166.222 126.078 165.256 127.378 164.316C128.428 163.56 129.557 162.252 130.116 161.752C130.378 161.515 130.05 161.16 129.768 160.95L125.402 157.407C124.207 158.55 119.742 160.03 119.742 160.03V160.036Z"
          fill="#FFB9B6"
        />
        <path
          d="M124.436 158.051C124.377 158.104 124.404 158.084 124.397 158.09C124.397 158.09 124.397 158.09 124.41 158.084C124.423 158.077 124.436 158.064 124.456 158.044C124.489 158.011 124.528 157.972 124.568 157.926C124.646 157.834 124.725 157.735 124.804 157.63C124.962 157.42 125.119 157.183 125.27 156.94C125.572 156.453 125.854 155.934 126.124 155.415C126.662 154.356 127.148 153.258 127.607 152.141C127.765 151.753 128.211 151.569 128.592 151.727C128.953 151.878 129.137 152.272 129.026 152.641C128.671 153.824 128.29 155.007 127.844 156.184C127.614 156.769 127.371 157.361 127.089 157.946C126.944 158.242 126.793 158.531 126.616 158.827C126.531 158.978 126.432 159.122 126.327 159.28C126.275 159.359 126.216 159.438 126.15 159.517C126.117 159.556 126.084 159.602 126.038 159.648L125.973 159.721C125.94 159.754 125.933 159.76 125.848 159.839C125.356 160.227 124.64 160.141 124.253 159.648C123.865 159.155 123.951 158.439 124.443 158.051H124.436Z"
          fill="#FFB9B6"
        />
        <path
          d="M125.637 158.892C125.564 158.919 125.564 158.919 125.564 158.919C125.578 158.919 125.578 158.919 125.597 158.906C125.63 158.886 125.663 158.873 125.709 158.84C125.788 158.794 125.886 158.715 125.978 158.636C126.162 158.478 126.346 158.261 126.523 158.044C126.878 157.597 127.193 157.078 127.482 156.532C127.764 155.98 128.02 155.408 128.256 154.817C128.493 154.225 128.696 153.62 128.9 153.009V152.996C129.038 152.601 129.464 152.384 129.858 152.516C130.226 152.641 130.436 153.022 130.357 153.397C130.068 154.711 129.714 156.026 129.195 157.321C128.926 157.965 128.624 158.61 128.223 159.241C128.02 159.556 127.803 159.865 127.514 160.168C127.376 160.319 127.226 160.47 127.042 160.615C126.956 160.687 126.851 160.759 126.746 160.832C126.694 160.865 126.621 160.904 126.562 160.937C126.536 160.95 126.49 160.976 126.444 160.996C126.405 161.016 126.365 161.036 126.254 161.068C125.65 161.239 125.026 160.884 124.855 160.286C124.685 159.681 125.039 159.057 125.637 158.886V158.892Z"
          fill="#FFB9B6"
        />
        <path
          d="M127.142 160.069C127.03 160.135 127.063 160.115 127.043 160.135C127.043 160.135 127.057 160.135 127.07 160.115C127.096 160.095 127.135 160.049 127.168 160.017C127.247 159.931 127.326 159.826 127.404 159.714C127.562 159.491 127.713 159.221 127.857 158.952C128.146 158.399 128.389 157.801 128.626 157.196C129.085 155.967 129.459 154.685 129.794 153.39C129.899 152.982 130.313 152.739 130.713 152.844C131.094 152.943 131.331 153.318 131.271 153.699C131.048 155.06 130.786 156.421 130.418 157.781C130.228 158.465 130.017 159.142 129.742 159.826C129.597 160.168 129.446 160.51 129.243 160.865C129.144 161.042 129.033 161.22 128.901 161.397C128.829 161.489 128.764 161.581 128.672 161.68C128.632 161.726 128.58 161.778 128.521 161.838C128.488 161.87 128.468 161.884 128.422 161.923C128.363 161.962 128.37 161.976 128.219 162.061C127.667 162.357 126.978 162.153 126.682 161.601C126.387 161.049 126.59 160.358 127.142 160.063V160.069Z"
          fill="#FFB9B6"
        />
        <path
          d="M126.774 162.028C126.728 162.041 126.833 162.015 126.879 162.002C126.938 161.989 127.004 161.949 127.069 161.93C127.201 161.864 127.332 161.798 127.47 161.713C127.739 161.555 127.995 161.338 128.238 161.114C128.724 160.648 129.151 160.069 129.505 159.438C129.873 158.813 130.149 158.117 130.411 157.413L130.739 156.322L131.002 155.204L131.015 155.158C131.114 154.751 131.514 154.501 131.921 154.6C132.309 154.692 132.551 155.066 132.492 155.448L132.302 156.677C132.223 157.084 132.125 157.492 132.039 157.9C131.816 158.708 131.567 159.523 131.186 160.312C130.825 161.101 130.359 161.87 129.748 162.561C129.439 162.902 129.098 163.231 128.711 163.514C128.52 163.658 128.31 163.79 128.087 163.915C127.969 163.974 127.864 164.033 127.732 164.092C127.595 164.152 127.509 164.191 127.273 164.257C126.662 164.395 126.058 164.007 125.92 163.389C125.783 162.778 126.17 162.173 126.787 162.035L126.774 162.028Z"
          fill="#FFB9B6"
        />
        <path
          d="M91.5 173.263C94.0474 171.304 96.6079 169.161 99.4113 167.511L91.7889 154.402C91.7889 154.402 90.1082 143.772 86.6679 144.528C83.2277 145.284 81.455 146.711 81.455 146.711C81.455 146.711 72.9726 153.508 90.1869 174.282C90.6268 173.94 91.0601 173.599 91.5 173.257V173.263Z"
          fill="#AA933E"
        />
        <path
          d="M86.509 164.29C84.7167 161.489 83.2067 158.439 82.3335 155.218C81.6178 152.568 80.8694 148.637 82.793 145.403C79.1821 146.921 74.4747 155.329 90.1791 174.289C90.6189 173.947 91.0523 173.605 91.4921 173.263C91.9254 172.935 92.3522 172.593 92.7855 172.257C90.4679 169.786 88.3276 167.149 86.5025 164.29H86.509Z"
          fill="#8A762A"
        />
        <path
          d="M92.3789 155.421C93.1142 156.296 93.7708 157.223 94.4076 158.156C95.0444 159.09 95.6353 160.056 96.2065 161.022C96.7777 161.995 97.3226 162.981 97.8282 163.994C98.3337 165.006 98.8064 166.038 99.1872 167.117C98.4387 166.249 97.7756 165.328 97.1454 164.388C96.5151 163.448 95.9242 162.488 95.3596 161.509C94.8015 160.529 94.2566 159.543 93.7576 158.524C93.2521 157.512 92.7794 156.486 92.3789 155.408V155.421Z"
          fill="#8A762A"
        />
        <path
          d="M88.63 171.948C87.2644 170.758 86.0563 169.391 84.9402 167.958C83.8307 166.518 82.8065 165.006 81.9464 163.396C81.0995 161.778 80.3379 160.102 79.8586 158.334C79.3859 156.565 79.1365 154.718 79.3334 152.91C79.5369 154.718 79.9834 156.434 80.5743 158.104C81.1848 159.767 81.9661 161.364 82.8065 162.929C83.0297 163.31 83.2398 163.705 83.4565 164.092L84.1458 165.236C84.6054 165.999 85.0847 166.755 85.5771 167.504C86.5619 168.997 87.5795 170.476 88.63 171.948Z"
          fill="#8A762A"
        />
        <path
          d="M119.262 92.8455L123.579 74.036L149.097 79.7211L119.262 92.8455Z"
          fill="#DB3E5A"
        />
        <path
          d="M193.314 5H124.095C120.018 5 116.713 8.30962 116.713 12.3922V74.9444C116.713 79.027 120.018 82.3367 124.095 82.3367H193.314C197.392 82.3367 200.697 79.027 200.697 74.9444V12.3922C200.697 8.30962 197.392 5 193.314 5Z"
          fill="#DB3E5A"
        />
        <path
          d="M185.001 42.1256V39.2909C185.103 37.9988 184.086 36.8868 182.788 36.8868H156.613L150.966 33.7859C150.552 33.5588 150.083 33.4335 149.606 33.4335H134.137C133.12 33.4335 132.291 34.2635 132.291 35.2815V42.1178H185.001V42.1256Z"
          fill="white"
        />
        <path
          d="M179.449 55.7355H139.409C138.822 55.7355 138.306 55.2578 138.251 54.6705L136.851 39.377C136.789 38.7036 137.25 38.1476 137.884 38.1476H180.99C181.624 38.1476 182.085 38.6957 182.022 39.377L180.623 54.6705C180.568 55.2578 180.052 55.7355 179.465 55.7355H179.449Z"
          fill="#AA933E"
        />
        <path
          d="M180.919 57.9829H138.047C137.422 57.9829 136.866 57.4739 136.804 56.8396L135.302 40.4655C135.24 39.745 135.732 39.1499 136.405 39.1499H182.554C183.226 39.1499 183.727 39.7372 183.656 40.4655L182.155 56.8396C182.1 57.4739 181.545 57.9829 180.911 57.9829H180.919Z"
          fill="#AA933E"
        />
        <path
          d="M182.108 60.0815H136.867C136.202 60.0815 135.615 59.5412 135.553 58.8756L133.965 41.5931C133.895 40.8335 134.419 40.207 135.131 40.207H183.836C184.548 40.207 185.072 40.8257 185.002 41.5931L183.414 58.8756C183.352 59.5412 182.765 60.0815 182.1 60.0815H182.108Z"
          fill="#AA933E"
        />
        <path
          d="M164.873 7.08476L145.725 26.1179L171.631 52.249L190.779 33.2159L164.873 7.08476Z"
          fill="white"
        />
        <path
          d="M165.08 11.6369L150.277 26.3503L151.093 27.1728L165.895 12.4594L165.08 11.6369Z"
          fill="#AA933E"
        />
        <path
          d="M166.833 13.4112L152.031 28.1246L152.847 28.9471L167.649 14.2337L166.833 13.4112Z"
          fill="#AA933E"
        />
        <path
          d="M163.626 20.1095L153.785 29.8909L154.601 30.7134L164.441 20.932L163.626 20.1095Z"
          fill="#AA933E"
        />
        <path
          d="M170.345 16.9464L155.543 31.6599L156.358 32.4824L171.161 17.7689L170.345 16.9464Z"
          fill="#AA933E"
        />
        <path
          d="M172.095 18.7151L157.293 33.4286L158.108 34.2511L172.911 19.5376L172.095 18.7151Z"
          fill="#AA933E"
        />
        <path
          d="M168.636 25.6706L159.045 35.2037L159.86 36.0262L169.451 26.4931L168.636 25.6706Z"
          fill="#AA933E"
        />
        <path
          d="M175.603 22.2559L160.801 36.9694L161.616 37.7919L176.418 23.0784L175.603 22.2559Z"
          fill="#AA933E"
        />
        <path
          d="M172.147 29.2058L162.557 38.7389L163.372 39.5614L172.963 30.0283L172.147 29.2058Z"
          fill="#AA933E"
        />
        <path
          d="M179.115 25.799L164.312 40.5125L165.128 41.335L179.93 26.6215L179.115 25.799Z"
          fill="#AA933E"
        />
        <path
          d="M180.873 27.56L166.07 42.2734L166.886 43.0959L181.688 28.3825L180.873 27.56Z"
          fill="#AA933E"
        />
        <path
          d="M177.892 34.0422L167.824 44.0498L168.64 44.8723L178.708 34.8647L177.892 34.0422Z"
          fill="#AA933E"
        />
        <path
          d="M184.39 31.1086L169.588 45.822L170.403 46.6445L185.206 31.9311L184.39 31.1086Z"
          fill="#AA933E"
        />
        <path
          d="M181.406 37.5853L171.338 47.5929L172.153 48.4154L182.221 38.4078L181.406 37.5853Z"
          fill="#AA933E"
        />
        <path
          d="M132.291 40.1914H185.009V73.4095C185.009 74.5998 184.039 75.5708 182.85 75.5708H134.449C133.261 75.5708 132.291 74.5998 132.291 73.4095V40.1914Z"
          fill="white"
        />
        <path
          d="M57.5913 125.269H34.1768C32.5399 125.269 31.2129 126.598 31.2129 128.237V150.515C31.2129 152.154 32.5399 153.483 34.1768 153.483H57.5913C59.2283 153.483 60.5553 152.154 60.5553 150.515V128.237C60.5553 126.598 59.2283 125.269 57.5913 125.269Z"
          fill="#DB749B"
        />
        <path
          d="M56.1765 135.206C56.1765 132.504 53.8694 130.312 51.0306 130.312C48.1918 130.312 45.8847 132.504 45.8847 135.206C45.8847 132.504 43.5777 130.312 40.7389 130.312C37.9 130.312 35.593 132.504 35.593 135.206C35.593 135.206 35.116 141.283 45.8847 148.432C56.6535 141.283 56.1765 135.206 56.1765 135.206Z"
          fill="white"
        />
        <path
          d="M151.975 99H139.572C138.704 99 138 99.7047 138 100.574V112.375C138 113.244 138.704 113.949 139.572 113.949H151.975C152.843 113.949 153.547 113.244 153.547 112.375V100.574C153.547 99.7047 152.843 99 151.975 99Z"
          fill="#DB749B"
        />
        <path
          d="M151.231 104.27C151.231 102.837 150.012 101.678 148.502 101.678C146.993 101.678 145.773 102.837 145.773 104.27C145.773 102.837 144.553 101.678 143.043 101.678C141.534 101.678 140.314 102.837 140.314 104.27C140.314 104.27 140.064 107.489 145.765 111.279C151.474 107.489 151.216 104.27 151.216 104.27H151.231Z"
          fill="white"
        />
        <path
          d="M164.082 182.324H140.667C139.03 182.324 137.703 183.652 137.703 185.292V207.57C137.703 209.209 139.03 210.538 140.667 210.538H164.082C165.719 210.538 167.046 209.209 167.046 207.57V185.292C167.046 183.652 165.719 182.324 164.082 182.324Z"
          fill="#3EAFDB"
        />
        <path
          d="M157.075 204.093H161.282C161.87 204.093 162.346 203.616 162.346 203.028V194.884C162.346 194.296 161.87 193.819 161.282 193.819H157.075C156.487 193.819 156.011 194.296 156.011 194.884V203.028C156.011 203.616 156.487 204.093 157.075 204.093Z"
          fill="white"
        />
        <path
          d="M143.554 193.522H148.05C148.527 193.522 148.918 193.122 148.918 192.629V191.846C148.918 191.846 148.918 191.775 148.918 191.736L149.051 190.053C149.106 189.324 149.701 188.761 150.42 188.761C151.179 188.761 151.789 189.387 151.789 190.162V191.775C151.789 192.042 151.867 192.308 152.008 192.535L152.391 193.177C152.578 193.498 152.837 193.78 153.134 194.007C153.619 194.367 154.322 194.9 154.322 194.9C154.612 195.119 154.776 195.464 154.776 195.832V200.765C154.776 201.133 154.604 201.478 154.315 201.697L153.431 202.37C153.235 202.519 152.993 202.598 152.751 202.598H145.266C145.04 202.598 144.805 202.527 144.617 202.394C143.898 201.893 143.859 201.29 143.968 200.844C144.062 200.452 143.952 200.053 143.694 199.747C143.358 199.363 143.319 198.941 143.366 198.604C143.421 198.244 143.358 197.876 143.139 197.586C142.779 197.124 142.779 196.646 142.858 196.302C142.928 195.973 142.834 195.636 142.639 195.362C142.099 194.587 142.639 193.514 143.561 193.514L143.554 193.522Z"
          fill="white"
        />
        <path
          d="M226.371 101.698H210.464C209.354 101.698 208.446 100.798 208.446 99.6778V84.5487C208.446 83.4368 209.346 82.5284 210.464 82.5284H226.363C227.474 82.5284 228.381 83.4289 228.381 84.5487V99.6778C228.381 100.79 227.481 101.698 226.363 101.698H226.371Z"
          fill="#3EAFDB"
        />
        <path
          d="M212.374 90.3436H215.228C215.627 90.3436 215.956 90.6725 215.956 91.0719V96.6004C215.956 96.9998 215.627 97.3287 215.228 97.3287H212.374C211.975 97.3287 211.646 96.9998 211.646 96.6004V91.0719C211.646 90.6725 211.975 90.3436 212.374 90.3436Z"
          fill="white"
        />
        <path
          d="M224.41 90.14H221.352C221.024 90.14 220.758 89.8659 220.758 89.537V89.0045C220.758 89.0045 220.758 88.9575 220.758 88.9262L220.672 87.7829C220.633 87.2896 220.226 86.9059 219.741 86.9059C219.225 86.9059 218.811 87.3287 218.811 87.8612V88.9575C218.811 89.1376 218.756 89.3178 218.662 89.4744L218.404 89.9129C218.271 90.1322 218.107 90.3279 217.903 90.4767C217.575 90.7195 217.09 91.0797 217.09 91.0797C216.895 91.2285 216.777 91.4634 216.777 91.714V95.0655C216.777 95.3161 216.895 95.551 217.09 95.6998L217.692 96.1619C217.825 96.2637 217.99 96.3185 218.154 96.3185H223.237C223.393 96.3185 223.55 96.2715 223.675 96.1775C224.16 95.833 224.191 95.4258 224.121 95.1204C224.058 94.8541 224.129 94.58 224.308 94.3764C224.535 94.118 224.559 93.8283 224.527 93.6012C224.496 93.3584 224.527 93.1079 224.684 92.9121C224.926 92.5989 224.926 92.27 224.879 92.0429C224.832 91.8158 224.895 91.5887 225.028 91.4007C225.395 90.8761 225.028 90.1478 224.402 90.1478L224.41 90.14Z"
          fill="white"
        />
        <path
          d="M71.1206 56.4677H47.7061C46.0692 56.4677 44.7422 57.7964 44.7422 59.4355V81.714C44.7422 83.3532 46.0692 84.6819 47.7061 84.6819H71.1206C72.7576 84.6819 74.0846 83.3532 74.0846 81.714V59.4355C74.0846 57.7964 72.7576 56.4677 71.1206 56.4677Z"
          fill="#FCBD55"
        />
        <path
          d="M68.3596 67.8222C70.127 73.3742 66.4514 79.7955 59.413 79.7955C52.3745 79.7955 48.7067 73.3821 50.4663 67.8222C51.7333 63.8364 55.6044 61.354 59.413 61.354C63.2215 61.354 67.0927 63.8442 68.3596 67.8222Z"
          fill="white"
        />
        <path
          d="M64.0185 73.4603C63.5649 74.2277 62.7907 74.7602 61.9774 75.097C61.164 75.4415 60.2803 75.5903 59.4044 75.606C58.5285 75.606 57.6448 75.465 56.8237 75.1283C56.0103 74.7994 55.2283 74.2669 54.7669 73.4838C54.7121 73.382 54.7434 73.2567 54.8451 73.2019C54.9155 73.1628 55.0015 73.1628 55.0719 73.2097H55.0797C55.7288 73.6561 56.4326 73.9223 57.16 74.1025C57.8951 74.2747 58.6458 74.3609 59.4044 74.3687C60.163 74.3687 60.9216 74.2982 61.6567 74.1259C62.3918 73.9458 63.1035 73.6796 63.7526 73.2332C63.8308 73.1706 63.9403 73.1941 63.995 73.2724C64.0341 73.3272 64.0341 73.4055 63.995 73.4603H64.0185Z"
          fill="#FCBD55"
        />
        <path
          d="M64.3395 68.9342C64.3395 69.5058 63.972 69.96 63.5262 69.96C63.0805 69.96 62.7129 69.498 62.7129 68.9342C62.7129 68.3703 63.0805 67.9083 63.5262 67.9083C63.972 67.9083 64.3395 68.3703 64.3395 68.9342Z"
          fill="#FCBD55"
        />
        <path
          d="M56.1052 68.9342C56.1052 69.5058 55.7376 69.96 55.2918 69.96C54.8461 69.96 54.4785 69.498 54.4785 68.9342C54.4785 68.3703 54.8461 67.9083 55.2918 67.9083C55.7376 67.9083 56.1052 68.3703 56.1052 68.9342Z"
          fill="#FCBD55"
        />
        <path
          d="M35.8886 180.601H17.2212C15.9125 180.601 14.8516 181.663 14.8516 182.974V200.734C14.8516 202.044 15.9125 203.107 17.2212 203.107H35.8886C37.1973 203.107 38.2582 202.044 38.2582 200.734V182.974C38.2582 181.663 37.1973 180.601 35.8886 180.601Z"
          fill="#FCBD55"
        />
        <path
          d="M33.6911 189.661C35.0988 194.093 32.1739 199.215 26.551 199.215C20.9281 199.215 18.0032 194.093 19.4109 189.661C20.4198 186.482 23.5089 184.501 26.551 184.501C29.5932 184.501 32.6823 186.482 33.6911 189.661Z"
          fill="white"
        />
        <path
          d="M30.2268 194.156C29.8671 194.767 29.2414 195.19 28.6002 195.464C27.9511 195.738 27.2472 195.855 26.5512 195.863C25.8552 195.863 25.1435 195.753 24.4944 195.479C23.8453 195.213 23.2197 194.79 22.8599 194.172C22.813 194.093 22.8443 193.991 22.9225 193.945C22.9772 193.913 23.0476 193.913 23.1024 193.945H23.1102C23.6263 194.305 24.1894 194.516 24.7681 194.657C25.3547 194.798 25.9568 194.861 26.559 194.869C27.7634 194.869 28.9912 194.665 30.0313 193.96C30.0939 193.913 30.1799 193.929 30.2268 193.991C30.2581 194.038 30.2581 194.093 30.2268 194.14V194.156Z"
          fill="#FCBD55"
        />
        <path
          d="M30.4837 190.546C30.4837 191 30.1944 191.368 29.8346 191.368C29.4749 191.368 29.1855 191 29.1855 190.546C29.1855 190.092 29.4749 189.724 29.8346 189.724C30.1944 189.724 30.4837 190.092 30.4837 190.546Z"
          fill="#FCBD55"
        />
        <path
          d="M23.9154 190.546C23.9154 191 23.626 191.368 23.2663 191.368C22.9065 191.368 22.6172 191 22.6172 190.546C22.6172 190.092 22.9065 189.724 23.2663 189.724C23.626 189.724 23.9154 190.092 23.9154 190.546Z"
          fill="#FCBD55"
        />
        <path
          d="M138 150.462C138.602 150.979 139.197 151.48 139.799 151.973"
          stroke="#AA933E"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M143.443 154.878C207.618 203.719 232.612 110.408 177.83 120.697C141.035 127.612 203.489 230.336 240.863 186.875C256.95 168.175 223.345 139.585 265.802 92.0679"
          stroke="#AA933E"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M267.367 90.3451C267.891 89.7734 268.431 89.2096 268.97 88.6301"
          stroke="#AA933E"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M276.492 108.826C289.13 108.826 299.375 98.5672 299.375 85.9128C299.375 73.2584 289.13 63 276.492 63C263.854 63 253.609 73.2584 253.609 85.9128C253.609 98.5672 263.854 108.826 276.492 108.826Z"
          fill="#AA933E"
        />
        <path
          d="M276.477 88.7475L288.451 75.6467C288.177 75.537 287.88 75.4666 287.567 75.4666H265.419C264.95 75.4666 264.536 75.6388 264.176 75.8816L276.477 88.7475Z"
          fill="white"
        />
        <path
          d="M289.724 77.017L282.443 84.9809L289.873 92.5062V77.7766C289.873 77.5025 289.802 77.2519 289.716 77.0092L289.724 77.017Z"
          fill="white"
        />
        <path
          d="M270.322 85.0122L263.158 77.5182C263.15 77.6043 263.104 77.6826 263.104 77.7766V92.7177L270.322 85.0122Z"
          fill="white"
        />
        <path
          d="M281.184 86.3669L276.507 91.4883L271.62 86.3748L263.416 95.1296C263.807 95.85 264.542 96.359 265.418 96.359H287.566C288.512 96.359 289.317 95.7874 289.677 94.973L281.184 86.3748V86.3669Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3230_5374">
          <rect width="299" height="288" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoContentIcon;
