import React from 'react'
import { multipleValueDropdown, multipleValueDropdownByName } from '../../../utils/nocoFunctions'
import { useAppSelector } from '../../../config/hooks'
import DownloadIcon from '../../../assets/icons/DownloadIcon'
import { baseUrl, contentUrl } from '../../../utils/contant'

const AdditionalInfoCard = ({data}) => {
 const {session}=useAppSelector((state)=>state.login)
 const {preferredLocation,industries}=useAppSelector((state)=>state.appConfig)

    console.log(data)
  return (
    <div className='pb-4'>
        <h1 className='text-white font-bold text-lg'>{data.email}</h1>
        <div className='grid grid-cols-2 mt-2 gap-1'>
          <p className='text-lxgray-200 text-base'>Location : <span className='text-lxgray-100'>{multipleValueDropdown(data.preferredLocation,preferredLocation)}</span></p>
          <p className='text-lxgray-200 text-base'>Industry : <span className='text-lxgray-100'>{multipleValueDropdownByName(data.preferredIndustry,industries)}</span></p>
          <p className='text-lxgray-200 text-base'>Last Salary : <span className='text-lxgray-100'>{data.lastSalary=== 0 ?"":`${data.lastSalary}  ${data.lastSalary.toString().length==1?"Cr":"Lakh"}`}</span></p>
          <p className='text-lxgray-200 text-base'>Relocation : <span className='text-lxgray-100'>{data.relocation?"Yes":"No"}</span></p>
        </div>
        <div className='flex items-center'>
          <h3 className='text-lxgray-200 text-base'>Resume : {" "}</h3>
          <div className='flex items-center gap-2'>
            <h2 className='text-lxgray-100 pl-2'>{data.resume.name}</h2>
            <a href={`${baseUrl}/${contentUrl}/${data?.resume.url}?t=${session.token}`}>
                  <div>
                    {" "}
                    <DownloadIcon />
                  </div>
            </a>
          </div>
        </div>
    </div>
  )
}

export default AdditionalInfoCard
