import React, { useEffect } from 'react'
import { formatDate } from '../Clubs and Events/BothCommon/DateFormat'
import JobCardBox from '../job/components/JobCardBox'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getJobDetailApi } from '../../slices/jobSlice'
import { Link } from 'react-router-dom'
import CommonCard from '../Clubs and Events/BothCommon/CommonCard'
import { chatRoomActive, removeChat } from '../../slices/chatSlice'


const Message = ({messageType,message,time,metadata,conversationId}:{messageType:string,message:string,time:string,metadata?:any,conversationId?:string}) => {
  const dispatch=useAppDispatch()
  // const {jobDes}=useAppSelector((state)=>state.job)
  
  const {userId}=useAppSelector((state)=>state.login.session)
  // useEffect(()=>{
  //   if(message==="Job Share" && metadata){
  //     dispatch(getJobDetailApi({jobId:metadata.id}))
  //   }
  // },[])
const jobDes={
  id:metadata?.id,
  title:metadata?.title,
  author:{
    picUrl: metadata?.contents[0].url
  },
  industries:metadata?.contents[0].displayName,
  location:metadata?.body,
  workplaceType:metadata?.contents[0].type,
  createdAt:null,
}

  const renderContent = (conversationId) => {
    if (message === "Job Share" ) {
      return (
        <Link onClick={()=>dispatch(removeChat(conversationId))} to={`/tab/jobs/description/Recommended/${jobDes?.id}`}>
          <JobCardBox data={jobDes} title='' />
        </Link>
      );
    } else if (message === "Event Share") {
      return (
        // <Link to={`/tab/events/description/${eventDes.authorId === userId ? "Open" : "Recommended"}/${eventDes.id}`}>
        //   <EventCardBox data={eventDes} title='' />
        // </Link>
        <div className=''>
          <Link onClick={()=>dispatch(removeChat(conversationId))} key={metadata.id} to={`/tab/events/${metadata.id}`}>
            <CommonCard
              details={{
                title: metadata?.title,
                detail: metadata?.body,
                bannerImageUrl: metadata?.contents[0]?.url,
                members: [],
                offline:metadata?.contents[0].type,
                cardType: "event",
                isPrivate:true,
                cardHW:true
              }}
            />
          </Link>
        </div>
      );
    } else if (message === "Club Share" ) {
      return (
        // <Link to={`/tab/clubs/description/${clubDes.authorId === userId ? "Open" : "Recommended"}/${clubDes.id}`}>
        //   <ClubCardBox data={clubDes} title='' />
        // </Link>
        <div className=''>
          <Link onClick={()=>dispatch(removeChat(conversationId))} key={metadata.id} to={`/tab/clubs/${metadata.id}`}>
            <CommonCard
              details={{
                title: metadata?.title,
                detail: metadata?.body,
                bannerImage: metadata?.contents[0]?.url,
                members: [],
                cardType: "club",
                isPrivate:true,
                cardHW:true
              }}
            />
          </Link>
        </div>

      );
    } else {
      return (
        <div
          className={`text-sm p-2 border border-lxgray-300 ${messageType === "sender" ? 'bg-lxgray-800 rounded-b-lg rounded-l-lg items-end' : 'bg-lxgray-500 rounded-b-lg rounded-r-lg'}`}
        >
          <h1 className='whitespace-pre-line'>{message}</h1>
        </div>
      );
    }
  };
  return (
   
      <div className={`flex flex-col ${messageType==="sender"?"justify-end items-end pl-28":" justify-start items-start pr-28"}`}>
                {/* {message==="Job Share"?
                <Link to={`/tab/jobs/description/${jobDes.authorId===userId?"Open":"Recommended"}/${jobDes.id}`}>
                <JobCardBox data={jobDes} title=''/>
                </Link>
                :<div className={`text-sm p-2 border border-lxgray-300  ${messageType==="sender"?'bg-lxgray-800 rounded-b-lg rounded-l-lg items-end':'bg-lxgray-500 rounded-b-lg rounded-r-lg'}`}>
                        <h1 className='whitespace-pre-line'>{message}</h1>
                </div>} */}
                {renderContent(conversationId)}
                <p className={`text-lxgray-400 text-xs mt-1 ${messageType==="sender"?"text-end":""}`}>{formatDate(time,"Asia/Kolkata").split(",")[3]}</p>
      </div>
    
  )
}

export default Message
