import * as React from "react"

function ArticleIcon() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <g clipPath="url(#clip0_4353_5468)">
        <rect width={48} height={48} rx={12} fill="#007AFF" />
        <rect width={48} height={48} rx={12} fill="#fff" fillOpacity={0.88} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.865 35.621l7.756-7.73h-7.756v7.73z"
          fill="#FF9500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.61 12H13.39c-.758 0-1.39.632-1.39 1.39v21.22c0 .758.606 1.39 1.39 1.39h11.924v-9.398c0-.707.58-1.288 1.288-1.288H36V13.389c0-.757-.632-1.389-1.39-1.389z"
          fill="#FF9500"
        />
      </g>
      <defs>
        <clipPath id="clip0_4353_5468">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArticleIcon
