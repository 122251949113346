import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { getMyAllPost } from '../../slices/userSlice';
import { openFeedsModal } from '../../slices/homeFeedSlice';
import AddMultiple from '../Clubs and Events/BothCommon/AddMultiple';
import UserPosts from '../profile/components/UserPosts';
import HomePopup from '../home/components/popup/HomePopup';
import Popup from '../home/components/popup/Popup';
import Post from '../home/components/post/Post';
import PollForm from '../home/components/poll/PollForm';
import SurveyForm from '../home/components/survey/SurveyForm';
import QueryForm from '../home/components/query/QueryForm';
import OpenImage from '../home/components/OpenImage';
import RepostForm from '../home/components/repost/RepostForm';

const CompanyPosts = ({companyId}:{companyId:string}) => {
  const dispatch=useAppDispatch()
    const {userPosts}=useAppSelector((state)=>state.user)
    const modalState = useAppSelector((state) => state.createHomeFeedsData);
    const {company}=useAppSelector((state)=>state.company)

    const {
      isOpenModal,
      openedModalType,
      createPostModalType,
      iscreatePostModalTypeOpen,
    } = modalState;

    useEffect(()=>{
        dispatch(getMyAllPost({authorId:companyId}))
    },[companyId])

    console.log(userPosts.posts)

    const toggleFeedsModal = (type: string) => {
      dispatch(openFeedsModal(type));
    };
  return (
    <div className='min-h-svh'>
      <div className='py-4'>

     {    company?.PageRole!=="hiringmanager" &&  <div className="pl-4" onClick={()=>toggleFeedsModal("create")}><AddMultiple placeholder={"Share Your Experience . . ."} type={"createPost"}/></div>}

      {openedModalType === "create" ? (
        <HomePopup isOpen={isOpenModal} popupHeight="h-auto" authorType={"COMPANY"} authorId={companyId}/>
      ) : createPostModalType === "post" ? (
        <Popup
          title="Create post"
          isOpen={iscreatePostModalTypeOpen}
          popupHeight="h-auto"
        >
          <Post authorType={"COMPANY"} authorId={companyId}/>
        </Popup>
      ) : createPostModalType === "poll" ? (
        <Popup
          title="Create a poll"
          isOpen={iscreatePostModalTypeOpen}
          popupHeight="h-auto"
        >
          <PollForm authorType={"COMPANY"} authorId={companyId} />
        </Popup>
      ) : createPostModalType === "SurveyScreen" ? (
        <Popup
          title="Create a Survey"
          isOpen={iscreatePostModalTypeOpen}
          popupHeight="h-auto"
        >
          <SurveyForm authorType={"COMPANY"} authorId={companyId}/>
        </Popup>
      ) : createPostModalType === "query" ? (
        <Popup
          title="Create a Query"
          isOpen={iscreatePostModalTypeOpen}
          popupHeight="h-auto"
        >
          <QueryForm authorType={"COMPANY"} authorId={companyId}/>
        </Popup>
      ) : createPostModalType.includes("openImage?=") ? (
        <OpenImage
          postId={createPostModalType}
          isOpen={iscreatePostModalTypeOpen}
          title=""
        />
      ) : createPostModalType.includes("repost?=") ? (
        <Popup
          title="Repost"
          isOpen={iscreatePostModalTypeOpen}
          popupHeight="h-auto"
        >
          <RepostForm />
        </Popup>
      ) : null}
      </div>
      {userPosts.posts.length==0 && <h1 className='text-white text-center '> No Posts Available .... Create  a NEW POST</h1>}
      {userPosts.posts.length>0 && <div className='px-16'><UserPosts feeds={userPosts}/></div>}
    </div>
  )
}

export default CompanyPosts
