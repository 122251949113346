import React from "react";

const Attach = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5774 7.93201C16.9038 7.6217 17.4338 7.62073 17.7614 7.92993C18.089 8.23914 18.0901 8.74145 17.7638 9.05188L10.0594 16.334C8.58416 17.7593 6.41535 18.3252 4.37805 17.8164C2.34071 17.3076 0.747968 15.8025 0.205597 13.8734C-0.336773 11.9443 0.254617 9.88782 1.7548 8.48608L9.45894 1.20496C10.5106 0.178101 12.0642 -0.232788 13.5251 0.129395C14.986 0.491577 16.1281 1.5708 16.5143 2.9541C16.9005 4.3374 16.4709 5.81055 15.3901 6.80994L7.67643 14.0909C7.04243 14.6932 6.11743 14.9294 5.2499 14.7103C4.38233 14.4912 3.70405 13.8502 3.47055 13.0288C3.23701 12.2074 3.48372 11.3302 4.11772 10.7279L11.2362 4.00891C11.5628 3.69885 12.0929 3.69824 12.4203 4.00769C12.7476 4.31726 12.7482 4.81958 12.4216 5.12976L5.30288 11.8489C4.97591 12.1594 4.97662 12.6625 5.30449 12.9723C5.63236 13.2821 6.1632 13.2814 6.49017 12.9708L14.2038 5.68982C14.8381 5.08704 15.0847 4.20935 14.8509 3.38757C14.617 2.5658 13.9381 1.92456 13.07 1.70569C12.2019 1.48682 11.2765 1.72339 10.6424 2.32642L2.93887 9.60681C1.33987 11.1648 1.36003 13.6514 2.98412 15.1859C4.6082 16.7203 7.23255 16.7323 8.87216 15.2128L16.5774 7.93201Z"
          fill="#AA933E"
        />
      </svg>
    </div>
  );
};

export default Attach;
