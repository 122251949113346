import profile from "../../../assets/images/Blank_Placeholders.png";
import EditIcon from "../../../assets/icons/EditIcon";
import Delete_Icon from "../../../assets/icons/Delete_Icon";
import { openProfileModal } from "../../../slices/profileSlice";
import { useAppDispatch } from "../../../config/hooks";
import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";

const EducationCard = ({ educationDetail, editEducation }) => {
  const dispatch = useAppDispatch();

  const { institute, degree, startDate, endDate, description, id } =
    educationDetail;

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: id,
    };
    dispatch(openProfileModal(modalTypes));
  };

  return (
    <div className="flex gap-2">
      <div className="w-[70px]">
        <div className="h-[50px] w-[50px]">
          <img
            className="h-full w-full rounded-full object-cover"
            src={profile}
            alt="Organization image"
          />
        </div>
      </div>
      <div className="w-full">
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-xl text-white font-bold">{institute}</h1>
            {editEducation && (
              <div className="flex flex-row-reverse  gap-4 self-start items-center justify-center">
                <div
                  className="cursor-pointer"
                  onClick={() => toggleModalHandler("education", true)}>
                  <EditIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => toggleModalHandler("education?=delete", true)}>
                  <Delete_Icon />
                </div>
              </div>
            )}
          </div>
          <p className="text-base text-golden font-semibold">{degree}</p>
        </div>

        <div className="my-2">
          <p className="text-base text-white font-normal tracking-wider">
            {startDate.year} - {endDate.year}
          </p>
        </div>

        <div>
          <p
            className={`text-sm text-lxgray-100 font-normal ${
              showMore ? "line-clamp-none" : "line-clamp-3"
            }`}
            ref={contentRef}>
            {description}
          </p>
          {description && isOverflowing && (
            <div className="flex justify-end items-center">
              <button
                className="border-none text-golden text-base font-normal w-[100px] text-center"
                onClick={textToggleHandler}>
                {showMore ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
