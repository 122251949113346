import React from 'react'
import { Outlet } from 'react-router-dom'
import RewardsHeader from './components/RewardsHeader'

const RewardsPage = () => {
  return (
    <div className={`min-h-lvh dark:bg-black py-1  `}>
      <RewardsHeader />
      <Outlet />     
    </div>
  )
}

export default RewardsPage
