import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { updateJobStatusApi } from '../../slices/jobSlice'
import { useNavigate } from 'react-router-dom'
import CommonPopup from '../company/CommonPopup'
import UpdateJob from './components/UpdateJob'
import ReportPopup from '../../components/ReportPopup'
import Popup from "../home/components/popup/Popup"
import RepostForm from '../home/components/repost/RepostForm'
import { openCreatePostModalType } from '../../slices/homeFeedSlice'
import SharePopup from '../../components/SharePopup'
import ShareWithUsers from '../../components/ShareWithUsers'
interface Props{
  data:string[],
  jobId:string,
  pathName:string,
  setShowDotData?:Function
}

const EditDotPopup:React.FC<Props> = ({data,jobId,pathName,setShowDotData}) => {

    const dispatch=useAppDispatch()
    const navigate=useNavigate()
    const[isOpen,setIsOpen]=useState(false)
    const modalState = useAppSelector((state) => state.createHomeFeedsData);
    const { iscreatePostModalTypeOpen} = modalState;
    const [updatedData,setUpdatedData]=useState([])
    const [isPopupReportVisible, setPopupReportVisible] = useState(false);
    const [isPopupShareVisible, setPopupShareVisible] = useState(false);
    useEffect(()=>{
     
      setUpdatedData(data)
        if(pathName.includes("Open")){
          setUpdatedData(["Edit",...data,])
        }
    },[])

    const handleClick=(e:React.MouseEvent<HTMLDivElement>,ele)=>{
        e.preventDefault()
        console.log(ele,jobId)

        if(ele==="Inactive"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"INACTIVE"})) 
          navigate(`/tab/jobs/hiring`)      
        }
        if(ele==="Open"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"OPEN"}))  
          navigate(`/tab/jobs/hiring`)     
        }
        if(ele==="Close"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"CLOSED"})) 
          navigate(`/tab/jobs/hiring`)      
        }
        if(ele==="Edit"){
          setIsOpen(true)
        }
        if(ele=="Report"){
          setPopupReportVisible(true)
        }
        if(ele=="onFeed"){
          //  dispatch(openCreatePostModalType("JOBPOST"));

          setPopupReportVisible(true)
        }
        if(ele=="Share With Connection"){
          setPopupShareVisible(true)
        }
        
        // navigate(`/tab/jobs/hiring`)
    }
    
  return (
    <div>

    
    <div className='absolute bg-lxgray-800 text-center text-lxgray-100 py-1 right-8 top-0 rounded-lg '>
        {
          updatedData?.map((ele,i)=>{
            return (<p onClick={(e)=>handleClick(e,ele)} className={`text-sm  min-w-24 p-1 ${updatedData.length-1===i?"":"border-b border-lxgray-200"} hover:bg-lxgray-400 `} key={`${i}Popup`}>{ele}</p>)
          })
        } 
    </div>
   {isOpen &&  <div>
      <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><UpdateJob setIsOpen={setIsOpen} /></CommonPopup>
    </div>}

      <ReportPopup
      visible={isPopupReportVisible}
      onClose={setPopupReportVisible}
      postId={jobId}/>

       <SharePopup
        visible={isPopupShareVisible}
        onClose={setPopupShareVisible}
        children={<ShareWithUsers setPopupShareVisible={setPopupShareVisible} contentType={"job"}/>}
        />

        {/* <Popup
          title="Repost"
          isOpen={isPopupReportVisible}
          popupHeight="h-auto"
        >
         <h1>REe</h1>
        </Popup> */}

    </div>
  )
}

export default EditDotPopup
