import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import { GetNotificationResponse, Notifications } from "../models/notification";

class NetwokService {
//   sendNotificationToken(
//     token: string
//   ): AxiosPromise<ApiResponse<Response>> {
//     return apiClient.post(`/v1/notifications/token`, { token: token });
//   }

  getAllNotifications(): AxiosPromise<ApiResponse<GetNotificationResponse>> {
    return apiClient.get(`/v1/notifications`);
  }


  markReadNotification(
    data: Notifications
  ): AxiosPromise<ApiResponse<Response>> {
    return apiClient.patch(`/v1/notifications/${data.id}/read`);
  }

}

const notificationService = new NetwokService();
export default notificationService;