import uploadContent from '../../../config/UploadContent';
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../config/store';
import UploadIcon from '../../../assets/icons/UploadIcon';
import Dropdown from '../../job/components/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import Switch from '../../job/Switch';
import { baseUrl, contentUrl } from '../../../utils/contant';
import { updateClubApi } from '../../../slices/clubSlice';
import { useNavigate } from 'react-router-dom';
import Advertisement from '../../advertisement/Advertisement';

const UpdateClub = () => {
    const navigate=useNavigate()
    const { userId,token } = useSelector((state: RootState) => state.login.session);
    const {clubDetail} =useAppSelector((state)=>state.clubs)
    const dispatch=useAppDispatch()
    const { currencies,industries,categories} = useAppSelector((state) => state.appConfig);

    const {register,setValue,handleSubmit,formState:{errors},watch,control}=useForm()
    const [updatedBanner,setUpdatedBanner]=useState("")
    const inputRef = useRef(null);
    
    const [isPaidClub, setIsPaidClub] = useState(clubDetail?.fee > 0);

    // console.log("ispaid",isPaid)
    console.log("ispaidClub",isPaidClub)
   
    useEffect(() => {
       
        const handleDragOver = (event) => {
          event.preventDefault();
        };
    
        const handleDrop = (event) => {
          event.preventDefault();
          handleChange(event);
        };
    
        document.addEventListener("dragover", handleDragOver);
        document.addEventListener("drop", handleDrop);
    
        return () => {
          document.removeEventListener("dragover", handleDragOver);
          document.removeEventListener("drop", handleDrop);
        };
        
        
      }, []);
     

    
      const onSubmit = async (data) => {
        
        console.log(data);
        dispatch(updateClubApi({
          title: data.title,
          industry:data.industry,
          category:data.category,
          details:data.details,
          bannerImage:data.bannerImage,
          isPrivate:data.isPrivate==="true"?true:false,
          fee:!isPaidClub?0:data.fee,
          email:data.email,
          clubId:clubDetail.id
        }))

        navigate(`/tab/clubs/${clubDetail.id}`)

    }

      const handleChange = (event) => {
        // console.log(event)
       
    
        const selectedFile =
          event instanceof DragEvent
            ? event.dataTransfer.files
            : event.target.files;
        console.log(selectedFile);
    
        uploadContent.uploadClubContent(userId, selectedFile, (response) => {
          //console.log(response,jobId);
          const { contentType, name, size, url } = response[0];
          const attachment = {
            contentType: contentType,
            name: name,
            size: size,
            url: url,
          };
          console.log(attachment);
          setUpdatedBanner(attachment.url)
          setValue("bannerImage",(attachment.url));
          
        });
      };
    console.log(isPaidClub)
  return (
    <div className='flex w-2/3 mx-auto'>

    
    <div className="mx-12 p-8 dark:bg-lxgray-700 mt-1 rounded-lg hit flex-1">
         <h1 className="text-white text-lg font-semibold">Update Club</h1>
         <div>

         <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-1">
            <h1 className="text-lxgray-100">Upload File</h1>
                <label className="text-lxgray-300 ">
                Drag or choose your file to upload (less than or equal to 3 MegaBytes)
                </label>
                <input
                ref={inputRef}
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
                accept=".jpg,.jpeg,.png"
                type="file"
                onChange={handleChange}
                
                //
                />

            <div className="relative">
              <div className="bg-lxgray-400 ">
                <span
                  onClick={() => inputRef?.current?.click()}
                  {...register("bannerImage",{value:clubDetail.bannerImage})}
                  className="rounded "
                  >
                  {" "}
                  {/* {isUploading ? "Uploading..." : "Upload"} */}
                  <div className="cursor-pointer">   
                    <img src={updatedBanner.length>0?`${baseUrl}/${contentUrl}/${updatedBanner}?t=${token}`:`${baseUrl}/${contentUrl}/${clubDetail?.bannerImage}?t=${token}`} alt="bannerImage" className='w-full h-40 object-contain'/>
                  </div>  
                </span>     
              </div>
            </div>
            {errors.bannerImage && <span className="text-red-500">Upload Banner Image</span>}

            <div>

            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Club Title *
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                defaultValue={clubDetail.title}
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("title",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter the Valid Title</span>}
            </div>

            <div className="text-lxgray-300 mb-4 flex flex-col">
              <label htmlFor="" className="text-sm p-1 ">
                Type *
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  defaultChecked={!clubDetail.isPrivate?true:false}
                  value={"false"}
                  className=" w-4 h-4 mr-2 "
                  {...register("isPrivate")}
                />
                <label htmlFor="public" className="text-white p-1 mr-6 ">
                  Public
                </label>

                <input
                  type="radio"
                  defaultChecked={clubDetail.isPrivate?true:false}
                  value={"true"}
                  className=" h-4 w-4 mr-2"
                  {...register("isPrivate")}
                />
                <label htmlFor="client" className="text-white p-1 ">
                  Private
                </label>
              </div>
            </div>
            
            <div className="flex p-1 text-sm text-lxgray-300 justify-between mb-3">
                <span className="text-white">Set Club to be Free or Paid *</span>
                <div className="flex gap-2 items-center">
                    <label htmlFor="" className="text-lxgray-100">Club is Paid</label>
                    <input type="checkbox" defaultChecked={clubDetail.fee>0?true:false} onChange={(e)=>setValue("switch",e.target.checked)} className="hidden" />
                    {/* <div onClick={()=>isPaid.current?.click()} {...register('switch',{value:clubDetail.fee>0?true:false})}><Switch isopen={clubDetail?.fee>0?true:false}/></div> */}
                    <div onClick={() => setIsPaidClub(!isPaidClub)} {...register('switch', { value: isPaidClub })}>
                                    <Switch isopen={isPaidClub} />
                    </div>
                </div>
            </div>

           
            {isPaidClub && <div className="flex gap-4 mb-4">
          <div className="flex flex-col w-1/4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Currency *
            </label>
            <div className="text-white">
              <select
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
                defaultValue={currencies[0].Title}
                {...register("currency")}
              >
                <option value="" disabled>
                  Select
                </option>
                {currencies.map((ele, i) => (
                  <option key={`${i}jobType`} value={ele.Title}>
                    {ele.Title}
                  </option>
                ))}
              </select>
              </div>
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Amount *
            </label>
            <input
            type="text"
            defaultValue={clubDetail?.fee}
            placeholder="Enter Your Amount"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("fee",{setValueAs:(value)=>Number(value),valueAsNumber:true})}
            onInput={(e) => {
              const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
              target.value = target.value.replace(/[^0-9]/g, ''); // Filter input
            }}
          />
          </div>
      </div>}


      <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Industry *
              </label>
              <div className="text-white">
                <Dropdown
                  name="industry"
                  control={control}
                  defaultValue={clubDetail?.industry||[]}
                  placeholder={"Choose The Relevant Industry"}
                  options={industries.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Name };
                  })}
                />
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Category *
              </label>
              <div className="text-white">
                <Dropdown
                  name="category"
                  control={control}
                  defaultValue={clubDetail?.category||[]}
                  placeholder={"Choose The Relevant Category"}
                  options={categories.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Categories };
                  })}
                />
              </div>
            </div>

            <div className="text-lxgray-300 flex flex-col mb-4 relative">
              <label htmlFor="" className="text-sm p-1">
                Enter Club Description *
              </label>
              <textarea
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
                rows={5}
                defaultValue={clubDetail.details}
                placeholder="Enter Here"
                maxLength={2600}
                {...register("details",{required:true,maxLength:2600})}
                // required
              ></textarea>
                {errors.details && errors.details.type === "required" && (
                <span className="text-red-500">Club details are required</span>
                )}
                {errors.details && errors.details.type === "maxLength" && (
                <span className="text-red-500">Maximum 2600 characters allowed</span>
                )}
            </div>

            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Email*
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                defaultValue={clubDetail.email}
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("email",{required:"Email is required", pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  message: "Enter a valid email address",
                }})}
              />
                {errors.email && <span className="text-red-500">{errors.email.message.toString()}</span>}
            </div>
            <div className='flex justify-end'>
              <button className='text-white bg-golden px-8 py-2 rounded mt-2 font-semibold'>Update</button>
            </div>
            </div>
            </form>
         </div>
    </div>
    {/* <div className='border-l border-lxgray-300 px-5'>
      <Advertisement/>
    </div>/ */}
    </div>
  )
}

export default UpdateClub
