import React, { useState } from 'react'

import CrossIcon from '../assets/icons/CrossIcon';
import { useAppDispatch } from '../config/hooks';
import { actionOnPostApi, doActionOnPostApi } from '../slices/postSlice';


const reportReason=["Inappropriate content","Copyright infringement","Spam or misleading information","Impersonation or fake account","Harassment or bullying","Privacy concerns","Hate speech or discriminatory language","Violence or graphic content"]

const ReportPopup = ({ visible, onClose ,postId}) => {
    const dispatch=useAppDispatch()
    const [show,setShow]=useState(false)
    const [selectedReason, setSelectedReason] = useState(null);
    if (!visible) return null;

    const handleRadioChange = (e) => {
       
        setSelectedReason(e.target.value);
        if(show){
            setShow(false)
        }
      };
    
      console.log(postId,"PPOST ID")
    const handleSubmit=()=>{
        if(selectedReason?.length==0){
            return
        }
        // console.log("Selected Reason",selectedReason,selectedReason.length)
        // console.log(postId)
       
        dispatch(actionOnPostApi({
            postId:postId,
            action:"report",
            summary:selectedReason
        }))

        onClose(false)
    }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]  ">
        
    <div className="flex  flex-col  gap-2 bg-lxgray-800 rounded-lg shadow-lg p-6  border border-golden relative w-1/2">
      <div  onClick={()=>onClose(false)} className='absolute top-3 right-3 hover:scale-110 cursor-pointer'>
        <CrossIcon width='14'/>
      </div>
       <h2 className="text-lg  text-white">Report this post</h2>
        <div>
            <p className='text-xs text-lxgray-200 '>We want to ensure that our community remains a safe and welcoming space for everyone. If you believe this post violates our community guidelines or makes you feel uncomfortable, please let us know by reporting it.</p>
        </div>
      
      <div>
        <h3 className='text-lxgray-100 text-sm'>Reason for Reporting</h3>
            <div className='grid grid-cols-2 p-4 gap-4'>
            {
                reportReason.map((ele,i)=>{
                    return (
                        <div key={i+"Re"} className='text-lxgray-100 text-sm flex gap-2 items-center'>
                            <input type="radio"
                            name='reportReason'
                            value={ele}
                            checked={selectedReason===ele}
                            onChange={handleRadioChange}
                             />
                            <label >{ele}</label>
                        </div>
                    )

                })
            }
            <div className='text-lxgray-100 text-sm flex gap-2 items-center'>
                <input type="radio"
                 name='reportReason'
                 value={""}
                 checked={selectedReason===""} 
                 onChange={() => {
                    setShow(true);
                    setSelectedReason("");
                  }}
                />
                <label className='  '>Other (please specify)</label>
            </div>
            </div>
           { show && <input type="text" 
           value={selectedReason} 
           className='w-full bg-lxgray-800 border border-lxgray-300 text-sm rounded p-2 text-lxgray-200' 
           placeholder='Your Reason'
           onChange={(e)=>setSelectedReason(e.target.value)}
           />}

      </div>
      
      <button 
      onClick={handleSubmit}      
        className={`mt-4 px-8 py-2 text-sm  w-fit  text-white rounded hover:bg-white hover:text-golden ${selectedReason?.length===0?"bg-lxgray-200 cursor-not-allowed":"bg-golden"}`}
        >
        Submit
      </button>
      
    </div>
  </div>
  )
}

export default ReportPopup
