import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { openProfileModal } from "../../../slices/profileSlice";

import { CreateEducationResponse } from "../../../models/user";

import EducationCard from "../components/EducationCard";
import NoContent from "../../../components/NoContent";

import PlusIcon from "../../../assets/icons/PlusIcon";

const EditEducation = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user);

  const { userEducation } = userData;

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
  };
  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full flex justify-between my-5">
          <h1 className="text-white text-xl font-bold">Add Education</h1>
          <button
            className="text-lg flex gap-2 items-center justify-center text-lxgray-400 font-normal"
            onClick={() => toggleModalHandler("education", false)}>
            <PlusIcon color="#8C8C8C" /> Add
          </button>
        </div>

        <div className="flex flex-col gap-4">
          {userEducation.map(
            (educationData: CreateEducationResponse, index: number) => (
              <EducationCard
                educationDetail={educationData}
                key={index}
                editEducation={true}
              />
            )
          )}
        </div>
        {userEducation && userEducation.length == 0 && <NoContent />}
      </div>
    </div>
  );
};

export default EditEducation;
