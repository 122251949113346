import React from "react";

const Salary = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.00268 11.5584H14.4232C15.5272 11.5584 16.4258 10.6599 16.4258 9.55578L16.4266 2.98509C16.4266 1.88104 15.528 0.982422 14.424 0.982422H2.00267C0.898619 0.982422 0 1.881 0 2.98509V9.55518C0 10.66 0.897806 11.5586 2.00267 11.5586L2.00268 11.5584ZM1.03355 2.98509C1.03355 2.4509 1.46849 2.01595 2.00268 2.01595H14.4232C14.9574 2.01595 15.3923 2.4509 15.3923 2.98509V9.55518C15.3923 10.0894 14.9574 10.5243 14.4232 10.5243L2.00268 10.5251C1.46849 10.5251 1.03355 10.0901 1.03355 9.55596V2.98509Z"
          fill="#8C8C8C"
        />
        <path
          d="M16.9662 5.82083V11.2993C16.9662 11.6761 16.6584 11.9831 16.2801 11.9831H12.3003C12.015 11.9831 11.7832 12.2142 11.7832 12.5002C11.7832 12.7856 12.0143 13.0174 12.3003 13.0174H16.2801C17.2283 13.0174 17.9997 12.2467 17.9997 11.2993V5.82085C17.9997 5.53554 17.7687 5.30371 17.4826 5.30371C17.1973 5.30371 16.9662 5.53552 16.9662 5.82083Z"
          fill="#8C8C8C"
        />
        <path
          d="M8.21331 9.31969C9.89498 9.31969 11.2626 7.95127 11.2626 6.27044C11.2626 4.58878 9.89414 3.22119 8.21331 3.22119C6.53165 3.22119 5.16406 4.58884 5.16406 6.27044C5.16406 7.95133 6.53171 9.31969 8.21331 9.31969ZM8.21331 4.25388C9.32512 4.25388 10.2291 5.1579 10.2291 6.26965C10.2291 7.3814 9.32506 8.28541 8.21331 8.28541C7.10151 8.28541 6.19755 7.3814 6.19755 6.26965C6.19755 5.15862 7.10157 4.25388 8.21331 4.25388Z"
          fill="#8C8C8C"
        />
      </svg>
    </div>
  );
};

export default Salary;
