const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "indent",
  "link",
  "image",
];

const CustomToolbar = () => (
  <div id="toolbar" className="bg-lxgray-800 rounded-lg">
    <select className="ql-font" defaultValue="" onChange={(e) => e.persist()}>
      <option value="serif text-red-900">Serif</option>
      <option value="sans-serif">Sans Serif</option>
      <option value="monospace">Monospace</option>
    </select>
    <select className="ql-size">
      <option value="small">Small</option>
      <option value="medium">Medium</option>
      <option value="large">Large</option>
      <option value="huge">Huge</option>
    </select>
    <button className="ql-bold">Bold</button>
    <button className="ql-italic">Italic</button>
    <button className="ql-underline">Underline</button>
    <button className="ql-strike">Strike</button>
    <button className="ql-blockquote">Blockquote</button>
    <button className="ql-list" value="ordered">
      Ordered List
    </button>
    <button className="ql-link">Link</button>
    <button className="ql-image">Image</button>
  </div>
);

export default CustomToolbar;
export { formats, modules };
