const PeopleIcon = ({ color = "#CDCED2" }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.22805 5.5996C9.20359 5.5996 9.99443 4.70417 9.99443 3.59961C9.99443 2.49504 9.20359 1.59961 8.22805 1.59961C7.2525 1.59961 6.46167 2.49504 6.46167 3.59961C6.46167 4.70417 7.2525 5.5996 8.22805 5.5996Z"
        fill={color}
      />
      <path
        d="M12.4672 3.99999C13.4427 3.99999 14.2336 3.10457 14.2336 2C14.2336 0.895429 13.4427 0 12.4672 0C11.4916 0 10.7008 0.895429 10.7008 2C10.7008 3.10457 11.4916 3.99999 12.4672 3.99999Z"
        fill={color}
      />
      <path
        d="M3.9883 3.99999C4.96385 3.99999 5.75468 3.10457 5.75468 2C5.75468 0.895429 4.96385 0 3.9883 0C3.01276 0 2.22192 0.895429 2.22192 2C2.22192 3.10457 3.01276 3.99999 3.9883 3.99999Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4072 9.20039V11.6004C11.4072 11.7064 11.3701 11.8084 11.3037 11.8832C11.2376 11.9584 11.1475 12.0004 11.0539 12.0004H5.40149C5.30788 12.0004 5.21779 11.9584 5.15173 11.8832C5.08531 11.8084 5.04822 11.7064 5.04822 11.6004V9.20039C5.04822 7.65399 6.15538 6.40039 7.52115 6.40039H8.93425C10.3 6.40039 11.4072 7.65399 11.4072 9.20039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1138 10.3998H15.2933C15.3869 10.3998 15.477 10.3578 15.5431 10.2826C15.6095 10.2078 15.6466 10.1058 15.6466 9.9998V7.5998C15.6466 6.0534 14.5394 4.7998 13.1737 4.7998H11.7606C11.0144 4.7998 10.3453 5.17421 9.89172 5.76581C11.1798 6.22581 12.1138 7.5894 12.1138 9.1998V10.3998Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56395 5.76605C6.11034 5.17445 5.44124 4.80005 4.69512 4.80005H3.28201C1.91625 4.80005 0.809082 6.05365 0.809082 7.60004V10C0.809082 10.106 0.846176 10.208 0.912592 10.2828C0.978654 10.358 1.06874 10.4 1.16236 10.4H4.34184V9.20004C4.34184 7.58964 5.27591 6.22605 6.56395 5.76605Z"
        fill={color}
      />
    </svg>
  );
};

export default PeopleIcon;
