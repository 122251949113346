import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {useForm} from "react-hook-form"
import Dropdown from "./Dropdown";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { createJobApi, jobJDAutoFilling, popupShowCreate } from "../../../slices/jobSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../config/store";
import Attach from "../../../assets/icons/Attach";
import Switch from "../Switch";
import { useNavigate } from "react-router-dom";
import { LuUploadCloud } from "react-icons/lu";
import uploadContent from "../../../config/UploadContent";
import { v4 as uuidv4 } from 'uuid';
import { baseUrl, contentUrl } from "../../../utils/contant";
import { Job } from "../../../models/job";


interface Props{
  authorId?:string,
  authorType?:string,
  setIsOpen?:Function,
  isOpen?:boolean
}

const CreateJob = ({authorId,authorType,setIsOpen,isOpen}:Props) => {
  const dispatch=useAppDispatch()
  const navigate=useNavigate()
  const {userId,token}=useSelector((state:RootState)=>state.login.session)
  const { skills,preferredLocation,qualification,jobTypes, workplaceTypes, industries ,currencies,experienceMinRange,experienceMaxRange,minSalaries,maxSalaries,functions,cities} = useAppSelector(
    (state) => state.appConfig
  );

  const {control,handleSubmit,register,watch,setValue,formState:{errors}}=useForm()
  const hideCompanyCheckBox=useRef<HTMLInputElement>()
  const hideSalaryCheckBox=useRef<HTMLInputElement>()
  const documentRef=useRef<HTMLInputElement>()
  const inputRef = useRef(null);
  const [isUploading,setIsUploading]=useState<boolean>(false)
  const [jd,setJD]=useState("")
  const [jdUrl,setJdUrl]=useState("")
  const minimumExperience=watch("experience.min")
  const minimumSalary=watch("salary.min")
  const [curentIndex,setCurrentIndex]=useState(0)
  const [expIndex,setExpIndex]=useState(0)
  

    useEffect(()=>{
      const index = minSalaries.findIndex(item => parseInt(item.Title) === minimumSalary);
      setCurrentIndex(index)
      // console.log(index);
    },[minimumSalary])
    
    useEffect(()=>{
      const index = experienceMinRange.findIndex(item => parseInt(item.Title) === minimumExperience);
      setExpIndex(index)
      // console.log(index);
    },[minimumExperience])

  const onSubmit = async(data) => {
    // console.log(data,"DATTATa")
    data.salary={
      min:data.salary.min,
      max:(data.salary.min===0||data.salary.min===1)?data.salary.min:data.salary.max
    }
   
   
    try{
      const response= await dispatch(createJobApi({authorId:(authorId?authorId:userId), authorType:authorType?authorType:"USER",functions:[""],qualification:" ",...data}))
      if (response) {
        console.error("Error creating job:", response);
        // Optionally show a user-facing error message
      } 
    }
    catch (error) {
      console.error("Caught error:", error);
      // Optionally show a user-facing error message
    }

    // console.log(response,"Response")
    if(authorType=="COMPANY"){
      navigate(`/tab/company/${authorId}`)
    }
    else{
      navigate(`/tab/jobs/hiring`)   
    }
    setIsOpen(false)
    
  };

  const handleChange=  (event)=>{
    // console.log(event)
    setIsUploading(true)
    
    const selectedFile=event.target.files
    console.log(selectedFile)

    uploadContent.uploadJD(userId, selectedFile,  async(response) => {
      //console.log(response,jobId);
      const { contentType, name, size, url } = response[0];
      const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
      };
      console.log(attachment)
      setValue("jd",JSON.stringify(attachment))
      setJD(selectedFile[0].name)
      // setJdUrl(url)
      // console.log("attachmentURL",url)
      setIsUploading(false)
      const formData =  new FormData();
      formData.append(
        "jd_file",
        `${baseUrl}/${contentUrl}/${url}?t=${token}`
      );
      const autoFillResponse = await dispatch(jobJDAutoFilling({data:formData}))
      console.log("RESPONSE AUTOFILL",autoFillResponse.payload.data.data)
        if (autoFillResponse.payload.data.data) {
          const filledData = autoFillResponse.payload.data.data; 
          console.log(filledData)// Adjust based on your actual response structure
          for (const key in filledData) {
            if (filledData.hasOwnProperty(key)) {
              setValue(key, filledData[key]); // Set the form values with auto-filled data
            }
          }
        }
      });  
    
    
  }
  return (
    <> 
    <div className="mx-12 p-8 dark:bg-lxgray-700 mt-1 rounded-lg">

      <div className="flex justify-between  items-center px-1">
        <h1 className="text-white text-lg font-semibold">
          Create Your Job Post
        </h1>
      </div>
      
      <div onClick={()=>inputRef?.current?.click()} className="flex items-center gap-3 justify-center text-white bg-lxgray-200  w-full rounded p-2 hover:bg-white hover:text-golden hover:font-semibold cursor-pointer ">
          <h1 className=" "><LuUploadCloud /></h1>
          <input
          ref={inputRef}
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 mb-4 hidden"
          accept=".doc, .docx, .pdf"
          placeholder="Attach your resume"
          type="file"
          onChange={handleChange}
          // 
        />
          <div className="">
          <button  {...register("jd")} className=" rounded p-1 w-full " type="button">  {isUploading ? 'Uploading...' : 'Upload your Job Description (i.e:- .pdf , .docx) '}</button>
         </div>
      </div>
      <h1 className="text-base text-lxgreen-100 mt-1">{jd}</h1>
      <div className="text-center text-gray-300 my-4">
        <h1>or</h1>
      </div>
      

      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col mt-1'>
      <div className="   flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
          Enter Job Title *
        </label>
        <input
          type="text"
          placeholder="Enter Title"
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
          {...register('title',{
            required:true
          })}
          
       />
       {errors.title && (
            <p className="text-red-500">Job title is required.</p>
          )}
      </div>

      <div className="   flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
          Company Name *
        </label>
        <input
          type="text"
          placeholder="Your Company Name"
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
          {...register('company',{
            required:true
          })}
        />
        {errors.company && (
            <p className="text-red-500">Company name is required.</p>
          )}
        <div className="flex p-1 text-sm text-lxgray-300 items-center justify-between">
          <span className="">*You can Hide the company name from candidates</span>
          <div className="flex items-center">
         
          <input ref={hideCompanyCheckBox} type="checkbox"  defaultChecked={false} onChange={(e)=>setValue("hideCompany",e.target.checked)} className="hidden" />
          </div>
          <div onClick={()=>hideCompanyCheckBox.current?.click()}  {...register('hideCompany',{value:false})}><Switch/></div>
        </div>
      </div>

      <div className="flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
          Industry *
        </label>
        <div className="text-white">
          <Dropdown name="industries" control={control} defaultValue={[]} placeholder={"Choose The Relevant Industry"} options={industries.map((ele)=>{
          return { value:ele.Name,label:ele.Name}
          })}  />
        </div> 
      </div>

      {/* <div className="flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
          Functions *
        </label>
        <div className="text-white">
          <Dropdown name="functions" control={control} defaultValue={[]} placeholder={"Choose The Relevant Industry"} options={functions.map((ele)=>{
          return { value:ele.Name,label:ele.Name}
          })}  />
        </div> 
      </div> */}

      <div className="  flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
          Skills *
        </label>
        <div className="text-white">
          <Dropdown name="skills" control={control} defaultValue={[]} placeholder={"Choose The Relevant skills"} options={skills.map((ele)=>{
          return { value:ele.Name,label:ele.Name}
          })}  />
        </div> 
      </div>

      <div className="  flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
        Qualification *
        </label>
        <div className="text-white">
          <Dropdown name="qualifications" control={control} defaultValue={[]} placeholder={"Choose The Relevant qualification"} options={qualification.map((ele)=>{
          return { value:ele.Title,label:ele.Title}
          })}  />
        </div> 
      </div>

      {/* <div className="   flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
         Qualification *
        </label>
        <div className="text-white">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('qualification')}>
            <option className="text-lxgray-300" value="" disabled>Select your  qualification</option>
            {qualification.map((ele,i)=><option key={`${i}jobType`} value={ele.Title}>{ele.Title}</option>)}
          </select>
        </div>
        {/* <input
          type="text"
          placeholder="Enter your qualification"
          className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
          {...register('qualification',{
            required:true
          })}
        /> 
        {errors.qualification && (
            <p className="text-red-500">Qualification is required.</p>
          )}
      </div> */}



      <div className="flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
          Job Type *
        </label>
        <div className="text-white">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('jobType')}>
            <option value="" disabled>Select</option>
            {jobTypes.map((ele,i)=><option key={`${i}jobType`} value={ele.Title}>{ele.Title}</option>)}
          </select>
        </div>
      </div>
     
      <div className=" flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
          Workplace Type *
        </label>
        <div className="text-white">
        <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('workplaceType')}>
          <option value="" disabled>Select</option>
          {workplaceTypes.map((ele,i)=><option key={`${i}jobType`} value={ele.Title}>{ele.Title}</option>)}
        </select>
        </div>
      </div>
      
      <div className="  flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
        Location *
        </label>
        <div className="text-white">
          <Dropdown name="location" control={control} defaultValue={[]} placeholder={"Choose The Relevant location"} options={preferredLocation.map((ele)=>{
          return { value:ele.Title,label:ele.Title}
          })}  />
        </div> 
      </div>

      {/* <div className=" flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
          
        </label>
        <div className="text-white">
        <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('location')}>
          <option value="" disabled>Select</option>
          {cities.map((ele,i)=><option key={`${i}jobType`} value={ele.Name}>{ele.Name}</option>)}
        </select>
        </div>
        {errors.location && (
            <p className="text-red-500">Location is required.</p>
          )}
      </div> */}

      <div className=" flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300">
          Experience *
        </label>
        <div className="flex justify-between">
          <div className="text-white w-1/2">
            <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={""} {...register('experience.min', { setValueAs: (value) => parseInt(value) ,required:true})}>
            <option value="" disabled>Minimum</option>
            {experienceMinRange.map((ele,i)=><option key={`${i}expMIN`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
            </select>
          </div>
          <div className="text-white relative w-1/2 pl-2">
            <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={""} {...register('experience.max', { setValueAs: (value) => parseInt(value) ,required:true})} >
            <option value="" disabled>Maximum</option>
            {experienceMaxRange.filter((ele)=>ele.Id>expIndex+1).map((ele,i)=><option key={`${i}expMAX`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
            </select>
          </div>
        </div>
      </div>
      {errors['experience.min'] && (
              <p className="text-red-500">Minimum Experience is required</p>
            )}
       {errors['experience.max'] && (
              <p className="text-red-500">Maximum  Experience is required</p>
            )}
    
      <div className="flex text-lxgray-300 flex-wrap">
        <div className="flex min-w-1/5 flex-col pr-1">
          <label htmlFor="" className="text-sm p-1 ">
            Currency *
          </label>
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={"INR"} {...register('currency')} required>
          {currencies.map((ele,i)=><option key={`${i}curr`} value={ele.Title}>{ele.Title}</option>)}
          </select>
        </div>
        <div className=" flex flex-1 gap-1 justify-between ">
          
          <div className="flex flex-col w-1/2 pl-1 ">
            <label htmlFor="" className="text-sm p-1">
              Select your min salary  *
            </label>
            <select  className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={""}  {...register('salary.min',{ setValueAs: (value) => parseFloat(value) ,required:true})} >
            <option value="" disabled>Minimum</option>
            {minSalaries?.map((ele,i)=><option key={`${i}minSAL`} value={ele.value}>{ele.Title}</option>)}
            </select>
          </div>

          <div className="flex flex-col w-1/2 pl-1">
            <label htmlFor="" className="text-sm p-1 ">
              Select your max salary  *
            </label>
            <select disabled={(minimumSalary===0||minimumSalary===1)?true:false} className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={""} {...register('salary.max',{ setValueAs: (value) => parseFloat(value) })} >
                <option value="" disabled>Maximum</option>
                {/* <option value="Open" >Open</option>
                <option value="Not Disclosed" >Not Disclosed</option> */}
                {minimumSalary && minSalaries?.filter((ele)=>ele.Id>curentIndex+1).map((ele,i)=><option key={`${i}maxSAL`} value={ele.value}>{ele.Title}</option>)}
            </select>
          </div>

        </div>

        {errors['salary.min'] && (
              <p className="text-red-500">{`Minimum salary is required`}</p>
            )}
       {/* {errors['salary.max'] && (
              <p className="text-red-500">{`Maximum salary is required`}</p>
            )} */}

      </div>
        <div className="flex p-1 text-sm text-lxgray-300 items-center justify-between mb-3">
          <span className="">*You can Hide the salary from candidates</span>
          <div className="flex items-center">
          <input type="checkbox" ref={hideSalaryCheckBox} defaultChecked={false} onChange={(e)=>setValue("hideSalary",e.target.checked)} className="hidden" />
          </div>
          <div onClick={()=>hideSalaryCheckBox.current?.click()} {...register('hideSalary',{value:false})}><Switch/></div>
        </div>

        <div className="text-lxgray-300 mb-4 flex flex-col">
          <label htmlFor="" className="text-sm p-1 ">
            Hiring For *
          </label>
          <div className="flex items-center">
            <input
              type="radio"
              value="CLIENT"
              className=" w-4 h-4 mr-2 "
              {...register('hiringFor')}
            />
            <label htmlFor="client" className="text-white p-1 mr-6 ">
              Client
            </label>

            <input
              type="radio"
              value="COMPANY"
              className=" h-4 w-4 mr-2"
              {...register('hiringFor')}
            />
            <label htmlFor="company" className="text-white p-1 ">
              Company
            </label>
          </div>
        </div>

        <div className="text-lxgray-300 flex flex-col mb-4 relative">
          <label htmlFor="" className="text-sm p-1">
            Enter Job Description *
          </label>
          <textarea
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
            name=""
            id=""
            rows={15}
            placeholder="Start typing here..."
            maxLength={26000}
            {...register('description',{
              required: 'Description is required.',
              maxLength: {
                value: 26000, // Adjusted for approximate word limit
                message: 'Description cannot exceed 26000 words.',
              },
            })}
          
          >
          </textarea>
          {/* <input type="file" ref={documentRef} className="hidden"/>
          <div onClick={()=>documentRef.current.click()} className="absolute bottom-2 right-2 cursor-pointer"><Attach/></div> */}
        </div>

     

        <div className="text-end">
          <button type="submit" className="text-white text-lg bg-golden px-12 py-2 rounded-lg font-semibold tracking-wide">
          Create
          </button>
        </div>
      </form>
    </div>
    </>
  );
};

export default CreateJob;
