import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMyClubsapi, getNextHostedClubApi } from "../../../slices/clubSlice";
import { Link } from "react-router-dom";
import CommonCard from "../BothCommon/CommonCard";
import InfiniteScroll from "react-infinite-scroll-component";

const OwnedClubs = () => {
  const dispatch = useAppDispatch();
  const { clubs ,metadata} = useAppSelector((state) => state.clubs.hostedClubs);

  useEffect(() => {
    dispatch(getMyClubsapi({ clubType: "hosting" }));
  }, []);


  const getMoreDataHandler=()=>{ 
    console.log("HOSTED")
    dispatch(getNextHostedClubApi({}))   
  }
  return (
    <>
    <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={clubs?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
      <div className="flex flex-wrap gap-2 justify-start">
        {clubs &&
          clubs.map((club) => (
            <Link key={club.id} to={`/tab/clubs/${club.id}`}>
              <CommonCard
                details={{
                  title: club?.title,
                  detail: club?.details,
                  bannerImage: club?.bannerImage,
                  members: club.members,
                  cardType: "club",
                }}
              />
            </Link>
          ))}
      </div>
      </InfiniteScroll>
      <div>
        {clubs && clubs.length == 0 && (
          <h1 className="text-lxgray-200 text-center">No Clubs Available</h1>
        )}
      </div>
    </>
  );
};

export default OwnedClubs;
