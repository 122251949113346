import { createSlice } from "@reduxjs/toolkit";

interface AppReduxState {
  isMenuClicked: boolean;
}
const initialState: AppReduxState = {
  isMenuClicked: false,
};
export const staticSlice = createSlice({
  name: "staticPageData",
  initialState,
  reducers: {
    toggleMobileSideMenu: (state) => {
      state.isMenuClicked = !state.isMenuClicked;
    },
    closeMobileSideMenu: (state) => {
      state.isMenuClicked = false;
    },
  },
});

export const { toggleMobileSideMenu, closeMobileSideMenu } =
  staticSlice.actions;
export default staticSlice.reducer;
