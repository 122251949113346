interface ProfileTextAreaPropTypes {
  value: string;
  title: string;
  error: string;
  placeholder?: string;
  name: string;
  className?: string;
  onChange: () => void;
  rows: number;
  maxLength: number;
}

const TextArea: React.FC<ProfileTextAreaPropTypes> = ({
  title,
  error,
  name,
  maxLength,
  className,
  ...props
}) => {
  return (
    <>
      {title && (
        <label htmlFor={name} className="ml-2 text-lxgray-200 text-sm">
          {title}
        </label>
      )}
      <div className="relative">
        <textarea
          className={`h-auto w-full text-lxgray-100
          bg-lxgray-700 outline-none px-4 py-2 text-base ring-0 focus:ring-0
          focus:outline-none border-2 border-lxgray-400 rounded-xl overflow-auto scrollbar-hidden placeholder:text-lxgray-400 ${className}`}
          style={{ resize: "none" }}
          id={name}
          {...props}
        />
        <p
          className={`absolute right-4 bottom-3 text-base text-lxgray-400 font-normal `}
        >
          {props?.value?.length || "0"} / {maxLength}
        </p>
      </div>
      <p className="text-red-900">{error}</p>
    </>
  );
};

export default TextArea;
