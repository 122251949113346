import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddCompanyEmployee,
  Company,
  CompanyEmployees,
  CreateNewCompanyRequest,
  DeleteCompanyRequest,
  FollowCompanyRequest,
  GetCompanyDetailRequest,
  GetOtpForDomainVerify,
  UpdateCompanyRequest,
  VerifyDomainRequest,
  VerifyOtpRequest
} from "../models/company";
import companyService from "../services/company.service";
import { toast } from "react-toastify";
import { logout } from "./loginSlice";
import { PaginatedMetadata } from "../models/base";
// import { clearPosts } from "../post/postSlice";


export interface CompanyReduxState {
  companies: Array<Company>;
  companyMetadata:PaginatedMetadata
  company: Company;
  employees: Array<CompanyEmployees>;
}
const initialState: CompanyReduxState = {
  companies: [],
  companyMetadata:null,
  company: null,
  employees: []
};



export const verifyCompanyDomain = createAsyncThunk(
  "company/verifyDomain",
  async (data: VerifyDomainRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.verifyDomain(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getOtpDomainVerification = createAsyncThunk(
  "company/getOtpDomainVerification",
  async (data: GetOtpForDomainVerify, { getState, rejectWithValue }) => {
    try {
      return await companyService.getOtpForDomainVerify(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyCompanyOtpApi = createAsyncThunk(
  "company/verifyOtpApi",
  async (data: VerifyOtpRequest, { getState, rejectWithValue }) => {
    try {
      return await companyService.verifyDomainOtp(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanyDetail = createAsyncThunk(
  "company/getCompanyDetail",
  async (data: GetCompanyDetailRequest, { getState, rejectWithValue, dispatch }) => {
    try {
      //dispatch(clearPosts())
      const response = await companyService.getCompanyDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompaniesApi = createAsyncThunk(
  "company/getCompaniesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      return await companyService.getAllCompanies();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (data: CreateNewCompanyRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.createCompany(data)
       toast("Company created successfully")
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (data: UpdateCompanyRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.updateCompany(data)
      // actionCompletedMessage("Company details updated successfully", "success", 2000);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const followCompanyApi = createAsyncThunk(
  "company/updateCompany",
  async (data: FollowCompanyRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.followCompany(data)
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteCompanyReq = createAsyncThunk(
  "company/deleteCompanyReq",
  async (data: DeleteCompanyRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.deleteCompany(data)
      // actionCompletedMessage("Company deleted successfully", "success", 2000);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addEmoloyeeApi = createAsyncThunk(
  "company/addEmoloyee",
  async (data: AddCompanyEmployee, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.addEmployee(data)
      // actionCompletedMessage("Employee added successfully", "success", 2000);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeEmployeeApi = createAsyncThunk(
  "company/removeEmployeeApi",
  async (data: AddCompanyEmployee, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.removeEmployee(data)
      // actionCompletedMessage("Employee remove successfully", "success", 2000);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllEmployeesApi = createAsyncThunk(
  "company/getAllEmployeesApi",
  async (data: GetCompanyDetailRequest, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.getAllEmployees(data)
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateEmployeeRoleApi = createAsyncThunk(
  "company/updateEmployeeRoleApi",
  async (data: AddCompanyEmployee, { getState, rejectWithValue }) => {
    try {
      const response = await companyService.updateEmployeeRole(data)
      // actionCompletedMessage("Employee Role updated successfully", "success", 2000);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextCompanyApi = createAsyncThunk(
  "company/getNextCompanyApi",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await companyService.getCompanyByUrl(
        state.company.companyMetadata.nextResultURL
      );
      //dispatch(updateClubs(response.data.data.clubs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);



export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCompaniesApi.fulfilled,
        (state: CompanyReduxState, action) => {
          state.companies = action.payload.data.data.companies;
          state.companyMetadata=action.payload.data.data.metadata;
        }
      )
      .addCase(getNextCompanyApi.fulfilled,(state,action)=>{
        // console.log(action.payload,"CLLUB  NEXT APIIII")
        // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
        state.companies=[...state.companies,...action.payload.data.data.companies]
        state.companyMetadata=action.payload.data.data.metadata
      })
      .addCase(
        getCompanyDetail.fulfilled,
        (state: CompanyReduxState, action) => {
          console.log(action.payload.data, "this is company detials")
          state.company = action.payload.data.data;
        }
      )
      .addCase(
        getAllEmployeesApi.fulfilled,
        (state: CompanyReduxState, action) => {
          state.employees = action.payload.data.data.employees;
          console.log(action.payload.data, "this is company employees")
        }
      )
      .addCase(
        addEmoloyeeApi.fulfilled,
        (state: CompanyReduxState, action) => {
          console.log(action.payload.data, "this is Added Employee")
        }
      )
      .addCase(
        removeEmployeeApi.fulfilled,
        (state: CompanyReduxState, action) => {
          let employees = state.employees;
          employees = employees.filter((emp) => emp.id !== action.meta.arg.userId)
          state.employees = employees;
        }
      )
      .addCase(
        updateEmployeeRoleApi.fulfilled,
        (state: CompanyReduxState, action) => {
          let employees = state.employees;
          employees.map((emp) => {
            if (emp.id === action.meta.arg.userId) {
              emp.pageRole = action.meta.arg.pageRole
            }
            return emp;
          })
          state.employees = employees;
        }
      )
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });

  },
});


export default companySlice.reducer;