import * as React from "react";

function VideoIcon() {
  return (
    <svg
      width={30}
      height={25}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22.75c0 .5.375.875.875.875h2.688v-1.75H.874c-.5 0-.875.375-.875.875zM29.125 21.875h-21.5v1.75h21.5c.5 0 .875-.375.875-.875s-.375-.875-.875-.875zM1.875 19.25h26.25A1.842 1.842 0 0030 17.375v-15.5A1.842 1.842 0 0028.125 0H1.875A1.842 1.842 0 000 1.875v15.5c0 1.062.813 1.875 1.875 1.875zm9.688-12.813c0-.875 1-1.437 1.75-.937L18 8.687a1.11 1.11 0 010 1.813l-4.688 3.187c-.75.5-1.75 0-1.75-.937V6.437zM5.563 21.062a.827.827 0 00-.813.813v1.812c0 .438.375.813.813.813a.827.827 0 00.812-.813v-1.812c0-.5-.375-.813-.813-.813z"
        fill="#8C8C8C"
      />
    </svg>
  );
}

export default VideoIcon;
