import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getAllBookmarkApi } from '../../slices/postSlice'
// import {bookmarks} from "../bookmark/bookConstant"
import GlobalPosts from '../globalSearch/GlobalPosts'
import OtherLists from '../globalSearch/OtherLists'

const Bookmarks = () => {
    const dispatch=useAppDispatch()
    const bookmark=useAppSelector((state)=>state.post)
    const {bookmarks}=bookmark

    const [activeTab, setActiveTab] = useState('all');
    useEffect(()=>{
        dispatch(getAllBookmarkApi())
    },[])
    const posts=bookmarks?bookmarks?.filter((ele)=>ele.tableType=="post").map((ele)=>ele.content.data):[]
    const events=bookmarks?bookmarks?.filter((ele)=>ele.tableType=="event").map((ele)=>ele.content.data):[]
    const clubs=bookmarks?bookmarks?.filter((ele)=>ele.tableType=="club").map((ele)=>ele.content.data):[]
    // const clubs=[]

    const title = [
        { title: "Posts", count: posts.length },
        { title: "Events", count: events.length },
        { title: "Clubs", count: clubs.length },
    ];
  return (
    <div className='bg-lxgray-800 min-h-screen flex  '>
        
        <div className='  flex  flex-col  pl-[5%] py-5 w-1/5  gap-4'>
                <h1
                    className={`${activeTab==="all"?"text-golden":"text-white"}  hover:text-golden text-lg font-semibold cursor-pointer`}
                    onClick={() => setActiveTab('all')} // Option to show all
                >
                    All ({posts.length + events.length + clubs.length}) {/* Show total count for All */}
                </h1>
            {
                title.map((ele)=>{
                    return (
                        <h1  onClick={() => setActiveTab(ele.title.toLowerCase())} className={`${activeTab===ele.title.toLowerCase()?"text-golden":"text-white"}  hover:text-golden text-lg font-semibold cursor-pointer border-b border-lxgray-400 px-2 `}>{ele.title} ({ele.count})</h1>
                    )
                })
            }
          
        </div>
       
        <div className='flex-1'>
             { posts.length==0 && events.length==0 && clubs.length==0 && <div className='text-white bg-lxgray-700 mt-3 w-2/3  mx-auto p-3'>
                <h1 className='text-center'>No Bookmarks yet...</h1>
              </div>}

              <div>
                  {/* Post Results */}
                  { (activeTab === 'all' || activeTab === 'posts') && ( posts.length>0) &&
                    <div className='text-white bg-lxgray-700 mt-3 w-2/3  mx-auto p-3 '>
                      <h1 className='text-lg font-medium py-2'>Posts Results</h1>
                      <GlobalPosts feeds={posts}/> 
                    </div>
                  }

              </div>

              {/* Event Results */}
              {
                (activeTab === 'all' || activeTab === 'events') && (events.length>0) && 
                  <div className='text-white bg-lxgray-700 mt-3 w-2/3 mx-auto p-3 '>
                      <h1 className='text-lg font-medium py-2'>Events Results</h1>
                      {
                          events.map((ele)=>{
                              return (
                                  <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400'>
                              <OtherLists title={`${ele.title}`} imgUrl={ele.bannerImageUrl} description={ele.details} description2={ele?.isOffline.toString()=="true"?"Offline":"Online"} buttonText={"Attend"} urlLink={`/tab/events/${ele.id}`}/>
                            </div>
                          )
                      })
                  }
                    </div>
                  }

                {/* ClubResults */}
                {
                  (activeTab === 'all' || activeTab === 'clubs') && (clubs?.length>0) && 
                  <div className='text-white bg-lxgray-700 mt-3 w-2/3 mx-auto p-3 '>
                    <h1 className='text-lg font-medium py-2'>Clubs Results</h1>
                    {
                    clubs.map((ele)=>{
                        return (
                          <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400'>
                            <OtherLists title={`${ele.title}`} imgUrl={ele.bannerImage} description={`${ele.noOfApplicants.toString()} members`}  buttonText={"Join"} urlLink={`/tab/clubs/${ele.id}`}/>
                          </div>
                        )
                      })
                    }
                  </div>
                }
      
            </div>
    </div>
  )
}

export default Bookmarks
