import React, { useEffect, useState } from 'react'
import background from "../../assets/images/default_profile_banner.png"
import EditDots from '../../assets/icons/EditDots'
import CompanyPosts from './CompanyPosts'
import CompanyEvents from './CompanyEvents'
import CompanyMemberShip from './CompanyMemberShip'
import CompanyClubs from './CompanyClubs'
import CompanyJobs from './CompanyJobs'
import PeopleIcon from '../../assets/icons/PeopleIcon'
import banner from "../../assets/images/default_profile_banner.png"
import { useParams } from 'react-router-dom'
import { getCompanyDetail } from '../../slices/companySlice'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { baseUrl ,contentUrl} from '../../utils/contant'
import CommonPopup from './CommonPopup'
import UpdateCompany from './UpdateCompany'
import ManageAdmin from './ManageAdmin'
import { multipleValueDropdownByName } from '../../utils/nocoFunctions'
import ReportPopup from '../../components/ReportPopup'

const Company = () => {
    const dispatch=useAppDispatch() 
    const {company}=useAppSelector((state)=>(state.company))
    const {industries}=useAppSelector((state)=>(state.appConfig))
    const { token } = useAppSelector((state) => state.login.session);
    const [tab,setTab]=useState("posts")
    const {companyId}=useParams()
    const [show,setShow]=useState<boolean>(false)
    const [seeMore,setSeeMore]=useState(false)
    const [isOpen,setIsOpen]=useState<boolean>(false)
    const [isOpenManageAdmin,setIsOpenManageAdmin]=useState<boolean>(false)
    const [isPopupReportVisible, setPopupReportVisible] = useState(false);

    useEffect(()=>{
      dispatch(getCompanyDetail({id:companyId}))
    },[companyId])

    const handleEdit=()=>{
        setIsOpen(true)
        setShow(false)
    }

    const handleMangeAdmin=()=>{
      setIsOpenManageAdmin(true)
      setShow(false)

    }

    const handleReport=()=>{
      console.log("LETS REPRT")
      setPopupReportVisible(true)
      setShow(false)
    }


  return (
    <div className="flex gap-2 text-white flex-wrap w-[80%] mx-auto">
      <div className="bg-lxgray-600 flex-1">
        <div className="relative">
              <img
                src={`${company?.bannerImage? `${baseUrl}/${contentUrl}/${company.bannerImage}?t=${token}`:background}`}
                alt="Banner Image"
                className="w-full h-72 object-cover rounded-lg"
              />
              <div className="absolute left-0 right-0  transform -translate-y-1/2">
              <img
                src={`${company?.profileImage? `${baseUrl}/${contentUrl}/${company.profileImage}?t=${token}`:background}`}
                alt="Banner Image"
                className=" h-28 w-28 rounded-full object-cover border border-white mx-auto"
              />
              </div>
          </div>

          {isOpen && <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><UpdateCompany/></CommonPopup>}
          {isOpenManageAdmin && <CommonPopup setIsOpen={setIsOpenManageAdmin} isOpen={isOpenManageAdmin}><ManageAdmin/></CommonPopup>}

        <div className="px-4 py-4 flex flex-col items-center mt-10 relative">
                <div className=''>
                    <div className="flex justify-between mb-2 gap-4">
                        <h2 className="text-2xl font-bold">{company?.title}</h2>
                        <div className="absolute -top-4 right-4">
                          <div className="cursor-pointer p-2" onClick={()=>setShow(!show)}><EditDots/></div>
                        </div>
                    </div>
                    {show && <div className='absolute right-10 -top-2 bg-lxgray-700 rounded-lg'>
                      <ul className='text-lxgray-100 cursor-pointer'>
                        {(company?.PageRole==="superadmin") && <li onClick={handleEdit} className='px-6 text-center border-b border-lxgray-200 pt-1 hover:bg-lxgray-300'>Edit</li>}
                        {/* <li className='px-6 text-center border-b border-lxgray-200 pt-1 hover:bg-lxgray-300'>Share</li> */}
                        <li onClick={handleReport} className='px-6 text-center border-b border-lxgray-200 pt-1 hover:bg-lxgray-300'>Report</li>
                        {(company?.PageRole==="admin"||company?.PageRole==="superadmin") && <li onClick={handleMangeAdmin} className='px-6 text-center  pt-1 hover:bg-lxgray-300'>Manage Admin</li>}
                      </ul>
                    </div>}
                </div>
                <div className='flex flex-col items-center gap-1'>
                        <h2 className='text-lxgray-200 text-sm'>{company?.location}</h2>
                        <h1 className='text-lxgray-100 text-lg text-center'>{company?.tagline}</h1>
                        <div className='flex items-center gap-2'>
                        <PeopleIcon/>
                        <p> {company?.followerCount} Followers</p>
                        </div>
                 </div>
          

                
            
        </div>

        <div className='text-lxgray-100 text-md px-12 py-2'>
          <p className={`whitespace-pre-line ${!seeMore?"line-clamp-2":""} `}>
            {company?.description}
          </p>
          {company?.description.length>300 && <p onClick={()=>setSeeMore(!seeMore)} className=" cursor-pointer text-golden font-semibold">{!seeMore?"see More":"see Less"}</p>}
        </div>

        <div className='border-t border-lxgray-300 py-4 px-4 flex  justify-around '>

                    <div className=' '>

                        <div className='flex gap-6 mb-4'>
                            <h2 className='text-lxgray-200'>Industry:</h2>
                            <p>{multipleValueDropdownByName(company?.industry,industries)}</p>
                        </div>
                        
                        <div className='flex gap-6 '>
                            <h2 className='text-lxgray-200'>Employee Size:</h2>
                            <p>{company?.size} Employees</p>
                        </div>
                    </div>

                    <div className=' '>
                        <div className='flex gap-6 mb-4'>
                            <h2 className='text-lxgray-200'>Website Link:</h2>
                            <p className='text-golden'>{company?.pageName}</p>
                        </div>
                        <div className='flex gap-6'>
                            <h2 className='text-lxgray-200'>Company:</h2>
                            <p>{company?.registrationType}</p>
                        </div>
                    </div>
                    
                </div>
        

        <div className="shadow-inner shadow-black">
          <div className="flex justify-start  gap-20 text-lg  border-b border-lxgray-400 py-2 pl-8">
            <h1 onClick={()=>setTab("posts")} className={tab==="posts"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Posts</h1>
            {company?.PageRole!=="contentadmin" && <h1 onClick={()=>setTab("jobs")} className={tab==="jobs"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Jobs</h1>}
            {/* <h1 onClick={()=>setTab("membership")} className={tab==="membership"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Memberships</h1> */}
            { company?.PageRole!=="hiringmanager" && <h1 onClick={()=>setTab("events")} className={tab==="events"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Events</h1>}
            {/* <h1 onClick={()=>setTab("clubs")} className={tab==="clubs"?"text-golden border-b-2 border-golden px-2 cursor-pointer":"text-lxgray-200 px-2 cursor-pointer"}>Clubs</h1> */}
          </div>
          {tab==="posts" && <CompanyPosts companyId={companyId}/>}
          {tab==="jobs" && <CompanyJobs companyId={companyId}/>}
          {tab==="membership" && <CompanyMemberShip/>}
          {tab==="events" && <CompanyEvents authorId={companyId}/>}
          {tab==="clubs" && <CompanyClubs/>}
        </div>
        
      </div>

      {/* <div className='rounded '>
        <div className='h-fit  flex flex-col items-center bg-lxgray-500 rounded text-lxgray-200'>
          <img src={banner} alt="BANNER" className='w-80 h-60' />
          <h1 className='animate-pulse text-white'>AdvertiseMent</h1>
          <p>You may also Know</p>
        </div>  
      </div> */}

      <ReportPopup
      visible={isPopupReportVisible}
      onClose={setPopupReportVisible}
      postId={companyId}/>   
    
     
    </div>
  )
}

export default Company
