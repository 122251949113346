import React, { useEffect } from 'react'
import JobCard from './JobCard';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { getHiringJobs } from '../../../slices/jobSlice';
import NoRecentJobs from "../../../assets/images/NoRecentJobs.png";

const Hiring = () => {
  const dispatch=useAppDispatch()
  const {createdJobList,inActiveJobList,closedJobList} = useAppSelector((state) => state?.job);
  const {userId}=useAppSelector((state)=>state.login.session)

  useEffect(()=>{
    dispatch(getHiringJobs({authorId:userId}))
    // dispatch(getHiringJobs({authorId:userId,status:"CLOSED"}))
    // dispatch(getHiringJobs({authorId:userId,status:"INACTIVE"}))
  },[])

  // console.log(Object.keys(createdJobList))
  // console.log("Created",createdJobList)
  const openJobs = createdJobList?.ids.length>0? Object.values(createdJobList?.entities):[];
  const inActiveJobs = inActiveJobList?.ids.length>0?Object.values(inActiveJobList?.entities):[];
  const closedJobs =closedJobList?.ids.length>0? Object.values(closedJobList?.entities):[];

  return (
    <div>
      {openJobs.length>0 && <JobCard title="Open" active={true} jobs={openJobs}/>}
      {inActiveJobs.length>0 && <JobCard title="InActive" active={true} jobs={inActiveJobs}/>}
      {closedJobs.length>0 && <JobCard title="Closed" active={true} jobs={closedJobs}/>}
      {inActiveJobs.length===0 && closedJobs.length===0  && openJobs.length===0 && (
        <div className="flex justify-center mt-2">
          <img src={NoRecentJobs} alt="No Jobs Available" className="" />
        </div>
      )}
    </div>
  )
}

export default Hiring;
