import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AcceptNetworkRequest,
  GetMyNetworks,
  GetNetworkSuggestionResponse,
  GetNetworkSuggestions,
  Network,
  ReceivedNetwork,
  ReceivedNetworkInvitation,
  RemoveNetworkRequest,
  SendNetworkRequest,
  SendNetworkRequestResponse,
  SentNetworkRequest,
} from "../models/network";

import networkService from "../services/network.service";
import { logout } from "./loginSlice";
import { PaginatedMetadata } from "../models/base";

export interface NetworkReduxState {
  //state: Network[];
  networkToggle: number;
  networkSuggestions: GetNetworkSuggestionResponse;
  sendNetworkRequest: SendNetworkRequestResponse;
  receivedNetworkRequests: Array<ReceivedNetwork>;
  receivedNetworkRequestMetadata:PaginatedMetadata;
  sentNetworkRequests: Array<ReceivedNetwork>;
  sentNetworkRequestMetadata:PaginatedMetadata;
  myNetworks: GetMyNetworks;
}
const initialState: NetworkReduxState = {
  networkToggle: 0,
  networkSuggestions: {networkSuggestions:null,metadata:null},
  sendNetworkRequest: null,
  receivedNetworkRequests: [],
  receivedNetworkRequestMetadata:null,
  sentNetworkRequests: [],
  sentNetworkRequestMetadata:null,
  myNetworks: {} as GetMyNetworks,
};

export const getNetWorkSuggestionsApi = createAsyncThunk(
  "network/getSuggestions",
  async (data: GetNetworkSuggestions, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.getAllNetworkSuggestions(data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getReceivedRequestApi = createAsyncThunk(
  "network/getReceivedRequest",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.getReceivedRequest();
      // console.log(response, "response##############################");
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendNetworkRequestApi = createAsyncThunk(
  "network/sendRequest",
  async (data: SendNetworkRequest, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.sendNetworkRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSentNetworkRequestsApi = createAsyncThunk(
  "network/sentRequest",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.getSentNetworkRequests();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMyNetworksApi = createAsyncThunk(
  "network/myNetwork",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.getMyNetworks();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// export const getMyNetworkApi = createAsyncThunk(
  //   "event/getMyNetworkApi",
  //   async (data: GetMyNetworksRequest, { getState, rejectWithValue }) => {
  //     try {
  //       const response = await eventService.getMyNetworks(data);
  //       console.log(response, "my network Response");
  //       return response;
  //     } catch (error) {
  //       return rejectWithValue(error.response?.data);
  //     }
  //   }
  // );


export const acceptNetworkRequestApi = createAsyncThunk(
  "network/acceptNetwork",
  async (data: AcceptNetworkRequest, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.acceptNetworkRequest(data);
      console.log("network Accepted successfully");
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeNetworkApi = createAsyncThunk(
  "network/removeNetwork",
  async (data: RemoveNetworkRequest, { getState, rejectWithValue }) => {
    try {
      const response = await networkService.removeNetwork(data);
      console.log("network Accepted successfully");
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextRecommendationApi = createAsyncThunk(
  "network/getNextRecommendationApi",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
     
      // console.log("SCHK",state)
      const response = await networkService.getNetworkByUrl(
        // state.job.metadataJob.nextResultURL
        state.networks.networkSuggestions.metadata.nextResultURL
      );
      // dispatch(updateJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextMyNetworkApi = createAsyncThunk(
  "network/getNextMyNetworkApi",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
     
      // console.log("SCHK",state)
      const response = await networkService.getNetworkByUrl(
        // state.job.metadataJob.nextResultURL
        state.networks.myNetworks.metadata.nextResultURL
      );
      // dispatch(updateJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextSentRequestApi = createAsyncThunk(
  "network/getNextSentRequestApi",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
     
      console.log("SCHK", state.networks.sentNetworkRequestMetadata.metadata.nextResultURL)
      const response = await networkService.getNetworkByUrl(
        // state.job.metadataJob.nextResultURL
        state.networks.sentNetworkRequestMetadata.metadata.nextResultURL
      );
      console.log(response)// dispatch(updateJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    toggleNetworkTab: (state, action) => {
      state.networkToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getNetWorkSuggestionsApi.fulfilled,
        (state: NetworkReduxState, action) => {
          console.log(action.payload)
          state.networkSuggestions = action.payload.data.data;
        }
      )
      .addCase(
        getReceivedRequestApi.fulfilled,
        (state: NetworkReduxState, action) => {
          // console.log(action.payload.data, "You are ahere@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
          console.log(action.payload.data.data.received, "You are ahere")
          state.receivedNetworkRequests = action.payload.data.data.received;
          state.receivedNetworkRequestMetadata=action.payload.data.data.metadata
        }
      )
      .addCase(
        sendNetworkRequestApi.fulfilled,
        (state: NetworkReduxState, action) => {
          const networksArray = state.networkSuggestions.networkSuggestions.filter(
            (network) =>
              network.suggestedUserId != action.payload.data.data.toUserId
          );
          state.networkSuggestions.networkSuggestions = networksArray;
        }
      )
      .addCase(
        getSentNetworkRequestsApi.fulfilled,
        (state: NetworkReduxState, action) => {
          state.sentNetworkRequests = action.payload.data.data.sent;
          state.sentNetworkRequestMetadata=action.payload.data.data.metadata
        }
      )
      .addCase(
        getMyNetworksApi.fulfilled,
        (state: NetworkReduxState, action) => {
          console.log(action.payload.data, "this is my networks");
          state.myNetworks = action.payload.data.data;
        }
      )
      .addCase(
        removeNetworkApi.fulfilled,
        (state: NetworkReduxState, action) => {
          console.log(
            action.payload.data,
            "this is my@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
          );
          const updatedNetwork=state.myNetworks.network.filter((item)=>item.id!==action.payload.data.data.id)
          state.myNetworks.network=updatedNetwork
        }
      )
      .addCase(
        acceptNetworkRequestApi.fulfilled,
        (state: NetworkReduxState, action) => {
          // console.log(
          //   action.payload.data,
          //   "this is my@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
          // );
          if(action.meta.arg.status=="withdraw"){
            const updatedSentRequests=state.sentNetworkRequests.filter((item)=>item.id!==action.meta.arg.networkId)
            state.sentNetworkRequests=updatedSentRequests
          }

          if(action.meta.arg.status=="accept"){
            const updatedSentRequests=state.receivedNetworkRequests.filter((item)=>item.id!==action.meta.arg.networkId)
            state.receivedNetworkRequests=updatedSentRequests
          }

          if(action.meta.arg.status=="reject"){
            const updatedSentRequests=state.receivedNetworkRequests.filter((item)=>item.id!==action.meta.arg.networkId)
            state.receivedNetworkRequests=updatedSentRequests
          }
        }
      )
      .addCase(getNextRecommendationApi.fulfilled,(state,action)=>{
           
            if(action.payload.data.data){
                state.networkSuggestions.networkSuggestions=[...state.networkSuggestions.networkSuggestions,...action.payload.data.data.networkSuggestions]
                state.networkSuggestions.metadata=action.payload.data.data.metadata
            }
       })

       .addCase(getNextMyNetworkApi.fulfilled,(state,action)=>{
           
        if(action.payload.data.data){
            state.myNetworks.network=[...state.myNetworks.network,...action.payload.data.data.network]
            state.myNetworks.metadata=action.payload.data.data.metadata
        }
       })
       .addCase(
        getNextSentRequestApi.fulfilled,
        (state: NetworkReduxState, action) => {
          console.log(action.payload,"SENT REQUEST")
          state.sentNetworkRequests = [...state.sentNetworkRequests,...action.payload.data.data.sent];
          state.sentNetworkRequestMetadata=action.payload.data.data.metadata
        }
      )
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });
  },
});

//export const companySlice = createSlice({});

//export const { verifyCompanyDomain } = companySlice.//;
export const { toggleNetworkTab } = networkSlice.actions;
export default networkSlice.reducer;
