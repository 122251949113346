const RemoveImageIcon = ({ width = "29", height = "29", color = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M14.4364 0.62793C6.7286 0.62793 0.480469 6.87606 0.480469 14.5838C0.480469 22.2916 6.7286 28.5397 14.4364 28.5397C22.1441 28.5397 28.3922 22.2916 28.3922 14.5838C28.3922 6.87606 22.1441 0.62793 14.4364 0.62793ZM22.4474 19.8272L19.6797 22.5949L14.4364 17.3515L9.19301 22.5949L6.42528 19.8272L11.6686 14.5838L6.42528 9.34047L9.19301 6.57274L14.4364 11.8161L19.6797 6.57274L22.4474 9.34047L17.2041 14.5838L22.4474 19.8272Z"
        fill={color}
      />
    </svg>
  );
};

export default RemoveImageIcon;
