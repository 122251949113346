import SignupForm from "./SignupForm";

import LogoLight from "../../assets/images/LinkWhite_logo.png";
import BgScreen2 from "../../assets/images/Web_Login.jpg";

const WebSignUp = () => {
  return (
    <div
      className="w-full bg-cover min-h-svh pb-6"
      style={{
        backgroundImage: `url(${BgScreen2})`,
      }}
    >
      <header className="w-full flex justify-center">
        <section className="w-[95%] mx-auto flex justify-start items-center">
          <img src={LogoLight} alt="LinkCxO" className="h-12" />
        </section>
      </header>
      <div className="w-[40%] mx-auto bg-lxgray-700 rounded-2xl">
        <SignupForm />
      </div>
    </div>
  );
};

export default WebSignUp;
