import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { loginThroughLinkedinApi } from '../../slices/loginSlice';
import { getUserDetailApi } from '../../slices/userSlice';
import { RootState } from '../../config/store';

const LoginWithLinkedin = () => {
   const dispatch=useAppDispatch()
   const ui = useAppSelector((state: RootState) => state.login.ui);
   const {session} = useAppSelector((state: RootState) => state.login);
   const { accountExists } = ui;
   const location = useLocation();
   const navigate = useNavigate();
   const from = location.state?.from?.pathname || "/";

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
  
      if (code) {
        console.log(code)
        dispatch(loginThroughLinkedinApi(code))
        // Exchange the code for an access token
        // axios.post('https://www.linkedin.com/oauth/v2/accessToken', null, {
        //   params: {
        //     grant_type: 'authorization_code',
        //     code: code,
        //     redirect_uri: REDIRECT_URI,
        //     client_id: LINKEDIN_CLIENT_ID,
        //     client_secret: 'your_client_secret',
        //   },
        // })
        // .then(response => {
        //   const accessToken = response.data.access_token;
        //   // Use the access token to fetch user data or store it as needed
        // })
        // .catch(err => {
        //   console.error(err);
        // });
      }
    }, [location]);

    useEffect(() => {
      if (accountExists) {
        // navigate("/tab");
        const fetchData = async () => {
          try {
             await dispatch( getUserDetailApi({ userId: session.id, })
            );
  
            // setUser(payload.data.data.userDetails);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
        navigate(from, { replace: true })
        // navigate("/tab")
      } else if (accountExists !== null) {
        navigate("/signup");
      }
    }, [accountExists]);
  
    return <div>Logging you in...</div>;
}

export default LoginWithLinkedin
