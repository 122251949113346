import { FC, useEffect, useRef, useState } from "react";

import "../../../../App.css";

import CrossIcon from "../../../../assets/icons/CrossIcon";

import { useAppDispatch } from "../../../../config/hooks";

import {
  closeFeedsModal,
  setArticleAuthor,
  togggleFeedsModalType,
} from "../../../../slices/homeFeedSlice";
import ArticleIcon from "./../../../../assets/icons/ArticleIcon";
import InsightsIcon from "./../../../../assets/icons/InsightsIcon";
import PollIcon from "./../../../../assets/icons/PollIcon";
import SurveyIcon from "./../../../../assets/icons/SurveyIcon";
import QueryIcon from "./../../../../assets/icons/QueryIcon";
import PostIcon from "./../../../../assets/icons/PostIcon";
// import PostMenuCard from "@linkcxo/core/src/components/advanced/common/PostMenuCard";
import { uuid } from "../../../../utils/uuid";
import PostMenuCard from "../../../../components/advanced/common/PostMenuCard";

interface ProfilePopupProps {
  isOpen: boolean;
  popupHeight?: string;
  authorType:string,
  authorId:string
}

export interface PostType {
  title: string;
  description: string;
  icon: unknown;
  type: string; // Add a 'type' property to match the expected type for onClickModal
  target: string; // If 'target' is used in history.push(postType.target)
}

const HomePopup: FC<ProfilePopupProps> = ({
  isOpen,
  popupHeight = "max-h-[551px]",
  authorId,authorType
}) => {
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [HoverState, setHoverState] = useState("#EFEFF3");

  useEffect(()=>{
    if(authorId && authorType){
      dispatch(setArticleAuthor({authorId:authorId,authorType:authorType}))
    }
  },[authorId,authorType])

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!menuRef.current.contains(e.target as Node)) {
        // onClose();
        closePopupHandler();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
 

  console.log("isOPEN",isOpen)
  const closePopupHandler = () => {
    dispatch(closeFeedsModal());
  };

  const hidePopuphandler = () => {
    if (!isOpen)
       dispatch(togggleFeedsModalType());
  };

  const postTypeMenu: PostType[] = [
    {
      icon: <PostIcon />,
      title: "Create a Post",
      target: `/post`,
      type: "post",
      description:
        "Create a Post and Engage with Your Network and empower your voice.",
    },
    {
      icon: <ArticleIcon />,
      title: "Compose an Article",
      target: "/article/new",
      type: "article",
      description:
        "Write an Article and Inspire Your Network with your findings!",
    },
    // {
    //   icon: <InsightsIcon />,
    //   title: "Create Insight",
    //   target: "InsightScreen",
    //   type: "InsightScreen",
    //   description: "Create a News Short and reach to the communuity.",
    // },
    {
      icon: <PollIcon />,
      title: "Create a Poll",
      target: "/poll",
      type: "poll",
      description: "Create a Poll & gather Insights and Decisions Together.",
    },
    {
      icon: <SurveyIcon />,
      title: "Create a Survey",
      target: "SurveyScreen",
      type: "SurveyScreen",
      description: "Create a Survey & gather Insights and Decisions Together.",
    },
    {
      icon: <QueryIcon />,
      title: "Post as a Query",
      target: "/query",
      type: "query",
      description:
        "Create a Query and Collaborate on Ideas and with Community.",
    },
  ];

  return (
    <div
      className={`fixed items-center top-0 
       left-0 w-full h-full flex justify-center ${
         !isOpen && "popup-closeanimation"
       } z-[55]`}
      onAnimationEnd={hidePopuphandler}
    >
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black opacity-50 ${
          !isOpen && "hidden"
        }`}
      ></div>
      <div
        ref={menuRef}
        className={`fixed z-10 bg-lxgray-700 p-6 shadow-md ${popupHeight} w-[60%] shadow-2xl ${
          isOpen && "popup-animation"
        }`}
      >
        <div>
          <div className="w-full flex justify-between">
            <h1 className="text-white text-xl font-bold tracking-wide">
              Share Your Experience !
            </h1>
            <button
              onClick={closePopupHandler}
              onMouseEnter={() => setHoverState("#AA933E")}
              onMouseLeave={() => setHoverState("#EFEFF3")}
              className="text-golden hover:rotate-90 transition-all duration-200"
            >
              <CrossIcon color={HoverState} />
            </button>
          </div>
          <p className="text-base text-lxgray-400 font-normal">
            Let the linkCxO community know your thoughts and express your ideas
            and experiences.
          </p>
        </div>
        <div className="w-full">
          <div
            className="mt-2"
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "10px",
            }}
          >
            {postTypeMenu.map((postType) => {
              return (
                <div className="cursor-pointer" key={uuid()}>
                  <PostMenuCard
                    postType={postType}
                    closeMenuHandler={closePopupHandler}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePopup;
