import React from "react";

const Job_Time = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path   
        
          d="M10.2221 2.03548H12.7148C13.7915 2.03548 14.6618 2.90572 14.6765 3.98245V8.4205C14.4424 8.24107 14.1849 8.09056 13.9095 7.97429V6.26868C12.523 6.66692 10.9153 6.91767 9.21906 7.02092V7.37491C9.21906 7.80266 8.86507 8.15666 8.43732 8.15666H6.84434C6.4166 8.15666 6.0626 7.80266 6.0626 7.37491V7.02092C4.38112 6.91767 2.78814 6.66692 1.40166 6.26868V10.7084C1.40166 11.3721 1.9474 11.9031 2.59639 11.9031H9.12003C9.17469 12.1712 9.25999 12.4281 9.37212 12.6701H2.59639C1.51965 12.6701 0.649414 11.7999 0.649414 10.7231V3.98245C0.649414 2.90572 1.51965 2.03548 2.59639 2.03548H5.08911V0.988239C5.08911 0.442495 5.53161 0 6.07735 0H9.23381C9.77956 0 10.2221 0.442495 10.2221 0.988239V2.03548ZM6.07735 0.752241C5.9446 0.752241 5.84135 0.85549 5.84135 0.988239V2.03548H9.46981V0.988239C9.46981 0.85549 9.36656 0.752241 9.23381 0.752241H6.07735ZM12.7295 2.78772H2.59639C1.93265 2.78772 1.40166 3.31871 1.41641 3.98245V5.48694C2.78814 5.89993 4.39587 6.16543 6.07735 6.26868V5.94418C6.07735 5.51644 6.43135 5.16244 6.85909 5.16244H8.48157C8.90932 5.16244 9.26331 5.51644 9.26331 5.94418V6.26868C10.9448 6.16543 12.5525 5.89993 13.9243 5.48694V3.98245C13.9243 3.31871 13.3785 2.78772 12.7295 2.78772ZM8.49632 7.37491V5.94418L6.85909 5.89993L6.82959 7.37491L8.46682 7.40441C8.48157 7.40441 8.49632 7.38966 8.49632 7.37491Z"
          fill="#8C8C8C"
        />
        <path
         
    
          d="M12.549 8.40039C11.0037 8.40039 9.74902 9.65503 9.74902 11.2004C9.74902 12.7457 11.0037 14.0004 12.549 14.0004C14.0944 14.0004 15.349 12.7457 15.349 11.2004C15.349 9.65503 14.0944 8.40039 12.549 8.40039ZM12.7357 8.78072V8.96039C12.7357 9.06352 12.6522 9.14706 12.549 9.14706C12.4459 9.14706 12.3623 9.06352 12.3623 8.96039V8.78072C11.1719 8.87149 10.2201 9.82301 10.1293 11.0137H10.309C10.4121 11.0137 10.4957 11.0973 10.4957 11.2004C10.4957 11.3035 10.4121 11.3871 10.309 11.3871H10.1293C10.2201 12.5775 11.1716 13.5293 12.3623 13.6201V13.4404C12.3623 13.3373 12.4459 13.2537 12.549 13.2537C12.6522 13.2537 12.7357 13.3373 12.7357 13.4404V13.6201C13.9262 13.5293 14.8779 12.5778 14.9687 11.3871H14.789C14.6859 11.3871 14.6024 11.3035 14.6024 11.2004C14.6024 11.0973 14.6859 11.0137 14.789 11.0137H14.9687C14.8779 9.82325 13.9264 8.87152 12.7357 8.78072ZM12.3623 9.70707V11.2004C12.3623 11.266 12.3966 11.3269 12.4531 11.3605L13.3864 11.9205C13.4746 11.9734 13.5894 11.945 13.6424 11.8565C13.6954 11.7683 13.6669 11.6535 13.5785 11.6006L12.7359 11.0949V9.70733C12.7359 9.60419 12.6524 9.52066 12.5493 9.52066C12.4461 9.52066 12.3626 9.60419 12.3626 9.70733L12.3623 9.70707Z"
          fill="#8C8C8C"
          stroke="#8C8C8C"
        />
      </svg>
    </div>
  );
};

export default Job_Time;
