import { useMemo } from "react";

import EditIcon from "../../../assets/icons/EditIcon";

import profile from "../../../assets/images/Blank_Placeholders.png";
import { openProfileModal } from "../../../slices/profileSlice";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import Delete_Icon from "../../../assets/icons/Delete_Icon";
import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";
// import { useRouteMatch } from "react-router-dom";

interface StartEndDate {
  month: string;
  year: string;
}

interface UserExperienceDetails {
  location: string;
  locationId: string;
  locationType: string;
  employmentType: string;
  title: string;
  description: string;
  startDate: StartEndDate;
  endDate: StartEndDate;
  isCurrentRole: boolean;
  skills: string[] | null;
  media: string[] | null;
}

interface WorkExperience {
  id: string;
  userId: string;
  organization: string;
  organizationId: string;
  userExperienceDetails: UserExperienceDetails[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface ExperienceCardPropTypes {
  useExperienceData: WorkExperience;
  editExpDetails: boolean;
}

const ExperienceCard: React.FC<ExperienceCardPropTypes> = ({
  useExperienceData,
  editExpDetails,
}) => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.appConfig.jobTypes);

  const { organization, userExperienceDetails, id } = useExperienceData;
  const {
    location,
    employmentType,
    title,
    endDate,
    startDate,
    description,
    isCurrentRole,
  } = userExperienceDetails[0];

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  const jobType =
    options &&
    options.filter((option) => option.Id === Number(employmentType))[0];

  function calculateYearMonthDifference(
    startDate: { year: string; month: string },
    endDate: { year: string; month: string }
  ) {
    const monthToNumber: Record<string, number> = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    // Create Date objects from the provided information
    const start = new Date(
      Number(startDate.year),
      monthToNumber[startDate.month]
    );
    const end = new Date(Number(endDate.year), monthToNumber[endDate.month]);

    // Calculate the difference in months
    const monthDiff =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    // Calculate the number of years and remaining months
    const years = Math.floor(monthDiff / 12);
    const months = monthDiff % 12;

    return { years, months };
  }

  const totalExperience = useMemo(
    () => calculateYearMonthDifference(startDate, endDate),
    [startDate, endDate]
  );

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: id,
    };
    dispatch(openProfileModal(modalTypes));
  };

  return (
    <div className="flex gap-2">
      <div className="w-[70px]">
        <div className="h-[50px] w-[50px]">
          <img
            className="h-full w-full rounded-full object-cover"
            src={profile}
            alt="Organization image"
          />
        </div>
      </div>
      <div className="w-full">
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-xl text-white font-bold">{title}</h1>
            {editExpDetails && (
              <div className="flex flex-row-reverse  gap-4 self-start items-center justify-center">
                <div
                  className="cursor-pointer"
                  onClick={() => toggleModalHandler("experience", true)}>
                  <EditIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    toggleModalHandler("experience?=delete", true)
                  }>
                  <Delete_Icon />
                </div>
              </div>
            )}
          </div>
          <p className="text-base text-golden font-semibold">
            {organization} {`( ${jobType?.Title} )`}
          </p>
        </div>

        <div className="my-2">
          <p className="text-base text-white font-normal tracking-wider">
            {`${startDate.month} ${startDate.year} - ${
              isCurrentRole ? "Present" : endDate.month + " " + endDate.year
            }  | ${
              totalExperience.years === 0
                ? totalExperience.months + " mos"
                : totalExperience.months === 0
                ? totalExperience.years + " yrs"
                : totalExperience.months === 0 && totalExperience.years === 0
                ? ""
                : totalExperience.years +
                  " yrs" +
                  " " +
                  totalExperience.months +
                  " mos"
            }`}
          </p>
          <p className="text-base text-white font-light tracking-wider">
            {location}
          </p>
        </div>

        <div>
          <p
            className={`text-sm text-lxgray-100 font-normal ${
              showMore ? "line-clamp-none" : "line-clamp-3"
            } `}
            ref={contentRef}>
            {description}
          </p>
          {description && isOverflowing && (
            <div className="flex justify-end items-center">
              <button
                className="border-none text-golden text-base font-normal w-[100px] text-center"
                onClick={textToggleHandler}>
                {showMore ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
