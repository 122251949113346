import { InputHTMLAttributes } from "react";
import ErrorValidationIcon from "../../../assets/icons/ErrorValidationIcon";

interface LxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  error: string;
  name: string;
  className?: string;
  isComment?: boolean;
}

const LxInput: React.FC<LxInputProps> = ({
  title,
  error,
  name,
  className,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={`ml-2 text-sm font-normal text-lxgray-200`}
      >
        {title}
      </label>
      <input
        className={`h-14 w-full bg-lxgray-700
      outline-none px-4 text-base ring-0 focus:ring-0
      focus:outline-none border rounded-xl placeholder:text-lxgray-200 text-lxgray-100 ${className}`}
        id={name}
        {...props}
      />
      {error && (
        <p className="text-red-400 px-2 flex items-center justify-start gap-1">
          <span className="self-baseline mt-[5px]">
            <ErrorValidationIcon />
          </span>
          {error}
        </p>
      )}
    </div>
  );
};

export default LxInput;
