import React from "react";

export default function LinkedInIcon() {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <g clipPath="url(#clip0_811_465)">
        <path
          d="M23.06 12.25c0-.78-.07-1.53-.2-2.25H12.5v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#0084FF"
        />
        <path
          d="M12.5 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.68v2.84C4.49 20.53 8.2 23 12.5 23z"
          fill="#34A853"
        />
        <path
          d="M6.34 14.09c-.22-.66-.35-1.36-.35-2.09 0-.73.13-1.43.35-2.09V7.07H2.68C1.93 8.55 1.5 10.22 1.5 12c0 1.78.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12.5 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.95 2.09 15.47 1 12.5 1 8.2 1 4.49 3.47 2.68 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
        <g clipPath="url(#clip1_811_465)" fillRule="evenodd" clipRule="evenodd">
          <path
            d="M3.167 24h18.666a2.667 2.667 0 002.667-2.667V2.667A2.667 2.667 0 0021.833 0H3.167A2.667 2.667 0 00.5 2.667v18.666A2.667 2.667 0 003.167 24z"
            fill="#007EBB"
          />
          <path
            d="M21.167 20.667h-3.562V14.6c0-1.663-.632-2.593-1.948-2.593-1.432 0-2.18.967-2.18 2.593v6.066h-3.433V9.11h3.433v1.557s1.032-1.91 3.484-1.91c2.45 0 4.206 1.497 4.206 4.592v7.317zM5.95 7.598a2.124 2.124 0 01-2.117-2.132c0-1.178.948-2.133 2.117-2.133s2.116.955 2.116 2.133A2.124 2.124 0 015.95 7.598zM4.178 20.667h3.578V9.11H4.178v11.556z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_811_465">
          <path fill="#fff" transform="translate(.5)" d="M0 0H24V24H0z" />
        </clipPath>
        <clipPath id="clip1_811_465">
          <path fill="#fff" transform="translate(.5)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
