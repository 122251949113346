import React from "react";
import RecommendedJobs from "./RecommendedJobs";
import FeaturedCompanies from "./FeaturedCompanies";
import RecentJobSearch from "./RecentJobSearch";
import FeaturedTypeCompanies from "./FeaturedTypeCompanies";
import OpenToWork from "./OpenToWork";
import TopCompanies from "./TopCompanies";
import ProfilePhoto from "./ProfilePhoto";

const Discover = () => {
  return (
    <div className="flex mx-2 py-1">
      <div className="w-2/3 mx-1 ">
        <RecommendedJobs />
        {/* <FeaturedCompanies /> */}
        <RecentJobSearch />
        {/* <FeaturedTypeCompanies /> */}
      </div>
      <div className="w-1/3 pt-4">
        {/* <OpenToWork /> */}
        {/* <TopCompanies /> */}
        <ProfilePhoto />
      </div>
    </div>
  );
};

export default Discover;
