import React from "react";
import JobDescriptionHeaderCard from "./JobDescriptionHeaderCard";
import {Job} from "../../../models/job"
import JobStatus from "./JobStatus";
import ApplicationStatus from "./ApplicationStatus";
import { useAppSelector } from "../../../config/hooks";
import JobDetailDescription from "./JobDetailDescription";
import SideChatBox from "../../messages/SideChatBox";

interface Props{
  jobDes:Job,
  title:string
}

const JobDetail: React.FC<Props>= ({ jobDes ,title}) => {
  const {session} =useAppSelector((state)=>state.login)
  const {active}=useAppSelector((state)=>state.chat.sideChat)
  console.log("jobDES",jobDes)
  return (
    <>
    <div className=" flex-1 text-white p-4 bg-lxgray-700 rounded-lg  ">

      {(jobDes.applied) && <div className="mb-2"><ApplicationStatus subStatus={jobDes?.jobApplication?.subStatus}/></div>}
     
      {/* //Job Details headerCard */}
      <JobDescriptionHeaderCard jobData={jobDes} />
      {(session.userId===jobDes.author.userId ||(jobDes.authorType==="COMPANY" || jobDes.createdBy===session.userId) )?<JobStatus jobDes={jobDes}/>:
      <JobDetailDescription jobDes={jobDes}/>
      }
    </div>
    {active && <div className="fixed bottom-0 right-0 w-1/3 h-3/4">
    <SideChatBox/>
  </div>}
  </>
  );
};

export default JobDetail;
