import React from "react";

export default function ArrowIcon({
  color = "white",
  width = "11",
  height = "18",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.553.427a1.539 1.539 0 00-.497-.316 1.6 1.6 0 00-1.172 0c-.186.073-.355.18-.497.316l-7.94 7.55A1.42 1.42 0 000 9.003c0 .386.161.755.448 1.028l7.942 7.55c.287.269.675.42 1.079.418.404-.001.79-.154 1.076-.425a1.42 1.42 0 00.449-1.023 1.418 1.418 0 00-.439-1.026L3.692 9.004l6.86-6.521A1.42 1.42 0 0011 1.455a1.42 1.42 0 00-.447-1.028z"
        fill={color}
      />
    </svg>
  );
}
