import { createSlice } from "@reduxjs/toolkit";

interface AppReduxState {
  profileSummeryText: string;
  toggleProfileModal: boolean;
  profileModalType: string | null;
  editForm: boolean;
  toggleEditProfilePopup: boolean;
  toggleContactInfoPopup: boolean;
  editFormId: string;
  postId: string;
  commentIdL1: string;
}
const initialState: AppReduxState = {
  profileSummeryText: "",
  toggleProfileModal: false,
  profileModalType: null,
  editForm: false,
  editFormId: "",
  toggleEditProfilePopup: false,
  toggleContactInfoPopup: false,
  postId: "",
  commentIdL1: "",
};
export const profileSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    setProfileSummery: (state, action) => {
      state.profileSummeryText = action.payload;
    },
    setPostId: (state, action) => {
      state.postId = action.payload;
    },
    setCommentIdL1: (state, action) => {
      state.commentIdL1 = action.payload;
    },
    toggleProfileModalHandler: (state, action) => {
      state.toggleProfileModal = action.payload;
    },
    toggleEditProfilPopupeHandler: (state, action) => {
      state.toggleEditProfilePopup = action.payload;
    },
    toggleContactInfoPopupeHandler: (state, action) => {
      state.toggleContactInfoPopup = action.payload;
    },
    modalTypeHandler: (state, action) => {
      state.profileModalType = action.payload;
    },
    editFormHandler: (state, action) => {
      state.editForm = action.payload;
    },
    setFormIdHandler: (state, action) => {
      state.editFormId = action.payload;
    },
    openProfileModal: (state, action) => {
      state.toggleProfileModal = true;
      state.profileModalType = action.payload.profileModalType;
      state.editForm = action.payload.editForm;
      state.editFormId = action.payload.editFormId;
    },
    closeProfileModal: (state) => {
      state.toggleProfileModal = false;
      // state.profileModalType = null;
      state.editForm = false;
      state.editFormId = "";
    },
  },
});

export const {
  setProfileSummery,
  toggleEditProfilPopupeHandler,
  toggleProfileModalHandler,
  modalTypeHandler,
  editFormHandler,
  setFormIdHandler,
  closeProfileModal,
  openProfileModal,
  toggleContactInfoPopupeHandler,
  setPostId,
  setCommentIdL1,
} = profileSlice.actions;
export default profileSlice.reducer;
