import React from 'react'
import SucessImage from "../../../assets/images/SuccessImage.jpg"

const InterestSuccessful = () => {
  return (
    <div className='flex flex-col  gap-2 items-center text-white p-'>
        <img src={SucessImage} alt="SuccessFul" className='w-25 h-25 bg-blend-multiply rounded'/>
        <h1 className='font-bold  text-lg'>Thank you for your interest!</h1>
        {/* <h4 className='text-xs text-lxgray-200'>Transaction ID : 2659874621354</h4> */}
        <p className='text-sm  text-lxgray-200 text-center'>Please wait while our marketing team connects with you shortly. </p>
        <div className='flex gap-4'>
            <button className='px-8 py-2 rounded text-golden border border-golden'>Back</button>
            <button className='px-8 py-2 rounded bg-golden hover:bg-white hover:text-golden'>Ok</button>
        </div>
    </div>
  )
}

export default InterestSuccessful
