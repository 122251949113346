import React from "react";

const AudioPlayIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" filter="url(#filter0_b_3442_30252)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 43.2002C33.7361 43.2002 43.25 33.6862 43.25 21.9502C43.25 10.2141 33.7361 0.700195 22 0.700195C10.2639 0.700195 0.75 10.2141 0.75 21.9502C0.75 33.6862 10.2639 43.2002 22 43.2002ZM19.5852 14.7061L29.2443 21.9504L19.5852 29.1947V14.7061Z"
          fill="#AA933E"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_3442_30252"
          x="-26.4328"
          y="-26.4826"
          width="96.8656"
          height="96.8656"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.5914" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3442_30252"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3442_30252"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default AudioPlayIcon;
