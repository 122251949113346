import React from 'react'
import Exclamatory from "../../../../assets/images/Exclamatory.png"

const NoJobs = ({text}) => {
  return (

    <div className='flex dark:bg-lxgray-800 py-2 items-center justify-center mt-2'>
    <img src={Exclamatory} alt="!" />
      <p className='text-lxgray-100 ml-2'>{text}</p>
    </div>
  )
}

export default NoJobs
