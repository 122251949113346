import PublicationsCard from "../components/PublicationsCard";

import PlusIcon from "../../../assets/icons/PlusIcon";

import { CreatePublication } from "../../../models/user";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { openProfileModal } from "../../../slices/profileSlice";
import NoContent from "../../../components/NoContent";

const EditPublication = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user);

  const { userPublications } = userData;

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
  };
  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full flex justify-between my-7">
          <h1 className="text-white text-xl font-bold">Add Publication</h1>
          <button
            className="text-lg flex gap-2 items-center justify-center text-lxgray-400 font-normal"
            onClick={() => toggleModalHandler("publication", false)}>
            <PlusIcon color="#8C8C8C" /> Add
          </button>
        </div>

        <div className="flex flex-col gap-4">
          {userPublications &&
            userPublications.map(
              (publicationData: CreatePublication, index: number) => {
                return (
                  <PublicationsCard
                    key={index}
                    publicationData={publicationData}
                    editPublicationDetail={true}
                  />
                );
              }
            )}
        </div>
        {userPublications && userPublications.length == 0 && <NoContent />}
      </div>
    </div>
  );
};

export default EditPublication;
