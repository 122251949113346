import React from "react";

const Filter = () => {
  return (
    <div>
      <svg
        width="34"
        height="24"
        viewBox="0 0 34 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9145 13.5333C15.6706 12.9901 15.3061 12.5096 14.8486 12.1285C14.391 11.7474 13.8526 11.4756 13.2742 11.334V1.45833C13.2742 1.20417 13.1733 0.960412 12.9936 0.78069C12.8138 0.600967 12.5701 0.5 12.3159 0.5C12.0617 0.5 11.818 0.600967 11.6383 0.78069C11.4585 0.960412 11.3576 1.20417 11.3576 1.45833V11.3292C11.1282 11.3878 10.9039 11.4647 10.6867 11.5592C9.94008 11.8973 9.31716 12.4594 8.90439 13.1676C8.49162 13.8757 8.30947 14.6947 8.3832 15.5111C8.45693 16.3274 8.7829 17.1005 9.31587 17.7233C9.84885 18.346 10.5624 18.7874 11.3576 18.9862V22.5417C11.3576 22.7958 11.4585 23.0396 11.6383 23.2193C11.818 23.399 12.0617 23.5 12.3159 23.5C12.5701 23.5 12.8138 23.399 12.9936 23.2193C13.1733 23.0396 13.2742 22.7958 13.2742 22.5417V18.991C13.8949 18.8394 14.4691 18.5384 14.9468 18.1141C15.4245 17.6899 15.7912 17.1553 16.0151 16.5569C16.1997 16.0714 16.2859 15.5541 16.2686 15.035C16.2513 14.5159 16.131 14.0054 15.9145 13.5333ZM14.223 15.8812C14.1049 16.1946 13.9114 16.4741 13.6595 16.6948C13.4077 16.9156 13.1053 17.0708 12.7791 17.1467C12.4529 17.2227 12.1131 17.217 11.7896 17.1302C11.4662 17.0434 11.1691 16.8782 10.9247 16.6492C10.6804 16.4202 10.4963 16.1345 10.3887 15.8173C10.2812 15.5002 10.2535 15.1614 10.3081 14.831C10.3628 14.5006 10.4981 14.1887 10.702 13.9231C10.906 13.6575 11.1723 13.4462 11.4774 13.3081C11.7388 13.1877 12.0233 13.1255 12.3111 13.126C12.7033 13.126 13.0873 13.2389 13.417 13.4514C13.7467 13.6638 14.0082 13.9668 14.1703 14.324C14.2834 14.5664 14.3472 14.829 14.3579 15.0963C14.3686 15.3637 14.326 15.6305 14.2326 15.8812H14.223Z"
          fill="#AA933E"
        />
        <path
          d="M33.6449 13.5333C33.4011 12.9901 33.0365 12.5096 32.579 12.1285C32.1215 11.7474 31.5831 11.4756 31.0047 11.334V1.45833C31.0047 1.20417 30.9037 0.960412 30.724 0.78069C30.5443 0.600967 30.3005 0.5 30.0464 0.5C29.7922 0.5 29.5485 0.600967 29.3687 0.78069C29.189 0.960412 29.088 1.20417 29.088 1.45833V11.3292C28.8587 11.3878 28.6343 11.4647 28.4172 11.5592C27.6705 11.8973 27.0476 12.4594 26.6349 13.1676C26.2221 13.8757 26.0399 14.6947 26.1137 15.5111C26.1874 16.3274 26.5134 17.1005 27.0463 17.7233C27.5793 18.346 28.2929 18.7874 29.088 18.9862V22.5417C29.088 22.7958 29.189 23.0396 29.3687 23.2193C29.5485 23.399 29.7922 23.5 30.0464 23.5C30.3005 23.5 30.5443 23.399 30.724 23.2193C30.9037 23.0396 31.0047 22.7958 31.0047 22.5417V18.991C31.6254 18.8394 32.1995 18.5384 32.6772 18.1141C33.1549 17.6899 33.5217 17.1553 33.7455 16.5569C33.9302 16.0714 34.0164 15.5541 33.9991 15.035C33.9818 14.5159 33.8614 14.0054 33.6449 13.5333ZM31.9535 15.8812C31.8354 16.1946 31.6419 16.4741 31.39 16.6948C31.1381 16.9156 30.8357 17.0708 30.5096 17.1467C30.1834 17.2227 29.8435 17.217 29.5201 17.1302C29.1966 17.0434 28.8996 16.8782 28.6552 16.6492C28.4108 16.4202 28.2267 16.1345 28.1192 15.8173C28.0116 15.5002 27.9839 15.1614 28.0386 14.831C28.0932 14.5006 28.2285 14.1887 28.4325 13.9231C28.6364 13.6575 28.9028 13.4462 29.2078 13.3081C29.4694 13.1881 29.7538 13.126 30.0416 13.126C30.4338 13.126 30.8178 13.2389 31.1475 13.4514C31.4772 13.6638 31.7387 13.9668 31.9008 14.324C32.0124 14.5671 32.0745 14.83 32.0835 15.0973C32.0926 15.3647 32.0484 15.6311 31.9535 15.8812Z"
          fill="#AA933E"
        />
        <path
          d="M22.5763 5.29167C22.4325 5.23896 22.2888 5.19583 22.1402 5.1575V1.45833C22.1402 1.20417 22.0392 0.960412 21.8595 0.78069C21.6798 0.600967 21.436 0.5 21.1819 0.5C20.9277 0.5 20.684 0.600967 20.5042 0.78069C20.3245 0.960412 20.2235 1.20417 20.2235 1.45833V5.17188C19.6628 5.31344 19.1398 5.57619 18.6915 5.94162C18.2432 6.30706 17.8805 6.76631 17.6288 7.28704C17.3771 7.80778 17.2426 8.37735 17.2347 8.95567C17.2269 9.53399 17.3459 10.107 17.5833 10.6344C17.7958 11.1079 18.1 11.5346 18.4783 11.8899C18.8566 12.2452 19.3016 12.522 19.7875 12.7044C19.9313 12.7571 20.0798 12.8002 20.2235 12.8385V22.5417C20.2235 22.7958 20.3245 23.0396 20.5042 23.2193C20.684 23.399 20.9277 23.5 21.1819 23.5C21.436 23.5 21.6798 23.399 21.8595 23.2193C22.0392 23.0396 22.1402 22.7958 22.1402 22.5417V12.8338C22.3707 12.7788 22.5953 12.7017 22.811 12.6037C23.5289 12.2849 24.1353 11.7592 24.5527 11.0938C24.9702 10.4285 25.1797 9.65375 25.1545 8.86869C25.1293 8.08364 24.8705 7.32394 24.4112 6.68675C23.9519 6.04956 23.3131 5.56382 22.5763 5.29167ZM23.1033 9.72396C22.9612 10.1009 22.7104 10.4273 22.3828 10.6617C22.0551 10.8961 21.6653 11.0281 21.2626 11.0409C20.86 11.0538 20.4625 10.9469 20.1206 10.7338C19.7787 10.5206 19.5077 10.2109 19.3419 9.84375C19.2018 9.53369 19.1416 9.19348 19.1668 8.85418C19.1921 8.51488 19.3019 8.1873 19.4863 7.90138C19.6707 7.61546 19.9239 7.3803 20.2226 7.21739C20.5213 7.05447 20.856 6.96899 21.1963 6.96875C21.4416 6.96894 21.685 7.01274 21.915 7.09812C22.1629 7.19381 22.3896 7.33744 22.582 7.52078C22.7744 7.70413 22.9287 7.92361 23.0363 8.16667C23.1502 8.40882 23.2148 8.67121 23.2263 8.93857C23.2378 9.20593 23.196 9.47291 23.1033 9.72396Z"
          fill="#AA933E"
        />
      </svg>
    </div>
  );
};

export default Filter;
