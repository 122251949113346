import React from "react";
export interface LxButtonProps {
  color?: "primary" | "secondary";
  onClick?: Function;
  children?: any;
  type?: "submit" | "button";
  className?: string;
}
export default function LxButton({
  color = "primary",
  type = "button",
  onClick = (e) => {},
  children,
  className = "",
}: LxButtonProps) {
  const bgCls = color === "primary" ? "bg-golden" : "bg-transparent";
  const hoverCls = color === "primary" ? "hover:opacity-100" : "";
  const borderCls =
    color !== "primary" ? "border-solid border-2 border-lxgray-200 " : "";
  const textCls =
    color === "primary" ? "text-white" : "text-white";
  return (
    <button
      type={type}
      onClick={(e) => {
        onClick(e);
      }}
      className={`px-4  py-3 w-full flex items-center justify-center rounded-xl font-medium ${bgCls} ${hoverCls} ${borderCls} ${textCls} ${className} `}
    >
      {children}
    </button>
  );
}
