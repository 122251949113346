import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAIRecommendedJobApi, getNextAIRecommendedJobApi } from "../../../slices/jobSlice";
import { Link } from "react-router-dom";
import JobCardBox from "./JobCardBox";
import Loading from "../../../components/basic/Loading";
import { H1Icon } from "@heroicons/react/24/outline";
import InfiniteScroll from "react-infinite-scroll-component";

const RecentJobSearch = () => {
  const dispatch=useAppDispatch()
  const {userId}=useAppSelector((state)=>state.login.session)
  const {aiRecommendedJobs}=useAppSelector((state)=>state.job)
  useEffect(()=>{
    dispatch(getAIRecommendedJobApi({id:userId}))
  },[])

  const getMoreDataHandler=()=>{
    dispatch(getNextAIRecommendedJobApi())
  }
  return (
    <div className="relative">

    <div className="mt-1.5 relative">
        {/* <h2 className="text-white font-bold text-lg">
           AI Recommended Jobs <span className="tracking-wider"></span>
        </h2> */}
         {/* <InfiniteScroll
                                  className="space-y-2 pb-4 no-scrollbar"
                                  dataLength={aiRecommendedJobs?.jobs?.length-5}
                                  next={() => getMoreDataHandler()}
                                  hasMore={aiRecommendedJobs?.metadata?.nextResultURL?true:false }
                                  // height={"calc(100vh - 155px)"}
                                  loader={""}
                                > */}
       {aiRecommendedJobs? <div className="grid grid-cols-2 gap-1.5 ">
            { aiRecommendedJobs?.jobs.map((ele)=>{
              return (
               
                <Link key={ele.id} to={`/tab/jobs/description/Recommended/${ele.id}`}>
                  <JobCardBox data={ele} title={"title"}/>
                </Link>
              
              )
            })     
            }
        </div>
        :
        <h1 className="animate-pulse text-lxgray-200">Loading...</h1>
        }
        {/* </InfiniteScroll>
       <h1 className="  pb-2 text-golden text-center">Loading...</h1> */}
      </div>
    </div>
  );
};

export default RecentJobSearch;
