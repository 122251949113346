import React from "react";

const Experience = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 13 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 2.37402L6.28002 5.1752L3.32364 7.30276L1 2.37402Z"
          stroke="#8C8C8C"
        
        
          
        
        />
        <path
          d="M4.62699 6.36523L5.73745 7.43729V7.75111L3.64062 15.6639L6.28018 17.4618"
          stroke="#8C8C8C"
          
         
         
        
        />
        <path
          d="M11.9997 2.37402L6.71875 5.1752L9.67512 7.30276L11.9997 2.37402Z"
          stroke="#8C8C8C"
        
          
       
          
        />
        <path
          d="M8.37194 6.36523L7.26148 7.43729V7.75111L9.35921 15.6639L6.71875 17.4618"
          stroke="#8C8C8C"
       
        
        
        
        />
        <path
          d="M1 2.14409C1 2.14409 6.29547 -1.46941 11.9996 2.14409"
          stroke="#8C8C8C"
        
       
          
       
        />
      </svg>
    </div>
  );
};

export default Experience;
