import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  AcceptNetworkRequest,
  GetMyNetworks,
  GetNetworkSuggestionResponse,
  GetNetworkSuggestions,
  Network,
  ReceivedNetworkInvitation,
  RemoveNetworkRequest,
  SendNetworkRequest,
  SendNetworkRequestResponse,
  SentNetworkRequest,
} from "../models/network";

class NetwokService {
  getAllNetworkSuggestions(
    getList: GetNetworkSuggestions
  ): AxiosPromise<ApiResponse<GetNetworkSuggestionResponse>> {
    return apiClient.get(
      `/v1/network-suggestion/user/${getList.userId}?buckets=all`
    );
  }

  getReceivedRequest(): AxiosPromise<ApiResponse<ReceivedNetworkInvitation>> {
    return apiClient.get(`/v1/network/received/user`);
  }

  sendNetworkRequest(
    sendRequest: SendNetworkRequest
  ): AxiosPromise<ApiResponse<SendNetworkRequestResponse>> {
    return apiClient.post(`/v1/network`, sendRequest);
  }

  getSentNetworkRequests(): AxiosPromise<ApiResponse<SentNetworkRequest>> {
    return apiClient.get(`/v1/network/sent/user`);
  }

  getMyNetworks(): AxiosPromise<ApiResponse<GetMyNetworks>> {
    return apiClient.get(`/v1/network/user`);
  }

  acceptNetworkRequest(
    networkData: AcceptNetworkRequest
  ): AxiosPromise<ApiResponse<GetMyNetworks>> {
    return apiClient.put(
      `/v1/network/${networkData.networkId}/${networkData.status}`
    );
  }

  removeNetwork(
    networkData: RemoveNetworkRequest
  ): AxiosPromise<ApiResponse<Network>> {
    return apiClient.delete(`/v1/network/user/remove/${networkData.networkId}`);
  }

  getNetworkByUrl(
    nextUrl: string
  ):
    AxiosPromise<ApiResponse<any>> {
    return apiClient.get(nextUrl);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }
}

const companyService = new NetwokService();
export default companyService;
