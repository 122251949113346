import React, { useRef } from 'react'
import PostCard from '../../components/PostCard';
import ImagePost from '../../components/ImagePost';
import PollCard from '../../components/PollCard';
import QueryPost from '../../components/QueryPost';
import SurveyPost from '../../components/SurveyPost';
import ArticlePost from '../../components/ArticlePost';
import { useAppSelector } from '../../config/hooks';

const GlobalPosts = ({feeds}) => {
    const edit = useAppSelector((state) => state.profileData);
    const openComments = useRef(null);
    const { postId } = edit;

    console.log(feeds)
  return (
    <div className='flex flex-col gap-4'>
      {feeds &&
          feeds.map((userContent) => {
            const { postType, id, content, metadata } = userContent;
            const { attachments } = metadata;

            if (postType === "POST") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <ImagePost userContent={userContent} attachments={attachments} />
                </PostCard>
              );
            } else if (postType === "POLL") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <PollCard userContent={userContent} />
                </PostCard>
              );
            } else if (postType === "QUERY") {
              return (
                <PostCard
                  userContent={userContent}
                  key={id}
                  postId={postId}
                  openComments={openComments}
                >
                  <QueryPost
                    userContent={userContent}
                    openComments={openComments}
                  />
                </PostCard>
              );
            } else if (postType === "SURVEY") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <SurveyPost userContent={userContent} />
                </PostCard>
              );
            } else if (postType === "ARTICLE") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <ArticlePost userContent={userContent} />
                </PostCard>
              );
            }
          })}
    </div>
  )
}

export default GlobalPosts
