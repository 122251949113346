import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddEventMemberRequest,
  CreateNewEventRequest,
  DeleteEventRequest,
  // GetAllEventRequest,
  GetEventDetails,
  GetFilteredEventRequest,
  GetMyEventRequest,
  GetMyNetworksRequest,
  UpdateEventRequest,
  Event,
  ActionOnEvent,
  AddEventMedia,
  GetAllEventResponse
} from "../models/event";
import eventService from "../services/event.service";
import { PaginatedMetadata } from "../models/base";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "./loginSlice";

export const createEvent = createAsyncThunk(
  "event/create",
  async (data: CreateNewEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.createEvent(data);
      // console.log(response, "");
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateEventAPi = createAsyncThunk(
  "event/updateEvent",
  async (data: UpdateEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.updateEvent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllEventApi = createAsyncThunk(
  "event/getAllEventApi",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      // const response = await eventService.getAllEvents(data);
      const response = await eventService.getAllEvents();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAIRecommendedEventsApi = createAsyncThunk(
  "event/getAIRecommendedEventsApi",
  async (data: GetEventDetails, { getState, rejectWithValue }) => {
    try {
      // const response = await eventService.getAllEvents(data);
      const response = await eventService.getAIRecommendedEvents(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllFilteredEventApi = createAsyncThunk(
  "event/getAllFilteredEventApi",
  async (data: GetFilteredEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getAllFilteredEvent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMyEventApi = createAsyncThunk(
  "event/getAllFilteredEventApi",
  async (data: GetMyEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getMyEvent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextEventApi = createAsyncThunk(
  "event/getNextEventApi",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await eventService.getEventByUrl(
        state.events.events.metadata.nextResultURL
      );
      //dispatch(updateClubs(response.data.data.clubs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextPendingEventApi = createAsyncThunk(
  "event/getNextPendingEventApi",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await eventService.getEventByUrl(
        state.events.pendingEvents.metadata.nextResultURL
      );
      //dispatch(updateClubs(response.data.data.clubs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextHostedEventApi = createAsyncThunk(
  "event/getNextHostedEventApi",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      // console.log(state,"HOSTED NETA")
      const response = await eventService.getEventByUrl(
        state.events.hoistingEvents.metadata.nextResultURL
      );
      //dispatch(updateClubs(response.data.data.clubs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getNextParticipatedEventApi = createAsyncThunk(
  "event/getNextParticipatedEventApi",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await eventService.getEventByUrl(
        state.events.participatingEvents.metadata.nextResultURL
      );
      //dispatch(updateClubs(response.data.data.clubs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


// export const getMyNetworkApi = createAsyncThunk(
//   "event/getMyNetworkApi",
//   async (data: GetMyNetworksRequest, { getState, rejectWithValue }) => {
//     try {
//       const response = await eventService.getMyNetworks(data);
//       console.log(response, "my network Response");
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const getEventDetailsApi = createAsyncThunk(
  "event/getEventDetails",
  async (data: GetEventDetails, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getEventDeatils(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addEventMembersApi = createAsyncThunk(
  "event/addEventMembersApi",
  async (data: AddEventMemberRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.addEventMembers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteEventApi = createAsyncThunk(
  "event/getAllEventApi",
  async (data: DeleteEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.deleteEvent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const requestToEventApi = createAsyncThunk(
  "event/requestToEventApi",
  async (data: AddEventMemberRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.requestToEvent(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const inviteMembersApi= createAsyncThunk(
   "event/invite",
   async(data:AddEventMemberRequest,{getState,rejectWithValue})=>{
    try{
      const response= await eventService.inviteMembers(data)
      return response
    }
    catch(error){
      return rejectWithValue(error.response?.data)
    }
   }
)

export const getEventMembersApi = createAsyncThunk(
  "event/getEventMembersApi",
  async (data: DeleteEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getEventMembers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const actionsOnMembers = createAsyncThunk(
  "event/actionsOnMembers",
  async (data: ActionOnEvent, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.actionsOnMembers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const actionOnEventInviteApi = createAsyncThunk(
  "event/actionOnEventInviteApi",
  async (data: ActionOnEvent, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.actionEventInvite(data);
      toast("Event Accepted Successfully")
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeMemberApi = createAsyncThunk(
  "event/removeMember",
  async (data: ActionOnEvent, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.removeMember(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getEventsByAuthorApi = createAsyncThunk(
  "event/getEventsByAuthorApi",
  async (data: GetMyEventRequest, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getEventsByAuthorId(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const uploadEventMediaApi = createAsyncThunk(
  "event/uploadEventMediaApi",
  async (data: AddEventMedia, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.uploadEventMedia(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getEventMediaApi = createAsyncThunk(
  "event/getEventMediaApi",
  async (eventId: string, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.getEventMedia(eventId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteEventMediaApi = createAsyncThunk(
  "event/deleteEventMediaApi",
  async (data: AddEventMedia, { getState, rejectWithValue }) => {
    try {
      const response = await eventService.deleteEventMedia(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export interface EventReduxState {
  events: GetAllEventResponse;
  // metadata: PaginatedMetadata;
  eventDetail: null | Event; 
  pendingEvents: GetAllEventResponse;
  hoistingEvents: GetAllEventResponse;
  participatingEvents: GetAllEventResponse;
  eventMembers: Array<any>; 
  eventMedia: Array<any>; 
}

const initialState:EventReduxState={
  events: {} as GetAllEventResponse,
  // metadata: {} as PaginatedMetadata,
  eventDetail: null,
  pendingEvents:{} as GetAllEventResponse, 
  hoistingEvents:{} as GetAllEventResponse, 
  participatingEvents:{} as GetAllEventResponse, 
  eventMembers:[],
  eventMedia:[]
}

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    addParticipatingEvents:(state,action)=>{
      console.log(action.payload)
      console.log(action.payload.data.data.events,"Payload")
      state.participatingEvents=action.payload.data.data.events
    },
    addHoistingEvents:(state,action)=>{
      state.hoistingEvents=action.payload.data.data.events
    },
    addPendingEvents:(state,action)=>{
      state.pendingEvents=action.payload.data.data.events
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventApi.fulfilled, (state, action) => {
        state.events = action.payload.data?.data;
       
      })
      .addCase(getEventDetailsApi.fulfilled, (state, action) => {
        state.eventDetail = action.payload.data?.data;
      })
      .addCase(createEvent.fulfilled, () => {
        toast(" Event Susscessfully Created",)
      
      })
      .addCase(getEventMembersApi.fulfilled,(state, action) => {
          state.eventMembers = action.payload.data.data;
        })
      .addCase(removeMemberApi.fulfilled, (state, action) => {
        state.eventMembers = state.eventMembers.filter(
          (member) => member.id !== action.meta.arg.memberId
        );
        // toast("Member Successfully Removed");
      })
      .addCase(
        getEventMediaApi.fulfilled,
        (state, action) => {
          console.log(action.payload, "action payload");
          state.eventMedia = action.payload.data.data.media
        }
      )
      .addCase(
        uploadEventMediaApi.fulfilled,
        (state, action) => {
          console.log(action.payload, "action payload");
          let media = state.eventMedia;
          media.push(action.payload.data.data);
          state.eventMedia = media;
        }
      )
      // .addCase(actionsOnMembers.fulfilled, (state, action) => {
      //   state.eventMembers = state.eventMembers.filter(
      //     (member) => member.id !== action.meta.arg.memberId
      //   );
      // })
      .addCase( actionsOnMembers.fulfilled, (state, action) => {
          let members = state.eventMembers;
          if (action.meta.arg.action == 'accept') {
            members.map((m) => {
              if(action.meta.arg.memberId==m.id){
                m.status = 'ACCEPTED'
              }
            })
          }
          if (action.meta.arg.action == 'reject') {
            members.map((m) => {
              if(action.meta.arg.memberId==m.id){
                m.status = 'REJECT'
              }
            })
          } 
          state.eventMembers = members;
        }
        
      )
      .addCase(getMyEventApi.fulfilled,(state,action)=>{
            console.log("CHECK",action.meta.arg.status)
            if(action.meta.arg.status==="pending"){
              state.pendingEvents=action.payload.data?.data
            }
            if(action.meta.arg.status==="hosting"){
              state.hoistingEvents=action.payload.data?.data
            }
            if(action.meta.arg.status==="participating"){
              state.participatingEvents=action.payload.data?.data
            }

      })
      .addCase(getEventsByAuthorApi.fulfilled,(state,action)=>{
             state.hoistingEvents=action.payload.data?.data
      })

      .addCase(getNextEventApi.fulfilled,(state,action)=>{
        // console.log(action.payload,"CLLUB  NEXT APIIII")
        // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
        state.events.events=[...state.events.events,...action.payload.data.data.events]
        state.events.metadata=action.payload.data.data.metadata

      })
      .addCase(getNextHostedEventApi.fulfilled,(state,action)=>{
         // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
        state.hoistingEvents.events=[...state.hoistingEvents.events,...action.payload.data.data.events]
        state.hoistingEvents.metadata=action.payload.data.data.metadata
      })
      .addCase(getNextPendingEventApi.fulfilled,(state,action)=>{
        // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
       state.pendingEvents.events=[...state.pendingEvents.events,...action.payload.data.data.events]
       state.pendingEvents.metadata=action.payload.data.data.metadata
     })
     .addCase(getNextParticipatedEventApi.fulfilled,(state,action)=>{
      // console.log(action.payload.data.data.clubs,"CLUB PAYLOAD")
     state.participatingEvents.events=[...state.participatingEvents.events,...action.payload.data.data.events]
     state.participatingEvents.metadata=action.payload.data.data.metadata
      })
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });

  }
});

export const {addHoistingEvents,addParticipatingEvents,addPendingEvents}=eventSlice.actions;
export default eventSlice.reducer
