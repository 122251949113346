import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getPartnersApi } from '../../../slices/rewardSlice'
import { baseUrl, contentUrl } from '../../../utils/contant'
import { Link } from 'react-router-dom'

const Partners = () => {
    const dispatch = useAppDispatch();
    const { partnerList } = useAppSelector((state) => state.rewards);
    const { categoryId } = useParams();
    const [filteredList, setFilteredList] = useState(partnerList);

    useEffect(() => {
        dispatch(getPartnersApi());
    }, [dispatch]);


    useEffect(() => {
        if(categoryId=="all"){
           setFilteredList(partnerList) 
        }
        else{
            const newList = partnerList.filter((item) => item.categoryIds.includes(categoryId));
            setFilteredList(newList);
        }
      
    }, [partnerList, categoryId]);
  
   
    return (
    <div className='text-white flex flex-wrap gap-2 px-[10%] py-10'>     
       {
        filteredList?.map((ele,i)=>{
            return (
                <Link to={`/tab/rewards/products/${ele.id}`} key={`partner-${i}`} >
                    <div                   
                    style={{
                        background: `linear-gradient(to bottom, ${ele?.backgroundColors[0]}, ${ele?.backgroundColors[1]})`
                    }} 
                    className="p-10 flex flex-col items-center gap-5 w-52 h-56 rounded hover:border"
                    >
                            <img src={ele.logoUrl} alt="Partner Image" className='w-20 h-20 object-cover'/>
                            <h1 className='text-center text-base font-semibold'>{ele.name}</h1>
                        </div>
                </Link>
            )
        })
       }
    </div>
  )
}

export default Partners
