import React from "react";

const Industry = ({ width = "18", height = "18" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5359 9.8415C13.4793 7.8001 12.1249 6.0747 10.1851 5.5719C10.1731 5.2801 9.93507 5.0459 9.64227 5.0459H8.30827C8.00767 5.0459 7.76307 5.2919 7.76307 5.5943V5.5967C5.82527 6.0943 4.47167 7.8113 4.41467 9.8415C4.14967 9.9607 3.96387 10.2259 3.96387 10.5347V11.1703C3.96387 11.5899 4.30527 11.9313 4.72487 11.9313H13.2257C13.6453 11.9313 13.9867 11.5899 13.9867 11.1703V10.5347C13.9867 10.2259 13.8011 9.9605 13.5359 9.8415ZM13.1331 9.7737H10.1875V5.9867C11.8743 6.4711 13.0509 7.9821 13.1331 9.7737ZM8.30827 5.4459H9.64227C9.72227 5.4459 9.78747 5.5125 9.78747 5.5943V9.7737H8.16307V5.5943C8.16307 5.5125 8.22827 5.4459 8.30827 5.4459ZM7.76307 6.0109V9.7737H4.81747C4.90027 7.9921 6.07727 6.4897 7.76307 6.0109ZM13.5867 11.1703C13.5867 11.3693 13.4247 11.5313 13.2257 11.5313H4.72487C4.52587 11.5313 4.36387 11.3693 4.36387 11.1703V10.5347C4.36387 10.3357 4.52587 10.1737 4.72487 10.1737H13.2257C13.4247 10.1737 13.5867 10.3357 13.5867 10.5347V11.1703Z"
        fill="#8C8C8C"
        stroke="#8C8C8C"        // Add stroke color
        strokeWidth="0.5"         // Add stroke width to increase boldness
      />
      <path
        d="M9 1C7.2644 1 5.6574 1.5574 4.345 2.5H2.5814C2.471 2.5 2.3814 2.5894 2.3814 2.7C2.3814 2.8106 2.471 2.9 2.5814 2.9H3.8324C2.1014 4.3686 1 6.5576 1 9C1 13.4112 4.5888 17 9 17C10.7356 17 12.3426 16.4426 13.655 15.5H15.4186C15.529 15.5 15.6186 15.4106 15.6186 15.3C15.6186 15.1894 15.529 15.1 15.4186 15.1H14.1676C15.8986 13.6314 17 11.4424 17 9C17 4.5888 13.4112 1 9 1ZM13.825 14.8674V13.5064C13.825 13.3958 13.7354 13.3064 13.625 13.3064C13.5146 13.3064 13.425 13.3958 13.425 13.5064V15.173C12.1776 16.0698 10.65 16.6 9 16.6C4.8094 16.6 1.4 13.1906 1.4 9C1.4 6.6398 2.4816 4.5276 4.175 3.1326V4.4936C4.175 4.6042 4.2646 4.6936 4.375 4.6936C4.4854 4.6936 4.575 4.6042 4.575 4.4936V2.827C5.8224 1.9302 7.35 1.4 9 1.4C13.1906 1.4 16.6 4.8094 16.6 9C16.6 11.3602 15.5184 13.4724 13.825 14.8674Z"
        fill="#8C8C8C"
        stroke="#8C8C8C"        // Add stroke color
        strokeWidth="0.5"         // Add stroke width to increase boldness
      />
    </svg>
  );
};

export default Industry;
