import moment from "moment";

export const LastAppliedDay = (date: Date) => {
    const currentDate: Date = new Date(); 
    const providedDate: Date = new Date(date);
    const differenceMs = currentDate.getTime() - providedDate.getTime();
    let differenceDays : string|number  = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    if(differenceDays==0){
        differenceDays= `Today`
    }
    else if(differenceDays>0 && differenceDays<=30){
        // const changetoMonths=parseInt(differenceDays/30)
        differenceDays=`${differenceDays} days ago`
    }
    else if(differenceDays>30 && differenceDays<365){
        const changetoMonths =Math.floor(differenceDays/30)
        differenceDays=`${changetoMonths} month ago`
    }
    else if(differenceDays>=365){
        const changetoYear =Math.floor(differenceDays/305)
        differenceDays=`${changetoYear} year ago`
    }
    return differenceDays;
}

export const getFormattedDate=(date:Date)=>{
    const dateObject = new Date(date);

    const formattedDate = {
        year: dateObject.getFullYear(),
        month: dateObject.getMonth() + 1, // Adding 1 because getMonth() returns zero-based index
        day: dateObject.getDate()
    };
    
    return formattedDate;
}

export const getFormattedTime=(timeString:string)=>{
    const [hours, minutes] = timeString.split(":").map(Number);

    // Pad minutes with leading zero if necessary
    const paddedMinutes = String(minutes).padStart(2, "0");

    const formattedTime = {
        hour: hours,
        minute: Number(paddedMinutes)
    };
    return formattedTime;
}
  

export const getPostValidity = (validity: string | number) => {
  const providedDate = moment(validity);
  const currentDate = moment();
  const timeRemaining = moment.duration(providedDate.diff(currentDate));
  const daysRemaining = timeRemaining.days();
  const hoursRemaining = timeRemaining.hours();

  return {
    daysRemaining,
    hoursRemaining,
  };
};
