import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Notifications } from "../models/notification";
import notificationService from "../services/notification.service";
import { logout } from "./loginSlice";

const notificationAdaptor = createEntityAdapter<Notifications>({});

export interface NotificationReduxState {
  notifications: EntityState<Notifications,string>;
}
const initialState: NotificationReduxState = {
  notifications: notificationAdaptor.getInitialState(),
};


export const getAllNotificationsApi = createAsyncThunk(
  "notification/getAllNotificationsApi",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const response = await notificationService.getAllNotifications();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllNotificationsApi.fulfilled,
        (state: NotificationReduxState, action) => {
          if (action.payload.data.data) {
            notificationAdaptor.setAll(state.notifications, action.payload.data.data.notifications);
          }
          else {

          }
        }
      )
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });

  },
});

export default notificationSlice.reducer;