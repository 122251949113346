import PlusIcon from "../../../assets/icons/PlusIcon";

import AwardCertificationCard from "../components/Award&CertificationCard";
import NoContent from "../../../components/NoContent";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { openProfileModal } from "../../../slices/profileSlice";

const EditAwards = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user);

  const { userAwards } = userData;

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
  };

  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start">
      <div className="w-[95%] mx-auto">
        <div className="w-full flex justify-between my-7">
          <h1 className="text-white text-xl font-bold">
            Add Award & Certification
          </h1>
          <button
            className="text-lg flex gap-2 items-center justify-center text-lxgray-400 font-normal"
            onClick={() => toggleModalHandler("certification", false)}>
            <PlusIcon color="#8C8C8C" /> Add
          </button>
        </div>

        <div className="mb-4 flex flex-col gap-4">
          {userAwards &&
            userAwards.map((awardData, index) => {
              return (
                <AwardCertificationCard
                  key={index}
                  awardData={awardData}
                  editAwdDetails={true}
                />
              );
            })}
        </div>
        {userAwards && userAwards.length == 0 && (
          <div className="mb-2">
            <NoContent />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditAwards;
