import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import { SearchData, SearchResponse } from "../models/search";


class SearchService {

  userSearch(
    data: SearchData
  ): AxiosPromise<ApiResponse<SearchResponse>> {
    return apiClient.get(`/v1/search?s=${data.text}&filter=${data.tab}`);
  }

}

const searchService = new SearchService();
export default searchService;