import React from "react";

const Calendar = () => {
  return (
    <div>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.05556 6.6H6V9.6H9.05556V6.6ZM8.44444 0V1.2H3.55556V0H2.33333V1.2H1.72222C1.04694 1.2 0.506111 1.737 0.506111 2.4L0.5 10.8C0.5 11.463 1.04694 12 1.72222 12H10.2778C10.9531 12 11.5 11.463 11.5 10.8V2.4C11.5 1.737 10.9531 1.2 10.2778 1.2H9.66667V0H8.44444ZM10.2778 10.8H1.72222V4.2H10.2778V10.8Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Calendar;
