import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getMembershipPlansApi, getMembershipsListApi } from '../../../slices/rewardSlice'
import RightTick from '../../../assets/icons/RightTick'
import PlanDetail from './PlanDetail'
import RewardsPopup from './RewardsPopup'
import PaymentSuccessful from './PaymentSuccessful'
import PaymentSummary from './PaymentSummary'
import logo from "../../../assets/images/Blank_Placeholders.png"
import { MdOutlineCurrencyRupee } from 'react-icons/md'
const Memberships = () => {
  const dispatch=useAppDispatch()
  const {membershipList,membershipPlans}=useAppSelector((state)=>state.rewards)
  const [title,setTitle]=useState("")
  const [price,setPrice]=useState(0)
  const [planId,setPlanId]=useState("")
  const [popupOpen,setPopupOpen]=useState(false)
  const [showPaymentSummary,setShowPaymentSummary]=useState(false)

  useEffect(()=>{
      dispatch(getMembershipsListApi()) 
      dispatch(getMembershipPlansApi())
  },[])
 

  return (
    <div className='relative'>
      <div id={"payment-mem"} className={`text-white mx-2 py-4 ${showPaymentSummary?'opacity-50 pointer-events-none':'' } `}>         
{/*         
        <div>
          <img src={"https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Group%201000006253.jpg"} alt="" className='object-cover w-full mb-2 rounded-lg' />
        </div> */}
        
        <div className='flex gap-4'>
          {/* //Table  */}
          { !title && <table className='table-auto w-full text-left border border-lxgray-400'>
              <thead className='rounded-t-lg'>
                <tr className='bg-lxgray-800/80 text-lxgray-300  '>
                  <th className='px-4 py-2 '></th>
                  <th className='px-4 py-2'>Basic</th>
                  <th className='px-4 py-2'>Premium</th>
                  <th className='px-4 py-2'>Elite</th>
                  
                </tr>
              </thead>
              <tbody>
                {membershipList?.map((membership) => (
                  <tr key={membership.Id} className='border-b border-lxgray-400 text-lxgray-200'>
                    <td className='pl-8 border border-lxgray-400'>{membership.title}</td>
                    <td className='pl-8 border border-lxgray-400'>{membership.basic ? <RightTick/> : ''}</td>
                    <td className='pl-8 border border-lxgray-400'>{membership.premium ? <RightTick/> : ''}</td>
                    <td className='pl-8 border border-lxgray-400'>{membership.elite ? <RightTick/> : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>}

            {/* MemberShip Plans */}
            {title && <div className='flex-1'>
                  <PlanDetail title={title}  setShowPaymentSummary={setShowPaymentSummary} setPrice={setPrice} setPlanId={setPlanId}/>             
            </div>}
            <div className='w-1/3'>
                {title!=="Premium" && 
                    <div className='relative'>
                      <img onClick={()=>setTitle('Premium')} src={"https://stagebucket.blob.core.windows.net/linkcxo/interest-icons-linkcxo/interest-icons-linkcxo/Group%201000006257%20(2).png"} alt="Premium" className='object-cover w-full  mb-2 rounded-lg hover:animate-pulse cursor-pointer' />                     
                       <div className='absolute bottom-0 left-1'>
                          <h1 className='text-golden text-xl  font-bold '>{membershipPlans[1]?.membership.description}</h1>
                          <h2 className='text-sm mb-2 flex items-center text-lxgray-200 line-through '><MdOutlineCurrencyRupee />{membershipPlans[1]?.membership.price}</h2>
                       </div>
                    </div>
                  }
                {title!=="Elite" && 
                <div className='relative'>
                  <img onClick={()=>setTitle('Elite')} src={"https://stagebucket.blob.core.windows.net/linkcxo/interest-icons-linkcxo/interest-icons-linkcxo/Group%201000006256%20(1).png"} alt="Elite" className='object-cover  w-full mb-2 rounded-lg hover:animate-pulse cursor-pointer' />
                  <div className='absolute bottom-0 left-1'>
                      <h1 className='text-golden text-xl  font-bold '>{membershipPlans[0]?.membership.description}</h1>
                      <h2 className='text-sm mb-2 flex items-center text-lxgray-200 line-through '><MdOutlineCurrencyRupee />{membershipPlans[0]?.membership.price}</h2>
                  </div>
                </div>
                }
                {title!=="Basic" &&
                <div className='relative'>
                <img onClick={()=>setTitle('Basic')} src={"https://stagebucket.blob.core.windows.net/linkcxo/interest-icons-linkcxo/interest-icons-linkcxo/Group%201000006258.png"} alt="Basic" className='object-cover  w-full mb-2 rounded-lg hover:animate-pulse  cursor-pointer' />
                <div className='absolute bottom-0 left-1'>
                    <h1 className='text-golden text-xl  font-bold '>{membershipPlans[2]?.membership.description}</h1>
                    <h2 className='text-sm mb-2 flex items-center text-lxgray-200 line-through '><MdOutlineCurrencyRupee />{membershipPlans[2]?.membership.price}</h2>
                </div>
              </div> 
                }
            </div>
        </div>
    
      </div>
        {showPaymentSummary && <div className='absolute top-2 right-0 w-2/5    '>
                <PaymentSummary price={price} logo={logo} title={title}  setShowPaymentSummary={setShowPaymentSummary} setPopupOpen={setPopupOpen} membershipPlan={true} planId={planId}/>         
                       
                </div>}
              { !showPaymentSummary && popupOpen && 
                    <div>
                        <RewardsPopup setPopupOpen={setPopupOpen}><PaymentSuccessful/></RewardsPopup>
                    </div>
                }
    </div>
  );
}

export default Memberships
