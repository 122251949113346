import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddLanguages,
  AddUserAwardRequest,
  AdditionalDetail,
  BasicUser,
  CreateEducationRequest,
  CreateExperienceRequest,
  CreatePublication,
  EmailUpdate,
  GetUserDetailsRequest,
  Language,
  PhoneUpdate,
  UpdateAwardRequest,
  UpdateEducationRequest,
  UpdateExperienceRequest,
  UpdatePublication,
  UpdateUserDetail,
  UserDeleteInfoRequest,
  Verify,
} from "../models/user";
import userService from "../services/user.service";
import { logout } from "./loginSlice";
import { createPostApi, deletePostApi, doActionOnPostApi } from "./postSlice";
import { POST_ACTION_LIKE, POST_ACTION_UNLIKE } from "../models/contants";
import loggerService from "../services/logger.service";
import { SaveUserLog } from "../models/logger";

export interface LoginReduxState {
  userId: string;
}

export interface UserDetailReduxState {
  isConnected:boolean;
  userDetails: BasicUser;
  userExperience: any;
  userLanguages: Array<Language>;
  userEducation: any;
  userAdditionalDetail: AdditionalDetail;
  userPublications: any;
  userAwards: any;
  userPosts: any;
}
const initialState: UserDetailReduxState = {
  isConnected:null,
  userDetails: null,
  userExperience: [],
  userLanguages: [],
  userAwards: [],
  userAdditionalDetail:null,
  userPublications: [],
  userEducation: [],
  userPosts: [],
};

export const getUserDetailApi = createAsyncThunk(
  "user/getUserDetailsApi",
  async (data: GetUserDetailsRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.getUserDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLoginUserDetailApi = createAsyncThunk(
  "user/getLoginUserDetailApi",
  async (data: GetUserDetailsRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.getUserDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOtherUserDetailApi = createAsyncThunk(
  "user/getOtherUserDetailApi",
  async (data: GetUserDetailsRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.getUserDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addExperienceApi = createAsyncThunk(
  "user/addExperienceApi",
  async (data: CreateExperienceRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEducationApi = createAsyncThunk(
  "user/addEducationApi",
  async (data: CreateEducationRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPublicationApi = createAsyncThunk(
  "user/addPublicationApi",
  async (data: CreatePublication, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addPublication(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAwardApi = createAsyncThunk(
  "user/addAwardApi",
  async (data: AddUserAwardRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addAward(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLanguagesApi = createAsyncThunk(
  "user/addLanguagesApi",
  async (data: AddLanguages, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addLanguages(data);
      console.log(response, "response");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const postLanguagesApi = createAsyncThunk(
  "user/postLanguagesApi",
  async (data: AddLanguages, { getState, rejectWithValue }) => {
    try {
      const response = await userService.postLanguages(data);
      console.log(response, "response");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfileApi = createAsyncThunk(
  "user/updateUserProfileApi",
  async (data: UpdateUserDetail, { getState, rejectWithValue }) => {
    try {
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$");
      const response = await userService.updateUserDetail(data);
      console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEducationApi = createAsyncThunk(
  "user/updateEducationApi",
  async (data: UpdateEducationRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updateEducation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userAwardDelete = createAsyncThunk(
  "user/userAwardDelete",
  async (data: UserDeleteInfoRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userEducationDelete = createAsyncThunk(
  "user/userEducationDelete",
  async (data: UserDeleteInfoRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userExperienceDelete = createAsyncThunk(
  "user/userExperienceDelete",
  async (data: UserDeleteInfoRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userPublicationDelete = createAsyncThunk(
  "user/userPublicationDelete",
  async (data: UserDeleteInfoRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.deleteUserInfo(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExperienceApi = createAsyncThunk(
  "user/updateExperienceApi",
  async (data: UpdateExperienceRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updateExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAwardsApi = createAsyncThunk(
  "user/updateAwardsApi",
  async (data: UpdateAwardRequest, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updateAward(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePublication = createAsyncThunk(
  "user/updatePublication",
  async (data: UpdatePublication, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updatePublication(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMyAllPost = createAsyncThunk(
  "home/getMyFeedsApi",
  async (data: { authorId: string }, { getState, rejectWithValue }) => {
    try {
      const response = await userService.getAllPost(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getOtherUsersAllPost = createAsyncThunk(
  "home/getOtherUsersAllPost",
  async (data: { authorId: string }, { getState, rejectWithValue }) => {
    try {
      const response = await userService.getAllPost(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addAdditionalDetailApi = createAsyncThunk(
  "user/addAdditionalDetailApi",
  async (data: AdditionalDetail, { getState, rejectWithValue }) => {
    try {
      const response = await userService.addAdditionalDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdditionalDetails = createAsyncThunk(
  "user/updateAdditionalDetails",
  async (data: AdditionalDetail, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updateAdditionaldetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEmailApi = createAsyncThunk(
  "user/updateEmailApi",
  async (data: EmailUpdate, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updateEmail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePhoneApi = createAsyncThunk(
  "user/updatePhoneApi",
  async (data: PhoneUpdate, { getState, rejectWithValue }) => {
    try {
      const response = await userService.updatePhone(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyApi = createAsyncThunk(
  "user/verifyApi",
  async (data: Verify, { getState, rejectWithValue ,dispatch}) => {
    try {
      const response = await userService.verifyEmailPhone(data);
      console.log(response)
       const state=getState()
      //  console.log(state,"State")
      if(response.status==200){
        const userId=state.login.session.userId
        dispatch(getLoginUserDetailApi({userId}))
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserLogsApi = createAsyncThunk(
  "user/saveUserLogsApi",
  async (data: SaveUserLog, { getState, rejectWithValue }) => {
    try {
        const response = await loggerService.postLogs(data);
        return response;
      }
     catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    submitPollThroughProfile: (state, action) => {
      const poll = action.payload;
      const userPost = state.userPosts.posts.map((postContent) => {
        if (postContent.id === poll.postId) {
          return {
            ...postContent,
            metadata: {
              ...postContent.metadata,
              isAnswered: true,
              options: postContent.metadata.options.map((optionData) => {
                if (optionData.optionId == poll.optionId) {
                  return { ...optionData, vote: optionData.vote + 1 };
                } else {
                  return optionData;
                }
              }),
            },
          };
        } else {
          return postContent;
        }
      });
      state.userPosts.posts = userPost;
    },
    submitCommentThroughProfile: (state, action) => {
      const post = action.payload;
      const userPost = state.userPosts.posts.map((postContent) => {
        if (postContent.id === post.postId) {
          return {
            ...postContent,
            counts: {
              ...postContent.counts,
              comment: postContent.counts.comment + 1,
            },
          };
        } else {
          return postContent;
        }
      });
      state.userPosts.posts = userPost;
    },
    getPostByNextUrl: (state, action) => {
      const previousPosts = state.userPosts.posts;
      console.log("action.payload.data.data :>> ", action.payload.posts);
      state.userPosts.posts = previousPosts.concat(action.payload.posts);
      state.userPosts.metadata = action.payload.metadata;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLoginUserDetailApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          state.userDetails = action.payload.data.data.userDetails;
          state.userExperience = action.payload.data.data.userExperience;
          state.userLanguages = action.payload.data.data.userLanguage;
          state.userEducation = action.payload.data.data.userEducation;
          state.userAwards = action.payload.data.data.userAwards;
          state.userAdditionalDetail=action.payload.data.data.userAdditionalInfo;
          state.userPublications = action.payload.data.data.userPublications;
        }
      )
      .addCase(
        addExperienceApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const experience = state.userExperience;
          console.log("action.payload.data.data", action.payload.data.data);
          experience.push(action.payload.data.data);
          state.userExperience = experience;
        }
      )
      .addCase(
        addEducationApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const educations = state.userEducation;
          console.log("educations", educations);
          educations.push(action.payload.data.data);
          state.userEducation = educations;
        }
      )
      .addCase(
        updateEducationApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const educations = state.userEducation;
          const educationDetail = action.payload.data.data;
          const newArray = educations.map((edDetail) => {
            if (edDetail.id === educationDetail.id) {
              return educationDetail;
            } else {
              return edDetail;
            }
          });
          state.userEducation = newArray;
        }
      )
      .addCase(
        addPublicationApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const publications = state.userPublications;
          publications.push(action.payload.data.data);
          state.userPublications = publications;
        }
      )
      .addCase(addLanguagesApi.fulfilled, (state, action) => {
        state.userLanguages = action.payload.data.data;
      })
      .addCase(postLanguagesApi.fulfilled, (state, action) => {
        state.userLanguages = action.payload.data.data;
      })
      .addCase(addAwardApi.fulfilled, (state: UserDetailReduxState, action) => {
        const awards = state.userAwards;
        awards.push(action.payload.data.data);
        state.userAwards = awards;
      })
      .addCase(
        updateAwardsApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const awards = state.userAwards;
          const updatedAwardDetail = action.payload.data.data;
          const newArray = awards.map((awardDetail) => {
            if (awardDetail.id === updatedAwardDetail.id) {
              return updatedAwardDetail;
            } else {
              return awardDetail;
            }
          });
          state.userAwards = newArray;
        }
      )
      .addCase(
        updatePublication.fulfilled,
        (state: UserDetailReduxState, action) => {
          const publications = state.userPublications;
          const pubDetail = action.payload.data.data;
          const newArray = publications.map((publicationDetail) => {
            if (publicationDetail.id === pubDetail.id) {
              return pubDetail;
            } else {
              return publicationDetail;
            }
          });
          state.userPublications = newArray;
        }
      )
      .addCase(
        updateExperienceApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          const experiences = state.userExperience;
          const expDetail = action.payload.data.data;
          const newArray = experiences.map((experienceDetail) => {
            if (experienceDetail.id === expDetail.id) {
              return expDetail;
            } else {
              return experienceDetail;
            }
          });
          state.userExperience = newArray;
        }
      )
      .addCase(
        updateUserProfileApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          state.userDetails = action.payload.data.data;
        }
      )
      .addCase(
        userAwardDelete.fulfilled,
        (state: UserDetailReduxState, action) => {
          const userAwards = state.userAwards;
          const deletedRecord = action.payload.data.data;
          const filteredArray = userAwards.filter(
            (awdDetail) => awdDetail.id !== deletedRecord.id
          );
          state.userAwards = filteredArray;
        }
      )
      .addCase(
        userEducationDelete.fulfilled,
        (state: UserDetailReduxState, action) => {
          const userEducations = state.userEducation;
          const deletedRecord = action.payload.data.data;
          const filteredArray = userEducations.filter(
            (eduDetail) => eduDetail.id !== deletedRecord.id
          );
          state.userEducation = filteredArray;
        }
      )
      .addCase(
        userExperienceDelete.fulfilled,
        (state: UserDetailReduxState, action) => {
          const userExp = state.userExperience;
          const deletedRecord = action.payload.data.data;
          const filteredArray = userExp.filter(
            (expDetail) => expDetail.id !== deletedRecord.id
          );
          state.userExperience = filteredArray;
        }
      )
      .addCase(
        userPublicationDelete.fulfilled,
        (state: UserDetailReduxState, action) => {
          const userPublications = state.userPublications;
          const deletedRecord = action.meta.arg;
          const filteredArray = userPublications.filter(
            (pubDetail) => pubDetail.id !== deletedRecord.id
          );
          state.userPublications = filteredArray;
        }
      )
      .addCase(
        getMyAllPost.fulfilled,
        (state: UserDetailReduxState, action) => {
          state.userPosts = action.payload.data.data;
        }
      )
      .addCase(
        addAdditionalDetailApi.fulfilled,
        (state: UserDetailReduxState, action) => {
          state.userAdditionalDetail = action.payload.data.data;
        }
      )
      .addCase(
        updateAdditionalDetails.fulfilled,
        (state: UserDetailReduxState, action) => {
          state.userAdditionalDetail = action.payload.data.data;
        })
        
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      })
      // .addCase(createPostApi.fulfilled,(state,action)=>{
      //   console.log(action.meta.arg.postType)
      //   console.log(action.payload.data.data)
      //   // const postData = action.payload?.data?.data;
      //   // // if(action.meta.arg.postType=="REPOST"){
      //   //   state.userPosts.posts.unshift(postData)
      //   // // }
        
      // })
      .addCase(deletePostApi.fulfilled,(state,action)=>{
        const id=action.meta.arg.postId
        const updatedPosts=state.userPosts.posts.filter((ele)=>ele.id!==id)

        state.userPosts.posts=updatedPosts
        
      })
      .addCase(
        doActionOnPostApi.fulfilled,
        (state, actionPayload) => {
          const { postId, action } = actionPayload.meta.arg;
          const post = state.userPosts.posts.find((ele)=>ele.id===postId);
          // console.log(post,"POSTTTTTT")
          console.log("ACTION,",action)
          if(post){
            if(action=="like"){
              post.counts[action]+=1;
              post.isLiked = true;
            }
            if(action=="unlike"){
              post.counts[action]-=1;
              post.isLiked = false;
            }    
          }
          // if (post) {
          //   switch (action) {
          //     case POST_ACTION_LIKE:
          //       post.counts[action]++;
          //       post.isLiked = true;
          //       break;
          //     case POST_ACTION_UNLIKE:
          //       post.counts[action]--;
          //       post.isLiked = false;
          //       break;
          //   }
          // }
        }
      )
      // .addCase(verifyApi.fulfilled,(state,action)=>{
      //   console.log(action.meta.arg.type,"TTT")
      //   if(action.meta.arg.type=="email"){
      //     state.userDetails.secondaryEmail=action.meta.arg.

      //   }
      //   if(action.meta.arg.type=="phone"){
          
      //   }
      // })
  },
});
export const {
  submitPollThroughProfile,
  submitCommentThroughProfile,
  getPostByNextUrl,
} = userSlice.actions;
export default userSlice.reducer;
