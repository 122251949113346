import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { addPendingEvents, getMyEventApi, getNextPendingEventApi } from '../../../slices/eventSlice'
import { Link } from 'react-router-dom'
import NoEvents from "../../../assets/images/NoEventsImage.png"
import CommonCard from '../BothCommon/CommonCard'
import InfiniteScroll from 'react-infinite-scroll-component'

const PendingEvents = () => {
  const dispatch=useAppDispatch()
  const {events,metadata}= useAppSelector((state)=>state.events.pendingEvents)
  const pendingEvents=events
  const [requestedEvents,setRequestedEvents]=useState([])
  const [InvitedEvents,setInvitedEvents]=useState([])
  
  useEffect(()=>{
    dispatch(getMyEventApi({status:"pending"}))
  },[])

  useEffect(() => {
    // Update the state whenever pendingEvents changes
    if(pendingEvents){
      const filterRequestedEvents = pendingEvents.filter((ele) => ele.requestMember.status === "PENDING" && ele.requestMember.type === "REQUEST")
      const filterInvitedEvents = pendingEvents.filter((ele) => ele.requestMember.status === "PENDING" && ele.requestMember.type === "INVITE")
  
      setRequestedEvents(filterRequestedEvents)
      setInvitedEvents(filterInvitedEvents)
    }
   
  }, [pendingEvents])
  
  const getMoreDataHandler=()=>{ 
    // console.log("HOSTED")
    dispatch(getNextPendingEventApi({}))   
  }
  
  return (
    <>
    <InfiniteScroll
          className="space-y-2 pb-4 no-scrollbar"
          dataLength={pendingEvents?.length-2}
          next={() => getMoreDataHandler()}
          hasMore={metadata?.nextResultURL?true:false }
          // height={"calc(100vh - 155px)"}
          loader={""}
        >
    {InvitedEvents?.length>0 &&
      (
      <div>
        <h1 className='text-white text-lg py-2'>Invited</h1>

        <div className='flex flex-wrap gap-2 justify-start'>
        {
          InvitedEvents?.map((event)=> 
            <Link  key={event.id} to={ `/tab/events/${event.id}`}>
            <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event?.author.firstName} ${event?.author.lastName}`,startDate:event?.parsedStartTime ,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
            </Link>
          )
        }
        </div>
      </div>)}

      { requestedEvents?.length>0 &&
      (
      <div className='mt-2'>
        <h1 className='text-white text-lg py-2'>Requested</h1>

        <div className='flex flex-wrap gap-2 justify-start'>
        {
          requestedEvents?.map((event)=> 
            <Link  key={event.id} to={ `/tab/events/${event.id}`}>
            <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event?.author.firstName} ${event?.author.lastName}`,startDate:event?.parsedStartTime ,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
            </Link>
          )
        }
        </div>
      </div>)}
      
      </InfiniteScroll> 
      {
        pendingEvents && pendingEvents?.length===0 && 
        <div className='flex justify-center'>
          <img src={NoEvents} alt="NO EVENTS AVAILABLE" />
        </div>
      }
    </>
  )
}

export default PendingEvents
