import { ChangeEvent, useState } from "react";
import Select from "react-select";
import { customColor } from "../../../../utils/contant";


export interface LxDropDownProps {
  title: string;
  error: string;
  onChange: (
    event: ChangeEvent<HTMLSelectElement> | { Id: number; Title: string }
  ) => void;
  options: Array<object>;
  placeholder: string;
  isMultiple?: boolean;
  dropDownHeight?: string;
  name?: string;
  dropDownBorderWidth?: string;
  dropDownBorderColor?: string;
  value: object;
  optionValue: string;
  optionLabel: string;
  isnastedComponent?: boolean;
}

const DropDown = ({
  title,
  error,
  optionValue,
  optionLabel,
  dropDownHeight = "3.5rem",
  dropDownBorderWidth = "1px",
  dropDownBorderColor = "rgb(108 114 117 )",
  isnastedComponent = false,
  ...props
}: LxDropDownProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: dropDownHeight,
      cursor: "pointer",
      fontSize: "16px",
      color: state.isSelected ? "#8C8C8C" : "#8C8C8C",
      border: isnastedComponent
        ? "none"
        : `${dropDownBorderWidth} solid ${
            state.isFocused ? dropDownBorderColor : dropDownBorderColor
          }`,
      borderRadius: "12px",
      backgroundColor: "#242525",
      boxShadow: state.isFocused ? "none" : "none",
      ":hover": {
        border: isnastedComponent
          ? "none"
          : `${dropDownBorderWidth} solid ${
              state.isFocused ? dropDownBorderColor : dropDownBorderColor
            }`,
      },
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "16px",
      // color: state.isSelected ? "red" : "red",
    }),
    singleValue: (provided: Record<string, unknown>) => ({
      ...provided,
      fontSize: "16px",
      color: "#CDCED2",
    }),
    option: (
      provided,
      state: {
        isSelected: unknown;
        isFocused: unknown;
      }
    ) => ({
      ...provided,
      fontSize: "16px",
      color: state.isSelected ? "#FFFFFF" : "#B0B0B0",
      backgroundColor: state.isSelected ? "#535354" : "#535354",
      ":hover": {
        backgroundColor: "#181919",
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#535354",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "220px",
      "::-webkit-scrollbar": {
        width: 0,
      }, 
    }),
    indicatorSeparator: (provided: Record<string, unknown>) => ({
      ...provided,
      display: "none",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      // display: "none",
    }),

    dropdownIndicatorContainer: (provided) => ({
      ...provided,
      cursor: "pointer",
      paddingRight: "8px",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      "& svg": {
        display: "none",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#696969", // Adjust the placeholder text color
      fontSize: "16px", // Adjust the font size as needed
    }),
  };

  const customIndicator = {
    marginRight: "12px",
    width: 0,
    height: 0,
    borderLeft: "12px solid transparent",
    borderRight: "12px solid transparent",
    borderTop: "10px solid #8C8C8C",
    transform: isFocused ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };

  return (
    <>
      <label className="ml-2 text-sm font-normal text-lxgray-200">
        {title}
      </label>
      <div className="mx-auto">
        <Select
          {...props}
          getOptionValue={(position) => position[optionValue]}
          getOptionLabel={(position) => position[optionLabel]}
          isClearable={true}
          backspaceRemovesValue={true}
          styles={customStyles}
          isSearchable={true}
          onMenuOpen={() => setIsFocused(true)}
          onMenuClose={() => setIsFocused(false)}
          // components={{
          //   DropdownIndicator: () => <div style={customIndicator}></div>,
          // }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: customColor.golden,
              primary: "black",
            },
          })}
        />
      </div>
      <p className="text-red-900">{error}</p>
    </>
  );
};

export default DropDown;
