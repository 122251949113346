const Search = ({ width = "19", height = "18" }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.58333 15.4167C12.6334 15.4167 15.9167 12.1334 15.9167 8.08333C15.9167 4.03325 12.6334 0.75 8.58333 0.75C4.53325 0.75 1.25 4.03325 1.25 8.08333C1.25 12.1334 4.53325 15.4167 8.58333 15.4167Z"
          stroke="#8C8C8C"
        />
        <path d="M17.7492 17.2502L13.7617 13.2627" stroke="#8C8C8C" />
      </svg>
    </div>
  );
};

export default Search;
