function AttachmentIcon({
  width = "23",
  height = "24",
  color = "#8C8C8C",
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.925 10.473a1.008 1.008 0 011.43 1.423l-9.279 9.255a7.062 7.062 0 11-10.001-9.974l9.278-9.254a5.044 5.044 0 117.143 7.123l-9.29 9.254a3.026 3.026 0 11-4.285-4.274l8.572-8.54a1.009 1.009 0 011.428 1.425l-8.573 8.54a1.01 1.01 0 101.43 1.425l9.289-9.253a3.028 3.028 0 10-4.289-4.275l-9.277 9.253a5.046 5.046 0 007.145 7.125l9.28-9.253z"
        fill={color}
      />
    </svg>
  );
}

export default AttachmentIcon;
