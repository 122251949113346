import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { addHoistingEvents, addPendingEvents, getMyEventApi, getNextHostedEventApi } from '../../../slices/eventSlice'
import { Link } from 'react-router-dom'
import NoEvents from "../../../assets/images/NoEventsImage.png"
import CommonCard from '../BothCommon/CommonCard'
import InfiniteScroll from 'react-infinite-scroll-component'

const HoistingEvents = () => {
  const dispatch=useAppDispatch()
  const {events,metadata}= useAppSelector((state)=>state.events.hoistingEvents)
  const hoistingEvents=events
  useEffect(()=>{
    dispatch(getMyEventApi({status:"hosting"}))
  },[])

  const getMoreDataHandler=()=>{ 
    // console.log("HOSTED")
    dispatch(getNextHostedEventApi({}))   
  }

  return (
    <>
    {hoistingEvents && hoistingEvents.length>0 &&
      (
      <div>
        <h1 className='text-white text-lg py-2'>My Events</h1>
        <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={hoistingEvents?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
        <div className='flex flex-wrap gap-2 justify-start'>
        {
          hoistingEvents?.map((event)=> 
            <Link  key={event.id} to={ `/tab/events/${event.id}`}>
            <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
            </Link>
          )
        }
        </div>
        </InfiniteScroll>


      </div>)}

      {
        hoistingEvents && hoistingEvents.length===0 && 
        <div className='flex justify-center'>
          <img src={NoEvents} alt="NO EVENTS AVAILABLE" />
        </div>
      }
    </>
  )
}

export default HoistingEvents
