import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../config/store";
import { useForm } from "react-hook-form";
import Dropdown from "../../job/components/Dropdown";
import uploadContent from "../../../config/UploadContent";
import UploadIcon from "../../../assets/icons/UploadIcon";
import ScheduleYourEvent from "./ScheduleYourEvent";
import Accordian from "../BothCommon/Accordian";
import { createEvent, inviteMembersApi } from "../../../slices/eventSlice";
import { getFormattedDate, getFormattedTime } from "../../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { baseUrl, contentUrl } from "../../../utils/contant";
import backgroundBanner from "../../../assets/images/Blank_Placeholders.png"
import { EventApiResponse } from "../../../models/event";
import Advertisement from "../../advertisement/Advertisement";


interface Props{
  authorId?:string,
  authorType?:string,
  setIsOpen?:Function
}

const CreateEvent = ({authorId,authorType,setIsOpen}:Props) => {
  const dispatch = useAppDispatch();
  const navigate=useNavigate()
  const { userId ,token} = useSelector((state: RootState) => state.login.session);
  const { industries, categories, functions } = useAppSelector(
    (state) => state.appConfig
  );

  const {myNetworks} =useAppSelector((state)=>state.networks)
  const {clubs}=useAppSelector((state)=>state.clubs.participatedClubs)

  const { control, handleSubmit, register, watch, setValue ,formState:{errors,isValid},getValues} = useForm({mode:"all"});
  const inputRef = useRef(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);
  const [updatedBanner,setUpdatedBanner]=useState("")
  const [showCC,setShowCC]=useState<boolean>(false)

  const [showIndex,setShowIndex]=useState(0)
 console.log(errors)
  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      handleChange(event);
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);

  const isScheduleValid = () => {
    const values = getValues();
    return (
      values.host &&
      values.isOffline &&
      values.emailId &&
      values.startDate &&
      values.startTime &&
      values.endTime &&
      values.endDate
    );
  };

  const onSubmit = async (data) => {
    try{
      console.log(data);
     const response= await dispatch(createEvent({title: data.title,
      details: data.details,
      industry: data.industry,
      functions: [" "],
      category: data.category,
      host: data.host,
      isPrivate: data.isPrivate==="false"?false:true,
      isOffline: data.isOffline==="false"?false:true,
      eventUrl: data.eventUrl,
      venue: data.venue,
      fee: !data.fee?0:data.fee,
      startDate: getFormattedDate(data.startDate),
      endDate:getFormattedDate(data.endDate),
      emailId: data.emailId,
      startTime:getFormattedTime(data.startTime),
      endTime: getFormattedTime(data.endTime),
      authorId:authorId?authorId:userId,
      authorType:authorType?authorType:"USER",
      bannerImageUrl: data.bannerImageUrl})) as EventApiResponse
      
      console.log("Response",response)
      const newEventId=response?.payload?.data?.data?.id
      await dispatch(inviteMembersApi({eventId:newEventId,data:{userIds:data.Connections?data.Connections:[],clubIds:data.Clubs?data.Clubs:[]}}))
      
      if(authorType=="COMPANY"){
        navigate(`/tab/company/${authorId}`)
        setIsOpen(false)
      }
      else if(authorType=="CLUB"){
        navigate(`/tab/clubs/${authorId}`)
        setIsOpen(false)
      }
     else{
      navigate("/tab/events/myevents/hosting")   
     }  

    }
    catch(err){
        console.log("err",err)
    }
  
  };

  const handleChange = (event) => {
    // console.log(event)
    setIsUploading(true);

    const selectedFile =
      event instanceof DragEvent
        ? event.dataTransfer.files
        : event.target.files;
    console.log(selectedFile);

    uploadContent.uploadEventContent(userId, selectedFile, (response) => {
      //console.log(response,jobId);
      const { contentType, name, size, url } = response[0];
      const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
      };
      console.log(attachment);
      setUpdatedBanner(attachment.url)
      setValue("bannerImageUrl",(attachment.url));
      setIsUploading(false);
    });
  };

  return (
    <div className="flex">

    
    <div className="mx-auto p-8 dark:bg-lxgray-700 mt-1 rounded-lg w-3/4 ">
      <div className="flex justify-between  items-center px-1">
        <h1 className="text-white text-lg font-semibold">{next && !showCC?"Schedule Your Event":(!showCC?"Create Your Event":"Share Event !!!")}</h1>
        {/* <div onClick={()=>dispatch(popupShowCreate())} className="hover:scale-90"><CrossIcon/></div> */}
      </div>
      
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-1">
        {
          showCC && (
          <div className="">
            <div>
              {
                ["Connections","Clubs"].map((ele,index)=>{
                  return(
                     <div>
                      <Accordian key={`${ele}accor`} heading={ele} showItems={index===showIndex?true:false} setShowIndex={()=>{setShowIndex(index)}}  control={control} register={register} watch={watch} setValue={setValue}/>             
                    </div>
                  )
                })
              }
              {/* {
               ( myNetworks.length===0 || clubs.length===0 ) && <div className="text-lxgray-200 p-4"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis harum corporis illum id quia cupiditate beatae quaerat aliquam? Ipsam, eligendi.</p></div>
              } */}
            </div>
            
            <div className="flex justify-end gap-2">
              <button onClick={()=>setShowCC(false)} className="text-white text-sm bg-lxgray-300 px-10 py-2 rounded-lg" type="button">Previous</button>
              <button onClick={()=>setShowCC(true)} className="text-white text-sm bg-golden px-10 py-2 rounded-lg">Create</button>
            </div>
          </div>
            )   
        }
        {!next && !showCC && (
          <div>
            <h1 className="text-lxgray-100">Upload File</h1>
            <label className="text-lxgray-300 ">
              Drag or choose your file to upload (less than or equal to 3 MegaBytes)
            </label>
            <input
              ref={inputRef}
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
              accept=".jpg,.jpeg,.png"
              // placeholder={<UploadIcon/>}
              type="file"
              onChange={handleChange}
              //
            />

            <div className="relative">
              <div className="bg-lxgray-400 ">
                <span
                  onClick={() => inputRef?.current?.click()}
                  {...register("bannerImageUrl",{required:true})}
                  className="rounded "
                  >
                  {" "}
                  {/* {isUploading ? "Uploading..." : "Upload"} */}
                  <div className="cursor-pointer relative">   
                    <img src={updatedBanner.length>0?`${baseUrl}/${contentUrl}/${updatedBanner}?t=${token}`:backgroundBanner} alt="bannerImage" className='w-full h-40 object-contain'/>
                    <div className="absolute top-[40%] left-[49%]"><UploadIcon/></div>
                  </div> 
                </span>     
              </div>
             
            </div>
            {errors.bannerImageUrl && <span className="text-red-500">Upload Banner Image</span>}

            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Event Title *
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("title",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter the Valid Title</span>}
            </div>

            <div className="text-lxgray-300 mb-4 flex flex-col">
              <label htmlFor="" className="text-sm p-1 ">
                Type *
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  defaultChecked={true}
                  value={"false"}
                  className=" w-4 h-4 mr-2 "
                  {...register("isPrivate")}
                />
                <label htmlFor="public" className="text-white p-1 mr-6 ">
                  Public
                </label>

                <input
                  type="radio"
                  defaultChecked={false}
                  value={"true"}
                  className=" h-4 w-4 mr-2"
                  {...register("isPrivate")}
                />
                <label htmlFor="client" className="text-white p-1 ">
                  Private
                </label>
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Industry *
              </label>
              <div className="text-white">
                <Dropdown
                  name="industry"
                  control={control}
                  defaultValue={[]}
                  placeholder={"Choose The Relevant Industry"}
                  options={industries.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Name };
                  })}
                />
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Category *
              </label>
              <div className="text-white">
                <Dropdown
                  name="category"
                  control={control}
                  defaultValue={[]}

                  placeholder={"Choose The Relevant Category"}
                  options={categories.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Categories };
                  })}
                />
              </div>
            </div>

            {/* <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Functions *
              </label>
              <div className="text-white">
                <Dropdown
                  name="functions"
                  control={control}
                  defaultValue={[]}
                  placeholder={"Choose The Relevant Function"}
                  options={functions.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Name };
                  })}
                />
              </div>
            </div> */}

            <div className="text-lxgray-300 flex flex-col mb-4 relative">
              <label htmlFor="" className="text-sm p-1">
                Enter Event Details *
              </label>
              <textarea
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
                name=""
                id=""
                rows={5}
                placeholder="Enter Here"
                maxLength={26000}
                {...register("details",{required:true})}
                // required
              ></textarea>
              {errors.details && errors.details.type === "required" && (
                <span className="text-red-500">Event details are required</span>
                )}
            </div>
          </div>
        )}
        {
          next && !showCC &&
          <ScheduleYourEvent control={control} register={register} watch={watch} setValue={setValue} errors={errors}/>
        }
        <div className="flex gap-4 justify-end">
          {next && !showCC  && <button onClick={()=>{setNext(false)}} className="text-white text-sm bg-lxgray-300 px-10 py-2 rounded-lg" type="button">
            Previous
          </button>}
         { !next && <button onClick={()=>setNext(true)}  disabled={!isValid} className={` text-sm  px-10 py-2 rounded-lg ${isValid?"bg-golden text-white":"bg-lxgray-300 cursor-not-allowed text-gray-300"}`} type="button">
            Next 
          </button>}
          { next && !showCC && <button onClick={()=>setShowCC(true)} disabled={!isScheduleValid()} className={`text-sm px-10 py-2 rounded-lg ${isScheduleValid()?"bg-golden text-white":"bg-lxgray-300 cursor-not-allowed text-gray-300"}`} type="button">
            Next
          </button>}
        </div>
      </form>
    </div>
    {/* <div className='border-l border-lxgray-300 px-5'>
      <Advertisement/>
    </div> */}
    </div>
  );
};

export default CreateEvent;
