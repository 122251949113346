import React from "react";

const SmallCross = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17254 6.99936L13.4118 1.54037C13.5684 1.37694 13.6564 1.15529 13.6564 0.92417C13.6564 0.693051 13.5684 0.471398 13.4118 0.307972C13.2552 0.144546 13.0428 0.0527344 12.8214 0.0527344C12.5999 0.0527344 12.3875 0.144546 12.2309 0.307972L6.99994 5.77565L1.76896 0.307972C1.61236 0.144546 1.39996 0.0527346 1.1785 0.0527346C0.957032 0.0527346 0.744637 0.144546 0.588037 0.307972C0.431437 0.471398 0.34346 0.693051 0.34346 0.92417C0.34346 1.15529 0.431437 1.37694 0.588037 1.54037L5.82733 6.99936L0.588037 12.4584C0.510089 12.539 0.448221 12.635 0.406 12.7408C0.363779 12.8465 0.342041 12.96 0.342041 13.0746C0.342041 13.1891 0.363779 13.3026 0.406 13.4083C0.448221 13.5141 0.510089 13.6101 0.588037 13.6908C0.665348 13.7721 0.757328 13.8367 0.85867 13.8807C0.960012 13.9248 1.06871 13.9475 1.1785 13.9475C1.28828 13.9475 1.39698 13.9248 1.49832 13.8807C1.59967 13.8367 1.69165 13.7721 1.76896 13.6908L6.99994 8.22308L12.2309 13.6908C12.3082 13.7721 12.4002 13.8367 12.5015 13.8807C12.6029 13.9248 12.7116 13.9475 12.8214 13.9475C12.9312 13.9475 13.0399 13.9248 13.1412 13.8807C13.2425 13.8367 13.3345 13.7721 13.4118 13.6908C13.4898 13.6101 13.5517 13.5141 13.5939 13.4083C13.6361 13.3026 13.6578 13.1891 13.6578 13.0746C13.6578 12.96 13.6361 12.8465 13.5939 12.7408C13.5517 12.635 13.4898 12.539 13.4118 12.4584L8.17254 6.99936Z"
        fill="#CDCED2"
      />
    </svg>
  );
};

export default SmallCross;
