import React, { useEffect, useMemo } from "react";
import JobCard from "./JobCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import NoRecentJobs from "../../../assets/images/NoRecentJobs.png";
import { getAppliedJobApi, getSavedJobApi } from "../../../slices/jobSlice";

const MyJobs = () => {
  const dispatch=useAppDispatch()
  const job = useAppSelector((state) => state?.job);
  const { appliedJobList, savedJobList }=job

  useEffect(()=>{
    dispatch(getAppliedJobApi());
    dispatch(getSavedJobApi());
  },[])

  
  // console.log("AppliedJOb ",appliedJobList,savedJobList)

  const jobsApplied = appliedJobList && Object.keys(appliedJobList?.entities).length > 0 
  ? Object.values(appliedJobList?.entities) 
  : [];

const jobsSaved = savedJobList && Object.keys(savedJobList?.entities).length > 0 
  ? Object.values(savedJobList?.entities) 
  : [];
  

  // console.log("Applied Jobs", jobsApplied);
  

  // console.log(jobsSaved,jobsApplied)
  return (
    <div>
      {jobsApplied?.length>0 && <JobCard title="Applied" active={true} jobs={jobsApplied?.map((ele)=>{return ele?.job })}/>}
      
      {jobsSaved?.length > 0 && (
        <JobCard title="Saved" active={true} jobs={jobsSaved} />
      )}
      
      {jobsSaved?.length===0 && jobsApplied?.length===0 && (
        <div className="flex justify-center mt-[10%]">
          <img src={NoRecentJobs} alt="No Jobs Available" className="" />
        </div>
      )}
      
    </div>
  );
};

export default MyJobs;
