import { baseUrl, contentUrl } from "../../../utils/contant";
import newimage from "../../../assets/images/Blank_Placeholders.png";
import background from "../../../assets/images/Blank_Placeholders.png";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { actionsOnMembers, removeMemberApi } from "../../../slices/eventSlice";
import {
  actionsOnClubMembers,
  removeClubMembersApi,
} from "../../../slices/clubSlice";
import ThreeDots from "../../../assets/icons/ThreeDots";
import EditDots from "../../../assets/icons/EditDots";
import { memo, useState } from "react";
import {
  addChat,
  checkConversationApi,
  createConversationApi,
} from "../../../slices/chatSlice";
import { useNavigate } from "react-router-dom";
import {
  CheckConversationApiFulfilledAction,
  CreateConversationAction,
} from "../../../models/chat";
import { dropDownByTitle } from "../../../utils/nocoFunctions";
import AlternateProfile from "../../../components/AlternateProfile";
import { Link } from "react-router-dom";

interface Props {
  type: string | "INVITE" | "REQUEST" | "PARTICIPANT";
  fullName: string;
  picUrl: string;
  designation: string;
  organization: string;
  eventId?: string;
  clubId?: string;
  memberId: string;
  status: string;
  authorId: string;
  memberUserId: string;
}

const Participants = ({
  type,
  fullName,
  picUrl,
  designation,
  organization,
  eventId,
  clubId,
  memberId,
  status,
  authorId,
  memberUserId,
}: Props) => {
  const { token, userId } = useAppSelector((state) => state.login.session);
  const {designations } = useAppSelector((state) => state.appConfig);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleMessage = async (otherUserId,e) => {
    e.preventDefault();
    const response = (await dispatch(
      checkConversationApi({ members: [userId, otherUserId] })
    )) as CheckConversationApiFulfilledAction;

    if (response.payload === "No Conversations Found") {
      const newResponse = (await dispatch(
        createConversationApi({ members: [userId, otherUserId] })
      )) as CreateConversationAction;
      //  setShowNetwork(false)
      dispatch(addChat({member:newResponse.payload.data.memberDetails.filter((ele)=>ele.userId!==userId),conversationId:newResponse.payload.data.id}))
      // navigate(`/tab/messages/${newResponse.payload?.data.id}`);
    } else {
      // setShowNetwork(false)
      dispatch(addChat({member:response.payload.data.memberDetails.filter((ele)=>ele.userId!==userId),conversationId:response.payload.data.id}))
      // navigate(`/tab/messages/${response.payload?.data.id}`);
    }
  };

  const handleEventRequest = (action,e) => {
    e.preventDefault();
    dispatch(
      actionsOnMembers({ eventId: eventId, memberId: memberId, action: action })
    );
  };

  const handleEventCancelRequest = (e) => {
    e.preventDefault();
    dispatch(removeMemberApi({ eventId: eventId, memberId: memberId }));
    setOpen(!isOpen);
  };

  const handleClubRequest = (action,e) => {
    e.preventDefault();
    dispatch(
      actionsOnClubMembers({
        clubId: clubId,
        memberId: memberId,
        action: action,
      })
    );
    setOpen(!isOpen);
  };

  const handleClubCancelRequest = (e) => {
    e.preventDefault();
    dispatch(removeClubMembersApi({ clubId: clubId, memberId: memberId }));
    setOpen(!isOpen);
  };

  return (
    <div className="">
      {eventId && (
      <Link to={`/tab/profile/${memberUserId}`}>
        <div className=" h-full flex justify-between items-center  gap-4 bg-lxgray-500 rounded-lg p-3 hover:bg-lxgray-400/60 cursor-pointer ">
          {picUrl?<img
            src={picUrl ? `${baseUrl}/${contentUrl}/${picUrl}?t=${token}` : background}
            alt="ProfilePic"
            className="object-cover w-12 h-12 rounded-full bg-lxgray-700"
          />
          :
          <AlternateProfile firstName={fullName} lastName={fullName.split(" ")[1]} width="16" height="12"/>}
          <div className="flex flex-col gap-1 w-4/5  ">
            <div className="flex items-center relative  justify-between  ">
              <h1 className="text-white text-base font-semibold line-clamp-1">{fullName}</h1>
              {status === "ACCEPTED" && authorId !== memberUserId && (
                <div
                  className="cursor-pointer"
                  onClick={(e) =>{ e.preventDefault(); setOpen(!isOpen);}}
                >
                  <EditDots />
                </div>
              )}
              {isOpen && (
                <div className="absolute right-4 top-3">
                  <ul className="bg-lxgray-400 cursor-pointer rounded">
                    <li
                      className="py-2 px-6 "
                      onClick={(e)=>handleEventCancelRequest(e)}
                    >
                      Remove
                    </li>
                    {/* <li className="py-2 px-6">Report</li> */}
                  </ul>
                </div>
              )}
            </div>

            <p className="text-lxgray-100 text-xs line-clamp-1 ">{dropDownByTitle(designation,designations)}</p>
            <p className="text-golden text-xs line-clamp-1 ">{organization}</p>
            {(type === "REQUEST" || type === "INVITE") &&
              status === "ACCEPTED" && (
                <button
                  onClick={(e) => handleMessage(memberUserId,e)}
                  className="  text-white bg-golden w-1/2  px-4 py-1 font-semibold rounded hover:bg-white hover:text-golden"
                >
                  Message
                </button>
              )}

            {(type === "" ) &&
              status === "ACCEPTED" && (
                <button
                  onClick={(e) => handleMessage(memberUserId,e)}
                  disabled
                  className="text-white  bg-lxgray-200 w-1/2  px-4 py-1 font-semibold rounded "
                >
                  Owner
                </button>
              )}

            {type === "REQUEST" && status !== "ACCEPTED" && (
              <div className="grid grid-cols-2 gap-2 ">
                <button
                  className="text-white bg-golden w-full  px-4 py-1 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={(e) => handleEventRequest("accept",e)}
                >
                  Accept
                </button>
                <button
                  className="text-golden border border-golden w-full px-4 py-1 font-semibold rounded hover:bg-golden hover:text-white"
                  onClick={(e) => handleEventRequest("reject",e)}
                >
                  Reject
                </button>
              </div>
            )}

            {/* {(type==="REQUEST"||type==="INVITE") && status==="ACCEPTED" && <div className="flex gap-2">
            <button className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded" onClick={handleEventCancelRequest}>Remove</button>
          </div>} */}

            {type === "INVITE" && status !== "ACCEPTED" && (
              
                <button
                  className="text-white bg-golden w-1/2 px-10 py-2 font-semibold rounded"
                  onClick={(e)=>handleEventCancelRequest(e)}
                >
                  Cancel
                </button>
              
            )}
          </div>
        </div>
        </Link> 
      )}

      {clubId && (
      <Link to={`/tab/profile/${memberUserId}`}>
        <div className=" h-full flex justify-between items-center  gap-4 bg-lxgray-500 rounded-lg p-3 hover:bg-lxgray-400/60 cursor-pointer">
          {picUrl?<img
            src={picUrl ? `${baseUrl}/${contentUrl}/${picUrl}?t=${token}` : background}
            alt="ProfilePic"
            className="object-cover w-12 h-12 rounded-full  bg-lxgray-700"
          />
          :
          <AlternateProfile firstName={fullName} lastName={fullName.split(" ")[1]} width="16" height="12"/>}
          <div className="flex flex-col   w-full">
            <div className="flex items-center relative  justify-between ">
              <h1 className="text-white text-base font-semibold line-clamp-1">{fullName}</h1>
              {status === "ACCEPTED" && authorId !== memberUserId && (
                <div
                  className="cursor-pointer"
                  onClick={(e) =>{ e.preventDefault(); setOpen(!isOpen);}}
                >
                  <EditDots />
                </div>
              )}
              {isOpen && (
                <div className="absolute right-4 top-3">
                  <ul className="bg-lxgray-400 cursor-pointer rounded">
                    <li
                      className="py-2 px-6 "
                      onClick={(e)=>handleClubCancelRequest(e)}
                    >
                      Remove
                    </li>
                    {/* <li className="py-2 px-6">Report</li> */}
                  </ul>
                </div>
              )}
            </div>
            <p className="text-lxgray-100 text-xs line-clamp-1">{dropDownByTitle(designation,designations)}</p>
            <p className="text-golden text-xs line-clamp-1">{organization}</p>
            {(type === "REQUEST" || type === "INVITE") &&
              status === "ACCEPTED" && (
                <button
                  onClick={(e) => handleMessage(memberUserId,e)}
                  className="text-white  bg-golden w-1/2  px-4 py-1 font-semibold rounded hover:bg-white hover:text-golden"
                >
                  Message
                </button>
              )}

            {(type === "" ) &&
              status === "ACCEPTED" && (
                <button
                  onClick={(e) => handleMessage(memberUserId,e)}
                  disabled
                  className="text-white  bg-lxgray-200 w-1/2  px-4 py-1 font-semibold rounded "
                >
                  Owner
                </button>
              )}

            {type === "REQUEST" && status !== "ACCEPTED" && (
              <div className="grid grid-cols-2 gap-2">
                <button
                  className="text-white bg-golden px-4 py-1 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={(e) => handleClubRequest("accept",e)}
                >
                  Accept
                </button>
                <button
                  className="text-golden border border-golden  px-4 py-1 font-semibold rounded hover:bg-golden hover:text-white"
                  onClick={(e) => handleClubRequest("reject",e)}
                >
                  Reject
                </button>
              </div>
            )}

            {/* {(type==="REQUEST"||type==="INVITE") && status==="ACCEPTED" && <div className="flex gap-2">
              <button className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded" onClick={handleClubCancelRequest}>Remove</button>
            </div>} */}

            {type === "INVITE" && status !== "ACCEPTED" && (
             
                <button
                  className="text-white bg-golden w-1/2  px-4 py-1 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={(e)=>handleClubCancelRequest(e)}
                >
                  Cancel
                </button>
            
            )}
          </div>
        </div>
      </Link>
      )}
    </div>
  );
};

export default Participants;
