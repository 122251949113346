import { useEffect, useState } from "react";
import { useAppDispatch } from "../config/hooks";
import { getSurveyDetailsApi } from "../slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPostValidity } from "../utils/dateUtils";
import { Link } from "react-router-dom";

const SurveyPost = ({ userContent }) => {
  const dispatch = useAppDispatch();
  const [surveyDetails, setSurveyDetails] = useState(null);
  const {
    metadata: { validity, surveyId },
  } = userContent;
  const { daysRemaining, hoursRemaining } = getPostValidity(validity);

  useEffect(() => {
    const getSurveyById = async () => {
      const surveyResponse = await dispatch(
        getSurveyDetailsApi({ surveyId })
      ).then(unwrapResult);
      setSurveyDetails(surveyResponse.data.data);
    };
    getSurveyById();
  }, [surveyId, dispatch]);

  return surveyDetails ? (
    <div>
      <h1 className="text-white text-lg text-start font-bold leading-none">
        {surveyDetails?.title}
      </h1>
      <div className="w-full">
        <div className="w-fit ml-auto mr-0">
          <span className="w-fit text-xs text-golden font-semibold">
            {daysRemaining > 0
              ? `${daysRemaining} DAYS LEFT`
              : hoursRemaining <= 0
              ? ""
              : `${hoursRemaining} HRS LEFT`}
          </span>
        </div>
      </div>
      <p className="text-lxgray-200 text-base text-start font-normal leading-1 whitespace-pre-wrap py-2 ">
        {surveyDetails?.description}
      </p>
      <h2 className="text-white text-lg font-semibold text-start mt-1">
        {surveyDetails?.questions[0]?.question} *
      </h2>
      <div className="w-full">
        <div className="w-fit ml-auto mr-0">
          <span className="w-fit text-base text-lxgray-400 font-semibold">
            {surveyDetails?.questions.length > 1 &&
              `+${surveyDetails?.questions.length - 1} MORE`}
          </span>
        </div>
      </div>
      <Link to={`/tab/survey/${surveyId}`}>
        <button className="w-full py-2 text-center rounded-xl bg-golden text-white text-lg font-semibold mt-2">
          {!surveyDetails.responses ? "Add Your Answer" : "View The Result"}
        </button>
      </Link>
    </div>
  ) : (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-2 py-1">
        <div className="h-5 bg-lxgray-600"></div>
        <div className="space-y-3">
          <div className="flex gap-2">
            <div className="h-5 bg-lxgray-600 flex-1"></div>
            <div className="h-5 bg-lxgray-600 w-1/4"></div>
          </div>
          <div className="h-5 bg-lxgray-600 w-[80%]"></div>
        </div>
      </div>
    </div>
  );
};

export default SurveyPost;
