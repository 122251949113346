export function formatDate(dateStr: any,timeZone:string): string {
    // console.log(dateStr)
  const date = new Date(dateStr);
  // console.log(date)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: timeZone
  };
  return date.toLocaleString("en-US", options);
}

// Example usage:
// const formattedDate = formatDate("0005-06-15T06:30:00Z");
// console.log(formattedDate); // Output: Sat, 15 Jun 0005 - 06:30 AM
