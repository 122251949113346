import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  Comment,
  CommentResponse,
  PaginatedCommentMetadata,
} from "../models/comment";
import { COMMENT_ACTION_LIKE, COMMENT_ACTION_UNLIKE } from "../models/contants";

import commentService from "../services/comment.service";

import useComment from "../utils/hooks/useComment";
import { logout } from "./loginSlice";

const commentAdapter = createEntityAdapter<CommentResponse>({});

interface CommentReduxState {
  comments: Array<CommentResponse>;
  levelOneComments: EntityState<CommentResponse, string>;
  metadata?: PaginatedCommentMetadata;
}
const initialState: CommentReduxState = {
  comments: [],
  levelOneComments: commentAdapter.getInitialState(),
  metadata: null,
};

export const getCommentsApi = createAsyncThunk(
  "comment/getCommentsApi",
  async (
    req: PaginatedCommentMetadata,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await commentService.getPaginatedComments(req);

      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const commentOnPostApi = createAsyncThunk(
  "comment/commentOnPostApi",
  async (data: Comment, { getState, rejectWithValue }) => {
    try {
      return await commentService.commentOnPost(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const doActionOnCommentApi = createAsyncThunk(
  "comment/doActionOnCommentApi",
  async (
    data: { action: string; postId: string; commentId: string },
    { getState, rejectWithValue }
  ) => {
    try {
      return await commentService.doAction(
        data.postId,
        data.commentId,
        data.action
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCommentApi = createAsyncThunk(
  "comment/updateCommentApi",
  async (data: Comment, { getState, rejectWithValue }) => {
    const response = await commentService.updateComment(data);
    return response;
  }
);

export const deleteCommentApi = createAsyncThunk(
  "comment/deleteCommentApi",
  async (data: Comment, { getState, rejectWithValue }) => {
    const response = await commentService.deleteComment(data);
    return response;
  }
);

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    clearComments: (state, action) => {
      const task = action.payload;
      // commentAdapter.removeAll(state.comments);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommentsApi.fulfilled, (state: CommentReduxState, action) => {
        const { addNewComments, addDepthRecursively } = useComment();
        const { commentId } = action.meta.arg;
        const apiRes = action.payload.data.data;
        const getUpdatedArray = apiRes.comments.map((comment) => {
          return { ...comment, replies: [] };
        });

        if (commentId) {
          const newTree = state.comments.map((comment) =>
            addNewComments(comment, commentId, getUpdatedArray)
          );
          addDepthRecursively(newTree);
          state.comments = newTree;
        } else {
          const getUpdatedArray = apiRes.comments.map((comment) => {
            return { ...comment, replies: [], depth: 0 };
          });
          state.comments = getUpdatedArray;
        }
      })
      .addCase(
        updateCommentApi.fulfilled,
        (state: CommentReduxState, action) => {
          const { id, content } = action.meta.arg;
          const { updateCommentContent } = useComment();
          updateCommentContent(state.comments, id, content);
        }
      )
      .addCase(
        deleteCommentApi.fulfilled,
        (state: CommentReduxState, action) => {
          const { id } = action.meta.arg;
          const { deleteComment } = useComment();
          const updatedComments = state.comments
            .map((comment) => deleteComment(comment, id))
            .filter((comment) => comment !== null);

          state.comments = updatedComments;
        }
      )
      .addCase(
        commentOnPostApi.fulfilled,
        (state: CommentReduxState, action) => {
          const { parentId } = action.meta.arg;

          const apiRes = {
            ...action.payload.data.data,
            replies: [],
          };

          const { postNewComments } = useComment();

          const addDepthRecursively = (comments, depth = 0) => {
            comments.forEach((comment) => {
              comment.depth = depth;
              if (comment.replies.length > 0) {
                addDepthRecursively(comment.replies, depth + 1);
              }
            });
          };

          if (parentId) {
            const newTree = state.comments.map((comment) =>
              postNewComments(comment, apiRes.parentId, apiRes)
            );
            addDepthRecursively(newTree);
            state.comments = newTree;
          } else {
            const newComment = {
              ...action.payload.data.data,
              replies: [],
              depth: 0,
            };
            state.comments.unshift(newComment);
            // commentAdapter.addOne(state.comments, action.payload.data.data);
          }
        }
      )
      .addCase(
        doActionOnCommentApi.fulfilled,
        (state: CommentReduxState, actionPayload) => {
          const { commentId, action } = actionPayload.meta.arg;
          // const comment = state.comments.entities[commentId];
          // if (comment) {
          //   switch (action) {
          //     case COMMENT_ACTION_LIKE:
          //       comment.counts[action]++;
          //       comment.isLiked = true;
          //       break;
          //     case COMMENT_ACTION_UNLIKE:
          //       comment.counts[COMMENT_ACTION_LIKE]--;
          //       comment.isLiked = false;
          //       break;
          //   }
          // }
        }
      )
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });
  },
});

export const { clearComments } = commentSlice.actions;

export default commentSlice.reducer;
