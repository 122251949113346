import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { POST_ACTION_LIKE, POST_ACTION_UNLIKE } from "../models/contants";

import { UploadFileRequest } from "../models/content";
import { DeletePost, Post, PostCardAction, PostPollMetadata, PostResponse } from "../models/post";
import contentService from "../services/content.service";
import postService from "../services/post.service";
// import { useAppDispatch } from "@linkcxo/web/src/config/hooks";
import {
  getMyAllPost,
  getPostByNextUrl,
  submitPollThroughProfile,
} from "../slices/userSlice";
import { BookmarkData } from "../models/bookmark";
import { toast } from "react-toastify";
import { logout } from "./loginSlice";


const postAdapter = createEntityAdapter<PostResponse>({});

interface PostReduxState {
  posts: EntityState<PostResponse, string>;
  bookmarks: Array<BookmarkData>;
}
const initialState: PostReduxState = {
  posts: postAdapter.getInitialState(),
  bookmarks: [],
  
};

export const createPostApi = createAsyncThunk(
  "post/createPostApi",
  async (data: Post, { getState,dispatch, rejectWithValue }) => {
    try {
      console.log(data, "you are in slice method");
      const response = await postService.createNewPost(data); 
      if(data.authorType!=="USER"){
        dispatch(getMyAllPost({authorId:data.authorId}))
      } 
      else{
        dispatch(getMyAllPost({authorId:" "}))
      }        
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const doActionOnPostApi = createAsyncThunk(
  "post/doActionOnPostApi",
  async (
    data: { action: string; postId: string },
    { getState, rejectWithValue }
  ) => {
    try {
      return await postService.doAction(data.postId, data.action);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitPollApi = createAsyncThunk(
  "post/submitPollApi",
  async (
    data: { postId: string; optionId: string },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postService.submitPollAnswer(
        data.postId,
        data.optionId
      );
      dispatch(
        submitPollThroughProfile({
          postId: data.postId,
          optionId: data.optionId,
        })
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPostByIdApi = createAsyncThunk(
  "post/getPostByIdApi",
  async (postId: string, { getState, rejectWithValue }) => {
    try {
      return await postService.getPostById(postId);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPostByUrl = createAsyncThunk(
  "post/getPostByUrl",
  async (nextUrl: string, { getState, rejectWithValue, dispatch }) => {
    try {
      const nextUrlResponse = await postService.getPostByUrl(nextUrl);
      dispatch(getPostByNextUrl(nextUrlResponse.data.data));
      return nextUrlResponse;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBookmarkApi = createAsyncThunk(
  "post/getAllBookmarkApi",
  async (
    data: null,
    { getState, rejectWithValue }
  ) => {
    const response = await postService.getAllBookmark();
    return response;
  }
);

export const bookmarkPostApi = createAsyncThunk(
  "post/bookmarkPostApi",
  async (
    data: BookmarkData,{getState,rejectWithValue}
  ) => {
    const response = await postService.bookmarkData(data);
    
    toast.success("Bookmarked SuccessFully")
    return response;
    
  }
);

export const actionOnPostApi = createAsyncThunk(
  "post/actionOnPostApi",
  async (data: PostCardAction, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await postService.postCardAction(data);
      toast.success("Reported successfully")
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePostApi = createAsyncThunk(
  "post/deletePostApi",
  async (
    data: DeletePost,
    { getState, rejectWithValue }
  ) => {
    const response = await postService.deletePost(data);
    return response;
  }
);


export const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    addPosts: (
      state: PostReduxState,
      action: PayloadAction<PostResponse[]>
    ) => {
      const posts = action.payload;
      postAdapter.setAll(state.posts, posts);
    },
    updatePosts: (
      state: PostReduxState,
      action: PayloadAction<PostResponse[]>
    ) => {
      const posts = action.payload;
      postAdapter.addMany(state.posts, posts);
    },

    clearPosts: (state, action) => {
      const task = action.payload;
      postAdapter.removeAll(state.posts);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPostApi.fulfilled, (state: PostReduxState, action) => {

        // const apiRes = action.payload.data.data;
        // state.posts.entities = {
        //   ...state.posts.entities,
        //   [apiRes.id]: {
        //     ...apiRes,
        //   },
        // };
      })
      // .addCase(getPostByIdApi.fulfilled, (state: PostReduxState, action) => {
      //   const apiRes = action.payload.data.data;
      //   state.posts.entities = {
      //     ...state.posts.entities,
      //     [apiRes.id]: {
      //       ...apiRes,
      //     },
      //   };
      // })
      // .addCase(
      //   doActionOnPostApi.fulfilled,
      //   (state: PostReduxState, actionPayload) => {
      //     const { postId, action } = actionPayload.meta.arg;
      //     const post = state.posts.entities[postId];
      //     console.log(post,"POSTTTTTT")
      //     if (post) {
      //       switch (action) {
      //         case POST_ACTION_LIKE:
      //           post.counts[action]++;
      //           post.isLiked = true;
      //           break;
      //         case POST_ACTION_UNLIKE:
      //           post.counts[POST_ACTION_LIKE]--;
      //           post.isLiked = false;
      //           break;
      //       }
      //     }
      //   }
      // )
      .addCase(
        getAllBookmarkApi.fulfilled,
        (state: PostReduxState, action) => {
          console.log(action.payload.data)
          state.bookmarks = action.payload.data.data.bookmarks;
        }
      )
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      })
      
    // .addCase(
    //   submitPollApi.fulfilled,
    //   (state: PostReduxState, actionPayload) => {
    //     const { postId, optionId } = actionPayload.meta.arg;
    //     const post = state.posts.entities[postId];
    //     const postMetadata = post.metadata as PostPollMetadata;
    //     if (postMetadata) {
    //       postMetadata.isAnswered = true;
    //     }
    //   }
    // );
  },
});

export const uploadFiles = createAsyncThunk(
  "post/uploadFilesApi",
  async (data: UploadFileRequest, { getState, rejectWithValue }) => {
    try {
      const response = await contentService.uploadFile(data);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const { addPosts, clearPosts, updatePosts } = postSlice.actions;

export default postSlice.reducer;
