import React from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import CommonCard from "../BothCommon/CommonCard";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNextClubApi } from "../../../slices/clubSlice";

const AllClubs = () => {
  const { clubs ,metadata} = useAppSelector((state) => state.clubs.clubs);
  const dispatch=useAppDispatch()
  const getMoreDataHandler=()=>{
    dispatch(getNextClubApi({}))
  }
  return (
    <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={clubs?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
    <div className="flex flex-wrap gap-2 mx-auto px-7 py-11">
      {clubs?.map((club, index) => (
        <Link key={club.id} to={`/tab/clubs/${club.id}`}>
          <CommonCard
            details={{
              title: club?.title,
              detail: club?.details,
              bannerImage: club?.bannerImage,
              members: club?.members,
              cardType: "club",
              isPrivate:club.isPrivate
            }}
          />
        </Link>
      ))}
    </div>
    </InfiniteScroll>
  );
};

export default AllClubs;
