import React from 'react'
import CrossIcon from '../../../assets/icons/CrossIcon'


const RewardsPopup = ({children,setPopupOpen}) => {
    const handleClick=()=>{
        setPopupOpen(false)
    }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className=" bg-lxgray-800 relative p-6 rounded shadow-lg max-w-lg w-full">
                    <button onClick={handleClick} className="absolute top-2 right-2 text-gray-500 p-2 hover:scale-75">
                       < CrossIcon/>
                    </button>
                    {children}
                </div>
            </div>
  )
}

export default RewardsPopup
