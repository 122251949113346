import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import ProfileInput from "../pages/profile/components/basic-components/ProfileInput";
import CommentCard from "./CommentCard";

import { useAppDispatch, useAppSelector } from "../config/hooks";

import { commentOnPostApi, getCommentsApi } from "../slices/commentSlice";
import { submitCommentThroughProfile } from "../slices/userSlice";

import SendButtonIcon from "../assets/icons/SendButtonIcon";

type FormValue = {
  comment: string;
};

const Comments = ({ userContent, parentId, comments, parent = "" }) => {
  const form = useForm<FormValue>();
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const { handleSubmit, control, reset } = form;
  const [loading, setLoading] = useState(true);

  const { id, authorType } = userContent;
  useEffect(() => {
    const fetchCommentsHandler = async () => {
      setLoading(true);
      try {
        await dispatch(
          getCommentsApi({
            postId: id,
            commentId: parentId,
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchCommentsHandler();
  }, [dispatch, id, parentId]);

  const onSubmit = (formData: FormValue) => {
    reset();
    dispatch(
      commentOnPostApi({
        postId: id,
        content: formData.comment,
        authorType: authorType,
        authorId: session.userId,
        parentId: parentId,
        id: "",
      })
    )
      .then((response) => {
        if (response) dispatch(submitCommentThroughProfile({ postId: id }));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProfileInput
                isComment={true}
                className={`h-[50px] border-2 border-lxgray-400 ${
                  parent === "article" && "bg-transparent"
                }`}
                placeholder={"Add comments"}
                title=""
                name="comment"
                value={value || ""}
                onChange={onChange}
                error={""}
                autoFocus={parent === "article" ? false : true}
              />
            )}
          />
          <button
            type="submit"
            className="absolute top-0 right-2 h-full flex items-center justify-center"
          >
            <SendButtonIcon />
          </button>
        </div>
      </form>
      {!loading && (
        <div className="space-y-4 mt-4">
          {comments &&
            comments.length > 0 &&
            comments.map((comment) => (
              <CommentCard
                comment={comment}
                key={comment.id}
                userContent={userContent}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Comments;
