import React from "react";

const AccordianDownArrow = ({width="23",height="14"}:{width?:string,height?:string}) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 23 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
          d="M21.5 2L11.5 12L1.5 2"
          stroke="white"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AccordianDownArrow;
