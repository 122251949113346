import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"; // Or use your preferred method of checking auth
import { useAppSelector } from "./hooks";

const PrivateRoute = ({ children }) => {
  const { session } = useAppSelector((state) => state.login);
  const location = useLocation();

  if (session !== null) {
    return children;
  }

  // Redirect to login with the current location
  return <Navigate to="/login-signup" state={{ from: location }} />;
};

export default PrivateRoute;