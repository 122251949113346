import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export const MessagePosition = {
  position: "bottom-right",
  style: {
    background: "white",
    color: "red",
  },
  autoClose: 2000,
};
export const articleErrorPosition = {
  position: "bottom-left",
  hideProgressBar: true,
  closeButton: false, // Hide the close icon
  icon: false,
  style: {
    background: "#C70039 ",
    color: "white",
  },
  autoClose: 2000,
};
