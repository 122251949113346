import { CreateEducationResponse } from "../../../models/user";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";

import EducationCard from "../components/EducationCard";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOtherUserDetailApi } from "../../../slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const UserEducation = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const userData = useAppSelector((state) => state.user);

  const { userEducation } = userData;

  const [otherUserDetails, setOtherUserDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data.userEducation);
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  if (profileId) {
    return (
      otherUserDetails && (
        <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
          <div className="w-[95%] mx-auto">
            <div className="w-full my-5">
              <h1 className="text-white text-xl font-bold">Education</h1>
            </div>

            <div className="flex flex-col gap-4">
              {otherUserDetails.map(
                (educationData: CreateEducationResponse, index: number) => (
                  <EducationCard
                    editEducation={false}
                    educationDetail={educationData}
                    key={index}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full my-5">
          <h1 className="text-white text-xl font-bold">Education</h1>
        </div>

        <div className="flex flex-col gap-4">
          {userEducation &&
            userEducation.map(
              (educationData: CreateEducationResponse, index: number) => (
                <EducationCard
                  editEducation={false}
                  educationDetail={educationData}
                  key={index}
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default UserEducation;
