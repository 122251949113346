import React from 'react'
import CrossIcon from '../assets/icons/CrossIcon';

const SharePopup = ({ visible, onClose,children }) => {
    if (!visible) return null;
    const handleSubmit=()=>{
        // if(selectedReason?.length==0){
        //     return
        }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]  ">
        
    <div className="flex  flex-col  gap-2 bg-lxgray-800 rounded-lg shadow-lg p-6  border border-golden relative w-1/2 ">
      <div  onClick={()=>onClose(false)} className='absolute top-3 right-3 hover:scale-110 cursor-pointer'>
        <CrossIcon width='14'/>
      </div>
        <h2 className="text-lg  text-white">Share with Connections</h2>
        <div>
            {children}
        </div>
      </div>
      
      {/* <button 
      onClick={handleSubmit}      
        className={`mt-4 px-8 py-2 text-sm  w-fit  text-white rounded hover:bg-white hover:text-golden`}
        >
        Submit
      </button> */}
      
    </div>
 
  )
}

export default SharePopup
