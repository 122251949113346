import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  AddCompanyEmployee,
  Company,
  CompanyEmployees,
  CompanyEmployeesResponse,
  CreateCompanyResponse,
  CreateNewCompanyRequest,
  DeleteCompanyRequest,
  FollowCompanyRequest,
  GetAllCompeniesResponse,
  GetCompanyDetailRequest,
  GetOtpForDomainVerify,
  GetOtpResponse,
  UpdateCompanyRequest,
  VerifiedDomainResponse,
  VerifyDomainRequest,
  VerifyOtpRequest,
} from "../models/company";

class CompanyService {
  getAllCompanies(): AxiosPromise<ApiResponse<GetAllCompeniesResponse>> {
    return apiClient.get(`/v1/companies`);
  }

  verifyDomain(
    domainVerifyReq: VerifyDomainRequest
  ): AxiosPromise<ApiResponse<VerifiedDomainResponse>> {
    return apiClient.post(`/v1/companies/authorize`, domainVerifyReq);
  }

  getOtpForDomainVerify(
    getOtp: GetOtpForDomainVerify
  ): AxiosPromise<ApiResponse<GetOtpResponse>> {
    return apiClient.post(`/v1/companies/auth/otp`, getOtp);
  }

  verifyDomainOtp(
    otpVerifyReq: VerifyOtpRequest
  ): AxiosPromise<ApiResponse<VerifiedDomainResponse>> {
    return apiClient.post(`/v1/companies/auth/otp/verify`, otpVerifyReq);
  }

  createCompany(
    newCompanyReq: CreateNewCompanyRequest
  ): AxiosPromise<ApiResponse<CreateCompanyResponse>> {
    return apiClient.post(`/v1/companies`, newCompanyReq);
  }

  updateCompany(
    updateCompanyReq: UpdateCompanyRequest
  ): AxiosPromise<ApiResponse<CreateCompanyResponse>> {
    return apiClient.put(
      `/v1/companies/${updateCompanyReq.id}`,
      updateCompanyReq
    );
  }

  addEmployee(
    data: AddCompanyEmployee
  ): AxiosPromise<ApiResponse<CompanyEmployees>> {
    return apiClient.post(
      `/v1/companies/${data.companyId}/employees`,
      data
    );
  }

  deleteCompany(
    deleteReq: DeleteCompanyRequest
  ): AxiosPromise<ApiResponse<GetAllCompeniesResponse>> {
    return apiClient.delete(`/v1/companies/${deleteReq.id}`)
  }

  getCompanyDetail(
    getDetail: GetCompanyDetailRequest
  ): AxiosPromise<ApiResponse<Company>> {
    return apiClient.get(`/v1/companies/${getDetail.id}`)
  }

  getAllEmployees(
    getDetail: GetCompanyDetailRequest
  ): AxiosPromise<ApiResponse<CompanyEmployeesResponse>> {
    return apiClient.get(`/v1/companies/${getDetail.id}/employees`)
  }

  removeEmployee(
    data: AddCompanyEmployee
  ): AxiosPromise<ApiResponse<CompanyEmployees>> {
    return apiClient.delete(
      `/v1/companies/${data.companyId}/employees/${data.userId}`
    );
  }

  updateEmployeeRole(
    data: AddCompanyEmployee
  ): AxiosPromise<ApiResponse<CompanyEmployees>> {
    return apiClient.patch(
      `/v1/companies/${data.companyId}/employees/${data.userId}`,
      data
    );
  }

  followCompany(
    data: FollowCompanyRequest
  ): AxiosPromise<ApiResponse<CompanyEmployees>> {
    return apiClient.post(
      `/v1/companies/${data.companyId}/followers`,
      data
    );
  }

  getCompanyByUrl(
    nextUrl: string
  ):
    AxiosPromise<ApiResponse<GetAllCompeniesResponse>> {
    return apiClient.get(nextUrl);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

}

const companyService = new CompanyService();
export default companyService;
