import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { useEffect, useMemo, useState } from "react";
import {
  getSurveyDetailsApi,
  submitSurveyAnswer,
} from "../../../../slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPostValidity } from "../../../../utils/dateUtils";
import EclipsVerticleDotsIcon from "../../../../assets/icons/EclipsVerticleDotsIcon";

import profile from "../../../../assets/images/Blank_Placeholders.png";
import moment from "moment";
import CustomOption from "../basic_components/CustomOption";
import { baseUrl, contentUrl } from "../../../../utils/contant";
import AlternateProfile from "../../../../components/AlternateProfile";

interface Answer {
  questionId: string;
  question: string;
  questionType: string;
  answer: string | string[];
}

const SurveyAnswer = () => {
  const { surveyId } = useParams();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.appConfig.designations);
  const session = useAppSelector((state) => state.login.session);
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [errorMsg, setErrorMsg] = useState({});
  const [answers, setAnswers] = useState({});
  const posts = useAppSelector((state) => state.user.userPosts.posts);

  useEffect(() => {
    getSurveyById();
  }, []);

  useEffect(() => {
    if (Object.keys(answers).length !== 0) {
      // for (const key in answers) {
      //   if (answers[key].questionType === "MULTIPLE") {
      //     if (answers[key].answer.length === 0) {
      //       setErrorMsg({ ...errorMsg, [key]: "Question is required" });
      //     } else {
      //       setErrorMsg({ ...errorMsg, [key]: "" });
      //     }
      //   }
      // }
      const error = surveyDetails.questions.reduce((acc, curr) => {
        if (!answers[curr.id]) {
          acc[curr.id] = "Question is required";
        } else if (
          answers[curr.id].answer.length === 0 &&
          (answers[curr.id].questionType === "SINGLE" ||
            answers[curr.id].questionType === "MULTIPLE")
        ) {
          acc[curr.id] = "Question is required";
        } else if (
          answers[curr.id].questionType === "STRING" &&
          !answers[curr.id].answer
        ) {
          acc[curr.id] = "Question is required";
        }
        return acc;
      }, {});

      setErrorMsg(error);
    }
  }, [answers]);

  const getSurveyById = async () => {
    const surveyResponse = await dispatch(
      getSurveyDetailsApi({ surveyId })
    ).then(unwrapResult);
    setSurveyDetails(surveyResponse.data.data);
    const answerData = {};
    const questions = surveyResponse.data.data.questions;
    const isAnswered = surveyResponse.data.data.responses;

    if (!isAnswered) {
      for (let index = 0; index < questions.length; index++) {
        const question = questions[index];
        answerData[question.id] = {
          questionId: question.id,
          question: question.question,
          questionType: question.questionType,
          answer: question.questionType === "STRING" ? "" : [],
        };
      }
      setAnswers(answerData);
    }
  };

  const post = useMemo(() => {
    return posts.find(
      (post: { postType: string; metadata: { surveyId: string } }) => {
        return (
          post.postType === "SURVEY" &&
          post.metadata.surveyId === surveyId.toString()
        );
      }
    );
  }, [posts, surveyId]);

  const {
    metadata: { validity },
    author: { picUrl, firstName, lastName, organization, designation },
    createdAt,
  } = post;

  const postRelativeTime = moment(createdAt).fromNow();
  const { daysRemaining, hoursRemaining } = getPostValidity(validity);

  const designantionData =
    !isNaN(designation) && designation
      ? options?.find((opt) => opt.Id === designation).Title
      : designation
      ? designation
      : "";

  const onChange = (
    questionId: string,
    selectedOption: string,
    question: string,
    questionType: string
  ) => {
    if (questionType === "SINGLE") {
      setAnswers({
        ...answers,
        [questionId]: {
          questionId,
          question,
          questionType: questionType,
          answer: [selectedOption],
        },
      });

      setErrorMsg({ ...errorMsg, [questionId]: "" });
    } else if (questionType === "MULTIPLE") {
      setAnswers(() => {
        return {
          ...answers,
          [questionId]: {
            questionId,
            question,
            questionType: questionType,
            answer:
              answers[questionId] &&
              answers[questionId]?.answer?.includes(selectedOption)
                ? answers[questionId].answer.filter(
                    (opt: string) => opt !== selectedOption
                  )
                : answers[questionId]
                ? [...answers[questionId].answer, selectedOption]
                : [selectedOption],
          },
        };
      });
    } else {
      setAnswers({
        ...answers,
        [questionId]: {
          questionId,
          question,
          questionType: questionType,
          answer: selectedOption,
        },
      });

      if (!selectedOption) {
        setErrorMsg({ ...errorMsg, [questionId]: "Question is required" });
      } else {
        setErrorMsg({ ...errorMsg, [questionId]: "" });
      }
    }
  };

  const validateForm = (arrayOfObjects: Answer[]) => {
    for (const question of arrayOfObjects) {
      if (question.questionType === "STRING" && question.answer === "") {
        return false;
      }
      if (
        ((question.questionType === "SINGLE" ||
          question.questionType === "MULTIPLE") &&
          !Array.isArray(question.answer)) ||
        question.answer.length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  const answerSurevyApihandler = async (arrayOfAnswers) => {
    const surveyAnswerResponse = await dispatch(
      submitSurveyAnswer({
        surveyId,
        userId: session.userId,
        answers: arrayOfAnswers,
      })
    ).then(unwrapResult);

    if (surveyAnswerResponse) {
      getSurveyById();
    }
  };

  const submitSurveyForm = () => {
    const arrayOfAnswers: Answer[] = Object.values(answers);
    const isValid =
      arrayOfAnswers.length > 0 ? validateForm(arrayOfAnswers) : false;

    const error = surveyDetails.questions.reduce((acc, curr) => {
      if (!answers[curr.id]) {
        acc[curr.id] = "Question is required";
      } else if (
        answers[curr.id].answer.length === 0 &&
        (answers[curr.id].questionType === "SINGLE" ||
          answers[curr.id].questionType === "MULTIPLE")
      ) {
        acc[curr.id] = "Question is required";
      } else if (
        answers[curr.id].questionType === "STRING" &&
        !answers[curr.id].answer
      ) {
        acc[curr.id] = "Question is required";
      }
      return acc;
    }, {});

    setErrorMsg(error);

    if (isValid) {
      answerSurevyApihandler(arrayOfAnswers);
    }
  };

  return surveyDetails ? (
    !surveyDetails.responses ? (
      <>
        <div className="bg-lxgray-700 mx-auto p-3">
          <div className="flex gap-4 items-start relative">
            <div className="w-[50px]">
              {picUrl ? (
                <div
                  className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full`}
                  style={{
                    backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
                  }}
                ></div>
              ) : (
                <div
                  // className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full`}
                  // style={{
                  //   backgroundImage: `url(${profile})`,
                  // }}
                >
                  <AlternateProfile firstName={firstName} lastName={lastName} width="[50px]" height="[50px]"/>
                </div>
              )}
            </div>
            <div className="text-start grow min-w-[70%]">
              <h1 className="text-lg text-white font-semibold leading-5">{`${firstName} ${lastName}`}</h1>
              <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
                <span className="text-start truncate">{`${designantionData} ${
                  designantionData && organization && "|"
                } ${organization}`}</span>
                <span className="text-end">{postRelativeTime}</span>
              </p>
            </div>

            {
              <div
                className="self-start ml-auto mr-0 cursor-pointer"
                // ref={parentRef}
                // onClick={() => togglePostModal()}
              >
                <EclipsVerticleDotsIcon />
              </div>
            }
            {/* {toggleModal && (
          <div
            className="w-[265px] absolute top-6 right-1 z-40 rounded-s-xl rounded-br-xl"
            ref={menuRef}
          >
            <div
              className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white tracking-tight text-start font-light  rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}
            >
              <h1 className="w-full  py-2 pl-3 cursor-pointer hover:opacity-90">
                Delete
              </h1>
              <h1 className="w-full py-2 pl-3 cursor-pointer hover:opacity-90">
                Bookmark
              </h1>
            </div>
          </div>
        )} */}
          </div>
          <div className="mt-5">
            <h1 className="text-white text-xl font-bold">
              {surveyDetails?.title}
            </h1>
            <div className="w-full">
              <div className="w-fit ml-auto mr-0">
                <span className="w-fit text-base text-golden font-semibold">
                  {daysRemaining > 0
                    ? `${daysRemaining} DAYS LEFT`
                    : hoursRemaining <= 0
                    ? ""
                    : `${hoursRemaining} HRS LEFT`}
                </span>
              </div>
            </div>
            <p className="text-white text-base text-start font-normal leading-none whitespace-pre-wrap">
              {surveyDetails?.description}
            </p>
            <div className="mt-3 space-y-3">
              {surveyDetails.questions.map(
                ({ id, question, questionType, options }) => (
                  <div key={id} className="bg-lxgray-800 px-3 py-5">
                    <div>
                      <h1 className="text-base text-white font-semibold pl-2">
                        {question}
                      </h1>
                    </div>

                    {(questionType === "SINGLE" ||
                      questionType === "MULTIPLE") && (
                      <div className="space-y-2 mt-2">
                        {options.map((option: string, index: number) => (
                          <CustomOption
                            key={`${id}_${index}`}
                            option={option}
                            checked={answers[id]?.answer?.includes(option)}
                            questionId={id}
                            question={question}
                            questionType={questionType}
                            onChange={onChange}
                          />
                        ))}
                        <p className="text-red-900 text-base leading-none">
                          {errorMsg[id]}
                        </p>
                      </div>
                    )}

                    {questionType === "STRING" && (
                      <>
                        <div className="relative mt-2">
                          <textarea
                            className={`h-auto w-full text-lxgray-100
                      bg-transparent outline-none px-4 py-2 text-base ring-0 focus:ring-0
                      focus:outline-none border-2 border-lxgray-400 rounded-xl overflow-auto scrollbar-hidden placeholder:text-lxgray-400`}
                            style={{ resize: "none" }}
                            rows={4}
                            name="STRING"
                            value={answers[id]?.answer}
                            title=""
                            maxLength={140}
                            onChange={(e) =>
                              onChange(
                                id,
                                e.target.value,
                                question,
                                questionType
                              )
                            }
                            placeholder="Write text here"
                          />
                          <p
                            className={`absolute right-4 bottom-3 text-base text-lxgray-400 font-normal`}
                          >
                            {answers[id]?.answer?.length || "0"} / {140}
                          </p>
                        </div>
                        <p className="text-red-900 text-base leading-none">
                          {errorMsg[id]}
                        </p>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <button
          className="w-full py-4 bg-golden rounded-lg text-center text-white text-xl font-bold tracking-wider mt-2 active:scale-95"
          onClick={submitSurveyForm}
        >
          Submit
        </button>
      </>
    ) : (
      <>
        <div className="bg-lxgray-700 mx-auto p-3">
          <div className="flex gap-4 items-start relative">
            <div className="w-[50px]">
              {picUrl ? (
                <div
                  className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full`}
                  style={{
                    backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
                  }}
                ></div>
              ) : (
                <div
                  className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full`}
                  style={{
                    backgroundImage: `url(${profile})`,
                  }}
                ></div>
              )}
            </div>
            <div className="text-start grow min-w-[70%]">
              <h1 className="text-lg text-white font-semibold leading-5">{`${firstName} ${lastName}`}</h1>
              <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
                <span className="text-start truncate">{`${designantionData} ${
                  designantionData && organization && "|"
                } ${organization}`}</span>
                <span className="text-end">{postRelativeTime}</span>
              </p>
            </div>

            {
              <div
                className="self-start ml-auto mr-0 cursor-pointer"
                // ref={parentRef}
                // onClick={() => togglePostModal()}
              >
                <EclipsVerticleDotsIcon />
              </div>
            }
            {/* {toggleModal && (
          <div
            className="w-[265px] absolute top-6 right-1 z-40 rounded-s-xl rounded-br-xl"
            ref={menuRef}
          >
            <div
              className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white tracking-tight text-start font-light  rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}
            >
              <h1 className="w-full  py-2 pl-3 cursor-pointer hover:opacity-90">
                Delete
              </h1>
              <h1 className="w-full py-2 pl-3 cursor-pointer hover:opacity-90">
                Bookmark
              </h1>
            </div>
          </div>
        )} */}
          </div>
          <div className="mt-5">
            <h1 className="text-white text-xl font-bold">
              {surveyDetails?.title}
            </h1>
            <div className="w-full">
              <div className="w-fit ml-auto mr-0">
                <span className="w-fit text-base text-golden font-semibold">
                  {daysRemaining > 0
                    ? `${daysRemaining} DAYS LEFT`
                    : hoursRemaining <= 0
                    ? ""
                    : `${hoursRemaining} HRS LEFT`}
                </span>
              </div>
            </div>
            <p className="text-white text-base text-start font-normal leading-none whitespace-pre-wrap">
              {surveyDetails?.description}
            </p>
            <div className="mt-3 space-y-3">
              {surveyDetails.questions.map(
                ({ id, question, questionType, options }) => (
                  <div key={id} className="bg-lxgray-800 px-3 py-5">
                    <div>
                      <h1 className="text-base text-white font-semibold pl-2">
                        {question}
                      </h1>
                    </div>

                    {(questionType === "SINGLE" ||
                      questionType === "MULTIPLE") && (
                      <div className="space-y-2 mt-2">
                        {options.map((option: string, index: number) => (
                          <ProgressBar
                            option={option}
                            key={`${id}_${index}`}
                            percentage={
                              surveyDetails.responses.aggregate[id][option] &&
                              Math.floor(
                                surveyDetails.responses.aggregate[id][option]
                              )
                            }
                          />
                        ))}
                      </div>
                    )}

                    {questionType === "STRING" &&
                      surveyDetails.responses.aggregate[id].responses.map(
                        (ans: string, index: number) => (
                          <div className="mt-2 px-2" key={`${id}_ans_${index}`}>
                            <div className="w-full bg-lxgray-700 rounded-lg border-l-[6px] border-l-golden p-2 whitespace-pre-wrap">
                              <p className="text-sm text-white font-normal tracking-wider">
                                {ans}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </>
    )
  ) : null;
};

const ProgressBar = ({ option, percentage }) => {
  console.log("percentage :>> ", percentage);
  return (
    <div className="px-2 space-y-1">
      <div className="flex items-center justify-between">
        <h1 className="text-base text-white font-normal">{option}</h1>
        <p className="text-sm text-golden">
          {percentage ? `${percentage} %` : "0 %"}
        </p>
      </div>
      <div className="bg-white h-4 rounded-full">
        {percentage && (
          <div
            className={`h-full bg-golden rounded-full`}
            style={{ width: `${percentage}%` }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default SurveyAnswer;
