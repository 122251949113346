import React from 'react'
import Calendar from '../../../assets/icons/Calendar'
import { formatDate } from './DateFormat'

const DateCard = ({newDate}) => {
  // console.log(newDate)
  return (
    <div className='flex items-center bg-lxred-100 w-fit px-4 py-1 rounded'>
      <Calendar/>
      <span className='ml-2 text-sm font-semibold'>{formatDate(newDate,"UTC")} IST</span>
    </div>
  )
}

export default DateCard
