import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import {  useSelector } from 'react-redux';
import { RootState } from '../../config/store';

import background from "../../../src/assets/images/Blank_Placeholders.png"
import EditIcon2 from '../../assets/icons/EditIcon2';
import { baseUrl, contentUrl } from '../../utils/contant';
import Dropdown from '../job/components/Dropdown';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { createCompany } from '../../slices/companySlice';
import { useLocation, useNavigate } from 'react-router-dom';
import uploadContent from '../../config/UploadContent';



const CreateCompany = () => {
    const navigate=useNavigate()
    const inputRef = useRef(null);
    const location=useLocation()
    console.log(location)
    const inputProfileRef = useRef(null);
    const dispatch=useAppDispatch()
    const { industries,companyType,employeeSize} = useAppSelector((state) => state.appConfig);

    const { userId ,token} = useSelector((state: RootState) => state.login.session);
    const [banImage,setbanImage]=useState("")
    const [proImage,setProImage]=useState("")

    const {register,setValue,handleSubmit,formState:{errors},watch,control}=useForm()
    const onSubmit = async (data) => {
        console.log(data);
         dispatch(createCompany({domain:location?.state.domain,emailId:location?.state.emailId,requestId:location?.state.requestId,...data,industry:data.industry,"authorization": true, pageName:"LinkCxo Company Page"}))
         navigate("/tab/company")
        }
        
      const handleBannerChange = (event) => {
        // console.log(event)
        const selectedFile = event.target.files;
        console.log(selectedFile);
    
        uploadContent.uploadCompanyContent(userId, selectedFile, (response) => {
          //console.log(response,jobId);
          const { contentType, name, size, url } = response[0];
          const attachment = {
            contentType: contentType,
            name: name,
            size: size,
            url: url,
          };
          console.log(attachment);
          setbanImage(attachment.url)
          setValue("bannerImage",(attachment.url));
         
        });
      };
      

      const handleProfileChange = (event) => {
        // console.log(event)
        const selectedFile = event.target.files;
        console.log(selectedFile);
    
        uploadContent.uploadCompanyContent(userId, selectedFile, (response) => {
          //console.log(response,jobId);
          const { contentType, name, size, url } = response[0];
          const attachment = {
            contentType: contentType,
            name: name,
            size: size,
            url: url,
          };
          console.log(attachment);
          setProImage(attachment.url)
          setValue("profileImage",(attachment.url));
         
        });
      };
    return (
        <div className="mx-12 p-8 dark:bg-lxgray-700 mt-1 rounded-lg hit">
        <h1 className="text-white text-lg font-semibold">Create Company</h1>
        <div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-1">
            <div className='relative'> 
                <img className="w-full h-36 object-cover rounded" src={banImage?`${baseUrl}/${contentUrl}/${banImage}?t=${token}`:background} alt="BannerImage" />
                <input
                    ref={inputRef}
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
                    accept=".jpg,.jpeg,.png"
                    // placeholder={<UploadIcon/>}
                    type="file"
                    onChange={handleBannerChange}
                    //
                    />
                <div
                  onClick={() => inputRef?.current?.click()}
                  {...register("bannerImage",{required:true})}
                  className="rounded w-fit absolute top-1 right-1 bg-white p-1"
                  >     
                    <EditIcon2/>
                </div> 
                {errors.bannerImage && <span className="text-red-500">Upload Banner Image</span>}  
            </div>

            <div className='relative w-fit  mx-auto -top-16 '> 
                <img className="w-32 h-32 object-cover rounded-full" src={proImage?`${baseUrl}/${contentUrl}/${proImage}?t=${token}`:background} alt="ProfileImage" />
                <input
                    ref={inputProfileRef}
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
                    accept=".jpg,.jpeg,.png"
                    // placeholder={<UploadIcon/>}
                    type="file"
                    onChange={handleProfileChange}
                    //
                    />
                <div
                  onClick={() => inputProfileRef?.current?.click()}
                  {...register("profileImage",{required:true})}
                  className="rounded w-fit absolute right-0 bottom-1 bg-golden p-1"
                  >     
                    <EditIcon2/>
                </div> 
                
            </div>
            <div className='mx-auto'>
                {errors.profileImage && <span className="text-red-500 mx-auto relative -top-10">Upload Profile Image</span>}  
            </div>

            <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Company Name *
              </label>
              <input
                type="text"
                placeholder="Enter your Company Name"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("title",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter a valid Company Name</span>}
            </div>

            <div className="text-lxgray-300 flex flex-col mb-4 relative">
              <label htmlFor="" className="text-sm p-1">
                Description *
              </label>
              <textarea
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
                rows={5}
                placeholder="Enter Here"
                maxLength={2600}
                {...register("description",{required:true,maxLength:2600})}
                // required
              ></textarea>
                {errors.description && errors.description.type === "required" && (
                <span className="text-red-500">Company details are required</span>
                )}
                {errors.description && errors.description.type === "maxLength" && (
                <span className="text-red-500">Maximum 2600 characters allowed</span>
                )}
            </div>

            <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Company Tagline *
              </label>
              <input
                type="text"
                placeholder="Enter your Company Tagline"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("tagline",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter a valid Tagline</span>}
            </div>

            {/* <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Company Domain *
              </label>
              <input
                type="text"
                placeholder="Enter your Company Website"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("domain",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter a valid Domain</span>}
            </div> */}

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Industry *
              </label>
              {/* <div className="text-white">
                <Dropdown
                  name="industry"
                  control={control}
                  defaultValue={[]}
                  placeholder={"Choose The Relevant Industry"}
                  options={industries.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Name };
                  })}
                />
              </div> */}
              <div className="text-white">
                <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('industry',{required:true})}>
                <option value="" disabled>Select</option>
                {industries.map((ele,i)=><option key={`${i}ind`} value={ele.Name}>{ele.Name}</option>)}
                </select>
              </div>
                {errors.industry && <span className="text-red-500">Industry is required</span>}
            </div>

            <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Head Office Location *
              </label>
              <input
                type="text"
                placeholder="Enter your Company location"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("location",{required:true})}
              />
                {errors.location && <span className="text-red-500">Enter a valid location</span>}
            </div>

            <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Company Type*
              </label>
              <div className="text-white">
                <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('registrationType',{required:true})}>
                <option value="" disabled>Select</option>
                {companyType.map((ele,i)=><option key={`${i}registrationType`} value={ele.Title}>{ele.Title}</option>)}
                </select>
              </div>
                {errors.registrationType && <span className="text-red-500">Company Type is required</span>}
            </div>

            <div className=" flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Employee Size*
              </label>
              <div className="text-white">
                <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue="" {...register('size',{required:true,setValueAs:(value)=>parseInt(value)})}>
                <option value="" disabled>Select</option>
                {employeeSize.map((ele,i)=><option key={`${i}size`} value={ele.Title}>{ele.Title}</option>)}
                </select>
              </div>
                {errors.size && <span className="text-red-500">Company Size is required</span>}
            </div>

            <button className='text-white font-semibold bg-golden px-8 py-2 rounded w-fit'>Create</button>


        </form>
        </div>
        </div>
    );
}

export default CreateCompany
