import { useState } from "react";

import CheckedIcon from "../assets/icons/CheckedIcon";

import { useAppDispatch } from "../config/hooks";
import { submitPollApi } from "../slices/postSlice";
import { getPostValidity } from "../utils/dateUtils";

const checkBoxColor = ["#8D61F1", "#FEB73E", "#1AD598", "#48AEFD", "#FE7F0A"];

const PollCard = ({ userContent }) => {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [toggler, setToggler] = useState(null);
  const {
    metadata: { question, options, validity, isAnswered },
    id,
  } = userContent;
  const { daysRemaining, hoursRemaining } = getPostValidity(validity);

  const toggleCheckBox = (toggledOptionId: number) => {
    if (isChecked && toggledOptionId === toggler) {
      setIsChecked(false);
    } else if (isChecked && toggledOptionId !== toggler) {
      setToggler(toggledOptionId);
    } else {
      setIsChecked(true);
      setToggler(toggledOptionId);
    }
    dispatch(
      submitPollApi({ postId: id, optionId: toggledOptionId.toString() })
    );
  };

  return isAnswered || (hoursRemaining <= 0 && daysRemaining <= 0) ? (
    <div>
      <ClosePollView options={options} question={question} />
    </div>
  ) : (
    <div>
      <div className="flex items-end justify-between  gap-4 ">
        <h1 className="text-xl text-white font-semibold">{question}</h1>
        <span className="text-xs text-golden font-semibold text-nowrap ">
          {daysRemaining > 0
            ? `${daysRemaining} DAYS LEFT`
            : hoursRemaining <= 0
            ? ""
            : `${hoursRemaining} HRS LEFT`}
        </span>
      </div>
      <div className="mt-3 space-y-4">
        {options &&
          options.map((opt, index) => (
            <div className="flex gap-3" key={opt.optionId}>
              <div
                id={opt.optionId}
                className={`w-[23px] h-[23px] rounded-lg border-2 flex items-center justify-center cursor-pointer `}
                style={{
                  borderColor: checkBoxColor[index],
                  backgroundColor:
                    isChecked && toggler === opt.optionId
                      ? checkBoxColor[index]
                      : "",
                }}
                onClick={() => toggleCheckBox(opt.optionId)}
              >
                {isChecked && toggler === opt.optionId && (
                  <CheckedIcon width="19" height="15" />
                )}
              </div>
              <label className="text-base text-white font-normal">
                {opt.option}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};
export default PollCard;

const ClosePollView = ({ options, question }) => {
  const totalVotes = options.reduce((acc: number, curr: { vote: number }) => {
    return acc + curr.vote;
  }, 0);

  const getPer = (v1: number, totalVotes: number): number => {
    if (totalVotes == 0) {
      return 0;
    }
    const percentage = (v1 / totalVotes) * 100;
    return Math.floor(percentage);
  };

  return (
    <div className="">
      <h1 className="text-xl text-white font-semibold">{question}</h1>
      {options.length === 2 && (
        <div className="flex items-center justify-center relative">
          <CircleProgressBar
            percentage={getPer(options[0].vote, totalVotes)}
            color={checkBoxColor[0]}
          />

          <div className="absolute ">
            <CircleProgressBar
              percentage={getPer(options[1].vote, totalVotes)}
              width="200"
              height="200"
              radius={52}
              color={checkBoxColor[1]}
              innerCircle={40}
              totalVotes={totalVotes}
            />
          </div>
        </div>
      )}
      {options.length === 3 && (
        <div className="flex items-center justify-center relative">
          <CircleProgressBar
            percentage={getPer(options[0].vote, totalVotes)}
            color={checkBoxColor[0]}
          />

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[1].vote, totalVotes)}
              width="200"
              height="200"
              radius={67}
              color={checkBoxColor[1]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[2].vote, totalVotes)}
              width="200"
              height="200"
              radius={52}
              color={checkBoxColor[2]}
              innerCircle={40}
              totalVotes={totalVotes}
            />
          </div>
        </div>
      )}

      {options.length === 4 && (
        <div className="flex items-center justify-center relative">
          <CircleProgressBar
            percentage={getPer(options[0].vote, totalVotes)}
            width="250"
            height="250"
            radius={97}
            color={checkBoxColor[0]}
          />

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[1].vote, totalVotes)}
              width="200"
              height="200"
              radius={82}
              color={checkBoxColor[1]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[2].vote, totalVotes)}
              width="200"
              height="200"
              radius={67}
              color={checkBoxColor[2]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[3].vote, totalVotes)}
              width="200"
              height="200"
              radius={52}
              color={checkBoxColor[3]}
              innerCircle={40}
              totalVotes={totalVotes}
            />
          </div>
        </div>
      )}

      {options.length === 5 && (
        <div className="flex items-center justify-center relative">
          <CircleProgressBar
            percentage={getPer(options[0].vote, totalVotes)}
            // percentage={getPer(2, 5)}
            color={checkBoxColor[0]}
          />

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[1].vote, totalVotes)}
              width="200"
              height="200"
              radius={97}
              color={checkBoxColor[1]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[2].vote, totalVotes)}
              width="200"
              height="200"
              radius={82}
              color={checkBoxColor[2]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[3].vote, totalVotes)}
              width="200"
              height="200"
              radius={67}
              color={checkBoxColor[3]}
            />
          </div>

          <div className="absolute">
            <CircleProgressBar
              percentage={getPer(options[4].vote, totalVotes)}
              width="200"
              height="200"
              radius={52}
              color={checkBoxColor[4]}
              innerCircle={40}
              totalVotes={totalVotes}
            />
          </div>
        </div>
      )}

      <div className="space-y-4">
        {options &&
          options.map(
            (
              opt: { optionId: number; option: string; vote: number },
              index: number
            ) => (
              <div className="space-y-1" key={opt.optionId}>
                <div className="flex items-center justify-between">
                  <h1 className="text-base text-lxgray-200 font-normal">
                    {opt.option}
                  </h1>
                  <p className="text-base space-x-1">
                    <span className="text-golden">{`${getPer(
                      opt.vote,
                      totalVotes
                    )}% `}</span>
                    <span className="text-lxgray-400 ">{`( ${opt.vote} ${
                      opt.vote <= 1 ? "Vote" : "Votes"
                    } )`}</span>
                  </p>
                </div>
                <div className="bg-[#D9D9D9] w-full rounded-full h-[12px] overflow-hidden border-transparent relative">
                  <div
                    className="h-full rounded-full border-transparent absolute top-0 left-0 animate-animateWidth"
                    style={{
                      width: `${getPer(opt.vote, totalVotes)}%`,
                      backgroundColor:
                        getPer(opt.vote, totalVotes) !== 0 &&
                        checkBoxColor[index],
                    }}
                  ></div>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

const CircleProgressBar = ({
  percentage,
  width = "250",
  height = "250",
  radius = 112,
  color,
  innerCircle = 0,
  totalVotes = null,
}) => {
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100; // here 22 is percentage
  return (
    <div className="">
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <circle
          cx={Number(width) / 2}
          cy={Number(height) / 2}
          strokeWidth={"12px"}
          r={radius}
          className="fill-lxgray-600 stroke-lxgray-600"
        />
        <circle
          cx={Number(width) / 2}
          cy={Number(height) / 2}
          strokeWidth={"12px"}
          r={radius}
          className={`circle-progress fill-none`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            stroke: color,
          }}
          transform={`rotate(-90 ${Number(width) / 2} ${Number(height) / 2})`}
        />
        <circle
          cx={Number(width) / 2}
          cy={Number(height) / 2}
          strokeWidth={"12px"}
          r={innerCircle}
          className={`fill-lxgray-800`}
        />
        <g
          transform={`translate(${Number(width) / 2}, ${Number(height) / 2})`}
          textAnchor="middle"
        >
          <text dy="-0.2em" fill="#DADADA" className="text-lg font-bold">
            {totalVotes}
          </text>
          <text dy="1em" fill="#DADADA" className="text-xs font-semibold">
            Total Votes
          </text>
        </g>
      </svg>
    </div>
  );
};
